import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import {
  AlertInfo as AlertInfoComponent,
  FormikTextarea,
  Switch as SwitchComponent,
} from "@ampeersenergy/ampeers-ui-components";

import {
  Column,
  FlexRawSelect,
  InlineCheckbox as InlineCheckboxComponent,
  Row,
  Select,
  SubTitleWithButton as SubTitleWithButtonComponent,
} from "../style";

import { ProtocolOptions } from "../shared-select-options";
import { LocalUnit, PropertyFormValue, TimeseriesFormValues } from "../types";
import { UnitsForm } from "../units-form";
import { findValue } from "../../helpers/object.utils";

const AlertInfo = styled(AlertInfoComponent)`
  ul {
    li {
      line-height: 1.4;
    }
  }
`;

const TextAreaInput = styled(FormikTextarea)`
  textarea {
    min-height: 0px;
  }
`;

const SubTitleWithButton = styled(SubTitleWithButtonComponent)`
  margin-top: 24px;
  margin-bottom: 0;
`;

const Switch = styled(SwitchComponent)`
  input:not(:checked) + .label {
    opacity: 1;
  }
`;

const InlineCheckbox = styled(InlineCheckboxComponent)`
  align-self: center;
`;

export interface TimeseriesFormProps {
  isEditing?: boolean;
  units: LocalUnit[];
  flowType: "read" | "write";
  identifier?: string;
}

export const TimeseriesForm: React.FC<TimeseriesFormProps> = ({
  isEditing,
  units,
  flowType,
  identifier,
}) => {
  const { values, setFieldValue } = useFormikContext<TimeseriesFormValues>();

  const accessor = identifier
    ? findValue<TimeseriesFormValues>(identifier, values)
    : values;

  const timeseriesTypeReference = flowType === "write" ? "target" : "source";

  const onTimeseriesTypeChange = useCallback(
    (value: "read" | "write") => {
      switch (value) {
        case "read":
          setFieldValue(identifier ? `${identifier}.target` : "target", {
            type: "none",
          });
          break;
        case "write":
          setFieldValue(identifier ? `${identifier}.source` : "source", {
            ...accessor.source,
            timezone: accessor.source.timezone ?? "UTC",
          });
          setFieldValue(identifier ? `${identifier}.target` : "target", {
            type: "district",
            protocol: accessor.target.protocol || accessor.source.protocol,
            externalId:
              accessor.target.externalId || accessor.source.externalId,
            additionalExternalId:
              (accessor.target.additionalExternalId ||
                accessor.source.additionalExternalId) ??
              null,
            unit: (accessor.target.unit || accessor.source.unit) ?? null,
            timezone: accessor.source.timezone ?? "UTC",
          });
          break;
        default:
          return;
      }
    },
    [accessor, identifier, setFieldValue]
  );

  const onTimeseriesSourceTypeChange = useCallback(
    (value: string) => {
      const refProp = identifier
        ? findValue<PropertyFormValue>(
            identifier.split(".").slice(0, 1).join("."),
            values
          )
        : undefined;
      setFieldValue(
        identifier
          ? `${identifier}.${timeseriesTypeReference}`
          : timeseriesTypeReference,
        {
          ...accessor[timeseriesTypeReference],
          type: value,
          unit:
            accessor[timeseriesTypeReference]?.unit ??
            refProp?.unit ??
            accessor.unit ??
            null,
        }
      );
    },
    [setFieldValue, identifier, accessor, timeseriesTypeReference, values]
  );

  if (!accessor) return null;

  return (
    <>
      <SubTitleWithButton $underlined>
        {flowType === "read" ? "Quelle" : "Target"}
        {flowType === "read" &&
          accessor?.reference?.propertyKey !== "operatingStatus" && (
            <Switch
              disabled={!isEditing}
              checked={accessor.target.type !== "none"}
              tinted
              activeColor={"#6eb0fa"}
              passiveColor={"#ff9b00"}
              onChange={() => {
                if (isEditing) {
                  onTimeseriesTypeChange(
                    accessor.target.type !== "none" ? "read" : "write"
                  );
                }
              }}
            >
              {accessor.target.type !== "none" ? "Read & Write" : "Read"}
            </Switch>
          )}
      </SubTitleWithButton>
      {accessor[timeseriesTypeReference].type === "district" &&
        flowType === "read" &&
        isEditing && (
          <AlertInfo title="Hinweis">
            {
              <>
                <strong>isInverted</strong>
                <br />
                isInverted gibt an, ob das Messsignal mit umgekehrtem Vorzeichen
                vom Quartier gesendet wird und vor Weiterverarbeitung per
                Vorzeichenwechsel verändert werden muss.
              </>
            }
          </AlertInfo>
        )}
      <Row flex={0}>
        <FlexRawSelect
          id={`${
            identifier ? `${identifier}.` : ""
          }${timeseriesTypeReference}.type`}
          name={`${
            identifier ? `${identifier}.` : ""
          }${timeseriesTypeReference}.type`}
          label="Typ *"
          isEditing={isEditing}
          value={accessor[timeseriesTypeReference].type}
          onChange={(e) => onTimeseriesSourceTypeChange(e.target.value)}
          disabled={accessor?.reference?.propertyKey === "operatingStatus"}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          {timeseriesTypeReference === "source" && (
            <option value="inference">Inference</option>
          )}
          <option value="district">District</option>
        </FlexRawSelect>
        {accessor[timeseriesTypeReference].type === "district" && (
          <Column>
            <Row>
              <Select
                flex={1}
                id={`${
                  identifier ? `${identifier}.` : ""
                }${timeseriesTypeReference}.protocol`}
                name={`${
                  identifier ? `${identifier}.` : ""
                }${timeseriesTypeReference}.protocol`}
                label={`Protokoll${
                  accessor.source.type === "district" ? " *" : ""
                }`}
                isEditing={isEditing}
              >
                <ProtocolOptions />
              </Select>
              <UnitsForm
                identifier={`${
                  identifier ? `${identifier}.` : ""
                }${timeseriesTypeReference}.unit`}
                label="Unit"
                isEditing={isEditing}
                units={(units ?? []).map((unit) => unit.key)}
              />
              {timeseriesTypeReference === "source" &&
                accessor.source.type === "district" && (
                  <InlineCheckbox
                    $margin="14px 0 0 0"
                    id={`${
                      identifier ? `${identifier}.` : ""
                    }${timeseriesTypeReference}.isInverted`}
                    key={`${
                      identifier ? `${identifier}.` : ""
                    }${timeseriesTypeReference}.isInverted`}
                    label="isInverted"
                    checked={!!accessor.source.isInverted}
                    onChange={(v) => {
                      setFieldValue(
                        `${
                          identifier ? `${identifier}.` : ""
                        }${timeseriesTypeReference}.isInverted`,
                        true
                      );
                    }}
                    disabled={!isEditing}
                  />
                )}
            </Row>
          </Column>
        )}
      </Row>
      {accessor[timeseriesTypeReference].type === "district" && (
        <>
          <TextAreaInput
            id={`${
              identifier ? `${identifier}.` : ""
            }${timeseriesTypeReference}.externalId`}
            name={`${
              identifier ? `${identifier}.` : ""
            }${timeseriesTypeReference}.externalId`}
            label={`External ID${
              accessor.source.type === "district" ? " *" : ""
            }`}
            isEditing={isEditing}
            rows={2}
          />
          <TextAreaInput
            id={`${
              identifier ? `${identifier}.` : ""
            }${timeseriesTypeReference}.additionalExternalId`}
            name={`${
              identifier ? `${identifier}.` : ""
            }${timeseriesTypeReference}.additionalExternalId`}
            label="Additional External Information"
            isEditing={isEditing}
            rows={2}
          />
        </>
      )}
    </>
  );
};
