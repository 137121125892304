import React, { useMemo } from "react";
import { useFormikContext } from "formik";

import { arrayToObject } from "../../helpers/array.utils";

import { ExtendedTimeseries, LocalAsset, TimeseriesFormValues } from "../types";
import {
  InferenceResultColumn,
  InferenceResultElement,
  InferenceResultPlus,
  InferenceResultWrapper,
} from "./style";
import { Column } from "../style";

export interface FormattedInferenceProps {
  timeseries: ExtendedTimeseries[];
  assets: LocalAsset[];
  inference: "default" | "active";
}

export const FormattedInference: React.FC<FormattedInferenceProps> = ({
  timeseries,
  assets,
  inference,
}) => {
  const { values } = useFormikContext<TimeseriesFormValues>();

  const timeseriesKeyMap = useMemo(
    () =>
      arrayToObject<
        ExtendedTimeseries,
        keyof ExtendedTimeseries,
        ExtendedTimeseries
      >(timeseries, "uuid"),
    [timeseries]
  );

  const assetKeyMap = useMemo(
    () =>
      arrayToObject<LocalAsset, keyof LocalAsset, LocalAsset>(assets, "key"),
    [assets]
  );

  const inferenceValues = values.inference?.[inference];

  if (!inferenceValues) {
    return null;
  }

  return (
    <InferenceResultWrapper>
      <InferenceResultColumn>
        <InferenceResultElement>
          <strong>{values.refTimeseriesProp?.[0]?.key}</strong>
        </InferenceResultElement>
        <InferenceResultPlus>=</InferenceResultPlus>
      </InferenceResultColumn>
      <InferenceResultColumn>
        {inferenceValues.inputs.map((input, index) => {
          const inputTimeseries = timeseriesKeyMap[input.timeseriesId];
          const asset = inputTimeseries?.reference.entityKey
            ? assetKeyMap[inputTimeseries.reference.entityKey]
            : undefined;

          return (
            <>
              <InferenceResultElement>
                {input.factor === -1 && (
                  <InferenceResultPlus>-</InferenceResultPlus>
                )}
                {input.factor !== -1 && index > 0 && (
                  <InferenceResultPlus>+</InferenceResultPlus>
                )}
                <Column>
                  <strong>{inputTimeseries?.refTimeseriesProp[0]?.key}</strong>
                  <span>({asset?.name})</span>
                </Column>
              </InferenceResultElement>
              {input.factor !== 1 && input.factor !== -1 ? (
                <>
                  <span>*</span>
                  <span>{input.factor}</span>
                </>
              ) : null}
            </>
          );
        })}
      </InferenceResultColumn>
    </InferenceResultWrapper>
  );
};
