import React, { Dispatch, SetStateAction, useState } from "react";
import styled, { useTheme } from "styled-components";
import {
  Button,
  ButtonGroup,
  ButtonIcon,
  Icons,
  Modal,
  Select,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import {
  DropdownDividerItem,
  DropdownItem,
  DropdownProps,
  PopOverItem,
} from "./dropdown";
import { DropdownButton } from "./dropdown-button";
import { DropdownCheckbox, DropdownCheckboxItem } from "./dropdown-checkbox";
import { useTimeseriesFilters } from "../hooks";
import {
  FilterState,
  LOCAL_STORAGE_FILTER_IDENTIFIER,
  getSession,
} from "../services/session.service";

const ButtonGroupLeft = styled.div`
  display: flex;

  button {
    margin-left: 4px;
  }
`;

const ButtonGroupRight = styled.div`
  display: flex;

  button {
    margin-right: 4px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PopOverItemWithDeleteButton = styled(PopOverItem)`
  justify-content: space-between;
  align-items: center;
  svg {
    margin: 0;
  }
`;

export interface TimeseriesFiltersProps {
  view: "table" | "graph";
  setView: Dispatch<SetStateAction<"table" | "graph">>;
  columnItems: (DropdownDividerItem | DropdownCheckboxItem)[];
  onColumnItemClick: DropdownProps["onItemClick"];
  exportItems: DropdownItem[];
  onExportItemClick: DropdownProps["onItemClick"];
  onGenerate: () => void;
  deleteFilter: ReturnType<typeof useTimeseriesFilters>["deleteFilter"];
  savedFilters: ReturnType<typeof useTimeseriesFilters>["savedFilters"];
  filterChanged: ReturnType<typeof useTimeseriesFilters>["filterChanged"];
  resetFilter: ReturnType<typeof useTimeseriesFilters>["resetFilter"];
  saveFilter: ReturnType<typeof useTimeseriesFilters>["saveFilter"];
}

export const TimeseriesTableActions: React.FC<TimeseriesFiltersProps> = ({
  view,
  setView,
  columnItems,
  onColumnItemClick,
  exportItems,
  onExportItemClick,
  onGenerate,
  deleteFilter,
  savedFilters,
  filterChanged,
  resetFilter,
  saveFilter,
  children,
}) => {
  const theme = useTheme() as Theme;
  const [openId, setOpenId] = useState<0 | 1 | null>(null);
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false);
  const [filterIndex, setFilterIndex] = useState(
    getSession<FilterState>(LOCAL_STORAGE_FILTER_IDENTIFIER)
      ?.activeTimeseriesFilter ?? -1
  );

  return (
    <>
      <Wrapper>
        <Buttons>
          <ButtonGroupRight>{children}</ButtonGroupRight>
          <ButtonGroupLeft>
            {filterChanged ? (
              <ButtonGroup>
                <Button onClick={() => setSaveFilterModalOpen(true)}>
                  Filter Speichern
                </Button>
                <Button
                  onClick={() => resetFilter()}
                  customTheme={{
                    primaryColor: theme.palette.error.color,
                    secondaryColor: theme.palette.error.background,
                    logo: "",
                  }}
                >
                  Zurücksetzen
                </Button>
              </ButtonGroup>
            ) : null}
            <ButtonIcon
              small
              secondary
              icon={view === "table" ? Icons.Table : Icons.Graph}
              onClick={() =>
                setView((v) => (v === "graph" ? "table" : "graph"))
              }
            />
            <DropdownCheckbox
              items={
                [
                  ...(savedFilters?.length
                    ? [
                        {
                          divider: true,
                          label: "Gespeicherte Filter",
                        },
                        ...savedFilters.map((filter, index) => ({
                          accessor: `filter-${index}`,
                          value: `filter-${index}`,
                          label: `Filter ${index + 1}`,
                          CustomPopOverItem: ({
                            children,
                            ...props
                          }: React.HTMLProps<HTMLDivElement>) => (
                            // @ts-ignore
                            <PopOverItemWithDeleteButton {...props}>
                              {children}
                              <Icons.Delete
                                size={20}
                                color={theme.palette.error.color}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteFilter(index);
                                }}
                              />
                            </PopOverItemWithDeleteButton>
                          ),
                        })),
                        { divider: true, label: "Spalten" },
                      ]
                    : []),
                  ...columnItems,
                ] as DropdownCheckboxItem[]
              }
              isOpen={openId === 0}
              onOpen={() => setOpenId(0)}
              onClose={() => setOpenId(null)}
              icon={Icons.Open}
              onItemClick={onColumnItemClick}
              position="right"
            ></DropdownCheckbox>
            <ButtonIcon
              small
              secondary
              icon={Icons.Sparks}
              onClick={onGenerate}
            >
              Gen. External IDs
            </ButtonIcon>
            <DropdownButton
              items={exportItems}
              isOpen={openId === 1}
              onOpen={() => setOpenId(1)}
              onClose={() => setOpenId(null)}
              icon={Icons.Export}
              onItemClick={onExportItemClick}
              position="right"
            >
              Export
            </DropdownButton>
          </ButtonGroupLeft>
        </Buttons>
      </Wrapper>
      <Modal
        isOpen={saveFilterModalOpen}
        contentLabel="save-filter-modal"
        title="Ansicht speichern"
        onRequestClose={() => setSaveFilterModalOpen(false)}
      >
        <Select
          id="savedFilters"
          value={filterIndex}
          onChange={(e) => setFilterIndex(+e.target.value)}
        >
          <option value={-1}>Neuer Filter</option>
          {savedFilters?.map((filter, index) => (
            <option key={`filter-${index}`} value={index}>
              Filter {index + 1}
            </option>
          ))}
        </Select>
        <ButtonGroup>
          <Button secondary onClick={() => setSaveFilterModalOpen(false)}>
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              saveFilter(filterIndex);
              setSaveFilterModalOpen(false);
            }}
          >
            Speichern
          </Button>
        </ButtonGroup>
      </Modal>
    </>
  );
};
