export const compose =
  (...fns: Function[]) =>
  (x?: any, ...args: any[]) =>
    fns.reduce((y, f) => f(y, ...args), x);

export const merge =
  (fn: Function): Function =>
  (e?: Array<any>, ...args: any[]) =>
    fn(e, ...args).concat(e ?? []);

export const composeAndMerge =
  (...fns: Function[]) =>
  (x?: any, ...args: any[]) =>
    compose(...fns.map((fn) => merge(fn)))(x, ...args);

export const debounce = <A extends Array<unknown>, RT>(
  fn: (...args: A) => RT,
  timeout = 300
) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: A) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      fn.apply(this as ThisParameterType<typeof fn>, args);
    }, timeout);
  };
};

export const curry = (fn: Function) => {
  const curried = (...args: any[]) => {
    if (args.length >= fn.length) {
      return fn.apply(this, args);
    } else {
      return (...args2: any[]) => {
        return curried.apply(this, args.concat(args2));
      };
    }
  };
  return curried;
};
