import { ContractTemplate, District } from "../graphql/sdks/controller";

import { flattenAssets, omitGraphqlType } from "./asset.utils";
import { mergePropertyTemplates } from "./properties.utils";
import {
  ContractFormValues,
  ExtendedContract,
  LocalAsset,
  LocalContract,
} from "../components/types";
import { sortMetadata } from "./metadata.utils";

export enum ContractType {
  electricityDelivery = "electricityDelivery",
  electricitySupply = "electricitySupply",
  gasSupply = "gasSupply",
}

export function getContractTypeName(type: ContractType): string {
  const TYPE_NAME_MAP: { [key in ContractType]: string } = {
    [ContractType.electricityDelivery]: "Einspeisevertrag",
    [ContractType.electricitySupply]: "Stromvertrag",
    [ContractType.gasSupply]: "Gasvertrag",
  };

  return TYPE_NAME_MAP[type];
}

export function formatInitialContractFormValues(
  initialContract?: ExtendedContract,
  contractTemplate?: ContractTemplate,
  assetId?: string
): ContractFormValues {
  const newProps = mergePropertyTemplates(
    initialContract?.properties ?? [],
    [],
    contractTemplate?.properties ?? [],
    {
      isMonitoringRelevant: true,
      isOptimizationRelevant: true,
      isOnsite: false,
    }
  );

  return {
    id: "",
    key: "",
    name: "",
    assetKey: "",
    description: "",
    template: "",
    templateDescription: "",
    notes: [],
    ...omitGraphqlType(initialContract ?? {}),
    parentId: assetId,
    assetId,
    contractKey: initialContract?.key,
    ...newProps,
    properties: newProps.properties.map((p) => ({
      ...p,
      value:
        p.dataType === "scalarList"
          ? (p.value ?? []).map((value: number | string, index: number) => ({
              key: index,
              value,
            }))
          : p.value,
    })),
    metadata: sortMetadata(initialContract?.metadata ?? []),
  };
}

export function flattenContracts<T extends LocalContract>(
  district: District,
  contractTransformFn?: (contract: LocalContract, asset?: LocalAsset) => T
): (T extends LocalContract ? T : LocalContract)[] {
  return flattenAssets(district).reduce(
    (contracts: LocalContract[], asset: LocalAsset) => [
      ...contracts,
      ...(contractTransformFn
        ? asset.contracts.map((contract) =>
            contractTransformFn(contract, asset)
          )
        : asset.contracts),
    ],
    []
  ) as (T extends LocalContract ? T : LocalContract)[];
}

export function getContractKeyAndName(
  contractType: ContractType,
  contracts: LocalContract[]
) {
  const assetsOfType =
    contracts?.filter((c) => c.template === contractType) ?? [];

  const key = `${contractType}${assetsOfType.length < 10 ? "0" : ""}${
    assetsOfType.length + 1
  }`;
  return {
    name: `${getContractTypeName(contractType)} ${assetsOfType.length + 1}`,
    key,
    contractKey: key,
  };
}
