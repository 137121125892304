import React from "react";
import {
  AlertRetryable,
  Bold,
  DestructiveActionConfirmation,
} from "@ampeersenergy/ampeers-ui-components";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { FormWrapper } from "./style";

export interface DeleteConfirmModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  entityName: string;
  onConfirm: (closeModal: () => void) => void;
  errorMsg?: string;
}

export const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  isOpen,
  setIsOpen,
  entityName,
  onConfirm,
  errorMsg,
}) => {
  return (
    <DestructiveActionConfirmation
      item={entityName}
      action="löschen"
      onConfirm={() => {
        onConfirm(() => setIsOpen(false));
        setIsOpen(false);
      }}
      onAbort={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      {errorMsg && <AlertRetryable message={errorMsg} />}
    </DestructiveActionConfirmation>
  );
};

export const CustomDeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  isOpen,
  setIsOpen,
  entityName,
  onConfirm,
  errorMsg,
  children,
}) => {
  return (
    <DestructiveActionConfirmation
      item={entityName}
      action="löschen"
      onConfirm={() => {
        onConfirm(() => setIsOpen(false));
        setIsOpen(false);
      }}
      onAbort={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      {errorMsg && <AlertRetryable message={errorMsg} />}
      {children}
    </DestructiveActionConfirmation>
  );
};

export interface DeleteConfirmModalWithFormProps
  extends Omit<DeleteConfirmModalProps, "onConfirm"> {
  validationSchema: yup.AnySchema;
  initialValues: object;
  onConfirm: (submitForm: Function) => Promise<any>;
  onSubmit: (values: FormikValues) => Promise<any>;
}

export const DeleteConfirmModalWithForm: React.FC<
  DeleteConfirmModalWithFormProps
> = ({
  isOpen,
  setIsOpen,
  entityName,
  onConfirm,
  errorMsg,
  initialValues,
  validationSchema,
  onSubmit,
  children,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isValid }) => (
        <FormWrapper>
          <DestructiveActionConfirmation
            item={entityName}
            action="löschen"
            onConfirm={() => {
              if (isValid) {
                onConfirm(submitForm).then(({ errors }) => {
                  if (!errors.length) setIsOpen(false);
                });
              }
            }}
            onAbort={() => setIsOpen(false)}
            isOpen={isOpen}
          >
            {errorMsg && <AlertRetryable message={errorMsg} />}
            Bist du sicher, dass du <Bold>{entityName}</Bold> löschen möchtest?
            <br />
            Dies kann nicht rückgängig gemacht werden.
            {children}
          </DestructiveActionConfirmation>
        </FormWrapper>
      )}
    </Formik>
  );
};
