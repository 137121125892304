import React, { useCallback, useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import {
  DynamicList,
  DynamicListProps,
  Icons,
  MultistepFormContext,
} from "@ampeersenergy/ampeers-ui-components";

import { noteValidationSchema } from "../validation";
import { ListContainer, Muted } from "../style";
import { AssetFormValues } from "../types";

import { NoteListItem } from "./note-list-item";

export interface NotesFormProps {
  isEditing?: boolean;
}

export const NotesForm: React.FC<NotesFormProps> = ({ isEditing }) => {
  const { values } = useFormikContext<AssetFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  const validateNote = useCallback(
    (item) => noteValidationSchema.isValidSync(item),
    []
  );

  const NoteListItemComponent = useCallback(
    (props) => {
      return <NoteListItem {...props} isEditing={isEditing} />;
    },
    [isEditing]
  );

  const onNewItem: DynamicListProps["onNewItem"] = useCallback(
    (item) => ({
      createdAt: DateTime.now().toISO(),
      ...item,
    }),
    []
  );

  useEffect(() => {
    if (registerField) {
      values.notes?.forEach((_, i) => {
        registerField(`notes[${i}].content`);
        registerField(`notes[${i}].createdAt`);
        registerField(`notes[${i}].createdBy`);
      });
    }

    return () => {
      if (unregisterField) {
        values.notes?.forEach((_, i) => {
          unregisterField(`notes[${i}].content`);
          unregisterField(`notes[${i}].createdAt`);
          unregisterField(`notes[${i}].createdBy`);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerField, unregisterField, values.notes]);

  return (
    <>
      {!isEditing && !values.notes?.length && (
        <Muted>Keine Notizen vorhanden</Muted>
      )}
      <ListContainer>
        <DynamicList
          name="notes"
          type=""
          items={values.notes.map((n, i) => ({ ...n, key: i }))}
          validateItem={validateNote}
          Icon={Icons.Notes}
          interactive={isEditing}
          ListItemComponent={NoteListItemComponent}
          onNewItem={onNewItem}
        />
      </ListContainer>
    </>
  );
};
