import {
  ButtonIcon,
  IconButtonProps as DefaultButtonProps,
  Icons,
  Theme,
  TooltipLight,
} from "@ampeersenergy/ampeers-ui-components";
import React from "react";
import styled, { css, useTheme } from "styled-components";

const IconButtonStyle = css<{
  $withTopMargin?: boolean;
  $margin?: string;
  $borderless?: boolean;
}>`
  ${(props) =>
    props.$margin
      ? `margin: ${props.$margin};`
      : `margin: ${props.$withTopMargin ?? true ? "3px" : "0px"} 4px 0px 4px;`}
  padding: 0px;
  height: 40px;
  width: 40px;
  align-self: flex-start;

  ${(props) =>
    props.$borderless
      ? `
    border: 0 !important;
    width: 24px;
    `
      : ""}
`;

export const IconButtonComponent = styled(ButtonIcon)<{
  $withTopMargin?: boolean;
  $margin?: string;
  $borderless?: boolean;
}>`
  ${IconButtonStyle}
`;

export const IconButtonDummySpace = styled.div<{
  $withTopMargin?: boolean;
  $margin?: string;
  $borderless?: boolean;
}>`
  ${IconButtonStyle}
`;

export interface IconButtonProps extends DefaultButtonProps {
  withTopMargin?: boolean;
  tooltipId?: string;
  tooltip?: string;
  borderless?: boolean;
  margin?: string;
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  if (props.tooltipId) {
    return (
      <TooltipLight id={`${props.tooltipId}-button`} text={props.tooltip!}>
        <IconButtonComponent
          data-tip
          data-for={`${props.tooltipId}-button`}
          $margin={props.margin}
          $withTopMargin={props.withTopMargin}
          $borderless={props.borderless}
          className="button"
          secondary
          {...props}
        />
      </TooltipLight>
    );
  }

  return (
    <IconButtonComponent
      $margin={props.margin}
      $withTopMargin={props.withTopMargin}
      $borderless={props.borderless}
      className="button"
      secondary
      {...props}
    />
  );
};

export const OpenCloseButton: React.FC<
  Omit<IconButtonProps, "icon"> & { isOpen?: boolean }
> = (props) => (
  <IconButton
    {...props}
    icon={props.isOpen ? Icons.ChevronUp : Icons.ChevronDown}
  />
);

export const DeleteButton: React.FC<Omit<IconButtonProps, "icon">> = (
  props
) => {
  const theme = useTheme() as Theme;

  return (
    <IconButton
      {...props}
      icon={Icons.Delete}
      color={theme.palette.error.color}
    />
  );
};

export const ExpandButton: React.FC<Omit<IconButtonProps, "icon">> = (
  props
) => <IconButton {...props} icon={Icons.Export} />;
