import {
  FormikSubmit,
  Icons,
  Link,
} from "@ampeersenergy/ampeers-ui-components";
import { Form } from "formik";
import styled, { css } from "styled-components";
import { CenteredRow } from "../style";

export const StepperWrapper = styled.div<{
  $direction: "horizontal" | "vertical";
}>`
  flex: 1;
  display: flex;
  justify-content: center;
  ${(props) =>
    props.$direction === "vertical" &&
    css`
      justify-content: flex-start;
      flex-direction: column;
    `}
`;

export const Step = styled.div<{ $direction: "horizontal" | "vertical" }>`
  display: flex;
  flex-direction: column;

  :first-child .line:first-child {
    opacity: 0;
  }

  :last-child .line:last-child {
    opacity: 0;
  }
`;

export const StepLabelWrapper = styled(CenteredRow)`
  cursor: pointer;
`;

export const StepIcon = styled.div<{ $isCurrent: boolean }>`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.palette.background};
  background: ${(props) =>
    props.$isCurrent
      ? props.theme.primaryColor
      : props.theme.palette.textMuted};
  text-align: center;
  font-size: 13px;
  border-radius: 50px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepLabel = styled.div<{
  $done: boolean;
  $direction: "horizontal" | "vertical";
}>`
  align-self: center;
  color: ${(props) =>
    props.$done ? props.theme.palette.text : props.theme.palette.textMuted};
  font-size: 14px;
  padding: ${(props) => (props.$direction === "horizontal" ? "0 30px" : "0 0")};
  cursor: pointer;
`;

export const HorizontalLine = styled.div<{ $done: boolean }>`
  position: relative;
  margin-top: 15px;
  flex: 1;

  border-top: 1px solid
    ${(props) =>
      props.$done ? props.theme.primaryColor : props.theme.palette.border};
`;

export const VerticalLine = styled.div<{ $done: boolean }>`
  position: relative;
  flex: 1;
  min-height: 30px;
  max-height: 30px;
  margin-left: 15px;

  border-left: 1px solid
    ${(props) =>
      props.$done ? props.theme.primaryColor : props.theme.palette.border};
`;

export const MultistepFormWrapper = styled(Form)<{
  $direction: "horizontal" | "vertical";
}>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.$direction === "vertical" &&
    css`
      flex-direction: row;
    `}
`;

export const MultistepFormContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

export const MultistepFormContent = styled.div`
  flex: 1;
`;

export const MultistepFormNavigationButtons = styled.div<{
  $withMarginBottom?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  ${(props) => (props.$withMarginBottom ? "margin-bottom: 12px;" : "")}
`;

export const ArrowLeft = styled(Icons.ArrowLeft)`
  margin: -6px 0 -6px -6px;
`;

export const ArrowRight = styled(Icons.ArrowRight)`
  margin: -6px -6px -6px 0;
`;

export const SubmitButton = styled(FormikSubmit)``;

export const AlertLink = styled(Link)`
  color: inherit;
`;
