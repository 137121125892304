import React, { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { AlertInfo, WarningAlert } from "@ampeersenergy/ampeers-ui-components";

import { MultistepFormContext } from "./multistep-form/context";
import { AssetFormValues, PropertyFormValue } from "./types";

import { Column, FormSectionHeader } from "./style";
import { TimeseriesForm, TimeseriesFormProps } from "./timeseries-form";
import { Header, PropertiesLabels } from "./properties-multistep-form";
import { AssetType } from "../helpers/asset.utils";
import { findValue } from "../helpers/object.utils";

export interface TimeseriesMultistepFormProps extends TimeseriesFormProps {
  withHeader?: boolean;
}

export const TimeseriesMultistepForm: React.FC<
  TimeseriesMultistepFormProps
> = ({ isEditing, withHeader, prefixIdentifier, items, propertyTemplates }) => {
  const { values } = useFormikContext<AssetFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";
    const accessor = prefixIdentifier
      ? findValue<{ timeseries: PropertyFormValue[] }>(
          prefixIdentifier ?? "",
          values
        )
      : values;

    if (registerField) {
      accessor.timeseries?.forEach((_, i) => {
        registerField(`${_prefixIdentifier}timeseries[${i}].propKey`);
        registerField(`${_prefixIdentifier}timeseries[${i}].value`);
        registerField(`${_prefixIdentifier}timeseries[${i}].dataType`);
        registerField(`${_prefixIdentifier}timeseries[${i}].dataSubType`);
        registerField(`${_prefixIdentifier}timeseries[${i}].unit`);
        registerField(`${_prefixIdentifier}timeseries[${i}].description`);
        registerField(`${_prefixIdentifier}timeseries[${i}].comment`);
      });
    }

    return () => {
      if (unregisterField) {
        accessor.timeseries?.forEach((_, i) => {
          unregisterField(`${_prefixIdentifier}timeseries[${i}].propKey`);
          unregisterField(`${_prefixIdentifier}timeseries[${i}].value`);
          unregisterField(`${_prefixIdentifier}timeseries[${i}].dataType`);
          unregisterField(`${_prefixIdentifier}timeseries[${i}].dataSubType`);
          unregisterField(`${_prefixIdentifier}timeseries[${i}].unit`);
          unregisterField(`${_prefixIdentifier}timeseries[${i}].description`);
          unregisterField(`${_prefixIdentifier}timeseries[${i}].comment`);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerField, unregisterField, values, prefixIdentifier]);

  return (
    <Column>
      {withHeader && (
        <Header $alignEnd={!withHeader}>
          <FormSectionHeader>Zeitreihen</FormSectionHeader>
          <PropertiesLabels flags={values.flags} />
        </Header>
      )}
      {values.template ? (
        <>
          {[AssetType.HeatPump].includes(values.template as AssetType) && (
            <AlertInfo title="Hinweis">
              <ul>
                <li>
                  <strong>Sole-Wasser-WP mit PVT-Kollektor:</strong>{" "}
                  maxFlowTempSource, warningMessageSourceTemp
                </li>
                <li>
                  <strong>Sole-Wasser-WP mit Erdsonden:</strong>{" "}
                  minFlowTempSource, warningMessageSourceTemp
                </li>
                <li>
                  <strong>Luft-Wasser-WP:</strong> warningMessageFreezing
                </li>
              </ul>
            </AlertInfo>
          )}
          {[AssetType.Boiler].includes(values.template as AssetType) && (
            <AlertInfo title="Hinweis">
              Prüfparameter nur bei Pelletkessel notwendig
            </AlertInfo>
          )}
          <TimeseriesForm
            isEditing={isEditing}
            prefixIdentifier={prefixIdentifier}
            items={items}
            propertyTemplates={propertyTemplates}
          />
        </>
      ) : (
        <WarningAlert>Es muss ein Template ausgewählt werden.</WarningAlert>
      )}
    </Column>
  );
};
