import { ExtendedAsset, TimeseriesTableData } from "../components/types";

export const filterBySourceOrTargetType = (
  rowFilter: Record<string, string[]>,
  tableData: TimeseriesTableData[]
) => {
  return tableData.filter(
    ({ source, target }) =>
      source.type !== "kpi" &&
      (rowFilter.sourceOrTargetType[0] === "all" ||
        rowFilter.sourceOrTargetType.includes(source.type) ||
        rowFilter.sourceOrTargetType.includes(target.type))
  );
};

export const filterByAssetTemplate = (
  rowFilter: Record<string, string[]>,
  tableData: TimeseriesTableData[]
) => {
  return tableData.filter(
    ({ origin, originElement }) =>
      rowFilter.assetTemplate[0] === "all" ||
      (origin === "asset" &&
        rowFilter.assetTemplate.includes(
          (originElement as ExtendedAsset)?.template
        ))
  );
};

export const filterByAsset = (
  rowFilter: Record<string, string[]>,
  tableData: TimeseriesTableData[]
) => {
  return tableData.filter(
    ({ originElement, building }) =>
      rowFilter.asset[0] === "all" ||
      rowFilter.asset.includes(originElement?.id ?? "") ||
      rowFilter.asset.includes(originElement?.key ?? "") ||
      rowFilter.asset.includes(building?.key ?? "") ||
      rowFilter.asset.includes(building?.id ?? "")
  );
};

export const filterByFlowType = (
  rowFilter: Record<string, string[]>,
  tableData: TimeseriesTableData[]
) => {
  return tableData.filter(
    ({ target }) =>
      rowFilter.sourceOrTargetType.includes("inference") ||
      rowFilter.flowType[0] === "all" ||
      (rowFilter.flowType.includes("write") && target.type !== "none") ||
      (rowFilter.flowType.includes("read") &&
        !rowFilter.flowType.includes("write") &&
        target.type === "none")
  );
};

export const filterByProtocol = (
  rowFilter: Record<string, (string | null)[]>,
  tableData: TimeseriesTableData[]
) => {
  return tableData.filter(
    ({ source, target }) =>
      rowFilter.protocol.includes("all") ||
      rowFilter.protocol.includes(source.protocol ?? null) ||
      rowFilter.protocol.includes(target.protocol ?? null)
  );
};

export const filterByTextSearch = (
  textSearch: { id: string; value: string }[],
  tableData: TimeseriesTableData[]
) => {
  if (!textSearch.length) {
    return tableData;
  }

  // iterate over a nested object until finding the last value in the chain which should be a string
  return tableData.filter((item) =>
    textSearch.every(({ id, value }) => {
      const nestedKeys: string[] = id.split(".");

      const nestedValue: string = nestedKeys.reduce(
        (acc, key) => (acc as Record<string, any>)[key],
        item
      ) as unknown as string;

      const e = nestedValue.toLowerCase().includes(value.toLowerCase());
      return e;
    })
  );
};

export const filterOutWeatherTimeseries = (
  _: unknown,
  tableData: TimeseriesTableData[]
) => {
  return tableData.filter(({ reference }) => reference.entityKey !== "weather");
};

export const pushSortGroup = (
  itemA: string,
  itemB: string,
  group: string[],
  direction: "top" | "bottom" = "top"
): number => {
  const aGroupIndex = group.findIndex((p) => itemA.includes(p));
  const bGroupIndex = group.findIndex((p) => itemB.includes(p));

  if (aGroupIndex !== -1 && bGroupIndex !== -1) {
    return aGroupIndex - bGroupIndex;
  } else if (aGroupIndex !== -1) {
    return direction === "top" ? -1 : 1;
  } else if (bGroupIndex !== -1) {
    return direction === "top" ? 1 : -1;
  }

  return 0;
};

export const sortProperties = (
  propA?: string | null,
  propB?: string | null,
  templateA?: TimeseriesTableData["originElementTemplate"],
  templateB?: TimeseriesTableData["originElementTemplate"]
) => {
  if (!propA) return -1;
  if (!propB) return 1;

  if (propA.includes("custom_") && propB.includes("custom_")) {
    return propA.localeCompare(propB);
  } else if (propA.includes("custom_")) {
    return 1;
  } else if (propB.includes("custom_")) {
    return -1;
  }

  const sortWarningProps = pushSortGroup(
    propA,
    propB,
    ["operatingStatus", "warningMessage", "errorMessage"],
    "bottom"
  );
  if (sortWarningProps) return sortWarningProps;

  const sortControlProps = pushSortGroup(propA, propB, [
    "controlStatusRead",
    "controlStatusWrite",
  ]);
  if (sortControlProps) return sortControlProps;

  if (templateA?.template === templateB?.template) {
    const templateControlProps = pushSortGroup(
      propA,
      propB,
      templateA?.properties.map((p) => p.key) ?? []
    );
    if (templateControlProps) return templateControlProps;
  }

  return propA.localeCompare(propB);
};
