import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import styled from "styled-components";
import {
  UploadDropzoneProvider,
  FileUpload,
} from "@ampeersenergy/dm-ui-core-components";
import {
  AlertRetryable,
  FormikInput,
  FormikSelect,
  Modal,
} from "@ampeersenergy/ampeers-ui-components";

import { Building } from "../graphql/sdks/controller";

import { groupAssetsByBuilding } from "../helpers/asset.utils";

import { FormWrapper } from "./style";
import { ExtendedAsset, UploadDocumentFormValues } from "./types";
import { _ae_env_ } from "../env";
import { APP_KEY, getSession } from "../services/session.service";
import { documentTypes } from "../helpers/document.utils";
import { useGetDocumentsForEntityLazyQuery } from "../graphql/sdks/document-manager";
import { uploadDocumentSchema } from "./validation";

const buildHeaders = (districtKey: string, companyKey: string) => {
  const { token, clientId, appKey, userId } = getSession() || {};

  return {
    "x-ampeers-app-verify": appKey || APP_KEY,
    ...(token && { "x-ampeers-user-token": token }),
    ...(userId && { "x-ampeers-user-id": userId }),
    ...(clientId && { "x-ampeers-client-id": clientId }),
    ...(companyKey && { "x-ampeers-company": companyKey.toUpperCase() }),
    ...(districtKey && { "x-ampeers-district": districtKey.toUpperCase() }),
  };
};

const UploadButtonWrapper = styled.div`
  margin-top: 14px;
`;

export interface UploadDocumentModalProps {
  districtId: string;
  districtKey: string;
  companyKey: string;
  buildings: Building[];
  assets: ExtendedAsset[];
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  refetchDocuments: ReturnType<typeof useGetDocumentsForEntityLazyQuery>[0];
}

export const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
  districtId,
  districtKey,
  companyKey,
  buildings,
  assets,
  isOpen,
  setIsOpen,
  refetchDocuments,
}) => {
  const [error, setError] = useState<Error | any | undefined>();
  const [file, setFileToUpload] = useState<File | null>(null);

  const onSubmit = useCallback(
    async (formValues: UploadDocumentFormValues) => {
      const { type, fileName, assetId } = formValues;

      if (!file) {
        return;
      }

      const url = `${_ae_env_.REACT_APP_BACKEND_URL}/document/external`;

      const formData = new FormData();

      formData.append("document", file);
      formData.append("districtId", districtId);
      formData.append("fileName", fileName);
      formData.append("type", type);

      if (assetId) {
        formData.append("assetId", assetId);
      }

      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
          headers: buildHeaders(districtKey, companyKey),
        });

        if (response.status !== 200) {
          setError("Upload fehlgeschlagen.");
          return;
        }

        await refetchDocuments({ variables: { groupId: type } });

        setIsOpen(false);
        setError(undefined);
      } catch (error) {
        setError((error as Error).message);
      }
    },
    [districtId, districtKey, companyKey, file, refetchDocuments, setIsOpen]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
        setError(undefined);
      }}
      title="Neues Dokument hochladen"
      maxWidth="medium"
    >
      {error && (
        <AlertRetryable
          message={error}
          onRetry={() => setError(undefined)}
        ></AlertRetryable>
      )}
      <UploadDropzoneProvider>
        <Formik
          validationSchema={uploadDocumentSchema}
          initialValues={
            {
              entity: "district",
              type: "",
              fileName: "",
            } as any
          }
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, submitForm }) => (
            <FormWrapper>
              <FormikSelect id="entity" name="entity" label="Entität">
                <option key="district" value="district">
                  Quartier
                </option>
                <option key="asset" value="asset">
                  Anlage
                </option>
              </FormikSelect>
              {values.entity === "asset" ? (
                <FormikSelect id="assetId" name="assetId" label="Anlage">
                  <option disabled value="" key={"-"}>
                    Bitte wählen
                  </option>
                  {Object.entries(groupAssetsByBuilding(assets)).flatMap(
                    ([buildingId, buildingAssets]) => {
                      const building = buildings.find(
                        (b) => b.id === buildingId
                      );

                      return (
                        <optgroup label={building?.name ?? building?.key}>
                          {buildingAssets.map((asset) => (
                            <option key={asset.id} value={asset.id}>
                              {asset.name ?? asset.key}
                            </option>
                          ))}
                        </optgroup>
                      );
                    }
                  )}
                </FormikSelect>
              ) : null}
              <FormikSelect id="type" name="type" label="Typ">
                <option disabled value="" key={"-"}>
                  Bitte wählen
                </option>
                {Object.entries(documentTypes[values.entity]).map(
                  ([key, label]) => (
                    <option key={key} value={key}>
                      {label}
                    </option>
                  )
                )}
              </FormikSelect>
              {file ? (
                <FormikInput
                  id="fileName"
                  name="fileName"
                  label="Name"
                  hint="Dateierweiterung nicht vergessen: 'Document Name.pdf'"
                />
              ) : null}
              <UploadButtonWrapper>
                <FileUpload
                  onChange={(file) => {
                    setFileToUpload(file);
                    if (file && file.name !== values.fileName) {
                      setFieldValue("fileName", file.name);
                    }
                  }}
                  onUpload={() => submitForm()}
                />
              </UploadButtonWrapper>
            </FormWrapper>
          )}
        </Formik>
      </UploadDropzoneProvider>
    </Modal>
  );
};
