/** @module env
 * One of the issues with baking configuration into static build artifacts is,
 * that we had to build a new (and in that way also distinct) artifact for each
 * target environment.
 * Each build could potentially produce slightly different results, even when
 * building from the same underlying source.
 *
 * To make operating our components predictable and testable, we want to provide
 * an easy and reliable way to deliver and configure a single build artifact
 * for multiple environments.
 *
 * This allows us to build a single artifact, test the same artifact with
 * different configurations (e.g. on different environments), which only have
 * to follow a simple configuration contract.
 *
 * This then also aligns with following sections of 12-factor recommendations:
 *
 *  * §1: codebase
 *  * §3: config
 *  * §5: build, release, run
 *  * §10: dev/prod parity
 *
 * To accomodate for both (local) development tooling, which usually builds
 * upon hot-reload-capable dev-servers and other nice features, as well as
 * production-like operations, `_ae_env_` is introduced.
 *
 * This works around webpack plugins that bake certain -- seemingly -- static
 * things, like `process.env` into the build artifact.
 * See also <https://webpack.js.org/plugins/environment-plugin/>
 *
 * @see 12-factor guidelines (aged, but still valid): <https://12factor.net/>
 */

class FrontendEnv {
  /**
   * Create a config object from the first suitable object in a list of
   * arbitrary source objects.
   *
   * This is mostly useful for different contexts. During (local) development,
   * we will want to use {@code process.env}, while within release artifacts
   * all config values should be read from {@code window._ae_env_} exclusively,
   * when it is available.
   */
  static build(...sources: ({ [key: string]: any } | null | undefined)[]): FrontendEnv {
    const targetEnv = new FrontendEnv();

    for (const source of sources) {
      if (typeof source !== 'undefined' && source !== null) {
        // Copy stuff from source to targetEnv, but only properties that already
        // exist on targetEnv.
        Object.keys(targetEnv)
          .filter((k) => k in source)
          .forEach(k => targetEnv[k as keyof FrontendEnv] = source[k]);
        break;
      }
    }

    // log missing config values, so we can identify missing values easily
    Object.entries(targetEnv)
      .filter(([k, v]) => typeof v === 'undefined' || v === null || v === '')
      .forEach(([k, v]) => {
        console.warn('Missing env config', k);
      })

    return targetEnv;
  }

  /**
   * The controller backend service's public URL (without trailing '/').
   */
  REACT_APP_BACKEND_URL: string = '';
}


/**
 * _ae_env_ is our single reference point for frontend configuration matters.
 * Its contents may either be provided via window._ae_env_, or from process.env.
 *
 * Static builds
 * -------------
 * `_ae_env_` is derived from `window._ae_env_`.
 * The latter may be provided as a simple `Map<string, string>` in `config.js`
 * of the build artifact. This script is loaded from `index.html`.
 *
 * Behaviour is currently undefined for anything else than null and plain
 * string-mappings.
 *
 * Dev server
 * ----------
 * When `window._ae_env_` is not specified or null, a fallback to `process.env`
 * is provided. This is usually not useful, when e.g. serving a client, though.
 *
 * If `process.env` is used here, its contents are copied into a new object,
 * which is then assigned to `_ae_env_`.
 */
export const _ae_env_: FrontendEnv = FrontendEnv.build(
  (window as unknown as { _ae_env_: any })._ae_env_, process.env,
);
