import { FlexRow, Icons, SubTitle } from "@ampeersenergy/ampeers-ui-components";
import styled from "styled-components";
import ReactFlow from "react-flow-renderer";

export const StyledReactFlow = styled(ReactFlow)<{ $isVisible: boolean }>`
  ${({ $isVisible }) =>
    $isVisible
      ? ""
      : `
          .react-flow__renderer {
            opacity: 0;
          }
      `}

  .react-flow__edge-path {
    &.hovered {
      stroke: ${(props) => props.theme.primaryColor};
      stroke-width: 2px;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74vh;
  width: 100%;
`;

export const FlowWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  height: 100%;
  background: #fff;
`;

export const FloatingDropdowns = styled.div`
  position: absolute;
  z-index: 5;
  margin: 12px 16px;
  display: flex;
  gap: 4px;
`;

export const Color = styled.div<{ $colors: [string, string] }>`
  width: 32px;
  background: repeating-linear-gradient(
    -45deg,
    ${(props) => props.$colors[0]},
    ${(props) => props.$colors[0]} 10px,
    ${(props) => props.$colors[1]} 10px,
    ${(props) => props.$colors[1]} 20px
  );
  border-radius: 4px 0 0 4px;
`;

export const Label = styled(FlexRow)`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background-color: #fff;
  z-index: 2;
  box-sizing: border-box;

  &:hover {
    box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.primaryColor};

    .color {
      margin: 1px -1px 1px 1px;
    }
  }

  .assetName,
  .name,
  .type {
    letter-spacing: 1.5px;
    color: ${(props) => props.theme.palette.gray[800]};
  }

  .nameInfo {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
  }

  .column {
    padding: 12px;
    align-items: flex-start;
    font-size: 18px;
  }

  .type {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const SubTitleWithoutBottomMargin = styled(SubTitle)`
  margin-bottom: 0;
`;

export const AssetName = styled.h6`
  margin: 0;
  margin-bottom: 18px;
  font-weight: normal;
  font-size: 15px;
`;

export const WarningIcon = styled(Icons.Warning)`
  margin: -4px 0 0 4px;
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 0;
`;

export const InferenceResultWrapper = styled.div`
  display: inline-flex;
  padding: 7px 22px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.border};
`;

export const InferenceResultColumn = styled.div`
  display: inline-flex;
  gap: 14px;

  &:last-child {
    flex-wrap: wrap;
  }
`;

export const InferenceResultElement = styled.span`
  display: flex;
  letter-spacing: 1px;
`;

export const InferenceResultPlus = styled.span`
  padding-right: 14px;
`;

export const DropdownItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 12px;

  .inference-ratio {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-right: -4px;
    }

    &.orange > :first-child {
      color: ${(props) => props.theme.palette.warning.color};
    }
  }
`;
