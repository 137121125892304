import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Button,
  FlexRow,
  formatDateTime,
  Input,
  SubTitle,
  Tab,
  Table,
  Tabs,
} from "@ampeersenergy/ampeers-ui-components";
import {
  District,
  GetSnapshotDocument,
  GetSnapshotsDocument,
  useCreateSnapshotMutation,
  useGetSnapshotQuery,
  useGetSnapshotsQuery,
  useRemoveSnapshotsMutation,
  useRestoreSnapshotMutation,
} from "../graphql/sdks/controller";
import { Column, Row } from "./style";
import { DiffEditor } from "@monaco-editor/react";
import { BulkDeleteModal } from "./bulk-delete-modal";

const Container = styled(Row)`
  height: 86vh;
`;

const HalfWidthSubTitle = styled(SubTitle)`
  width: 50%;
  float: left;
`;

const SnapshotDescription = styled(Input)`
  margin-right: 6px;
`;

const snapshotColumns = [
  {
    Header: "Timestamp",
    accessor: "timestamp",
    Cell: ({ value }: { value: string }) => formatDateTime(value),
  },
  {
    Header: "Beschreibung",
    accessor: "description",
    Cell: ({ value }: { value: string }) => value || "--",
  },
];

const sectionNameMap: Record<string, string> = {
  districts: "Distrikt",
  buildings: "Gebäude",
  assets: "Anlagen",
  contracts: "Verträge",
  timeseries: "Zeitreihen",
  flowGroups: "Flow\u2007Gruppen",
  flowTasks: "Flow\u2007Aufgaben",
  forecasts: "Prognosen",
  optimizations: "Optimierung",
  connections: "Connections",
};

const getFilteredObject = (input: any) => ({
  ...input,
  buildings: undefined,
  assets: undefined,
  contracts: undefined,
});

const getFiltered = (input: any) =>
  Array.isArray(input)
    ? input.map(getFilteredObject)
    : getFilteredObject(input);

export interface SnapshotOverviewProps {
  district: District;
}

export const SnapshotOverview: React.FC<SnapshotOverviewProps> = ({
  district,
}) => {
  const [selectedSection, setSelectedSection] = useState<string>("districts");
  const [selectedSnapshot, setSelectedSnapshot] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [snapshotDescription, setSnapshotDescription] = useState<string>("");

  const { data: snapshot } = useGetSnapshotQuery({
    variables: {
      districtIdentifier: {
        company: district.companyKey,
        district: district.key,
      },
    },
  });

  const { data: snapshots, loading: snapshotsLoading } = useGetSnapshotsQuery({
    variables: {
      districtIdentifier: {
        company: district.companyKey,
        district: district.key,
      },
    },
  });

  const [createSnapshot] = useCreateSnapshotMutation({
    errorPolicy: "all",
    refetchQueries: [
      {
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
        },
        query: GetSnapshotsDocument,
      },
    ],
  });

  const [restoreSnapshot] = useRestoreSnapshotMutation({
    errorPolicy: "all",
    refetchQueries: [
      {
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
        },
        query: GetSnapshotsDocument,
      },
      {
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
        },
        query: GetSnapshotDocument,
      },
    ],
  });

  const [removeSnapshots, { error: removeError }] = useRemoveSnapshotsMutation({
    errorPolicy: "all",
    refetchQueries: [
      {
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
        },
        query: GetSnapshotsDocument,
      },
    ],
  });

  const currentSnapshot = snapshot?.snapshot || {};
  const currentSnapshotText = JSON.stringify(
    getFiltered(currentSnapshot[selectedSection]),
    null,
    2
  );

  const previousSnapshots = useMemo(
    () =>
      [...(snapshots?.snapshots || [])].sort(
        (a: { timestamp: string }, b: { timestamp: string }) =>
          b.timestamp.localeCompare(a.timestamp)
      ),
    [snapshots]
  );
  const selectedSnapshotText = JSON.stringify(
    getFiltered(selectedSnapshot[selectedSection]),
    null,
    2
  );

  const onRowSelect = useCallback(
    (rowIds: string[]) => {
      setSelectedRows(
        rowIds
          .map((rowId) => previousSnapshots.find((_, i) => i === +rowId)?.id)
          .filter((v): v is string => !!v)
      );
    },
    [setSelectedRows, previousSnapshots]
  );

  const onDelete = useCallback(async () => {
    await removeSnapshots({
      variables: {
        ids: selectedRows,
      },
    });
  }, [removeSnapshots, selectedRows]);

  const onCreateSnapshot = useCallback(async () => {
    await createSnapshot({
      variables: { description: snapshotDescription || null },
    });
    setSnapshotDescription("");
  }, [createSnapshot, snapshotDescription]);

  const onRestoreSnapshot = useCallback(async () => {
    if (selectedSnapshot) {
      await restoreSnapshot({ variables: { id: selectedSnapshot.id } });
    }
  }, [restoreSnapshot, selectedSnapshot]);

  useEffect(() => {
    if (previousSnapshots.length) {
      setSelectedSnapshot(previousSnapshots[0]);
    }
  }, [previousSnapshots]);

  return (
    <Container>
      <Column>
        <Table
          compact
          withAlternatingRows
          withBoxShadow
          withPagination
          multiSelect
          onSelect={onRowSelect}
          filterKind="Snapshots"
          isLoading={snapshotsLoading}
          columns={snapshotColumns}
          data={previousSnapshots}
          onRowClick={({ original }) => setSelectedSnapshot(original)}
          renderTableActions={() => (
            <>
              {!!selectedRows.length && (
                <BulkDeleteModal
                  entityName="Snapshots"
                  removeError={removeError}
                  nameExtractFn={(id) => {
                    const snapshot = previousSnapshots.find((s) => s.id === id);
                    return snapshot?._id ?? "--";
                  }}
                  onDelete={onDelete}
                  selectedRows={selectedRows}
                />
              )}
              <FlexRow>
                <SnapshotDescription
                  id="snapshotDescription"
                  placeholder="Beschreibung"
                  value={snapshotDescription}
                  onChange={(e) => {
                    setSnapshotDescription(e.target.value);
                  }}
                />
                <Button onClick={onCreateSnapshot}>Snapshot erstellen</Button>
              </FlexRow>
            </>
          )}
        />
        <br />
        <Button onClick={onRestoreSnapshot}>Snapshot wiederherstellen</Button>
      </Column>
      <Column>
        <div>
          <HalfWidthSubTitle>
            Vorheriger Snapshot — {formatDateTime(selectedSnapshot.timestamp)}
          </HalfWidthSubTitle>
          <HalfWidthSubTitle>Aktueller Snapshot</HalfWidthSubTitle>
        </div>
        <Tabs>
          {Object.keys(currentSnapshot)
            .filter(
              (key) =>
                ![
                  "company",
                  "district",
                  "id",
                  "timestamp",
                  "version",
                  "description",
                ].includes(key)
            )
            .map((key) => (
              <Tab
                title={sectionNameMap[key] || key}
                onClick={() => setSelectedSection(key)}
              ></Tab>
            ))}
        </Tabs>
        <DiffEditor
          height="72vh"
          language="json"
          options={{
            wordWrap: "wordWrapColumn",
            wrappingIndent: "indent",
            tabSize: 2,
            minimap: {
              enabled: false,
            },
          }}
          original={selectedSnapshotText}
          modified={currentSnapshotText}
        />
      </Column>
    </Container>
  );
};
