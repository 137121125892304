import React from "react";
import { useHistory } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import {
  BaseIcon,
  IconProps,
  Icons,
  Navigation as NavigationWrapper,
  NavigationItem,
} from "@ampeersenergy/ampeers-ui-components";

import { useLogoutUserMutation } from "../graphql/sdks/auth";

import { clearSession } from "../services/session.service";

export const Logout = (props: IconProps) => (
  <BaseIcon {...props} size={20}>
    <path d="M13 0h3.8v15.7H13z" />
    <path d="M15 30c-5.2 0-10.2-2.8-13-7.5A15.1 15.1 0 014.3 4.4l.5-.5 2.7 2.7-.5.5a11.3 11.3 0 007.9 19.2c6.2 0 11.3-5.1 11.3-11.3 0-3-1.2-5.8-3.3-7.9l-.5-.5 2.7-2.7.5.5A15 15 0 0115 30z" />
  </BaseIcon>
);

export const Navigation: React.FC = () => {
  const history = useHistory();
  const [logout] = useLogoutUserMutation();
  return (
    <NavigationWrapper
      centerComponent={
        <>
          <NavigationItem
            tooltip="DM Controller"
            icon={Icons.Project}
            href="/districts"
          />
        </>
      }
      endComponent={
        <NavigationItem
          tooltip="Logout"
          icon={Logout}
          onClick={() => {
            logout()
              .then(() => {
                clearSession();
                history.push("/login");
              })
              .catch((error) => {
                if (
                  error.graphQLErrors.some(
                    (e: ApolloError["graphQLErrors"][0]) =>
                      e?.extensions?.code === "UNAUTHENTICATED"
                  )
                ) {
                  clearSession();
                  history.push("/login");
                }
              });
          }}
        />
      }
    />
  );
};
