import React, { useCallback, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import {
  Button,
  ColumnDefinition,
  Icons,
  Table as TableComponent,
  Theme,
  TooltipLight,
  withDialogProvider,
} from "@ampeersenergy/ampeers-ui-components";

import { AssetType, getAssetKeyAndName } from "../../helpers/asset.utils";
import { dynamicPageSize } from "../../helpers/html.utils";

import {
  AssetListItem,
  LocalAsset,
  LocalDistrict,
} from "../../components/types";
import { TableIcon } from "../../components/table-icon";
import { QuickActions } from "../../components/quick-actions";
import { NotesIcon } from "../../components/notes-icon";
import { DeleteConfirmModal } from "../../components/delete-confirm-modal";
import { extractErrorMessage } from "../../helpers/error.utils";
import {
  GetAssetsDocument,
  useRemoveAssetsMutation,
} from "../../graphql/sdks/controller";
import useCloneAsset from "../../hooks/useCloneAsset";
import { BulkDeleteModal } from "../../components/bulk-delete-modal";

const Table: typeof TableComponent = styled(TableComponent)`
  tr > td:last-child {
    padding: 0 !important;
  }
`;

const AssetQuickActions: React.FC<{
  asset: AssetListItem;
  openId?: string;
  setOpenId: (value?: string) => void;
  onDuplicate: (asset: LocalAsset) => void;
  setAssetToEdit: (original: Partial<AssetListItem> | undefined) => void;
  setDeleteId?: (id: string) => void;
}> = ({
  asset,
  openId,
  setOpenId,
  onDuplicate,
  setAssetToEdit,
  setDeleteId,
}) => {
  const onAddNote = useCallback(() => {
    setAssetToEdit({ ...asset, initialStepIndex: 4 });
  }, [setAssetToEdit, asset]);

  const onDelete = useCallback(() => {
    setDeleteId?.(asset.id);
  }, [setDeleteId, asset]);

  return (
    <QuickActions
      id={asset.id}
      isOpen={asset.id === openId}
      onClick={() => {
        openId === asset.id ? setOpenId(undefined) : setOpenId(asset.id);
      }}
      items={[
        { label: "Notiz hinzufügen", Icon: Icons.File, onClick: onAddNote },
        {
          label: "Anlage duplizieren",
          Icon: Icons.Refresh,
          onClick: () => onDuplicate(asset),
        },
        {
          label: "Anlage löschen",
          color: "#E30045",
          Icon: Icons.Delete,
          onClick: onDelete,
        },
      ]}
    />
  );
};

export interface AssetsProps {
  district: LocalDistrict;
  assets: AssetListItem[];
  loading: boolean;
  onCreateAssetClick: (value: boolean) => void;
  setAssetToEdit: (original: Partial<AssetListItem> | undefined) => void;
}

export const Assets = withDialogProvider<AssetsProps>(
  ({ district, loading, assets, setAssetToEdit, onCreateAssetClick }) => {
    const theme = useTheme() as Theme;

    const [openId, setOpenId] = useState<string | undefined>();
    const [deleteId, setDeleteId] = useState<string | undefined>();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const [cloneAsset] = useCloneAsset(district.companyKey, district.key);

    const [removeAssets, { error: removeError }] = useRemoveAssetsMutation({
      errorPolicy: "all",
      refetchQueries: [
        {
          variables: {
            districtIdentifier: {
              company: district.companyKey,
              district: district.key,
            },
          },
          query: GetAssetsDocument,
        },
      ],
    });

    const assetToDelete = useMemo(
      () => assets.find(({ id }) => id === deleteId),
      [deleteId, assets]
    );

    const onDuplicate = useCallback(
      (asset: LocalAsset) => {
        cloneAsset({
          variables: {
            sourceKey: asset.key,
            targetKey: getAssetKeyAndName(asset.template as AssetType, assets)
              .key,
          },
        }).then(({ data }) => setAssetToEdit?.(data?.cloneAsset));
      },
      [assets, cloneAsset, setAssetToEdit]
    );

    const assetColumns: ColumnDefinition<AssetListItem>[] = useMemo(
      () => [
        {
          Header: "",
          accessor: "type",
          Cell: ({ value }: { value: AssetType }) => (
            <TableIcon type={value} color={theme.primaryColor} />
          ),
          width: 30,
        },
        {
          Header: "Name",
          accessor: "name",
          width: "20%",
          Cell: ({ value, row }) => (
            <span>
              {value}{" "}
              {row.original.displayWarning && (
                <TooltipLight
                  id={`${row.original.id}-required-warning`}
                  text="Bei dieser Anlage gibt es fehlende Properties oder Zeitreihen"
                >
                  <Icons.Warning
                    data-tip
                    data-for={`${row.original.id}-required-warning`}
                    size={25}
                    color={theme.palette.warning.color}
                    style={{ margin: "-4px" }}
                  />
                </TooltipLight>
              )}
            </span>
          ),
          sortType: (rowA, rowB) => {
            return rowA.original.name.localeCompare(
              rowB.original.name,
              undefined,
              {
                numeric: true,
              }
            );
          },
        },
        {
          Header: "Gebäude",
          accessor: "buildingName",
          Cell: ({ value }: { value?: string }) => value ?? "--",
        },
        {
          Header: "Key",
          accessor: "key",
        },
        {
          Header: "Template",
          accessor: "template",
        },
        {
          Header: "",
          accessor: "notes.length",
          width: "1%",
          Cell: ({ value, row }) => (
            <NotesIcon
              onClick={(e) => {
                e.stopPropagation();
                setAssetToEdit({ ...row.original, initialStepIndex: 4 });
              }}
              value={value}
            />
          ),
        },
        {
          Header: "",
          accessor: "quickActions",
          width: "1%",
          Cell: ({ row }: { row: { original: AssetListItem } }) => (
            <AssetQuickActions
              asset={row.original}
              openId={openId}
              setOpenId={setOpenId}
              setAssetToEdit={setAssetToEdit}
              onDuplicate={onDuplicate}
              setDeleteId={setDeleteId}
            />
          ),
        },
      ],
      [theme, openId, setOpenId, setAssetToEdit, onDuplicate]
    );

    const onRowSelect = useCallback(
      (rowIds: string[]) => {
        setSelectedRows(
          rowIds
            .map((rowId) => assets.find((_, i) => i === +rowId)?.id)
            .filter((v): v is string => !!v)
        );
      },
      [setSelectedRows, assets]
    );

    const onDelete = useCallback(() => {
      return removeAssets({
        variables: {
          ids: selectedRows,
        },
      });
    }, [removeAssets, selectedRows]);

    return (
      <>
        <Table
          compact
          withFilter
          withAlternatingRows
          withBoxShadow
          withPagination
          multiSelect
          onSelect={onRowSelect}
          filterKind="Anlagen"
          isLoading={loading}
          columns={assetColumns}
          data={assets}
          pageSize={dynamicPageSize()}
          onRowClick={({ original }) => {
            setAssetToEdit(original);
          }}
          renderTableActions={() => (
            <>
              {!!selectedRows.length && (
                <BulkDeleteModal
                  entityName="Anlagen"
                  removeError={removeError}
                  nameExtractFn={(id) => {
                    const asset = assets.find((a) => a.id === id);
                    return asset?.name ?? asset?.key ?? "--";
                  }}
                  onDelete={onDelete}
                  selectedRows={selectedRows}
                />
              )}
              <Button onClick={() => onCreateAssetClick(true)}>
                Anlage anlegen
              </Button>
            </>
          )}
          initialState={{
            sortBy: [
              {
                id: "name",
              },
            ],
          }}
        />
        <DeleteConfirmModal
          entityName={assetToDelete?.name ?? "--"}
          isOpen={deleteId !== undefined}
          setIsOpen={() => {
            setDeleteId(undefined);
          }}
          onConfirm={() => {
            if (!assetToDelete?.id) {
              return;
            }
            removeAssets({ variables: { ids: [assetToDelete.id] } });
            setDeleteId(undefined);
          }}
          errorMsg={removeError && extractErrorMessage(removeError)}
        />
      </>
    );
  }
);
