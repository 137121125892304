import { DateTime } from "luxon";

/**
 * formats a date to a localized date only format
 * @param date
 */
export const formatDate = (date: string) =>
  DateTime.fromISO(date)
    .setLocale("de-DE")
    .toLocaleString({
      ...DateTime.DATE_SHORT,
      day: "2-digit",
      month: "2-digit",
    });
