import React, { ChangeEvent } from "react";
import { useTheme } from "styled-components";
import {
  DynamicListItemProps,
  FormikInput,
  Theme,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";

import { ExtendedMetadata } from "../types";
import { DeleteButton, FlexRawSelect, NewItemEditWrapper } from "./style";
import { InlineCheckbox, Input, NewItemWrapper, PlusIcon, Row } from "../style";
import { CommunicationFlow } from "../communication-flow";

export interface MetadataItemFormProps extends DynamicListItemProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  isEditing?: boolean;
  item: DynamicListItemProps["item"] & ExtendedMetadata;
}

export const MetadataItemForm: React.FC<MetadataItemFormProps> = ({
  item,
  setFieldValue,
  isEditing,
  ...props
}) => {
  const theme = useTheme() as Theme; // TODO: Remove this cast

  const { onAddItem, onDelete } = useDynamicListItem({
    ...props,
    item,
    index: item.index,
  });

  if (item.isNewValue) {
    return (
      <NewItemWrapper onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        Metadata hinzufügen
      </NewItemWrapper>
    );
  }

  if (!isEditing) {
    return (
      <FormikInput
        name={`metadata[${item.index}].value`}
        label={`${item.key}`}
        isEditing={false}
      />
    );
  }

  return (
    <NewItemEditWrapper>
      <Row>
        <Input
          flex={3}
          name={`metadata[${item.index}].label`}
          label="Label *"
          isEditing={isEditing}
        />
        {isEditing && (
          <>
            {item.inputType !== "communicationFlow" && (
              <FlexRawSelect
                id={`metadata[${item.index}].type`}
                name={`metadata[${item.index}].type`}
                label="Type *"
                value={item.type}
                isEditing={isEditing}
                onChange={async (e: ChangeEvent<{ value: string }>) => {
                  setFieldValue(`metadata[${item.index}].type`, e.target.value);
                }}
              >
                <option value="string" key="string">
                  String
                </option>
                <option value="boolean" key="boolean">
                  Boolean
                </option>
              </FlexRawSelect>
            )}
            <DeleteButton borderless onClick={onDelete} />
          </>
        )}
      </Row>

      <Row>
        <Input
          name={`metadata[${item.index}].key`}
          label="Key *"
          isEditing={isEditing}
        />
        {item.inputType === "communicationFlow" ? (
          <CommunicationFlow
            key={item.originalKey}
            label="Value"
            id={`metadata[${item.index}].value`}
            value={item.value as string}
            setValue={(value) => {
              setFieldValue(`metadata[${item.index}].value`, value);
            }}
            isEditing={isEditing}
          />
        ) : item.type === "string" ? (
          <Input
            flex={3}
            name={`metadata[${item.index}].value`}
            label="Value"
            isEditing={isEditing}
          />
        ) : (
          <InlineCheckbox
            flex={3}
            id={`metadata[${item.index}].value`}
            key={item.originalKey}
            label={`${item.value}`}
            checked={item.value === "true"}
            onChange={(v) =>
              setFieldValue(
                `metadata[${item.index}].value`,
                v ? "true" : "false"
              )
            }
            disabled={!isEditing}
          />
        )}
      </Row>
    </NewItemEditWrapper>
  );
};
