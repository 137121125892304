import React, { useCallback, useMemo, useState } from "react";
import { FormikHelpers } from "formik";
import { useTheme } from "styled-components";
import { ApolloError } from "@apollo/client";
import {
  AlertRetryable,
  Button,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import {
  GetDistrictsDocument,
  useCreateContractMutation,
  useGetContractTemplateQuery,
  useGetContractTemplatesQuery,
  useRemoveContractsMutation,
  useUpdateContractMutation,
} from "../../graphql/sdks/controller";

import { formatPropertiesBeforeSubmit } from "../../helpers/properties.utils";
import { extractErrorMessage } from "../../helpers/error.utils";
import { formatInitialContractFormValues } from "../../helpers/contract.utils";
import { contractValidationSchema } from "../validation";

import { DeleteConfirmModal } from "../delete-confirm-modal";
import { ExtendedAsset, ExtendedContract, ContractFormValues } from "../types";
import { MultistepForm } from "../multistep-form";
import { ContractFormTemplate } from "./template";
import { ContractFormAsset } from "./asset";
import { PropertiesMultistepForm } from "../properties-multistep-form";
import { AccordionForm } from "../accordion-form";
import { FormSection, FormSectionHeader } from "../style";
import { NotesForm } from "../notes-form";

export interface CreateEditContractFlowProps {
  contract?: ExtendedContract;
  assets?: ExtendedAsset[];
  assetId?: string;
  startOnEdit?: boolean;
  contracts?: ExtendedContract[];
  renderAsMultistepForm?: boolean;

  onClose?: () => void;
  onSuccess?: () => void;
  onDelete?: () => void;
}

const refetchQueries = [
  {
    query: GetDistrictsDocument,
    variables: {
      filter: {
        multipleDistricts: [
          {
            company: "all",
            district: "all",
          },
        ],
      },
    },
  },
];

export const CreateEditContractFlow: React.FC<CreateEditContractFlowProps> = ({
  contract,
  assets,
  assetId,
  contracts,
  startOnEdit = false,
  renderAsMultistepForm,
  onSuccess,
  onDelete,
  onClose,
}) => {
  const theme = useTheme() as Theme;
  const [isEditing, setIsEditing] = useState(startOnEdit);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const {
    data: contractTemplates,
    loading: loadingContractTemplates,
    error: templatesError,
    refetch: refetchContractTemplates,
  } = useGetContractTemplatesQuery();

  const {
    data: contractTemplate,
    loading: loadingTemplate,
    error: templateError,
    refetch: refetchContractTemplate,
  } = useGetContractTemplateQuery({
    skip: !contract,
    variables: {
      templateKey: contract?.template ?? "",
    },
  });

  const [createContract] = useCreateContractMutation({
    errorPolicy: "all",
    refetchQueries,
  });

  const [updateContract] = useUpdateContractMutation({
    errorPolicy: "all",
    refetchQueries,
  });

  const [removeContract, { error: removeError }] = useRemoveContractsMutation({
    errorPolicy: "all",
    refetchQueries,
  });

  const onSubmit = useCallback(
    async (
      { newValues: values }: { newValues: ContractFormValues },
      { setValues }: FormikHelpers<any>
    ) => {
      try {
        const {
          parentId,
          id,
          assetId,
          assetKey,
          assetName,
          properties,
          timeseries: valuesTimeseries,
          tensors,
          metadata,
          contractKey,
          propertyTemplates,
          templateDescription,
          description,
          notes,
          elementType,
          ...restValues
        } = values;

        const contractValues = {
          ...restValues,
          description: description || undefined,
          properties: formatPropertiesBeforeSubmit({
            properties,
            timeseries: valuesTimeseries,
          }),
          metadata: metadata.map(({ key, label, value }) => ({
            key,
            label,
            value:
              value === true ? "true" : value === false ? "false" : value ?? "",
          })),
          notes: notes.map((n) => ({
            content: n.content,
            createdBy: n.createdBy,
            createdAt: n.createdAt,
          })),
        };

        let newContractValues;
        if (!contract) {
          const { data, errors } = await createContract({
            variables: {
              parentId: parentId || undefined,
              contract: contractValues,
            },
          });

          newContractValues = data?.createContract;
          if (errors?.length) {
            throw errors[0].message ?? "Unknown Error";
          }
        } else {
          const { data, errors } = await updateContract({
            variables: {
              parentId: parentId || undefined,
              contract: contractValues,
            },
          });

          newContractValues = data?.updateContract;
          if (errors?.length) {
            throw errors[0]?.message ?? "Unknown Error";
          }
        }

        setIsEditing(false);
        onClose?.();

        if (newContractValues) {
          setValues(
            formatInitialContractFormValues(
              newContractValues as ExtendedContract,
              contractTemplate?.contractTemplate,
              assetId
            )
          );
        }

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          throw extractErrorMessage(e);
        } else if (typeof e === "string") {
          throw e;
        } else {
          throw e;
        }
      }
    },
    [
      contract,
      contractTemplate,
      onSuccess,
      createContract,
      updateContract,
      onClose,
    ]
  );

  const initialValues = useMemo(() => {
    return formatInitialContractFormValues(
      contract,
      contractTemplate?.contractTemplate,
      assetId
    );
  }, [contract, contractTemplate, assetId]);

  return (
    <>
      {renderAsMultistepForm && (
        <>
          {(templatesError || templateError) && (
            <AlertRetryable
              message={extractErrorMessage((templatesError ?? templateError)!)}
              onRetry={() => {
                if (templatesError) {
                  refetchContractTemplates();
                } else if (templateError) {
                  refetchContractTemplate();
                }
              }}
            />
          )}
          <MultistepForm
            initialValues={initialValues}
            validationSchema={contractValidationSchema}
            onSubmit={onSubmit}
            showSubmitInEveryStep
            extraActionButtons={
              !!contract && (
                <Button
                  secondary
                  customTheme={{
                    primaryColor: theme.palette.error.color,
                    secondaryColor: theme.palette.error.background,
                  }}
                  onClick={() => setConfirmDeleteModalOpen(true)}
                >
                  Löschen
                </Button>
              )
            }
          >
            <MultistepForm.Step title="Template">
              <FormSection>
                <FormSectionHeader>Template</FormSectionHeader>
                <ContractFormTemplate
                  existingContract={!!contract}
                  contracts={contracts ?? []}
                  getContractTemplate={refetchContractTemplate}
                  templates={contractTemplates?.contractTemplates ?? []}
                  loadingTemplate={loadingTemplate || loadingContractTemplates}
                  isEditing={isEditing}
                />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step title="Anlage">
              <FormSection>
                <FormSectionHeader>Anlage</FormSectionHeader>
                <ContractFormAsset
                  assets={assets ?? []}
                  isEditing={isEditing}
                />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step title="Properties">
              <FormSection>
                <PropertiesMultistepForm isEditing={isEditing} withHeader />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step title="Notizen">
              <FormSection>
                <FormSectionHeader>Notizen</FormSectionHeader>
                <NotesForm isEditing={isEditing} />
              </FormSection>
            </MultistepForm.Step>
          </MultistepForm>
          {!!contract && (
            <DeleteConfirmModal
              entityName={contract.name}
              isOpen={confirmDeleteModalOpen}
              setIsOpen={setConfirmDeleteModalOpen}
              onConfirm={() => {
                removeContract({ variables: { ids: [contract.id] } });
                onClose?.();
                onDelete?.();
              }}
              errorMsg={removeError && extractErrorMessage(removeError)}
            />
          )}
        </>
      )}
      {!renderAsMultistepForm && (
        <AccordionForm
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          initialValues={initialValues}
          validationSchema={contractValidationSchema}
          onSubmit={onSubmit}
          onDelete={() => setConfirmDeleteModalOpen(true)}
        >
          <AccordionForm.Item label="Template">
            <ContractFormTemplate
              existingContract={!!contract}
              contracts={contracts ?? []}
              getContractTemplate={refetchContractTemplate}
              templates={contractTemplates?.contractTemplates ?? []}
              loadingTemplate={loadingTemplate || loadingContractTemplates}
              isEditing={isEditing}
            />
          </AccordionForm.Item>
          <AccordionForm.Item label="Anlage">
            <ContractFormAsset assets={assets ?? []} isEditing={isEditing} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Properties">
            <PropertiesMultistepForm isEditing={isEditing} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Notizen">
            <NotesForm isEditing={isEditing} />
          </AccordionForm.Item>
        </AccordionForm>
      )}
      {!!contract && (
        <DeleteConfirmModal
          entityName={contract.name}
          isOpen={confirmDeleteModalOpen}
          setIsOpen={setConfirmDeleteModalOpen}
          onConfirm={() => {
            removeContract({ variables: { ids: [contract.id] } });
            onClose?.();
            onDelete?.();
          }}
          errorMsg={removeError && extractErrorMessage(removeError)}
        />
      )}
    </>
  );
};
