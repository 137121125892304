import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { AuthorizedRoute } from "./components/authorized-route";
import { MainLayout } from "./components/layout";
import { history } from "./history";
import { DistrictsPage } from "./pages/districts.page";
import { DistrictPage } from "./pages/district.page";
import { LoginPage } from "./pages/login.page";

import "./styles/fonts.css";

const AuthorizedRoutes: React.FC = () => {
  return (
    <>
      <AuthorizedRoute exact path="/districts" children={<DistrictsPage />} />
      <AuthorizedRoute
        path="/districts/:companyDistrict"
        children={<DistrictPage />}
      />
      <AuthorizedRoute exact path="/">
        <Redirect to="/districts" />
      </AuthorizedRoute>
    </>
  );
};

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" children={<LoginPage />} />
        <MainLayout>
          <AuthorizedRoutes />
        </MainLayout>
      </Switch>
    </Router>
  );
}

export default App;
