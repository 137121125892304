import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { ApolloError } from "@apollo/client";
import Editor from "@monaco-editor/react";
import {
  AlertRetryable,
  Button,
  ButtonIcon as ButtonIconComponent,
  ButtonGroup as ButtonGroupComponent,
  Icons,
  SubTitle,
  SuccessAlert,
  Tabs,
  Tab,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";

import {
  District,
  useGetTimeseriesQuery,
  usePublishTimeseriesMutation,
  useUpdateTimeseriesMutation,
  useGenerateForecastsMutation,
} from "../graphql/sdks/controller";

import { dynamicPageSize } from "../helpers/html.utils";
import { extractErrorMessage } from "../helpers/error.utils";

import {
  Column,
  Row,
  SubTitleWithButton as SubTitleWithButtonComponent,
} from "./style";
import { ExtendedProperty, LocalAsset } from "./types";
import {
  formatTimeseriesBeforeSubmit,
  sortProperties,
} from "../helpers/properties.utils";
import { Table } from "./table";
import { TableProps } from "./table";

type SummationInference = {
  inputs: { entityKey: string; propertyKey: string; factor: number }[];
  offset: number;
  thresholdType?: number;
};

interface EntityTableData {
  key: string;
  name: string;
  measuredCount: string;
  inferenceCount: string;
  isSelected: boolean;
}

const Container = styled(Row)`
  height: 86vh;
`;

const AvailableTimeseriesWrapper = styled(Column)`
  margin-left: 4px;
  margin-right: px;
`;

const ColumnWithoutMargin = styled(Column)`
  margin: 0;
`;

const ButtonGroup = styled(ButtonGroupComponent)`
  margin-left: 4px;
`;

const GenerateButton = styled(ButtonIconComponent)`
  margin-right: 4px;
  padding: 4px;
`;

const SubTitleWithButton = styled(SubTitleWithButtonComponent)`
  margin-bottom: 0;
`;

interface AvailablePropertiesProps {
  items: object[];
  loading?: boolean;
  columns: TableProps<any>["columns"];
}

const AvailableProperties: React.FC<AvailablePropertiesProps> = ({
  items,
  loading,
  columns,
}) => (
  <AvailableTimeseriesWrapper>
    <Table
      compact
      alternatingRows
      pageSize={3}
      isLoading={!!loading}
      filterKind="Properties"
      columns={columns}
      data={items}
    />
  </AvailableTimeseriesWrapper>
);

const entityColumns = [
  {
    Header: "Key",
    accessor: "key",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Measured Count",
    accessor: "measuredCount",
  },
  {
    Header: "Inference Count",
    accessor: "inferenceCount",
  },
];

export interface TimeseriesTableProps {
  district: District;
  assets: LocalAsset[];
}

export const InferencesOverview: React.FC<TimeseriesTableProps> = ({
  district,
  assets,
}) => {
  const [generateForecasts, { error: generateForecastsError }] =
    useGenerateForecastsMutation();

  const onGenerateForecasts = useCallback(async () => {
    await generateForecasts();
  }, [generateForecasts]);

  const [selectedEntityKey, setSelectedEntityKey] = useState<
    string | undefined
  >();
  const [selectedTab, setSelectedTab] =
    useState<"newMeasurement">("newMeasurement");
  const [value, setValue] = useState<string>("{}");
  const [valuesChanged, setValuesChanged] = useState<boolean>(false);
  const [error, setError] = useState<Error | any | undefined>();
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);

  const { data: timeseries, loading } = useGetTimeseriesQuery({
    variables: {
      districtIdentifier: {
        company: district.companyKey,
        district: district.key,
      },
      filter: {
        sourceType: ["district", "inference"],
      },
    },
  });

  const [updateTimeseries] = useUpdateTimeseriesMutation();
  const [publishTimeseries] = usePublishTimeseriesMutation();

  const sortedTimeseries = useMemo(
    () =>
      [...(timeseries?.timeseries ?? [])].sort((a, b) => {
        if (a.reference.entityKey === b.reference.entityKey) {
          return String(a.reference.propertyKey).localeCompare(
            String(b.reference.propertyKey)
          );
        }
        return String(a.reference.entityKey).localeCompare(
          String(b.reference.entityKey)
        );
      }),
    [timeseries]
  );

  const districtMeasuredMap = useMemo(
    () =>
      sortedTimeseries.reduce<Record<string, string[]>>((acc, ts) => {
        const { reference, source } = ts;
        if (!reference.entityKey || !reference.propertyKey) {
          return acc;
        }
        if (!acc[reference.entityKey]) {
          acc[reference.entityKey] = [];
        }
        if (source.type === "district") {
          acc[reference.entityKey].push(reference.propertyKey);
        }
        return acc;
      }, {}) ?? {},
    [sortedTimeseries]
  );

  const newInferenceMap = useMemo(
    () =>
      sortedTimeseries.reduce<
        Record<string, Record<string, SummationInference | null>>
      >((acc, ts) => {
        const { reference, source } = ts;
        if (!reference.entityKey || !reference.propertyKey) {
          return acc;
        }
        if (!acc[reference.entityKey]) {
          acc[reference.entityKey] = {};
        }
        if (source.type === "inference") {
          acc[reference.entityKey][reference.propertyKey] = source.summation
            ? {
                inputs: source.summation.inputs.map(
                  ({ entityKey, propertyKey, factor }) => ({
                    entityKey,
                    propertyKey,
                    factor,
                  })
                ),
                offset: source.summation.offset,
                thresholdType: source.summation.thresholdType ?? undefined,
              }
            : null;
        }
        return acc;
      }, {}) ?? {},
    [sortedTimeseries]
  );

  const entities = useMemo(
    () =>
      sortedTimeseries.reduce<EntityTableData[]>((acc, ts) => {
        const { reference } = ts;
        if (
          !reference.entityKey ||
          acc.some((entity) => entity.key === reference.entityKey)
        ) {
          return acc;
        }
        const inferenceCount = `${
          Object.values(newInferenceMap[reference.entityKey] || {}).filter(
            (i) => !!i
          ).length
        } / ${
          Object.values(newInferenceMap[reference.entityKey] || {}).length
        }`;
        const allAssets = [
          ...district.assets,
          ...district.buildings.flatMap((building) => building.assets),
        ];
        const entityName =
          reference.type === "district"
            ? district.name
            : allAssets.find((asset) => asset.key === reference.entityKey)
                ?.name ?? "";
        acc.push({
          key: reference.entityKey,
          name: entityName,
          measuredCount: (
            districtMeasuredMap[reference.entityKey].length || 0
          ).toString(),
          inferenceCount,
          isSelected: reference.entityKey === selectedEntityKey,
        });
        return acc;
      }, []),
    [
      district,
      sortedTimeseries,
      districtMeasuredMap,
      newInferenceMap,
      selectedEntityKey,
    ]
  );

  const properties = useMemo(
    () =>
      sortProperties(
        (assets
          .find((asset) => asset.key === selectedEntityKey)
          ?.properties.filter((p) => p.dataType === "Param") ??
          []) as ExtendedProperty[]
      ) ?? [],
    [assets, selectedEntityKey]
  );

  const districtMeasured = useMemo(
    () =>
      (districtMeasuredMap?.[selectedEntityKey ?? ""] ?? [])
        .filter(
          (p) =>
            !["errorMessage", "warningMessage", "operatingStatusMessage"].some(
              (m) => p.includes(m)
            )
        )
        .sort((p1, p2) => p1.localeCompare(p2)),
    [selectedEntityKey, districtMeasuredMap]
  );

  const handleAssetClicked = useCallback(
    ({ key }: { key: string }) => {
      setSelectedEntityKey(key);
      if (selectedTab === "newMeasurement") {
        setValue(JSON.stringify(newInferenceMap[key] || {}, null, 2));
      }
    },
    [newInferenceMap, selectedTab]
  );

  const handleTabClicked = useCallback(
    (tab: "newMeasurement") => {
      setSelectedTab(tab);
      if (!selectedEntityKey) {
        return;
      }
      if (tab === "newMeasurement") {
        setValue(
          JSON.stringify(newInferenceMap[selectedEntityKey] || {}, null, 2)
        );
      }
    },
    [newInferenceMap, selectedEntityKey]
  );

  const handleSave = useCallback(async () => {
    try {
      if (!selectedEntityKey) {
        throw new Error("No entity selected");
      }
      const allInferences = JSON.parse(value);
      const filteredTimeseries =
        sortedTimeseries.filter(
          (t) =>
            t.reference.entityKey === selectedEntityKey &&
            t.source.type === "inference"
        ) || [];

      const allResults = await Promise.all(
        filteredTimeseries.map(async (ts) => {
          const inferences = allInferences[ts.reference.propertyKey!];
          let timeseriesValues;
          if (selectedTab === "newMeasurement") {
            timeseriesValues = {
              ...ts,
              source: {
                ...ts.source,
                summation: inferences,
              },
            };
          } else {
            throw new Error(`Undefined tab selected - You chose poorly!`);
          }
          const response = await updateTimeseries({
            variables: {
              timeseries: formatTimeseriesBeforeSubmit(timeseriesValues),
            },
          });
          return response;
        })
      );
      await publishTimeseries();

      const allErrors = allResults.flatMap((r) => r.errors).filter((e) => !!e);

      if (allErrors.length) {
        setError(allErrors[0]);
        throw allErrors[0]?.message ?? "Unknown Error";
      }

      setError(undefined);
      setSuccessfulUpdate(true);
      setTimeout(() => setSuccessfulUpdate(false), 5000);
    } catch (e) {
      if (e instanceof ApolloError) {
        setError({ message: extractErrorMessage(e) });
        throw extractErrorMessage(e);
      } else if (typeof e === "string") {
        setError({ name: "Error", message: e });
        throw e;
      } else {
        setError(e as Error);
        throw e;
      }
    }
  }, [
    publishTimeseries,
    updateTimeseries,
    sortedTimeseries,
    selectedEntityKey,
    selectedTab,
    value,
  ]);

  return (
    <Container>
      <Column flex={1}>
        <SubTitle>Entitäten</SubTitle>
        <Table
          compact
          alternatingRows
          pageSize={dynamicPageSize(19, 25)}
          isLoading={loading}
          columns={entityColumns}
          data={entities || []}
          onRowClick={handleAssetClicked}
          initialState={{
            sortBy: [
              {
                id: "key",
              },
            ],
          }}
        />
      </Column>
      <Column flex={2}>
        {generateForecastsError && (
          <AlertRetryable message={generateForecastsError?.message} />
        )}
        <SubTitleWithButton>
          Inferenzen
          <ButtonGroup>
            {valuesChanged && <Button onClick={handleSave}>Speichern</Button>}
            <GenerateButton
              icon={Icons.Sparks}
              key="generate-forecasts"
              secondary
              onClick={onGenerateForecasts}
            >
              Generate Forecasts
            </GenerateButton>
          </ButtonGroup>
        </SubTitleWithButton>
        {successfulUpdate && (
          <SuccessAlert>Erfolgreich aktualisiert.</SuccessAlert>
        )}
        {error && (
          <AlertRetryable
            message={error?.message}
            onRetry={() => setError(undefined)}
          />
        )}
        <Tabs>
          <Tab
            title="New Measurement"
            onClick={() => handleTabClicked("newMeasurement")}
          ></Tab>
          <Tab title=""></Tab>
        </Tabs>
        <Editor
          height="45vh"
          language="json"
          options={{
            wordWrap: "wordWrapColumn",
            wrappingIndent: "indent",
            tabSize: 2,
            minimap: {
              enabled: false,
            },
          }}
          value={value}
          onChange={(v) => {
            if (!valuesChanged) setValuesChanged(true);
            setValue(v ?? "{}");
          }}
        />
        <ColumnWithoutMargin flex={1}>
          <SubTitle>Vorhandene Eigenschaften</SubTitle>
          {!!selectedEntityKey ? (
            <Row>
              <AvailableProperties
                columns={[
                  {
                    accessor: "key",
                    Header: "Distrikt gemessene Eigenschaften",
                  },
                ]}
                items={districtMeasured.map((i) => ({ key: i }))}
              />
              <AvailableProperties
                columns={[
                  {
                    accessor: "key",
                    Header: "Skalare Eigenschaften",
                    width: "150px",
                  },
                  {
                    accessor: "value",
                    Header: "",
                    Cell: ({
                      value,
                      row,
                    }: {
                      value: string;
                      row: { original: ExtendedProperty };
                    }) =>
                      `${value}${
                        row.original.unit ? ` ${row.original.unit}` : ""
                      }`,
                  },
                ]}
                items={properties}
              />
            </Row>
          ) : (
            <WarningAlert>Keine Entität ausgewählt</WarningAlert>
          )}
        </ColumnWithoutMargin>
      </Column>
    </Container>
  );
};
