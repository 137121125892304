import React, { useCallback, useContext, useMemo } from "react";
import { DateTime } from "luxon";
import { useTheme } from "styled-components";
import {
  DynamicListItemProps,
  Flex,
  FormikTextarea,
  Theme,
  useDynamicListItem,
  MultistepFormContext,
  Label,
} from "@ampeersenergy/ampeers-ui-components";

import { Note } from "../../graphql/sdks/controller";

import { Input, NewItemWrapper, PlusIcon, Row } from "../style";
import { DeleteButton } from "../icon-button";

export interface NoteListItemProps extends DynamicListItemProps {
  item: DynamicListItemProps["item"] & Note;
  isEditing?: boolean;
}

export const NoteListItem: React.FC<NoteListItemProps> = ({
  item,
  index,
  isEditing,
  ...props
}) => {
  const theme = useTheme() as Theme; // TODO: Remove this cast

  const { unregisterField } = useContext(MultistepFormContext) ?? {};

  const { onAddItem, onDelete } = useDynamicListItem({
    ...props,
    item,
    index,
  });

  const onNoteDelete = useCallback(() => {
    if (unregisterField) {
      unregisterField(`notes[${index}].content`);
      unregisterField(`notes[${index}].createdAt`);
      unregisterField(`notes[${index}].createdBy`);
    }
    onDelete();
  }, [index, unregisterField, onDelete]);

  const formattedDate = useMemo(
    () =>
      DateTime.fromISO(item.createdAt)
        .setLocale("de-DE")
        .toLocaleString(DateTime.DATETIME_MED),
    [item]
  );

  if (item.isNewValue) {
    if (!isEditing) {
      return null;
    }

    return (
      <NewItemWrapper onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        Notiz hinzufügen
      </NewItemWrapper>
    );
  }

  if (!isEditing) {
    return (
      <div>
        <Label>
          {item.createdBy}: {formattedDate}
        </Label>
        <span>{item.content}</span>
      </div>
    );
  }

  return (
    <Row>
      <Flex>
        <Row>
          <Input
            flex={1}
            id={`notes[${index}].createdBy`}
            name={`notes[${index}].createdBy`}
            label={`${formattedDate}`}
            placeholder="Erstellt von *"
            isEditing={isEditing}
          />
        </Row>
        <FormikTextarea
          id={`notes[${index}].content`}
          name={`notes[${index}].content`}
          placeholder="Notiz *"
          isEditing={isEditing}
          required
        />
      </Flex>
      {!!isEditing && (
        <DeleteButton margin="37px 4px 0px 4px" onClick={onNoteDelete} />
      )}
    </Row>
  );
};
