import React, { useMemo } from "react";
import { UpdatedTimeseriesExternalIds } from "./types";
import { ExtendedColumn, Table } from "../table";
import { InlineCheckbox } from "../style";

export interface TimeseriesExternalIdsTableProps {
  timeseries: UpdatedTimeseriesExternalIds[];
  onTimeseriesChange: (
    timeseries: UpdatedTimeseriesExternalIds,
    sourceOrTarget: "source" | "target",
    value: string
  ) => void;
}

export const TimeseriesExternalIdsTable: React.FC<
  TimeseriesExternalIdsTableProps
> = ({ timeseries, onTimeseriesChange }) => {
  const columns: ExtendedColumn<UpdatedTimeseriesExternalIds>[] =
    useMemo(() => {
      return [
        {
          accessor: "reference.propertyKey",
          Header: "Property",
          width: "4%",
        },
        {
          Header: "Quelle",
          width: "48%",
          columns: [
            {
              accessor: "source.externalId",
              Header: "Aktuelle ID",
              width: "50%",
              Cell: Cell(onTimeseriesChange, "source"),
            },
            {
              accessor: "source.updatedExternalId",
              Header: "Generierte ID",
              width: "50%",
              Cell: Cell(onTimeseriesChange, "source"),
            },
          ],
        },
        {
          Header: "Target",
          width: "48%",
          columns: [
            {
              accessor: "target.externalId",
              Header: "Aktuelle ID",
              width: "50%",
              Cell: Cell(onTimeseriesChange, "target"),
            },
            {
              accessor: "target.updatedExternalId",
              Header: "Generierte ID",
              width: "50%",
              Cell: Cell(onTimeseriesChange, "target"),
            },
          ],
        },
      ].filter((v) => !!v) as ExtendedColumn<UpdatedTimeseriesExternalIds>[];
    }, [onTimeseriesChange]);

  return (
    <Table compact isLoading={false} columns={columns} data={timeseries} />
  );
};

const Cell =
  (
    onTimeseriesChange: TimeseriesExternalIdsTableProps["onTimeseriesChange"],
    sourceOrTarget: "source" | "target"
  ) =>
  ({ value, row }: { value: string | null; row: any }) => {
    if (!value) return null;

    if (
      row.original[sourceOrTarget].updatedExternalId ===
      row.original[sourceOrTarget].externalId
    ) {
      return null;
    }

    return (
      <InlineCheckbox
        id={value}
        key={value}
        label={value}
        checked={
          row.original[sourceOrTarget].updatedExternalIdToBeCommited === value
        }
        onChange={(v) => {
          onTimeseriesChange(row.original, sourceOrTarget, value);
        }}
      />
    );
  };
