import React, { ChangeEvent, useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTheme } from "styled-components";
import {
  DynamicListItemProps,
  ErrorAlert,
  Label,
  Theme,
  Select as RawSelect,
  FormikInput,
  Button,
  DynamicList,
  Icons,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";

import { PropertyTemplate } from "../../graphql/sdks/controller";

import { AssetFormValues, LocalUnit, TensorFormValue } from "../types";

import { InterpolationListItem } from "../tensors-form/interpolation-list-item";
import { InterpolationEditForm } from "../tensors-form/interpolation-edit-form";
import { UnitsForm } from "../units-form";

import {
  ButtonGroupPullRight,
  InlineItemsWrapper,
  PropertyEditComponentWrapper,
} from "../properties-form/style";
import { Input, ListContainer, Row, Select } from "../style";

import { tensorValidationSchema } from "../validation";
import {
  isPropertyRequired,
  isValidTensorValue,
} from "../../helpers/properties.utils";
import { ValidationError } from "yup";
import { findValue } from "../../helpers/object.utils";

const dimensionLabels: Record<string, string> = {
  dimensionX: "X-Achse",
  dimensionY: "Y-Achse",
};
export interface DimensionFormProps {
  item: DynamicListItemProps["item"] & TensorFormValue;
  identifier: string;
  isEditing?: boolean;
  units: string[];
}

export const DimensionForm: React.FC<DimensionFormProps> = ({
  item,
  identifier,
  isEditing,
  units,
}) => {
  return (
    <>
      <Label>
        {dimensionLabels[identifier.split(".").slice(-1)[0]] ?? "--"}
      </Label>
      <Row>
        <Input
          id={`${identifier}.key`}
          name={`${identifier}.key`}
          label="Key *"
          isEditing={isEditing}
        />
        <Input
          id={`${identifier}.description`}
          name={`${identifier}.description`}
          label="Beschreibung *"
          isEditing={isEditing}
        />
        <UnitsForm
          identifier={`${identifier}.unit`}
          isEditing={isEditing}
          units={units}
          disabled={!units.length || (item.unit !== null && units.length < 2)}
        />
      </Row>
    </>
  );
};

export interface TensorEditFormProps extends DynamicListItemProps {
  isEditing?: boolean;
  item: DynamicListItemProps["item"] & TensorFormValue;
  propertyTemplates: PropertyTemplate[];
  unitKeys: (string | null | undefined)[];
  units: LocalUnit[];
  isValid?: boolean;
  prefixIdentifier?: string;
  setEditMode: (value: boolean) => void;
  onDelete: () => void;
}

export const TensorEditForm: React.FC<TensorEditFormProps> = (props) => {
  const {
    item,
    index,
    isEditing,
    propertyTemplates,
    unitKeys,
    units,
    prefixIdentifier,
    setEditMode,
    onDelete,
  } = props;
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const theme = useTheme() as Theme;
  const { values, setFieldValue } = useFormikContext<AssetFormValues>();

  const items = useMemo(
    () =>
      prefixIdentifier
        ? findValue<AssetFormValues>(prefixIdentifier, values).tensors
        : values.tensors,
    [prefixIdentifier, values]
  );

  const hasInvalidValues = useMemo(() => {
    try {
      tensorValidationSchema.validateSync(item);
    } catch (e) {
      const validationError = e as ValidationError;
      return (
        !validationError.params ||
        (validationError.params.path as string).includes("interpolations")
      );
    }
  }, [item]);

  const onPropertyTemplateChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      const template = propertyTemplates.find(
        ({ key }) => key === e.target.value
      );

      if (template)
        setFieldValue(`${_prefixIdentifier}tensors[${index}]`, {
          ...template,
          key: template.key,
          propKey: template.key,
          value: item.value,
          unit: template.unit,
        });
    },
    [index, propertyTemplates, item, _prefixIdentifier, setFieldValue]
  );

  const propertyExists = useCallback(
    (propKey: string): boolean => {
      return items.some((p) => p.key === propKey);
    },
    [items]
  );

  const onAddInterpolation = useCallback(() => {
    const template = propertyTemplates.find(({ key }) => key === item.key);

    return {
      dimensionX: {
        values: new Array(3).fill(null),
        unit: null,
        ...(template?.defaultValue?.dimensionX ?? {}),
      },
      dimensionY: {
        values: new Array(3).fill(null),
        unit: null,
        ...(template?.defaultValue?.dimensionY ?? {}),
      },
      values: new Array(3).fill(new Array(3).fill(null)),
    };
  }, [item, propertyTemplates]);

  const InterpolationEditComponent = useCallback(
    (props) => {
      return (
        <InterpolationEditForm
          item={props.item}
          index={index}
          interpolationIndex={props.index}
          isEditing={isEditing}
          units={units ?? []}
          prefixIdentifier={prefixIdentifier}
        />
      );
    },
    [isEditing, units, index, prefixIdentifier]
  );

  return (
    <PropertyEditComponentWrapper $borderColor={theme.primaryColor}>
      {item.isRequired && !isValidTensorValue(item.value) && (
        <ErrorAlert>
          Diese Property ist erforderlich, hat aber keine Interpolationen.
        </ErrorAlert>
      )}
      {!item.isRequired && !isValidTensorValue(item.value) && (
        <WarningAlert>Diese Property hat keine Interpolationen.</WarningAlert>
      )}
      {item.isRequired && hasInvalidValues && (
        <ErrorAlert>Diese Property hat ungültige Werte.</ErrorAlert>
      )}
      {!item.isRequired && hasInvalidValues && (
        <WarningAlert>Diese Property hat ungültige Werte.</WarningAlert>
      )}

      {isEditing && (
        <RawSelect
          id={`${_prefixIdentifier}tensors[${index}].key`}
          name={`${_prefixIdentifier}tensors[${index}].key`}
          label="Template"
          onChange={onPropertyTemplateChange}
          value={item.key}
          hint={item.description || "Keine Beschreibung vorhanden"}
        >
          <option value={"null"} key="null">
            --
          </option>
          {propertyTemplates
            ?.filter((p) => p.dataType === "tensor")
            .map(({ key, flags }) => (
              <option key={key} value={key} disabled={propertyExists(key)}>
                {key}
                {isPropertyRequired(values.flags, flags) ? " *" : ""}
              </option>
            ))}
        </RawSelect>
      )}
      <InlineItemsWrapper>
        <Input
          flex={3}
          id={`${_prefixIdentifier}tensors[${index}].propKey`}
          name={`${_prefixIdentifier}tensors[${index}].propKey`}
          label="Key *"
          isEditing={isEditing}
          hint={
            item.key !== item.propKey
              ? `Benutzerdefinierte Eigenschaften müssen mit "custom_" anfangen.`
              : ""
          }
        />
        <UnitsForm
          flex={2}
          identifier={`${_prefixIdentifier}tensors[${index}].unit`}
          isEditing={isEditing}
          units={unitKeys}
          label="Internal Unit"
          disabled={
            !unitKeys.length || (item.unit !== null && unitKeys.length < 2)
          }
        />
        <Select
          flex={2}
          id={`${_prefixIdentifier}tensors[${index}].dataSubType`}
          name={`${_prefixIdentifier}tensors[${index}].dataSubType`}
          label="Data sub type *"
          isEditing={isEditing}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          <option value="float">Float</option>
          <option value="integer">Integer</option>
          <option value="string">String</option>
          <option value="boolean">Boolean</option>
        </Select>
      </InlineItemsWrapper>
      {(item.isPlaceholder || item.propKey.includes("custom_")) && (
        <FormikInput
          id={`${_prefixIdentifier}tensors[${index}].description`}
          name={`${_prefixIdentifier}tensors[${index}].description`}
          label="Beschreibung *"
          isEditing={isEditing}
        />
      )}
      <ListContainer>
        <DynamicList
          name={`${_prefixIdentifier}tensors[${index}].value.interpolations`}
          type={"Interpolation"}
          items={item.value?.interpolations ?? []}
          validateItem={(item) =>
            tensorValidationSchema.pick(["value"]).isValidSync(item)
          }
          ListItemComponent={InterpolationListItem}
          EditComponent={InterpolationEditComponent}
          Icon={Icons.Settings}
          interactive={isEditing}
          onNewItem={onAddInterpolation}
        />
      </ListContainer>
      <ButtonGroupPullRight>
        {isEditing && (
          <Button
            secondary
            customTheme={{
              primaryColor: theme.palette.error.color,
              secondaryColor: theme.palette.error.background,
            }}
            onClick={onDelete}
          >
            Löschen
          </Button>
        )}
        <Button secondary onClick={() => setEditMode(false)}>
          Schliessen
        </Button>
      </ButtonGroupPullRight>
    </PropertyEditComponentWrapper>
  );
};
