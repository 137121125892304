import React, { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { DynamicList, Icons } from "@ampeersenergy/ampeers-ui-components";

import { LocalAsset, ExtendedTimeseries, TimeseriesFormValues } from "../types";

import { ListContainer } from "./style";
import { InputsEditForm } from "./inputs-edit-form";

import { extTimeseriesSummationInputValidationSchema } from "../validation";

export interface InputsListProps {
  timeseries: ExtendedTimeseries[];
  assets: LocalAsset[];
  isEditing?: boolean;
}

export const InputsList: React.FC<InputsListProps> = ({
  timeseries,
  assets,
  isEditing,
}) => {
  const { values } = useFormikContext<TimeseriesFormValues>();

  const onNewItem = useCallback((item) => {
    return {
      ...item,
      name: "",
      template: "",
      properties: [],
      timeseries: [],
      tensors: [],
    };
  }, []);

  const ChildrenAssetEditFormComponent = useCallback(
    (props) => {
      return (
        <InputsEditForm {...props} assets={assets} isEditing={isEditing} />
      );
    },
    [assets, isEditing]
  );

  const validateInputListItem = useCallback((item) => {
    return extTimeseriesSummationInputValidationSchema.isValidSync(item);
  }, []);

  const items = useMemo(() => {
    return (
      values.source.summation?.inputs?.map((input) => {
        const inputTimeseries = timeseries.find(
          (ts) =>
            ts.reference.propertyKey === input.propertyKey &&
            ts.reference.entityKey === input.entityKey
          // ts.uuid === input.timeseriesId
        );

        const inputAsset = assets.find(
          (asset) => asset.key === input.entityKey
        );

        return {
          ...input,
          key: `${inputTimeseries?.refTimeseriesProp?.[0]?.key} (${inputAsset?.name})`,
          inputTimeseries,
          inputAsset,
        };
      }) ?? []
    );
  }, [timeseries, assets, values]);

  return (
    <ListContainer>
      <DynamicList
        name="source.summation.inputs"
        type="Input"
        items={items}
        validateItem={validateInputListItem}
        EditComponent={ChildrenAssetEditFormComponent}
        Icon={Icons.Settings}
        interactive={isEditing}
        onNewItem={onNewItem}
      />
    </ListContainer>
  );
};
