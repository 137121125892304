import React from "react";
import styled from "styled-components";

import { Column, Row } from "../style";
import { AssetName, SubTitleWithoutBottomMargin } from "./style";

const Color = styled.div<{ $colors: [string, string] }>`
  background: repeating-linear-gradient(
    -45deg,
    ${(props) => props.$colors[0]},
    ${(props) => props.$colors[0]} 10px,
    ${(props) => props.$colors[1]} 10px,
    ${(props) => props.$colors[1]} 20px
  );
  width: 20px;
  height: 44 px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

export interface FormTileProps {
  name: string;
  colors: [string, string];
  assetName?: string;
}

export const FormTile: React.FC<FormTileProps> = ({
  name,
  colors,
  assetName,
}) => {
  return (
    <Row>
      <Color $colors={colors}></Color>
      <Column>
        <SubTitleWithoutBottomMargin>{name}</SubTitleWithoutBottomMargin>
        <AssetName>{assetName}</AssetName>
      </Column>
    </Row>
  );
};
