import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import { ApolloError } from "@apollo/client";
import {
  AlertRetryable,
  Button,
  FormikSubmit,
  Label,
  SubTitle,
  SuccessAlert,
} from "@ampeersenergy/ampeers-ui-components";

import {
  ButtonGroupPullRight,
  FormWrapperWithPadding,
  Input,
  Muted,
  Row,
  Select,
} from "../style";

import {
  ExtendedTimeseries,
  TimeseriesInferenceFormValues,
  LocalAsset,
  LocalDistrict,
} from "../types";
import { extTimeseriesValidationSchema } from "../validation";
import { InputsList } from "./inputs-list";
import {
  formatInitialTimeseriesFormValues,
  formatTimeseriesBeforeSubmit,
} from "../../helpers/properties.utils";
import { getColors } from "./utils";
import {
  GetAssetsDocument,
  GetTimeseriesDocument,
  useUpdateTimeseriesMutation,
} from "../../graphql/sdks/controller";
import { extractErrorMessage } from "../../helpers/error.utils";
import { FormattedInference } from "./formatted-inference";
import { FormTile } from "./form-tile";

export interface InferencesOverviewFormProps {
  selectedTimeseries?: ExtendedTimeseries;
  selectedAsset?: LocalAsset;
  timeseries: ExtendedTimeseries[];
  assets: LocalAsset[];
  district: LocalDistrict;
}

export const InferenceOverviewForm: React.FC<InferencesOverviewFormProps> = ({
  selectedTimeseries,
  selectedAsset,
  timeseries,
  assets,
  district,
}) => {
  const [error, setError] = useState<Error | any | undefined>();
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);

  const [updateTimeseries] = useUpdateTimeseriesMutation({
    refetchQueries: [
      {
        query: GetTimeseriesDocument,
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
          filter: {
            sourceType: ["district", "inference"],
          },
        },
      },
      {
        query: GetAssetsDocument,
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
        },
      },
    ],
  });

  const onSubmit = useCallback(
    async (values: TimeseriesInferenceFormValues, { setValues }) => {
      try {
        const {
          id,
          uuid,
          name,
          reference,
          unit,
          description,
          target,
          source,
          notes,
          comment,
          metadata,
        } = values;

        const timeseriesValues = formatTimeseriesBeforeSubmit({
          id,
          uuid,
          name,
          reference,
          unit,
          description,
          comment,
          target,
          source,
          notes,
          metadata,
        });

        let newTimeseriesValues;

        const { data, errors } = await updateTimeseries({
          variables: {
            timeseries: timeseriesValues,
          },
        });
        newTimeseriesValues = data?.updateTimeseries;
        if (errors?.length) {
          setError(errors[0].message);
          throw errors[0]?.message ?? "Unknown Error";
        }

        setTimeout(() => setSuccessfulUpdate(false), 5000);

        setError(undefined);
        setSuccessfulUpdate(true);

        return { data: newTimeseriesValues };
      } catch (e) {
        if (e instanceof ApolloError) {
          setError({ message: extractErrorMessage(e) });
          throw extractErrorMessage(e);
        } else if (typeof e === "string") {
          setError({ name: "Error", message: e });
          throw e;
        } else {
          setError(e as Error);
          throw e;
        }
      }
    },
    [updateTimeseries]
  );

  if (!selectedTimeseries) {
    return (
      <Muted style={{ padding: "10px 14px" }}>Keine Zeitreihe ausgewählt</Muted>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={
        formatInitialTimeseriesFormValues(selectedTimeseries) as any
      }
      validationSchema={extTimeseriesValidationSchema}
      onSubmit={onSubmit}
      validateOnChange
      validateOnMount
    >
      {({ values, resetForm, isSubmitting, isValid }) => (
        <FormWrapperWithPadding>
          {successfulUpdate && (
            <SuccessAlert>{values.name} erfolgreich aktualisiert.</SuccessAlert>
          )}
          {error && (
            <AlertRetryable
              message={error?.message}
              onRetry={() => setError(undefined)}
            />
          )}
          <FormTile
            name={values.refTimeseriesProp?.[0]?.key ?? values.name}
            colors={getColors(selectedTimeseries, selectedAsset)}
            assetName={values.asset?.name}
          />
          {values.description ? (
            <>
              <Label>Beschreibung</Label>
              {values.description}
            </>
          ) : null}
          {values.source.type === "district" ? (
            <>
              <Label>Protokoll</Label>
              {values.source.protocol ?? "keine Angabe"}
              <Label>Externe ID</Label>
              {values.source.externalId ?? "keine Angabe"}
              <Label>Zusätzliche Externe ID</Label>
              {values.source.additionalExternalId ?? "keine Angabe"}
              <Label>Einheiten Konvertierung</Label>
              {`${values.source.unit} → ${values.unit}`}
              <Label>Invertiert?</Label>
              {values.source.isInverted ? "Ja" : "Nein"}
            </>
          ) : (
            <>
              <Row>
                <Input
                  id="offset"
                  key="offset"
                  name="source.summation.offset"
                  label="Offset"
                  type="number"
                />
                <Select
                  id="thresholdType"
                  key="thresholdType"
                  name="source.summation.thresholdType"
                  label="Threshold Type"
                >
                  <option key="null" value={"null"}>
                    (null)
                  </option>
                  <option key="0" value="0">
                    {"bool(result > 0)"}
                  </option>
                  <option key="1" value="1">
                    {"max(result, 0)"}
                  </option>
                </Select>
              </Row>
              <SubTitle>Inputs</SubTitle>
              <InputsList timeseries={timeseries} assets={assets} isEditing />
              <SubTitle>Active Inference</SubTitle>
              <FormattedInference
                inference="active"
                timeseries={timeseries}
                assets={assets}
              />
              {/* <SubTitle>Default Inference</SubTitle>
              <FormattedInference
                inference="default"
                timeseries={timeseries}
                assets={assets}
              /> */}
            </>
          )}
          {values.source.type !== "district" ? (
            <ButtonGroupPullRight style={{ marginTop: 10 }}>
              <Button
                secondary
                onClick={() => {
                  resetForm();
                }}
              >
                Zurücksetzen
              </Button>
              <FormikSubmit isLoading={isSubmitting} disabled={!isValid}>
                Speichern
              </FormikSubmit>
            </ButtonGroupPullRight>
          ) : null}
        </FormWrapperWithPadding>
      )}
    </Formik>
  );
};
