import React, { useCallback, useContext } from "react";
import styled, { useTheme } from "styled-components";
import * as yup from "yup";
import {
  DynamicList,
  DynamicListItemProps,
  MultistepFormContext,
  Theme,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";

import { DistrictFormValues } from "../types";
import { DeleteButton } from "../icon-button";

import {
  Input,
  ListContainer,
  Muted,
  PlusIcon,
  SubTitleWithMargin,
} from "../style";

import { capitalize } from "../../helpers/string.utils";

const ListItemContainer = styled.li`
  display: flex;
  flex: 1;
`;

const NewItemWrapper = styled.li`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    ${(props) => `background-color: ${props.theme.palette.backgroundMuted};`}
  }
`;

export interface EmailListItemProps extends DynamicListItemProps {
  isEditing?: boolean;
  identifier: "alertingEmails" | "reportingEmails";
}

export interface EmailFormProps {
  isEditing?: boolean;
  title: string;
  contact: DistrictFormValues["contact"];
  identifier: "alertingEmails" | "reportingEmails";
}

const EmailListItem: React.FC<EmailListItemProps> = (props) => {
  const { item, identifier, isEditing, index } = props;

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  const {
    isValid,
    onAddItem,
    onDelete: onDeleteItem,
    EditComponent = () => null,
    type,
  } = useDynamicListItem({
    ...props,
  });

  const theme = useTheme() as Theme;

  const onAdd = useCallback(() => {
    if (registerField) {
      registerField(`contact.${identifier}[${index}].value`);
    }
    onAddItem();
  }, [registerField, identifier, index, onAddItem]);

  const onDelete = useCallback(() => {
    if (unregisterField) {
      unregisterField(`contact.${identifier}[${index}].value`);
    }
    onDeleteItem();
  }, [unregisterField, identifier, index, onDeleteItem]);

  if (item.isNewValue) {
    return (
      <NewItemWrapper key={item.key} onClick={onAdd}>
        <PlusIcon size={30} color={theme.primaryColor} />
        {type} hinzufügen
      </NewItemWrapper>
    );
  }

  return (
    <ListItemContainer key={item.key}>
      <EditComponent {...props} isValid={isValid} />
      {isEditing && <DeleteButton margin="36px 0 0 0" onClick={onDelete} />}
    </ListItemContainer>
  );
};

export const EmailForm: React.FC<EmailFormProps> = ({
  isEditing,
  contact,
  identifier,
  title,
}) => {
  const EmailEditComponent = useCallback(
    ({ index }) => {
      return (
        <Input
          name={`contact.${identifier}[${index}].value`}
          label="E-mail *"
          placeholder="email@ampeersenergy.de"
          isEditing={isEditing}
        />
      );
    },
    [isEditing, identifier]
  );

  const EmailListItemComponent = useCallback(
    (props) => {
      return (
        <EmailListItem
          {...props}
          identifier={identifier}
          isEditing={isEditing}
        />
      );
    },
    [isEditing, identifier]
  );

  const validateEmail = useCallback(
    (item) => yup.string().email().isValidSync(item),
    []
  );

  return (
    <ListContainer>
      <SubTitleWithMargin>{title}</SubTitleWithMargin>
      {isEditing || contact[identifier].length ? (
        <DynamicList
          name={`contact.${identifier}`}
          type="E-Mail"
          items={contact[identifier]}
          validateItem={validateEmail}
          ListItemComponent={EmailListItemComponent}
          EditComponent={EmailEditComponent}
          interactive={isEditing}
        />
      ) : (
        <Muted>Keine {capitalize(title)} vorhanden</Muted>
      )}
    </ListContainer>
  );
};
