import React, { useCallback, useMemo } from "react";

import {
  Building,
  District,
  GetDistrictsDocument,
  useUpdateAssetMutation,
  useUpdateBuildingMutation,
  useUpdateDistrictMutation,
} from "../../graphql/sdks/controller";

import { ExtendedAsset, ExtendedContract } from "../types";
import { ElementType, Entity } from "./types";

import { JsonViewerForm } from "../json-viewer";

export interface JsonEditorProps {
  entity: Partial<
    Entity<ExtendedAsset | Building | ExtendedContract | District>
  > & {
    buildingName?: string;
  };
}

const refetchQueries = [
  {
    query: GetDistrictsDocument,
    variables: {
      filter: {
        multipleDistricts: [
          {
            company: "all",
            district: "all",
          },
        ],
      },
    },
  },
];

export const JsonEditor: React.FC<JsonEditorProps> = ({ entity }) => {
  const [updateAsset] = useUpdateAssetMutation({
    refetchQueries,
  });

  const [updateBuilding] = useUpdateBuildingMutation({
    refetchQueries,
  });

  const [updateDistrict] = useUpdateDistrictMutation({
    refetchQueries,
  });

  const immutableKeys: (
    | "id"
    | "key"
    | "template"
    | "description"
    | "companyKey"
  )[] = useMemo(() => {
    switch (entity.elementType) {
      case ElementType.Asset:
        return ["key", "id", "template", "description"];
      case ElementType.Building:
        return ["key", "id", "template"];
      case ElementType.Contract:
        return ["key", "id"];
      case ElementType.District:
        return ["key", "companyKey", "id"];
      default:
        return [];
    }
  }, [entity]);

  const onSubmit = useCallback(
    async (values) => {
      const { id, key, companyKey, template, ...cleanValues } = values;

      switch (entity.elementType) {
        case ElementType.Asset:
          await updateAsset({
            variables: { asset: { ...cleanValues, key: entity.key, template } },
          });
          break;
        case ElementType.Building:
          await updateBuilding({
            variables: { building: { ...cleanValues, key: entity.key } },
          });
          break;
        case ElementType.District:
          await updateDistrict({ variables: { district: cleanValues } });
          break;
        default:
          break;
      }
    },
    [updateAsset, updateBuilding, updateDistrict, entity]
  );

  const { elementType, buildingName, ...cleanEntity } = entity;

  return (
    <>
      <JsonViewerForm
        id="district_json_editor"
        onSubmit={onSubmit}
        initialValues={cleanEntity}
        immutableKeys={immutableKeys as any}
      />
    </>
  );
};
