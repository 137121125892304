import React from "react";
import { SpinnerDark } from "@ampeersenergy/ampeers-ui-components";

import { SpinnerWrapper } from "./style";

import { ExtendedAsset, LocalDistrict, LocalTimeseries } from "../types";
import { GraphView } from "./graph-view";

export interface InferencesOverviewProps {
  district: LocalDistrict;
  assets: ExtendedAsset[];
  timeseries: LocalTimeseries[];
  loading: boolean;
}

export const InferencesOverview: React.FC<InferencesOverviewProps> = ({
  district,
  assets,
  timeseries,
  loading,
}) => {
  if (loading) {
    return (
      <SpinnerWrapper>
        <SpinnerDark size={25} />
      </SpinnerWrapper>
    );
  }

  return (
    <GraphView district={district} assets={assets} timeseries={timeseries} />
  );
};
