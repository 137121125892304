import React, { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import styled, { useTheme } from "styled-components";
import { Tag, Theme, WarningAlert } from "@ampeersenergy/ampeers-ui-components";

import { MultistepFormContext } from "./multistep-form/context";
import { PropertiesForm, PropertiesFormProps } from "./properties-form";
import { Column, FormSectionHeader, TagWrapper } from "./style";

import { AssetFormValues, PropertyFormValue } from "./types";
import { AssetFlags } from "../graphql/sdks/controller";
import { findValue } from "../helpers/object.utils";

export const Labels = styled.div`
  display: flex;
`;

export const Header = styled.div<{ $alignEnd: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.$alignEnd ? "flex-end" : "space-between"};
  ${(props) => (props.$alignEnd ? "margin-bottom: 12px;" : "")}
`;

export interface PropertiesMultistepFormProps extends PropertiesFormProps {
  withHeader?: boolean;
}

export const PropertiesMultistepForm: React.FC<
  PropertiesMultistepFormProps
> = ({ isEditing, withHeader, prefixIdentifier, items, propertyTemplates }) => {
  const { values } = useFormikContext<AssetFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";
    const accessor = prefixIdentifier
      ? findValue<{ properties: PropertyFormValue[] }>(
          prefixIdentifier ?? "",
          values
        )
      : values;

    if (registerField) {
      accessor.properties?.forEach((_, i) => {
        registerField(`${_prefixIdentifier}properties[${i}].propKey`);
        registerField(`${_prefixIdentifier}properties[${i}].value`);
        registerField(`${_prefixIdentifier}properties[${i}].dataType`);
        registerField(`${_prefixIdentifier}properties[${i}].dataSubType`);
        registerField(`${_prefixIdentifier}properties[${i}].unit`);
        registerField(`${_prefixIdentifier}properties[${i}].description`);
        registerField(`${_prefixIdentifier}properties[${i}].comment`);
      });
    }

    return () => {
      if (unregisterField) {
        accessor.properties?.forEach((_, i) => {
          unregisterField(`${_prefixIdentifier}properties[${i}].propKey`);
          unregisterField(`${_prefixIdentifier}properties[${i}].value`);
          unregisterField(`${_prefixIdentifier}properties[${i}].dataType`);
          unregisterField(`${_prefixIdentifier}properties[${i}].dataSubType`);
          unregisterField(`${_prefixIdentifier}properties[${i}].unit`);
          unregisterField(`${_prefixIdentifier}properties[${i}].description`);
          unregisterField(`${_prefixIdentifier}properties[${i}].comment`);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerField, unregisterField, values, prefixIdentifier]);

  return (
    <Column>
      {withHeader && (
        <Header $alignEnd={!withHeader}>
          <FormSectionHeader>Properties</FormSectionHeader>
          <PropertiesLabels flags={values.flags} />
        </Header>
      )}
      {values.template ? (
        <PropertiesForm
          isEditing={isEditing}
          prefixIdentifier={prefixIdentifier}
          items={items}
          propertyTemplates={propertyTemplates}
        />
      ) : (
        <WarningAlert>Es muss ein Template ausgewählt werden.</WarningAlert>
      )}
    </Column>
  );
};

export const PropertiesLabels: React.FC<{ flags?: AssetFlags }> = ({
  flags = { isMonitoringRelevant: true, isOptimizationRelevant: true },
}) => {
  const theme = useTheme() as Theme;
  return (
    <Labels>
      {flags.isMonitoringRelevant && (
        <TagWrapper>
          <span className="tag">
            <Tag color={theme.palette.warning.color}>mon</Tag>
          </span>
          <span className="label">Monitoring relevant</span>
        </TagWrapper>
      )}
      {flags.isOptimizationRelevant && (
        <TagWrapper>
          <span className="tag">
            <Tag color={theme.palette.success.color}>op</Tag>
          </span>
          <span className="label">Operation relevant</span>
        </TagWrapper>
      )}
    </Labels>
  );
};
