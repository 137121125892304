import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { DynamicList } from "@ampeersenergy/ampeers-ui-components";

import { ListContainer } from "../style";
import { AssetFormValues } from "../types";
import { ValuesListItem } from "./value-list-item";

export interface ValuesListProps {
  isEditing?: boolean;
  index: number;
}

export const ValuesList: React.FC<ValuesListProps> = ({ isEditing, index }) => {
  const { values } = useFormikContext<AssetFormValues>();

  const PropertyValueListItemComponent = useCallback(
    (props) => {
      return (
        <ValuesListItem {...props} propIndex={index} isEditing={isEditing} />
      );
    },
    [isEditing, index]
  );

  const validateProperty = useCallback((item) => {
    return true;
  }, []);

  return (
    <ListContainer>
      <DynamicList
        name={`properties[${index}].value`}
        items={values.properties[index].value ?? []}
        type="Wert"
        validateItem={validateProperty}
        ListItemComponent={PropertyValueListItemComponent}
      />
    </ListContainer>
  );
};
