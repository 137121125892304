import React from "react";
import { useTheme } from "styled-components";
import {
  DynamicListItemProps,
  Theme,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";

import { DeleteButton } from "../icon-button";
import { Input, NewItemWrapper, PlusIcon } from "../style";
import { PropertyListItemWrapper } from "./style";

export interface ValuesListItemProps extends DynamicListItemProps {
  propIndex: number;
  isEditing?: boolean;
  item: DynamicListItemProps["item"];
}

export const ValuesListItem: React.FC<ValuesListItemProps> = (props) => {
  const { item, index, isEditing, propIndex } = props;

  const theme = useTheme() as Theme;
  const { onAddItem, onDelete, type } = useDynamicListItem({
    ...props,
  });

  if (item.isNewValue) {
    return (
      <NewItemWrapper key={item.key} onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        {type} hinzufügen
      </NewItemWrapper>
    );
  }

  return (
    <PropertyListItemWrapper>
      <Input
        isEditing={isEditing}
        name={`properties[${propIndex}].value[${index}].value`}
      />
      {isEditing && (
        <DeleteButton
          secondary
          onClick={onDelete}
          color={theme.palette.error.color}
        />
      )}
    </PropertyListItemWrapper>
  );
};
