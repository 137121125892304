import { useCallback, useMemo, useState } from "react";

export const usePagination = <T,>(rows: T[], pageSize: number = 15) => {
  const totalSize = rows.length;
  const [pageIndex, setPageIndex] = useState(0);

  const nextPage = useCallback(
    () => setPageIndex((p) => p + 1),
    [setPageIndex]
  );
  const previousPage = useCallback(
    () => setPageIndex((p) => p - 1),
    [setPageIndex]
  );

  const pageCount = useMemo(
    () => Math.ceil(totalSize / pageSize),
    [pageSize, totalSize]
  );
  const currentPageLength = useMemo(
    () =>
      pageIndex < Math.ceil(totalSize / pageSize)
        ? pageSize
        : totalSize % pageSize || pageSize,
    [pageIndex, pageSize, totalSize]
  );
  const pages = useMemo(
    () =>
      Array.from({ length: pageCount }, (_, index) =>
        rows.slice(index * pageSize, (index + 1) * pageSize)
      ),
    [pageCount, rows, pageSize]
  );

  return {
    pageIndex,
    pageCount,
    currentPageLength,
    pages,
    nextPage,
    previousPage,
  };
};
