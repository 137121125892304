import React, { useCallback, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { BaseIcon, Icons, Theme } from "@ampeersenergy/ampeers-ui-components";

import {
  DropdownDividerItem,
  DropdownItem,
  DropdownValueItem,
} from "./dropdown";
import { DropdownCheckbox, DropdownCheckboxItem } from "./dropdown-checkbox";
import { DropdownButton } from "./dropdown-button";

export interface QuickFilterProps {
  genericLabel?: string;
  icon?: typeof BaseIcon;
  items: (DropdownItem | DropdownCheckboxItem)[];
  selectedFilter: (string | null)[];
  hasItems?: boolean;
  closeOnClick?: boolean;
  multiselect?: boolean;
  onItemClick?: (item: string) => void;
}

export const QuickFilter: React.FC<QuickFilterProps> = ({
  genericLabel,
  icon,
  items,
  selectedFilter,
  hasItems = true,
  closeOnClick = true,
  multiselect = false,
  onItemClick,
}) => {
  const theme = useTheme() as Theme;
  const [isOpen, setIsOpen] = useState(false);

  const _onItemClick = useCallback(
    (value) => {
      if (closeOnClick) {
        setIsOpen(false);
      }
      onItemClick?.(value);
    },
    [setIsOpen, onItemClick, closeOnClick]
  );

  const label: string = useMemo(() => {
    if (selectedFilter.length > 1 && genericLabel) {
      return `${genericLabel} (${selectedFilter.length})`;
    }

    const selectedValue = items.find(
      (item) =>
        !(item as DropdownDividerItem).divider &&
        selectedFilter.includes((item as DropdownValueItem).value ?? null)
    );

    if (selectedValue) {
      return (
        (selectedValue as DropdownItem & { originalLabel: string })
          ?.originalLabel ?? selectedValue?.label
      );
    }

    return "Filter";
  }, [genericLabel, items, selectedFilter]);

  const Component = multiselect ? DropdownCheckbox : DropdownButton;

  return (
    <Component
      items={items as DropdownCheckboxItem[]}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      icon={!hasItems ? Icons.Warning : icon ?? Icons.Filter}
      {...(hasItems ? {} : { buttonColor: theme.palette.warning.color })}
      onItemClick={_onItemClick}
    >
      {label}
    </Component>
  );
};
