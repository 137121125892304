import { Modal } from "@ampeersenergy/ampeers-ui-components";
import { CreateEditAssetFlow, CreateEditAssetFlowProps } from "./form";
import { MultistepWrapper } from "../style";

export interface CreateEditAssetFlowModalProps
  extends CreateEditAssetFlowProps {
  isOpen: boolean;
  title: string;
}

export const CreateEditAssetFlowModal: React.FC<
  CreateEditAssetFlowModalProps
> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      title={props.title}
      minWidth={1024}
    >
      <MultistepWrapper>
        <CreateEditAssetFlow {...props} />
      </MultistepWrapper>
    </Modal>
  );
};
