import {
  InMemoryCache,
  ApolloClient,
  from,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { _ae_env_ } from "../env";
import { CompanyDistrict } from "../graphql/sdks/auth";
import { history } from "../history";
import {
  APP_KEY,
  getSession,
  clearSession,
  appendSessionData,
} from "./session.service";

const errorMiddleware = onError(({ graphQLErrors /*, networkError */ }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      switch (extensions?.code) {
        case "UNAUTHENTICATED":
          clearSession();
          history.replace("/login");
          break;

        default:
          console.warn(message, locations, path, extensions);
          break;
      }
    });

  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const authMiddleware = new ApolloLink((operation, forward: any) => {
  const {
    token,
    clientId,
    appKey,
    userId,
    companyKey,
    districtKey,
    districts,
  } = getSession() || {};
  const isGetDistrictsOperation = operation.operationName === "getDistricts";
  const isMultiple = operation.variables?.filter?.multipleDistricts?.length > 1;

  let _companyKey = companyKey;
  let _districtKey = districtKey;
  let _clientId = clientId;

  if (isGetDistrictsOperation) {
    const filter = operation.variables?.filter?.multipleDistricts?.[0] ?? {};

    _companyKey = isMultiple ? "multiple" : filter.company;
    _districtKey = isMultiple ? "multiple" : filter.district;
  }

  if (!_companyKey && !_districtKey) {
    const [, , companyDistrict] = window.location.pathname.split("/");

    if (companyDistrict) {
      const [company, district] = companyDistrict.split("-");

      const districtFromRoute = districts?.find(
        (d: CompanyDistrict) => d.company === company && d.district === district
      );

      _companyKey = company ?? undefined;
      _districtKey = district ?? undefined;
      _clientId = districtFromRoute?.clientId ?? _clientId;

      appendSessionData({
        companyKey: _companyKey,
        districtKey: _districtKey,
        clientId: _clientId,
      });
    }
  }

  operation.setContext({
    headers: {
      "x-ampeers-app-verify": appKey || APP_KEY,
      ...(token && { "x-ampeers-user-token": token }),
      ...(userId && { "x-ampeers-user-id": userId }),
      ...(clientId && { "x-ampeers-client-id": _clientId }),
      ...(_companyKey && { "x-ampeers-company": _companyKey }),
      ...(_districtKey && { "x-ampeers-district": _districtKey }),
      ...operation.getContext().headers,
    },
  });

  return forward(operation).map((response: any) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const { districts } =
      response.data?.loginUser ?? response.data?.check2FAuth ?? {};

    const districtWithAllAccess = districts?.find(
      (d: CompanyDistrict) => d.company === "all" && d.district === "all"
    );

    if (headers) {
      const session = {
        ...(headers.get("x-ampeers-user-token") && {
          token: headers.get("x-ampeers-user-token"),
        }),
        ...(districtWithAllAccess && {
          clientId: districtWithAllAccess.clientId,
        }),
        ...(headers.get("x-ampeers-user-id") && {
          userId: headers.get("x-ampeers-user-id"),
        }),
        ...(districtWithAllAccess && {
          companyKey: districtWithAllAccess.company,
          districtKey: districtWithAllAccess.district,
        }),
        ...(districts && {
          districts,
        }),
      };

      if (Object.values(session).length !== 0) {
        appendSessionData(session);
      }
    }

    return response;
  });
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Property: {
        /** disable caching */
        keyFields: false,
      },
      UiAssetTimeSeries: {
        /** disable caching */
        keyFields: false,
      },
      TimeSeries: {
        /** disable caching */
        keyFields: false,
      },
      TimeseriesIdentifier: {
        /** disable caching */
        keyFields: false,
      },
      EventFlowLinkItem: {
        /** disable caching */
        keyFields: false,
      },
    },
  }),
  link: from([
    authMiddleware,
    errorMiddleware,
    createHttpLink({
      uri: `${_ae_env_.REACT_APP_BACKEND_URL}/graphql`,
    }),
  ]),
});
