import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  ColumnDefinition,
  Table,
} from "@ampeersenergy/ampeers-ui-components";

import { dynamicPageSize } from "../../helpers/html.utils";

import { ExtendedContract } from "../../components/types";
import { NotesIcon } from "../../components/notes-icon";
import {
  GetDistrictsDocument,
  useRemoveContractsMutation,
} from "../../graphql/sdks/controller";
import { BulkDeleteModal } from "../../components/bulk-delete-modal";

export interface ContractsProps {
  contracts: ExtendedContract[];
  loading: boolean;
  onCreateContractClick: (value: boolean) => void;
  onContractRowClick: (original: ExtendedContract | undefined) => void;
}

export const Contracts: React.FC<ContractsProps> = ({
  loading,
  contracts,
  onContractRowClick,
  onCreateContractClick,
}) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [removeContracts, { error: removeError }] = useRemoveContractsMutation({
    errorPolicy: "all",
    refetchQueries: [
      {
        query: GetDistrictsDocument,
        variables: {
          filter: {
            multipleDistricts: [
              {
                company: "all",
                district: "all",
              },
            ],
          },
        },
      },
    ],
  });

  const contractColumns: ColumnDefinition<
    Pick<
      ExtendedContract,
      "name" | "key" | "template" | "assetName" | "assetKey"
    >
  >[] = useMemo(
    () => [
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Name",
        accessor: "name",
        width: "20%",
        Cell: ({ value }: { value?: string }) => value || "--",
      },
      {
        Header: "Anlage",
        accessor: "assetName",
        Cell: ({ value, row }) =>
          value
            ? `${value} ${
                row.original.assetKey ? `(${row.original.assetKey})` : ""
              }`
            : "--",
      },
      {
        Header: "",
        accessor: "notes.length",
        width: "1%",
        Cell: ({ value }: { value: number }) => <NotesIcon value={value} />,
      },
    ],
    []
  );

  const onRowSelect = useCallback(
    (rowIds: string[]) => {
      setSelectedRows(
        rowIds
          .map((rowId) => contracts.find((_, i) => i === +rowId)?.id)
          .filter((v): v is string => !!v)
      );
    },
    [setSelectedRows, contracts]
  );

  const onDelete = useCallback(() => {
    return removeContracts({
      variables: {
        ids: selectedRows,
      },
    });
  }, [removeContracts, selectedRows]);

  return (
    <>
      <Table
        compact
        withFilter
        withAlternatingRows
        withBoxShadow
        withPagination
        multiSelect
        onSelect={onRowSelect}
        filterKind="Verträge"
        isLoading={loading}
        columns={contractColumns}
        data={contracts}
        pageSize={dynamicPageSize()}
        onRowClick={({ original }) => {
          onContractRowClick(original);
        }}
        renderTableActions={() => (
          <>
            {!!selectedRows.length && (
              <BulkDeleteModal
                entityName="Anlagen"
                nameExtractFn={(id) => {
                  const contract = contracts.find((c) => c.id === id);
                  return contract?.name ?? contract?.key ?? "--";
                }}
                removeError={removeError}
                onDelete={onDelete}
                selectedRows={selectedRows}
              />
            )}
            <Button onClick={() => onCreateContractClick(true)}>
              Vertrag anlegen
            </Button>
          </>
        )}
        initialState={{
          sortBy: [
            {
              id: "key",
              desc: false,
            },
          ],
        }}
      />
    </>
  );
};
