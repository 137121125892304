import React from "react";
import styled, { useTheme } from "styled-components";
import {
  Button as ButtonComponent,
  ErrorAlert,
  Icons,
  Theme,
  TooltipLight,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";
import { usePropertyFormValidation } from "../hooks/usePropertyFormValidation";
import { PropertyFormValue } from "./types";

const Button = styled(ButtonComponent)`
  flex-shrink: 0;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export interface FillPropertiesButtonProps {
  isEditing?: boolean;
  disabled?: boolean;
  properties: PropertyFormValue[];
  onFillProperties: () => void;
}

export const FillPropertiesButton: React.FC<FillPropertiesButtonProps> = ({
  isEditing,
  disabled,
  properties,
  onFillProperties,
}) => {
  const theme = useTheme() as Theme;

  const { hasErrors, hasWarnings } = usePropertyFormValidation(properties);

  if (!isEditing) return null;

  return (
    <>
      <TooltipLight
        id="fill-all-properties"
        text="Verfügbare Property- und Zeitreiheninformationen aus dem Template werden zur Anlage hinzugefügt, inklusive zu füllender Pflichtangaben. Bestehende Werte werden nicht überschrieben."
      >
        <Button disabled={disabled} secondary onClick={onFillProperties}>
          Property- und Zeitreiheninformationen aus dem Template hinzufügen
          <Icons.Warning
            data-tip
            data-for="fill-all-properties"
            size={25}
            color={theme.palette.warning.color}
          />
        </Button>
      </TooltipLight>
      {hasWarnings && (
        <WarningAlert>
          Leere Properties werden <strong>nicht</strong> gespeichert.
        </WarningAlert>
      )}
      {hasErrors && (
        <ErrorAlert>
          Diese Felder sind erforderlich. Wenn sie leer sind, werden sie{" "}
          <strong>nicht</strong> gespeichert.
        </ErrorAlert>
      )}
    </>
  );
};
