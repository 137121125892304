import React from "react";
import { Select } from "./style";

export interface UnitsFormProps {
  identifier: string;
  isEditing?: boolean;
  units: (string | null | undefined)[];
  withLabel?: boolean;
  label?: string;
  flex?: number;
  disabled?: boolean;
}

export const UnitsForm: React.FC<UnitsFormProps> = ({
  identifier,
  isEditing,
  units,
  withLabel = true,
  label,
  flex,
  disabled,
}) => {
  return (
    <Select
      flex={flex}
      id={identifier}
      name={identifier}
      label={withLabel || label ? label ?? "Unit" : ""}
      isEditing={isEditing}
      disabled={disabled}
    >
      <option key="null" value={"null"}>
        (null)
      </option>
      {units
        .filter((unit): unit is string => !!unit)
        .sort((u1, u2) => u1.localeCompare(u2))
        .map((unit) => (
          <option key={unit} value={unit || ""}>
            {unit}
          </option>
        ))}
    </Select>
  );
};
