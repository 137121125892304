import React, { useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import styled from "styled-components";
import { Formik, Form } from "formik";
import {
  AlertRetryable,
  Button,
  ButtonGroup,
  FormikSubmit,
  SuccessAlert,
} from "@ampeersenergy/ampeers-ui-components";
import { Entity } from "./district-overview/types";
import { ApolloError } from "@apollo/client";
import { extractErrorMessage } from "../helpers/error.utils";

const FormWrapperFullHeight = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const ButtonGroupWithMargin = styled(ButtonGroup)`
  margin: 10px;
`;

const AlertRetryableWithMargin = styled(AlertRetryable)`
  margin: 10px;
`;

const SuccessAlertWithMargin = styled(SuccessAlert)`
  margin: 10px;
`;

const Container = styled.div`
  overflow: hidden;
  flex: 1;
`;

export interface JsonViewerProps {
  id: string;
  content?: Partial<Entity<object>>;
  onChange?: ({ jsObject }: { jsObject?: JSON }) => void;
  reset?: boolean;
}

export interface JsonViewerFormProps {
  id: JsonViewerProps["id"];
  initialValues: Partial<Entity<object>>;
  onSubmit: (values: JSON | Partial<Entity<object>>) => void | Promise<void>;
  immutableKeys?: (keyof Partial<Entity<object>>)[];
}

export const JsonViewer: React.FC<JsonViewerProps> = ({
  id,
  content,
  onChange,
  reset,
}) => {
  return (
    <JSONInput
      id={id}
      placeholder={content}
      theme="dark_vscode_tribute"
      locale={locale}
      width="100%"
      height="100%"
      onKeyPressUpdate={false}
      onChange={onChange}
      reset={reset}
    />
  );
};

export const JsonViewerForm: React.FC<JsonViewerFormProps> = ({
  id,
  initialValues,
  onSubmit,
  immutableKeys = [],
}) => {
  const [error, setError] = useState<string | undefined>();
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);
  const [canReset, setCanReset] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{ jsObject: initialValues }}
      onSubmit={async (values) => {
        try {
          await onSubmit(values.jsObject);
          setError(undefined);
          setSuccessfulUpdate(true);
          setTimeout(() => setSuccessfulUpdate(false), 5000);
        } catch (e) {
          if (e instanceof ApolloError) {
            setError(extractErrorMessage(e));
          } else {
            setError((e as Error).message);
          }
        }
      }}
    >
      {({ values, setFieldValue, isValid }) => (
        <FormWrapperFullHeight>
          {successfulUpdate && (
            <SuccessAlertWithMargin>
              {values.jsObject.name} erfolgreich aktualisiert.
            </SuccessAlertWithMargin>
          )}
          {error && (
            <AlertRetryableWithMargin
              message={error}
              onRetry={() => setError(undefined)}
            ></AlertRetryableWithMargin>
          )}
          <Container>
            <JsonViewer
              id={id}
              onChange={({ jsObject }) => {
                if (jsObject) {
                  const entity = jsObject as Partial<Entity<object>>;
                  setError(undefined);
                  if (
                    immutableKeys.some(
                      (key) => entity[key] !== initialValues[key]
                    )
                  ) {
                    setError(
                      `Diese Felder können nicht geändert werden: ${immutableKeys.join(
                        ", "
                      )}`
                    );
                  }

                  setFieldValue("jsObject", jsObject);
                  setCanReset(true);
                }
              }}
              content={values.jsObject as Partial<Entity<object>>}
            />
          </Container>
          <ButtonGroupWithMargin>
            <Button
              secondary
              disabled={!canReset}
              onClick={() => {
                setFieldValue("jsObject", initialValues);
                setError(undefined);
                setCanReset(false);
              }}
            >
              Zurücksetzen
            </Button>
            <FormikSubmit disabled={!isValid}>Speichern</FormikSubmit>
          </ButtonGroupWithMargin>
        </FormWrapperFullHeight>
      )}
    </Formik>
  );
};
