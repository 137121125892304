import { CompanyDistrict } from "../graphql/sdks/controller";
import { TimeseriesViewFilter } from "../components/types";

export interface SessionState {
  appKey?: string;
  token?: string;
  clientId?: string;
  userId?: string;
  companyKey?: string;
  districtKey?: string;
  districts?: CompanyDistrict[];
}

export interface FilterState {
  activeTimeseriesFilter?: number;
  timeseriesFilters?: TimeseriesViewFilter[];
}

export const APP_KEY = "ap_dm_internal";
export const LOCAL_STORAGE_AUTH_IDENTIFIER = "ae.dminternal.auth";
export const LOCAL_STORAGE_FILTER_IDENTIFIER = "ae.dminternal.filter";

export const storeSessionData = (
  sessionState: SessionState | FilterState,
  identifier = LOCAL_STORAGE_AUTH_IDENTIFIER
) => {
  if (identifier === LOCAL_STORAGE_AUTH_IDENTIFIER) {
    const { companyKey, districtKey, ...rest } = sessionState as SessionState;
    localStorage.setItem(identifier, JSON.stringify(rest));
    sessionStorage.setItem(
      LOCAL_STORAGE_AUTH_IDENTIFIER,
      JSON.stringify({ companyKey, districtKey, clientId: rest.clientId })
    );
  } else {
    localStorage.setItem(identifier, JSON.stringify(sessionState));
  }
};

export const appendSessionData = (
  sessionState: SessionState | FilterState,
  identifier = LOCAL_STORAGE_AUTH_IDENTIFIER
) => {
  const currentStorageData = getSession(identifier);
  storeSessionData(
    { ...(currentStorageData || {}), ...sessionState },
    identifier
  );
};

export const clearSession = () => {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_IDENTIFIER);
  sessionStorage.removeItem(LOCAL_STORAGE_AUTH_IDENTIFIER);
};

export const getSession = <T extends SessionState | FilterState = SessionState>(
  identifier = LOCAL_STORAGE_AUTH_IDENTIFIER
): T | undefined => {
  const localStorageData = localStorage.getItem(identifier);
  const sessionStorageData = sessionStorage.getItem(identifier);

  if (localStorageData || sessionStorageData)
    return {
      ...JSON.parse(localStorageData || "{}"),
      ...JSON.parse(sessionStorageData || "{}"),
    };
};

export const hasSession = (): boolean => {
  return !!getSession();
};

export const hasValidSession = (): boolean => {
  const session = getSession();
  if (!session) return false;

  return !!(session.token && session.clientId && session.userId);
};
