import React, { MouseEventHandler, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import {
  BaseIcon,
  IconProps,
  Icons,
  Input,
  Label,
  Theme,
  useHover,
} from "@ampeersenergy/ampeers-ui-components";
import { margins, Row } from "./style";
import { IconButton } from "./icon-button";
export interface CommunicationFlowProps {
  id: string;
  value: string;
  setValue: (newValue: string) => void;
  label?: string;
  isEditing?: boolean;
}

export interface ChangeableIconProps {
  defaultIcon: typeof BaseIcon;
  hoverIcon: typeof BaseIcon;
  color?: string;
  hoverColor?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CommunicationFlowWrapper = styled.div<{ $flex: number }>`
  flex: ${(props) => props.$flex ?? 1};

  margin-left: 6px;
  margin-right: 6px;
  ${margins}

  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .label {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .button > svg {
    transform: rotate(45deg) scale(1.2);
  }
`;

export const CounterArrows = (props: IconProps) => (
  <BaseIcon {...props} size={15}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M20.8 16.3v5.5H7.4l-.1 4.7L0 19.2l7.4-7.4v4.5z" />
      <path d="M9.2 13.7V8.2h13.4l.1-4.7 7.3 7.3-7.4 7.4v-4.5z" />
    </svg>
  </BaseIcon>
);

const ChangeableIcon: React.FC<ChangeableIconProps> = ({
  defaultIcon,
  hoverIcon,
  color,
  hoverColor,
  onClick,
}) => {
  const { isHovered, hoverHandlers } = useHover();

  return (
    <div {...hoverHandlers}>
      <IconButton
        className=""
        icon={isHovered ? hoverIcon : defaultIcon}
        color={isHovered ? hoverColor : color}
        onClick={onClick}
      />
    </div>
  );
};

export const CommunicationFlow: React.FC<CommunicationFlowProps> = ({
  id,
  value,
  setValue,
  label,
  isEditing,
}) => {
  const theme = useTheme() as Theme;
  const nodes = value.split("↔");

  const addItem = useCallback(() => {
    setValue(`${value} ↔ `);
  }, [value, setValue]);

  const onChange = useCallback(
    (v, index) => {
      nodes.splice(index, 1, v);
      setValue(nodes.join("↔"));
    },
    [nodes, setValue]
  );

  const removeItem = useCallback(
    (index) => {
      nodes.splice(index, 1);
      setValue(nodes.join("↔"));
    },
    [nodes, setValue]
  );

  return (
    <CommunicationFlowWrapper $flex={isEditing ? 3 : 1}>
      {label && <Label className="label">{label}</Label>}
      {isEditing ? (
        <div className="wrapper">
          {nodes.map((v, index) => (
            <Row key={`${id}.[${index}]`}>
              <Input
                type="text"
                id={`${id}.[${index}]`}
                value={v}
                onChange={(e) => onChange(e.target.value, index)}
              />
              {index < nodes.length - 1 ? (
                <ChangeableIcon
                  defaultIcon={CounterArrows}
                  hoverIcon={Icons.Delete}
                  hoverColor={theme.palette.error.color}
                  onClick={() => removeItem(index + 1)}
                />
              ) : (
                <IconButton icon={Icons.Plus} onClick={addItem} />
              )}
            </Row>
          ))}
        </div>
      ) : (
        <span>{value}</span>
      )}
    </CommunicationFlowWrapper>
  );
};
