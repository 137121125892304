import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@ampeersenergy/ampeers-ui-components";
import App from "./app";
import { client } from "./services/apollo.service";
import logo from "./assets/ae-logo.png";
import { ErrorHandler } from "./components/error-handler";
import "./yup.setup";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ThemeProvider extendedTheme={{ logo }}>
          <ErrorHandler>
            <App />
          </ErrorHandler>
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
