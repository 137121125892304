import React, { ChangeEvent, useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionItem,
  DynamicListItemProps,
} from "@ampeersenergy/ampeers-ui-components";
import { PropertiesMultistepForm } from "../properties-multistep-form";
import { TensorsForm } from "../tensors-form";
import { TimeseriesMultistepForm } from "../timeseries-multistep-form";
import {
  Column,
  FlexRawSelect,
  Input,
  Row,
  Spinner,
  SubTitleWithMargin,
} from "../style";
import { AssetTemplate } from "../../graphql/sdks/controller";
import {
  AssetType,
  getAssetKeyAndName,
  getAssetTypeName,
} from "../../helpers/asset.utils";
import { useFormikContext } from "formik";
import { AssetFormValues, ExtendedProperty, LocalTimeseries } from "../types";
import { mergePropertyTemplates } from "../../helpers/properties.utils";

export interface ChildrenAssetsEditFormProps {
  isEditing?: boolean;
  index: number;
  item: DynamicListItemProps["item"] & AssetFormValues["children"][0];
  isValid: boolean;
  template?: AssetTemplate;
  loadingTemplates?: boolean;
  timeseries: LocalTimeseries[];
}

export const ChildrenAssetsEditForm: React.FC<ChildrenAssetsEditFormProps> = ({
  isEditing,
  item,
  index,
  template,
  loadingTemplates,
  timeseries,
}) => {
  const { values, setValues } = useFormikContext<AssetFormValues>();

  const childTemplate = useMemo(
    () => template?.children.find((child) => child.template === item.template),
    [template, item]
  );

  const onAssetTemplateChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      try {
        const newChildTemplate = template?.children.find(
          (child) => child.template === e.target.value
        );

        if (!newChildTemplate) {
          throw new Error(`Cannot find template: ${e.target.value}`);
        }

        const assetKeyAndName = getAssetKeyAndName(
          newChildTemplate.template as AssetType,
          values.children
        );

        const newAssetKeyAndName = {
          key: item.key || assetKeyAndName.key,
          name: item.name || assetKeyAndName.name,
        };

        const newAssetChildren = values.children.concat();
        newAssetChildren.splice(index, 1, {
          ...newAssetKeyAndName,
          template: newChildTemplate.template,
          description: newChildTemplate.description,
          ...mergePropertyTemplates(
            [
              ...item.properties,
              ...item.timeseries,
              ...item.tensors,
            ] as ExtendedProperty[],
            timeseries,
            newChildTemplate.properties ?? [],
            values.flags,
            newAssetKeyAndName.key
          ),
        });

        setValues({
          ...values,
          children: newAssetChildren,
        });
      } catch (error) {}
    },
    [template?.children, values, index, item, timeseries, setValues]
  );

  return (
    <Column>
      <Row>
        <FlexRawSelect
          id="childTemplate"
          label="Template *"
          isEditing={isEditing}
          value={childTemplate?.template || ""}
          onChange={onAssetTemplateChange}
          hint={childTemplate?.description ?? "Keine Beschreibung vorhanden"}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          {template?.children.map((template) => (
            <option key={template.template} value={template.template}>
              {getAssetTypeName(template.template as AssetType)} (
              {template.template})
            </option>
          ))}
        </FlexRawSelect>
        {loadingTemplates && <Spinner size={25} />}
      </Row>
      <Row>
        <Input
          name={`children[${index}].key`}
          label="Key *"
          placeholder="Key"
          required
          hint="Einzigartiger Identifikator, der die Anlage intern indexiert. Format: assetKey01 (Camel Case, nur Zahlen und Buchstaben, keine Umlaute oder Sonderzeichen wie ß, - oder _)."
          isEditing={isEditing}
        />
        <Input
          name={`children[${index}].name`}
          label="Name *"
          placeholder="Name"
          required
          isEditing={isEditing}
          hint="Name, der in der District Management App angezeigt wird"
        />
      </Row>
      <Accordion
        autoClose
        withPadding={false}
        LabelComponent={SubTitleWithMargin}
      >
        <AccordionItem label="Properties">
          <PropertiesMultistepForm
            prefixIdentifier={`children[${index}]`}
            isEditing={isEditing}
            items={item.properties}
            propertyTemplates={item.propertyTemplates}
          />
        </AccordionItem>
        <AccordionItem label="Kennfelder">
          <TensorsForm
            prefixIdentifier={`children[${index}]`}
            isEditing={isEditing}
            items={item.tensors}
            propertyTemplates={item.propertyTemplates}
          />
        </AccordionItem>
        <AccordionItem label="Zeitreihen">
          <TimeseriesMultistepForm
            prefixIdentifier={`children[${index}]`}
            isEditing={isEditing}
            items={item.timeseries}
            propertyTemplates={item.propertyTemplates}
          />
        </AccordionItem>
      </Accordion>
    </Column>
  );
};
