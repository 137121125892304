import { useLocation } from "react-router";
import {
  LocalAsset,
  LocalContract,
  LocalTimeseries,
} from "../components/types";
import { Building } from "../graphql/sdks/controller";

export const useGetElementFromRoute = ({
  assets,
  buildings,
  contracts,
  timeseries,
}: {
  assets: LocalAsset[];
  buildings: Building[];
  contracts: LocalContract[];
  timeseries: LocalTimeseries[];
}) => {
  const location = useLocation();

  const [id, path] = location.pathname.split("/").reverse();

  switch (path) {
    case "assets":
      return assets.find((a) => a.id === id || a.key === id);
    case "buildings":
      return buildings.find((b) => b.id === id || b.key === id);
    case "timeseries":
      return timeseries.find((t) => t.id === id || t.uuid === id);
    case "contracts":
      return contracts.find((c) => c.id === id || c.key === id);
  }
};
