/**
 * IMPORTANT
 * This is a copy of the implementation available in ampeers-service-utils@4.3.3
 * When using that package here, the app could not be compiled due to missing polyfills
 * for NodeJS libraries such as os, fs and path.
 *
 * This implementation will be deleted once a dedicated package with shared functions
 * and services as described in https://ampeers.atlassian.net/browse/DM-2721 is created
 */

import Papa, { ParseMeta, UnparseConfig } from "papaparse";
import { saveAs } from "file-saver";

/**
 * maps the keys of an object given a keyMap
 * @param object
 * @param keyMap
 * @param filterOutKeys
 */
function transformObject(
  object: { [key: string]: string },
  keyMap: { [key: string]: string },
  valueMap: { [key: string]: (value: any) => any } = {},
  filterOutKeys = false
) {
  return Object.keys(filterOutKeys ? keyMap : object).reduce(
    (newObject, key) => ({
      ...newObject,
      [keyMap[key]]: valueMap[key] ? valueMap[key](object[key]) : object[key],
    }),
    {}
  );
}

interface NumeralConfig {
  locales: {
    csvImport: { delimiters: { thousands: string; decimal: string } };
  };
  register: (
    locale: string,
    localeName: string,
    {
      delimiters,
      abbreviations,
      ordinal,
      currency,
    }: {
      delimiters: { thousands: string; decimal: string };
      abbreviations: {
        thousand: string;
        million: string;
        billion: string;
        trillion: string;
      };
      ordinal: () => string;
      currency: {
        symbol: string;
      };
    }
  ) => void;
}

interface DocumentNode {
  definitions: {
    name: { value: string };
  }[];
}

interface ApolloClient {
  mutate: ({
    variables,
    mutation,
  }: {
    variables: JSON;
    mutation: DocumentNode;
    errorPolicy: string;
  }) => Promise<{ data: Record<string, unknown> }>;
}

export interface KeyMap {
  [key: string]: string;
}

export interface ValueMap<T, P> {
  [key: string]: (value: T) => P;
}

interface ParseResult {
  data: Record<string, string>[];
  meta: ParseMeta;
}

export interface CSVConvertConfig {
  headers: string[];
  fields: string[];
  fileName: string;
}

export interface CSVConfig {
  mutation: DocumentNode;
  keyMap?: KeyMap;
  valueMap?: ValueMap<unknown, unknown>;
  mutationVariableResult: string;
  mutationVariables?: JSON;
  dynamicTyping?: boolean;
  valueDelimiters?: { thousands: string; decimal: string };
  validateFn: (values: ParseResult) => boolean;
}

export class CSVService {
  keyMap?: KeyMap;
  valueMap?: ValueMap<unknown, unknown>;
  mutation?: DocumentNode;
  mutationVariableResult?: string;
  mutationVariables?: JSON;
  dynamicTyping?: boolean = false;
  valueDelimiters: { thousands: string; decimal: string } = {
    thousands: " ",
    decimal: ",",
  };

  validate: (values: ParseResult) => boolean;

  apolloClient: ApolloClient;

  constructor(
    config: CSVConfig,
    numeralConfig: NumeralConfig,
    apolloClient: ApolloClient
  ) {
    this.keyMap = config.keyMap;
    this.valueMap = config.valueMap;
    this.mutation = config.mutation;
    this.mutationVariables = config.mutationVariables;
    this.mutationVariableResult = config.mutationVariableResult;
    this.dynamicTyping = config.dynamicTyping;
    this.validate = config.validateFn;
    if (config.valueDelimiters) this.valueDelimiters = config.valueDelimiters;

    this.apolloClient = apolloClient;

    if (!numeralConfig.locales.csvImport) {
      numeralConfig.register("locale", "csvImport", {
        delimiters: this.valueDelimiters,
        abbreviations: {
          thousand: "k",
          million: "m",
          billion: "b",
          trillion: "t",
        },
        ordinal: function () {
          return ".";
        },
        currency: {
          symbol: "€",
        },
      });
    } else {
      numeralConfig.locales.csvImport.delimiters = this.valueDelimiters;
    }
  }

  /**
   * Updates the service config
   * @param config
   */
  setConfig(
    config: CSVConfig,
    numeralConfig: NumeralConfig,
    apolloClient: ApolloClient
  ) {
    this.keyMap = config.keyMap;
    this.valueMap = config.valueMap;
    this.mutation = config.mutation;
    this.mutationVariables = config.mutationVariables;
    this.mutationVariableResult = config.mutationVariableResult;
    this.dynamicTyping = config.dynamicTyping;
    this.validate = config.validateFn;

    if (config.valueDelimiters) {
      this.valueDelimiters = config.valueDelimiters;
      numeralConfig.locales.csvImport.delimiters = config.valueDelimiters;
    }

    this.apolloClient = apolloClient;
  }

  /**
   * converts an array of objects into a CSV string format
   * @param data
   * @param config
   */
  static toCSV = (
    data: Record<string, string>[],
    { headers, fields }: Pick<CSVConvertConfig, "headers" | "fields">,
    config?: UnparseConfig
  ): string => {
    return Papa.unparse(
      {
        fields: headers,
        data: data.map((values: { [key: string]: unknown }) =>
          fields.map((field: string) => values[field])
        ),
      },
      config
    );
  };

  /**
   * converts an array of objects into a CSV string format and downloads it
   * @param data
   * @param config
   */
  static downloadCSV = (
    data: Record<string, string>[],
    config: CSVConvertConfig
  ) => {
    const parsedCsv = CSVService.toCSV(data, config);

    saveAs(
      new Blob([parsedCsv], { type: "text/csv;charset=utf-8" }),
      config.fileName
    );
  };

  /**
   * push the parsed results to the server through mutation
   * @param results
   */
  push = (results: Record<string, string>[]) => {
    if (!this.mutation) {
      throw Error("CSV_PUSH_FAILED: Mutation not defined");
    } else if (!this.mutationVariableResult) {
      throw Error("CSV_PUSH_FAILED: mutationVariableResult not defined");
    } else {
      return this.apolloClient
        .mutate({
          mutation: this.mutation,
          variables: {
            [this.mutationVariableResult]: results,
            ...(this.mutationVariables || {}),
          } as JSON,
          errorPolicy: "all",
        })
        .then(({ data }) => data[this.mutation!.definitions[0].name.value]);
    }
  };

  /**
   * transform the parsed csv file
   * @param data
   */
  transform = (data: Record<string, string>[]) => {
    return this.keyMap
      ? data.map((obj) =>
          transformObject(obj, this.keyMap!, this.valueMap, true)
        )
      : data;
  };

  /**
   * parse a single CSV File into a JSON format according to a keyMap and a valueMap
   * @param file
   */
  parse = (file: File | string): Promise<ParseResult> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file as any, {
        header: true,
        dynamicTyping: this.dynamicTyping,
        skipEmptyLines: true,
        complete: ({ data, errors, meta }) => {
          if (errors.length) {
            return reject(errors);
          } else {
            resolve({ data: data as Record<string, string>[], meta });
          }
        },
      });
    });
  };

  /**
   * iterate over an array of files, parse them and push the values to server
   * @param files
   */
  uploadFiles = async (files: (File | string)[]) => {
    return Promise.all(files.map((file) => this.parse(file)))
      .then((parsedFiles) => {
        parsedFiles.forEach((parsedFile) => this.validate(parsedFile));
        return parsedFiles;
      })
      .then((parsedFiles) =>
        parsedFiles.map(({ data }) => this.transform(data))
      )
      .then((mappedData) =>
        mappedData.reduce(
          (merged: Record<string, string>[], data: Record<string, string>[]) =>
            merged.concat(data),
          [] as Record<string, string>[]
        )
      )
      .then((results) => this.push(results));
  };
}
