import styled from "styled-components";
import {
  AlertInfo as AlertInfoComponent,
  AlertRetryable as AlertRetryableComponent,
  Accordion as AccordionComponent,
  SuccessAlert as SuccessAlertComponent,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";
import { CenteredRow, Column, Row } from "../style";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  padding: 10px 14px;
  height: 86vh;
  max-width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background};
`;

export const StepperWrapper = styled.div`
  flex: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Step = styled.div<{
  $isActive: boolean;
  $isSelected: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 7px 32px;
  border-radius: 4px;
  border: solid 1px
    ${(props) =>
      props.$isSelected
        ? props.theme.primaryColor
        : props.theme.palette.textSubtle};

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StepLabelWrapper = styled(CenteredRow)`
  gap: 12px;
`;

export const StepLabel = styled.div<{
  $isActive: boolean;
  $isSelected: boolean;
}>`
  align-self: center;
  color: ${(props) =>
    props.$isSelected
      ? props.theme.primaryColor
      : props.theme.palette.textSubtle};
  font-size: 16px;
  font-weight: ${(props) => (props.$isSelected ? "bold" : "normal")};
  letter-spacing: 0.6px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 12px 0;
`;

export const StepIcon = styled.div<{ $isSelected: boolean }>`
  width: 30px;
  height: 30px;
  color: ${(props) => props.theme.palette.background};
  background: ${(props) =>
    props.$isSelected
      ? props.theme.primaryColor
      : props.theme.palette.textSubtle};
  text-align: center;
  font-size: 18px;
  border-radius: 50px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColorSquare = styled.div<{ $color: string }>`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: ${(props) => props.$color};
`;

export const EntityNameWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 18px;
  }
`;

export const EntityName = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  font-size: 14px;
  padding: 6px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
`;

export const Messages = styled.div`
  margin-left: 32px;
`;

export const AccordionSubtitle = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 6px 0 !important;
  color: ${(props) => props.theme.palette.text};
`;

export const RowFullHeight = styled(Row)`
  max-height: 100%;
  width: 100%;
  flex: 1;
  align-self: flex-start;
`;

export const ColumnFullHeight = styled(Column)`
  justify-content: space-between;
`;

export const Accordion = styled(AccordionComponent)`
  overflow-y: auto;
  margin-bottom: 12px;
`;

export const SuccessAlert = styled(SuccessAlertComponent)`
  width: 100%;
  margin: 0;
`;

export const StateNotCheckedAlert = styled(WarningAlert)`
  width: 100%;
  align-self: flex-start;
`;

export const AlertRetryable = styled(AlertRetryableComponent)`
  flex: 0;
  width: 100%;
`;

export const AlertInfo = styled(AlertInfoComponent)`
  width: 100%;
  flex: 0;
`;
