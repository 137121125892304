import { useFormikContext } from "formik";
import React from "react";
import {
  Accordion,
  AccordionItem,
  Button as ButtonComponent,
  ButtonGroup,
  FormikInput,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import { District } from "../../graphql/sdks/controller";

import {
  Column,
  ColumnWithInputWidth,
  Row,
  SubTitleWithButton,
  SubTitleWithMargin,
} from "../style";
import { DistrictFormValues } from "../types";

import { AddressForm } from "../address-form";
import { PropertiesForm } from "../properties-form";
import { NotesForm } from "../notes-form";
import { EmailForm } from "./e-mail-form";
import { Header, PropertiesLabels } from "../properties-multistep-form";
import { useTheme } from "styled-components";
import { TimeseriesForm } from "../timeseries-form";

export interface DistrictFormProps {
  isEditing?: boolean;
  district: District;
  setIsEditing: (value: boolean) => void;
  setConfirmDeleteModalOpen: (value: boolean) => void;
  onCancel: ({
    resetForm,
    touched,
    onConfirm,
  }: {
    resetForm: Function;
    touched: object;
    onConfirm?: Function;
  }) => void;
}

export const DistrictForm: React.FC<DistrictFormProps> = ({
  district,
  isEditing,
  setIsEditing,
  setConfirmDeleteModalOpen,
  onCancel,
}) => {
  const theme = useTheme() as Theme;
  const { values, isSubmitting, isValid, touched, resetForm, submitForm } =
    useFormikContext<DistrictFormValues>();

  return (
    <>
      <SubTitleWithButton>
        {district.name}
        <ButtonGroup>
          <ButtonComponent
            secondary
            customTheme={{
              primaryColor: theme.palette.error.color,
              secondaryColor: theme.palette.error.background,
            }}
            onClick={() => setConfirmDeleteModalOpen(true)}
          >
            Löschen
          </ButtonComponent>
          {isEditing ? (
            <>
              <ButtonComponent
                secondary
                onClick={() => onCancel({ resetForm, touched })}
              >
                {Object.keys(touched).length
                  ? "Änderungen verwerfen"
                  : "Abbrechen"}
              </ButtonComponent>
              <ButtonComponent
                isLoading={isSubmitting}
                disabled={!isValid}
                onClick={submitForm}
              >
                Speichern
              </ButtonComponent>
            </>
          ) : (
            <ButtonComponent
              onClick={() => setIsEditing(true)}
              disabled={isEditing}
            >
              Bearbeiten
            </ButtonComponent>
          )}
        </ButtonGroup>
      </SubTitleWithButton>
      {/* {Object.keys(errors).length ? (
        <AlertRetryable message={formatFormErrors(errors)} />
      ) : null} */}
      <Row>
        <Column style={{ width: "60%" }}>
          <FormikInput
            name="key"
            label="District Key *"
            placeholder="demo"
            isEditing={isEditing}
            disabled
          />
          <FormikInput
            name="companyKey"
            label="Company Key *"
            placeholder="ampeers"
            isEditing={isEditing}
            disabled
          />
          <FormikInput
            name="name"
            label="District Name *"
            placeholder="AMPEERS ENERGY Quartier"
            isEditing={isEditing}
            hint="Name, der in der District Management App angezeigt wird"
          />
        </Column>
        <ColumnWithInputWidth style={{ width: "40%" }}>
          <AddressForm accessor="address" isEditing={isEditing} />
        </ColumnWithInputWidth>
      </Row>

      <Row>
        <Column>
          <EmailForm
            identifier="alertingEmails"
            title="Alerting E-Mails"
            isEditing={isEditing}
            contact={values.contact}
          />
        </Column>
        <Column>
          <EmailForm
            identifier="reportingEmails"
            title="Reporting E-Mails"
            isEditing={isEditing}
            contact={values.contact}
          />
        </Column>
      </Row>

      <Accordion
        withPadding={false}
        autoClose={false}
        LabelComponent={SubTitleWithMargin}
        initialOpenIds={[0, 1, 2, 3]}
      >
        <AccordionItem label="Properties">
          <Column>
            <Header $alignEnd>
              <PropertiesLabels />
            </Header>
            <PropertiesForm isEditing={isEditing} canDuplicate />
          </Column>
        </AccordionItem>
        <AccordionItem label="Zeitreihen">
          <Column>
            <Header $alignEnd>
              <PropertiesLabels />
            </Header>
            <TimeseriesForm isEditing={isEditing} canDuplicate />
          </Column>
        </AccordionItem>
        {/* <AccordionItem label="Metadaten">
                <MetadataForm isEditing={isEditing} />
              </AccordionItem> */}
        <AccordionItem label="Notizen">
          <NotesForm isEditing={isEditing} />
        </AccordionItem>
      </Accordion>
      {!isEditing && (
        <ButtonGroup>
          <ButtonComponent
            secondary
            customTheme={{
              primaryColor: theme.palette.error.color,
              secondaryColor: theme.palette.error.background,
            }}
            onClick={() => setConfirmDeleteModalOpen(true)}
          >
            Löschen
          </ButtonComponent>
          <ButtonComponent
            onClick={() => setIsEditing(true)}
            disabled={isEditing}
          >
            Bearbeiten
          </ButtonComponent>
        </ButtonGroup>
      )}
    </>
  );
};
