import React, { useCallback, useMemo, useState } from "react";
import { Button, Table } from "@ampeersenergy/ampeers-ui-components";

import {
  Building,
  GetDistrictsDocument,
  useRemoveBuildingsMutation,
} from "../../graphql/sdks/controller";

import { AssetType, getAssetAddress } from "../../helpers/asset.utils";
import { TableIcon } from "../../components/table-icon";
import { dynamicPageSize } from "../../helpers/html.utils";
import { NotesIcon } from "../../components/notes-icon";
import { BulkDeleteModal } from "../../components/bulk-delete-modal";

export interface BuildingsProps {
  buildings: Building[];
  loading: boolean;
  onCreateBuildingClick: (value: boolean) => void;
  onBuildingRowClick: (original: Building | undefined) => void;
}

export const Buildings: React.FC<BuildingsProps> = ({
  loading,
  buildings,
  onBuildingRowClick,
  onCreateBuildingClick,
}) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [removeBuildings, { error: removeError }] = useRemoveBuildingsMutation({
    errorPolicy: "all",
    refetchQueries: [
      {
        query: GetDistrictsDocument,
        variables: {
          filter: {
            multipleDistricts: [
              {
                company: "all",
                district: "all",
              },
            ],
          },
        },
      },
    ],
  });

  const buildingColumns = useMemo(
    () => [
      {
        Header: "",
        accessor: "assetType",
        Cell: ({ value }: { value: AssetType }) => <TableIcon type={value} />,
        width: 30,
      },
      {
        Header: "Name",
        accessor: "name",
        width: "20%",
      },
      {
        Header: "Address",
        accessor: "shortAddress",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "",
        accessor: "notes.length",
        width: "1%",
        Cell: ({ value }: { value: number }) => <NotesIcon value={value} />,
      },
    ],
    []
  );

  const tableData = useMemo(
    () =>
      buildings.map((building) => ({
        ...building,
        shortAddress: getAssetAddress(building),
      })),
    [buildings]
  );

  const onRowSelect = useCallback(
    (rowIds: string[]) => {
      setSelectedRows(
        rowIds
          .map((rowId) => buildings.find((_, i) => i === +rowId)?.id)
          .filter((v): v is string => !!v)
      );
    },
    [setSelectedRows, buildings]
  );

  const onDelete = useCallback(() => {
    return removeBuildings({
      variables: {
        ids: selectedRows,
      },
    });
  }, [removeBuildings, selectedRows]);

  return (
    <>
      <Table
        compact
        withFilter
        withAlternatingRows
        withBoxShadow
        withPagination
        multiSelect
        onSelect={onRowSelect}
        filterKind="Gebäude"
        isLoading={loading}
        columns={buildingColumns}
        data={tableData}
        pageSize={dynamicPageSize()}
        onRowClick={({ original }) => {
          onBuildingRowClick(original);
        }}
        renderTableActions={() => (
          <>
            {!!selectedRows.length && (
              <BulkDeleteModal
                entityName="Gebäude"
                nameExtractFn={(id) => {
                  const building = buildings.find((b) => b.id === id);
                  return building?.name ?? building?.key ?? "--";
                }}
                removeError={removeError}
                onDelete={onDelete}
                selectedRows={selectedRows}
              />
            )}
            <Button onClick={() => onCreateBuildingClick(true)}>
              Gebäude anlegen
            </Button>
          </>
        )}
      />
    </>
  );
};
