import ElkConstructor from "elkjs";
import {
  Elements,
  isEdge,
  isNode,
  Node,
  ArrowHeadType,
  Position,
  Edge,
} from "react-flow-renderer";
import { LocalAsset, ExtendedTimeseries, LocalTimeseries } from "../types";
import { InferenceTypeEnum } from "../../graphql/sdks/controller";

const NODE_BASE_HEIGHT = 82;
const NODE_NAME_HEIGHT = 21;
const NODE_TYPE_HEIGHT = 27;
const NODE_BASE_WIDTH = 150;
const NODE_MIN_WIDTH = 450;

const INFERENCE_TYPE_MAP: Record<string, string | undefined> = {
  OPTIONAL: 'Optional',
  DEFAULT_DERIVATIVE: "Derived",
  DEFAULT_SUMMATION: "Inferred",
  CUSTOM_SUMMATION: "Inferred",
};

export const getInferenceType = (
  timeseries: ExtendedTimeseries,
  asset?: LocalAsset
) => {
  if (asset?.key !== timeseries.reference.entityKey) {
    return "external";
  }
  if (timeseries.source.type === "district") {
    return "measured";
  }
  if (timeseries.inference) {
    return "inferred";
  }
  return "missing";
};

export const getColors = (
  timeseries: ExtendedTimeseries,
  asset?: LocalAsset
): [string, string] => {
  if (asset?.key !== timeseries.reference.entityKey) {
    return ["#4eb7cc", "#4eb7cc"];
  }
  if (timeseries.source.type === "district") {
    return ["#8bdb78", "#8bdb78"];
  }
  if (timeseries.inference) {
    if (
      [
        InferenceTypeEnum.Optional,
        InferenceTypeEnum.DefaultDerivative,
        InferenceTypeEnum.DefaultSummation,
      ].includes(timeseries.inference.active.type)
    ) {
      return ["#d9d9d9", "#d9d9d9"];
    }
    return ["#ccefc3", "#ccefc3"];
  }
  return ["#ffa40e", "#ffa40e"];
};

export function hasMissingInference(t: LocalTimeseries) {
  return t.source.type !== "district" && !t.inference;
}

export function hasMissingInferences(
  timeseries: ExtendedTimeseries[],
  asset: LocalAsset
) {
  return timeseries.some(
    (t) => t.reference.entityKey === asset.key && hasMissingInference(t)
  );
}

export function getInferenceCount(
  timeseries: ExtendedTimeseries[],
  asset: LocalAsset
) {
  const timeseriesForAsset = timeseries.filter(
    (t) => t.reference.entityKey === asset.key
  );

  return {
    total: timeseriesForAsset.filter((ts) => ts.source.type !== "district")
      .length,
    filled: timeseriesForAsset.filter(
      (ts) => ts.source.type !== "district" && !!ts.inference
    ).length,
  };
}

export const getLayoutedElements = async (
  elements: Elements
): Promise<Elements> => {
  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.layered.spacing.nodeNodeBetweenLayers": "40",
      "elk.spacing.nodeNode": "20",
    },
    children: elements.filter(isNode).map((e) => {
      return {
        id: e.id,
        width: Math.max(
          Math.max(
            e.data.name.length * 16,
            (e.data.assetName || "").length * 10
          ) + NODE_BASE_WIDTH,
          NODE_MIN_WIDTH
        ),
        height:
          NODE_BASE_HEIGHT +
          (e.data.isExternal ? NODE_NAME_HEIGHT : 0) +
          (e.data.type ? NODE_TYPE_HEIGHT : 0),
      };
    }),
    edges: elements.filter(isEdge).map((e) => ({
      id: e.id,
      sources: [e.source],
      targets: [e.target],
    })),
  };

  const elk = new ElkConstructor();

  try {
    const result = await elk.layout(graph as any);

    return elements.map((e) => {
      if (isEdge(e)) {
        return e;
      }
      const f = result.children?.find((c) => c.id === e.id);
      if (!f) {
        return e;
      }
      e.position = {
        x: f.x || 0,
        y: f.y || 0,
      };
      return e;
    });
  } catch (e) {
    console.warn(e);
    return [];
  }
};

const getKey = (timeseries: ExtendedTimeseries) => {
  const { reference } = timeseries;
  const propertyIndex =
    (reference.type === "district" || reference.type === "asset") &&
    typeof reference.propertyIndex === "number"
      ? String(reference.propertyIndex! + 1).padStart(2, "0")
      : "";
  return reference.childEntityKey
    ? `${reference.childEntityKey}-${reference.propertyKey}${propertyIndex}`
    : `${reference.propertyKey}${propertyIndex}`;
};

export function buildAssetElements(
  allTimeseries: ExtendedTimeseries[],
  assets: LocalAsset[],
  asset?: LocalAsset
): Promise<Elements> {
  const timeseriesForAsset = asset
    ? allTimeseries.filter(
        (ts) =>
          ts.reference.entityKey === asset.key ||
          ts.reference.childEntityKey === asset.key
      )
    : allTimeseries;

  const sortedTimeseries = timeseriesForAsset.concat().sort((ts1, ts2) => {
    if (ts1.reference.entityKey === ts2.reference.entityKey) {
      return String(ts1.reference.propertyKey).localeCompare(
        String(ts2.reference.propertyKey)
      );
    }
    return String(ts1.reference.entityKey).localeCompare(
      String(ts2.reference.entityKey)
    );
  });

  const elements = sortedTimeseries.flatMap((timeseries) => {
    const isExternal = timeseries.reference.entityKey !== asset?.key;

    const rootElement: Node = {
      id: timeseries.uuid,
      position: { x: 0, y: 0 },
      type: "tile",
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      data: {
        timeseries,
        key: getKey(timeseries),
        name: getKey(timeseries),
        assetName: isExternal ? asset?.name : null,
        colors: getColors(timeseries, asset),
        isExternal,
        type: INFERENCE_TYPE_MAP[String(timeseries.inference?.active.type)],
        description: timeseries.description,
      },
    };

    const childrenElements = (
      timeseries.inference?.active?.inputs ?? []
    ).flatMap((input) => {
      const inputTimeseries = allTimeseries.find(
        (ts) => ts.uuid === input.timeseriesId
      );

      if (!inputTimeseries) {
        console.warn(`Cannot find timeseries for id: ${timeseries.uuid}`);
        return [];
      }

      const childAsset = assets.find(
        (a) =>
          a.key === inputTimeseries.reference.entityKey ||
          a.children.some((c) => c.key === inputTimeseries.reference.entityKey)
      );

      const isChildExternal =
        timeseries.reference.entityKey !== childAsset?.key;

      const edge: Edge = {
        id: `${timeseries.uuid}->${inputTimeseries.uuid}`,
        type: "flow",
        source: inputTimeseries.uuid,
        target: timeseries.uuid,
        label: input.factor,
        animated: true,
        arrowHeadType: ArrowHeadType.ArrowClosed,
      };

      const childNode: Node = {
        id: input.timeseriesId,
        position: { x: 0, y: 0 },
        type: "tile",
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        data: {
          inputTimeseries,
          input,
          key: getKey(inputTimeseries),
          name: getKey(inputTimeseries),
          assetName: isChildExternal ? childAsset?.name : null,
          colors: getColors(inputTimeseries, asset),
          isExternal: isChildExternal,
          type: INFERENCE_TYPE_MAP[
            String(inputTimeseries.inference?.active.type)
          ],
          description: timeseries.description,
        },
      };

      return [childNode, edge];
    });

    return [rootElement, ...childrenElements];
  });

  const filteredElements = elements.reduce<typeof elements>((acc, element) => {
    const elementExists = acc.find((a) => a.id === element.id);
    return elementExists ? acc : [...acc, element];
  }, []);

  let edgeOffsets: Record<string, number> = {};

  const elementsWithEdgeOffset = filteredElements.map((element) => {
    if (isEdge(element)) {
      const offset = edgeOffsets[element.source] ?? -1;

      edgeOffsets = {
        ...edgeOffsets,
        [element.source]: offset + 1,
      };

      return {
        ...element,
        data: {
          ...element.data,
          offset: offset + 1,
        },
      };
    }

    return element;
  });

  return getLayoutedElements(elementsWithEdgeOffset);
}
