import { Modal } from "@ampeersenergy/ampeers-ui-components";
import { MultistepWrapper } from "../style";
import { CreateEditContractFlow, CreateEditContractFlowProps } from "./form";

export interface CreateEditContractFlowModalProps
  extends CreateEditContractFlowProps {
  isOpen: boolean;
  title: string;
}

export const CreateEditContractFlowModal: React.FC<
  CreateEditContractFlowModalProps
> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      title={props.title}
      minWidth={1024}
      maxWidth="xl"
      minHeight={480}
    >
      <MultistepWrapper>
        <CreateEditContractFlow {...props} />
      </MultistepWrapper>
    </Modal>
  );
};
