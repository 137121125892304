import React from "react";
import styled from "styled-components";

import { Navigation } from "./navigation";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  height: 100vh;
  width: 100wh;
  overflow: hidden;

  aside {
    flex: 0 1 auto;
    z-index: 2;
    height: 100%;
  }

  main {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }

  main > div {
    height: 100vh;
    width: 100px; // setting any width here so the content takes up all space of window width
    overflow-x: hidden; // removing horizontal scroll which should be handled by content container
    overflow-y: auto;
  }
`;

export const MainLayout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <aside>
        <Navigation />
      </aside>
      <main>{children}</main>
    </Wrapper>
  );
};
