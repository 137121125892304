import React from "react";
import styled, { useTheme } from "styled-components";
import {
  DynamicListItemProps,
  Icons,
  Label,
  Theme,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";

import { Divider, NewItemWrapper, PlusIcon } from "../style";
import { IconButton } from "../icon-button";

const SmallIconButton = styled(IconButton)`
  padding: 0px;
  height: 32px;
  width: 34px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export interface InterpolationListItemProps extends DynamicListItemProps {}

export const InterpolationListItem: React.FC<InterpolationListItemProps> = (
  props
) => {
  const { item, index } = props;

  const {
    editMode,
    setEditMode,
    isValid,
    onAddItem,
    onDelete,
    EditComponent = () => null,
  } = useDynamicListItem({
    ...props,
  });
  const theme = useTheme() as Theme;

  if (item.isNewValue) {
    return (
      <NewItemWrapper key={item.key} onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        Interpolation hinzufügen
      </NewItemWrapper>
    );
  }

  return (
    <li>
      <LabelWithIcon onClick={() => setEditMode(!editMode)}>
        <span>Interpolation Leistungsstufe {index + 1}</span>
        <IconWrapper>
          <SmallIconButton
            borderless
            icon={editMode ? Icons.ChevronUp : Icons.ChevronDown}
            onClick={() => setEditMode(!editMode)}
          />
          <SmallIconButton
            borderless
            icon={Icons.Delete}
            onClick={onDelete}
            color={theme.palette.error.color}
          />
        </IconWrapper>
      </LabelWithIcon>
      <Divider />
      {editMode && <EditComponent {...props} isValid={isValid} />}
    </li>
  );
};
