import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled, { useTheme } from "styled-components";
import { saveAs } from "file-saver";
import {
  Button as ButtonComponent,
  Icons,
  Modal,
  SpinnerDark,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import { APP_KEY, getSession } from "../services/session.service";

const Wrapper = styled.div`
  border: 1px solid #979797;
  border-radius: 4px;
  display: inline-block;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};

    svg {
      fill: #fff;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(ButtonComponent)`
  align-self: flex-start;
`;

const IFrame = styled.iframe`
  &.is-loading {
    display: none;
  }

  border: none;
  outline: 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1000px;
  width: 850px;
  background: whitesmoke;
`;

interface FilePreviewProps {
  children?: React.ReactElement;
  name: string;
  url?: string;
  fileName: string;
  withPreview?: boolean;
  clientId?: string;
  documentType?: "pdf" | "yaml";
  withIcon?: boolean;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}

export const FilePreview: React.FC<FilePreviewProps> = (props) => {
  const {
    name,
    url,
    withPreview,
    fileName,
    clientId,
    documentType,
    children,
    withIcon = true,
    isOpen,
    setIsOpen,
  } = props;

  const theme = useTheme() as Theme;
  const [internalIsOpen, setInternalIsOpen] = useState(!!isOpen);
  const [isLoading, setIsLoading] = useState(true);
  const [authorizedUrl, setAuthorizedUrl] = useState<string | undefined>();
  const {
    clientId: sessionClientId,
    token,
    districtKey,
    companyKey,
    appKey = APP_KEY,
  } = useMemo(() => getSession() ?? {}, []);

  const contentType = useMemo(() => {
    switch (documentType) {
      case "pdf":
        return "application/pdf";
      case "yaml":
      default:
        return;
    }
  }, [documentType]);

  const fetchDocument = useCallback(
    async (onSuccess: (response: any, fileName?: string) => void) => {
      if (
        !url ||
        (!clientId && !sessionClientId) ||
        !token ||
        !appKey ||
        !companyKey ||
        !districtKey
      ) {
        if (!url) console.error("Missing url");
        if (!clientId && !sessionClientId) console.error("Missing clientId");
        if (!token) console.error("Missing token");
        if (!appKey) console.error("Missing appKey");
        if (!districtKey) console.error("Missing districtKey");
        if (!companyKey) console.error("Missing companyKey");
        return Promise.resolve();
      }

      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open("GET", url);
        xhr.onreadystatechange = handler;
        xhr.responseType = withPreview ? "arraybuffer" : "blob";
        xhr.setRequestHeader(
          "x-ampeers-client-id",
          (clientId ?? sessionClientId)!
        );
        xhr.setRequestHeader("x-ampeers-user-token", token);
        xhr.setRequestHeader("x-ampeers-app-verify", appKey);
        xhr.setRequestHeader("x-ampeers-company", companyKey);
        xhr.setRequestHeader("x-ampeers-district", districtKey);
        contentType && xhr.setRequestHeader("Content-Type", contentType);
        xhr.send();

        function handler(this: any) {
          if (this.readyState === this.DONE) {
            if (this.status === 200) {
              if (withPreview)
                onSuccess(
                  new Blob(
                    [this.response],
                    contentType && { type: contentType }
                  ),
                  fileName
                );
              else onSuccess(this.response, fileName);
              resolve(this.response);
            } else {
              console.error("Failed to load document:", this);
              reject("Failed to load document");
            }
          }
        }
      });
    },
    [
      url,
      clientId,
      sessionClientId,
      token,
      appKey,
      companyKey,
      districtKey,
      withPreview,
      contentType,
      fileName,
    ]
  );

  const onDownload = useCallback(() => {
    if (withPreview) {
      setIsLoading(true);
      (setIsOpen ?? setInternalIsOpen)(true);
      fetchDocument((response) => {
        setAuthorizedUrl(URL.createObjectURL(response));
        setIsLoading(false);
      });
    } else {
      fetchDocument(saveAs);
    }
  }, [fetchDocument, withPreview, setIsOpen, setInternalIsOpen]);

  const onSaveFile = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (authorizedUrl) saveAs(authorizedUrl, fileName);
    },
    [authorizedUrl, fileName]
  );

  useEffect(() => {
    if (isOpen) {
      onDownload();
    }
  }, [isOpen, onDownload]);

  return (
    <>
      <Modal
        isOpen={isOpen ?? internalIsOpen}
        contentLabel={`modal-${name}`}
        title={`${name}`}
        onRequestClose={() => (setIsOpen ?? setInternalIsOpen)(false)}
      >
        {isLoading && (
          <LoadingWrapper>
            <SpinnerDark size={35} />
            Laden…
          </LoadingWrapper>
        )}
        <Column>
          <div>
            <IFrame
              src={authorizedUrl}
              scrolling="auto"
              height={documentType === "pdf" ? "1000" : "600"}
              width={documentType === "pdf" ? "850" : "400"}
              // onLoad={() => setIsLoading(false)}
              className={isLoading ? "is-loading" : ""}
            />
          </div>
          {withPreview && documentType !== "pdf" && (
            <Button onClick={onSaveFile}>Herunterladen</Button>
          )}
        </Column>
      </Modal>
      {withIcon ? (
        children ? (
          React.Children.map(
            children,
            (child: React.ReactElement, index: number) =>
              React.cloneElement(child, {
                onClick: onDownload,
              })
          )
        ) : (
          <Wrapper
            data-testid={`file-preview-download-${name}`}
            onClick={onDownload}
          >
            <Icons.File size={20} color={theme.primaryColor} />
          </Wrapper>
        )
      ) : null}
    </>
  );
};
