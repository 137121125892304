import { useCallback, useEffect, useRef } from "react";

export interface OutsideClickHandlerProps {
  onOutsideClick: (e: MouseEvent) => void;
}

export const useOutsideClickHandler = <T extends HTMLDivElement>(
  props: OutsideClickHandlerProps
) => {
  const targetRef = useRef<T>(null);

  const onMouseDown = useCallback(
    (e) => {
      const isDescendantOfRoot =
        targetRef?.current && e.target && targetRef.current.contains(e.target);
      if (!isDescendantOfRoot) {
        props.onOutsideClick(e);
      }
    },
    [props]
  );

  useEffect(() => {
    // Mount
    window.document.addEventListener("mousedown", onMouseDown);

    // Unmount
    return () => {
      window.document.removeEventListener("mousedown", onMouseDown);
    };
  }, [onMouseDown]);

  return { targetRef };
};
