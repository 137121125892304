export enum ElementType {
  District = "district",
  Asset = "asset",
  Building = "building",
  Contract = "contract",
}

export type Entity<T> = T & {
  id: string;
  key: string;
  name?: string;
  elementType: ElementType;
  template?: string;
  isVirtual?: boolean;
  index?: number;
  groupSize?: number;
  maxGroupWidth?: number;
  maxGroupHeight?: number;
};
