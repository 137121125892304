import {
  CheckboxNew,
  Icons,
  Pagination,
  TableCells,
  TableStyledWithBoxShadow,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";
import React, { useCallback, useEffect, useMemo } from "react";
import ContentLoader from "react-content-loader";
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  Column,
  ColumnInterfaceBasedOnValue,
  Row,
  TableInstance,
  HeaderGroup,
  UseSortByColumnProps,
  UseSortByOptions,
  UseRowSelectRowProps,
  UseExpandedRowProps,
  UsePaginationInstanceProps,
} from "react-table";
import styled, { useTheme } from "styled-components";

const { SortAscending, SortDescending, Sort } = Icons;

export const EmptyResult = styled.div`
  padding: 50px 0px;
  text-align: center;
  color: ${(props) => props.theme.palette.textMuted};
  font-size: 14px;
  border: 1px solid ${(props) => props.theme.palette.border};
  border-top: 0px;
  background: ${(props) => props.theme.palette.background};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RowWrapper = styled.tr`
  td {
    border-bottom: 1px solid ${(props) => props.theme.palette.border};
  }

  &.selected > td {
    background: ${(props) => props.theme.secondaryColor} !important;
  }

  &.clickable {
    cursor: pointer !important;
  }

  &.alternating {
    :nth-child(2n - 1) > td {
      background: ${(props) => props.theme.palette.backgroundMuted};
    }

    &:not(:last-child) {
      td {
        border-bottom: 0px;
      }
    }
  }
`;

export type ExtendedColumn<T extends {}> = Omit<
  ColumnInterfaceBasedOnValue<T> & Column,
  "accessor" | "width" | "minWidth" | "maxWidth"
> & {
  Cell?: (props: TableCells<T>) => React.ReactNode;
  accessor?: string;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
};

type ModifiedColumn = Omit<
  ColumnInterfaceBasedOnValue,
  "accessor" | "width" | "minWidth" | "maxWidth"
> & {
  accessor?: string;
  __width?: string | number;
  __minWidth?: string | number;
  __maxWidth?: string | number;
};

export type ExtendedHeader<T extends {}> = HeaderGroup &
  UseSortByColumnProps<T> &
  UseSortByOptions<T> &
  ModifiedColumn;

export type ExtendedRow<T extends {}> = Row<T> &
  UseRowSelectRowProps<T> &
  UseExpandedRowProps<T>;

export type ExtendedTable<T extends {}> = TableInstance<T> &
  UsePaginationInstanceProps<T>;

export interface TableProps<T extends {}> {
  columns: ExtendedColumn<T>[];
  data: T[];
  initialState?: any;
  pageSize?: number;
  filterKind?: string;
  isLoading: boolean;
  compact?: boolean;
  canSelectRows?: boolean;
  withPagination?: boolean;
  alternatingRows?: boolean;
  onSelect?: (selectedRows: string[]) => void;
  onRowClick?: (item: T) => void;
}

export const Table = <T extends {}>(props: TableProps<T>) => {
  const {
    columns,
    data,
    initialState = {},
    pageSize: controlledPageSize,
    filterKind,
    isLoading,
    compact,
    canSelectRows,
    withPagination,
    alternatingRows,
    onSelect,
    onRowClick,
  } = props;
  const theme = useTheme() as Theme;

  const modifiedColumns: ModifiedColumn[] = useMemo(
    () =>
      [
        ...(canSelectRows
          ? [
              {
                id: "selection",
                accessor: "selection",
                width: "1%",
                Header: ({ getToggleAllRowsSelectedProps }: any) => {
                  const { onChange } = getToggleAllRowsSelectedProps() ?? {};

                  return (
                    <CheckboxNew
                      id={`${filterKind}-checkbox-all`}
                      {...getToggleAllRowsSelectedProps()}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(_, e) => onChange(e)}
                    />
                  );
                },
                Cell: ({ row }: any) => {
                  const { onChange } = row.getToggleRowSelectedProps() ?? {};
                  return (
                    <CheckboxNew
                      id={`${filterKind}-checkbox-${row.id}`}
                      {...row.getToggleRowSelectedProps()}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(_, e) => onChange(e)}
                    />
                  );
                },
                filterable: false,
                disableSortBy: true,
              } as ExtendedColumn<T>,
            ]
          : []),
        ...columns,
      ].map(({ width, maxWidth, minWidth, Cell, ...column }) => ({
        __width: width,
        __maxWidth: maxWidth,
        __minWidth: minWidth,
        Cell: Cell ?? (({ value }: { value: any }) => value),
        ...column,
      })),
    [columns, filterKind, canSelectRows]
  );

  const { headerGroups, rows, getTableProps, prepareRow, ...otherProps } =
    useTable(
      {
        columns: modifiedColumns,
        data,
        autoResetPage: false,
        initialState: {
          ...initialState,
          pageSize: controlledPageSize,
        },
      } as any,
      useSortBy,
      ...(withPagination ? [usePagination] : []),
      ...(canSelectRows ? [useRowSelect] : [])
    );

  const {
    page,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,

    state: { pageIndex, pageSize, selectedRowIds },
  } = otherProps as any;

  const renderSortedHeader = useCallback(
    (column: ExtendedHeader<T>) => {
      const style = { verticalAlign: "bottom" };

      return column.Header !== "" && !column.disableSortBy && column.canSort ? (
        column.isSorted ? (
          column.isSortedDesc ? (
            <SortDescending
              size="20"
              color={theme.primaryColor}
              style={style}
            />
          ) : (
            <SortAscending size="20" color={theme.primaryColor} style={style} />
          )
        ) : (
          <Sort size="20" style={style} />
        )
      ) : null;
    },
    [theme]
  );

  const renderHeader = useCallback(
    (column: ExtendedHeader<T>) => {
      return (
        <th
          {...column.getHeaderProps()}
          {...column.getHeaderProps(column.getSortByToggleProps())}
          style={{
            ...(column.__width && { width: column.__width }),
            ...(column.__minWidth && { width: column.__minWidth }),
            ...(column.__maxWidth && { maxWidth: column.__maxWidth }),
          }}
          className={`
          ${column.disableSortBy ? "" : "canSort"}
          ${column.isSorted ? "sorted" : ""}
          ${compact ? "compact" : ""}
          ${column.id === "selection" ? "selection" : ""}
        `}
        >
          {column.Header !== "" && !column.disableSortBy ? (
            <HeaderWrapper>
              {column.render("Header")}
              {column.id !== "selection" && renderSortedHeader(column)}
            </HeaderWrapper>
          ) : (
            column.render("Header")
          )}
        </th>
      );
    },
    [compact, renderSortedHeader]
  );

  const renderRow = useCallback(
    (row: ExtendedRow<T>) => {
      prepareRow(row as any);
      const rowProps = row.getRowProps();
      const { cells, isSelected } = row;

      if (isSelected || (row.original as any).isSelected)
        rowProps.className = `${rowProps.className || ""} selected`;

      if (alternatingRows) {
        rowProps.className = `${rowProps.className || ""} alternating`;
      }

      if (onRowClick) {
        rowProps.className = `${rowProps.className || ""} clickable`;
      }

      return (
        <RowWrapper onClick={() => onRowClick?.(row.original)} {...rowProps}>
          {cells.map((cell: any, cellIndex: number) => {
            const cellProps = cell.getCellProps({
              colSpan: cell.row.original.colSpan,
              style: {
                ...(cell.column.style || {}),
                ...(cell.column.id !== "selection"
                  ? { paddingLeft: (row.depth + 1) * (compact ? 8 : 12) }
                  : {}),
              },
            });

            if (compact)
              cellProps.className = `${cellProps.className || ""} compact`;

            if (cell.column.id === "selection")
              cellProps.className = `${cellProps.className || ""} selection`;

            return <td {...cellProps}>{cell.render("Cell")}</td>;
          })}
        </RowWrapper>
      );
    },
    [prepareRow, onRowClick, compact, alternatingRows]
  );

  const renderLoadingRows = useCallback(
    (pageSize: number, columns: ModifiedColumn[]) => {
      return Array(pageSize)
        .fill(0)
        .map((_: any, index: number) => (
          <RowWrapper key={index}>
            {columns.map((_: any, y: number) => (
              <td key={`${index}${y}`}>
                <ContentLoader
                  id="content-loader-table"
                  height={21}
                  speed={2}
                  style={{ width: "100%" }}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="100%" height="21" />
                </ContentLoader>
              </td>
            ))}
          </RowWrapper>
        ));
    },
    []
  );

  useEffect(() => {
    if (onSelect) {
      onSelect(Object.keys(selectedRowIds));
    }
  }, [selectedRowIds, onSelect]);

  const rowsOrPage = withPagination ? page : rows;

  return (
    <TableStyledWithBoxShadow>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                renderHeader(column as ExtendedHeader<T>)
              )}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading
            ? renderLoadingRows(3, modifiedColumns)
            : rowsOrPage.length > 0
            ? rowsOrPage.map((row: ExtendedRow<T>) => renderRow(row))
            : null}
        </tbody>
      </table>
      {!isLoading && rowsOrPage.length === 0 && (
        <EmptyResult>Keine {filterKind} gefunden.</EmptyResult>
      )}
      {withPagination && (
        <Pagination
          nextPage={nextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          page={{ length: page.length }}
          rowCount={rows.length}
        />
      )}
    </TableStyledWithBoxShadow>
  );
};
