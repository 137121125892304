import React from "react";
import { Modal } from "@ampeersenergy/ampeers-ui-components";

import { MultistepWrapper } from "../style";

import { CreateEditBuildingFlow, CreateEditBuildingFlowProps } from "./form";

export interface CreateEditBuildingFlowModalProps
  extends CreateEditBuildingFlowProps {
  isOpen: boolean;
  title: string;
}

export const CreateEditBuildingFlowModal: React.FC<
  CreateEditBuildingFlowModalProps
> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      title={props.title}
      minWidth={1024}
      maxWidth="xl"
      minHeight={480}
    >
      <MultistepWrapper>
        <CreateEditBuildingFlow {...props} />
      </MultistepWrapper>
    </Modal>
  );
};
