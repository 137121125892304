import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Background,
  Controls,
  Elements,
  ReactFlowProvider,
  isEdge,
} from "react-flow-renderer";
import { Icons } from "@ampeersenergy/ampeers-ui-components";

import {
  ExtendedTimeseries,
  ExtendedAsset,
  LocalDistrict,
  LocalTimeseries,
  LocalAsset,
} from "../types";

import {
  DropdownItemWrapper,
  FloatingDropdowns,
  FlowWrapper,
  StyledReactFlow,
} from "./style";
import { Labels, Panel } from "../style";
import { AssetNotesForm } from "./asset-notes-form";
import { InferenceOverviewForm } from "./form";
import { Label } from "../label";
import { Tile } from "./tile";

import {
  buildAssetElements,
  getInferenceCount,
  getInferenceType,
  hasMissingInferences,
} from "./utils";
import {
  addOrRemoveFromArray,
  arrayToObject,
  removeFromArray,
} from "../../helpers/array.utils";
import { TimeseriesNotesForm } from "./timeseries-notes-form";
import { groupAssetsByBuilding } from "../../helpers/asset.utils";
import { DropdownDividerItem } from "../dropdown";
import { FlowEdge } from "./flow-edge";
import { DropdownButton } from "../dropdown-button";
import { DropdownCheckbox } from "../dropdown-checkbox";

const nodeTypes = { tile: Tile };

const edgeTypes = { flow: FlowEdge };

export interface GraphViewProps {
  district: LocalDistrict;
  assets: ExtendedAsset[];
  timeseries: LocalTimeseries[];
}

export const GraphView: React.FC<GraphViewProps> = ({
  district,
  assets,
  timeseries,
}) => {
  const sortedAssets = useMemo(
    () =>
      assets.sort((a1, a2) =>
        a1.name.localeCompare(a2.name, undefined, { numeric: true })
      ),
    [assets]
  );

  const [timeseriesFilter, setTimeseriesFilter] = useState<string[]>([]);
  const [hoveredId, setHoveredId] = useState<string | undefined>();
  const [elements, setElements] = useState<Elements>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<number | undefined>(
    undefined
  );
  const [sidebarPanelOpen, setSidebarPanelOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<ExtendedAsset | undefined>(
    sortedAssets[0]
  );
  const [selectedTimeseries, setSelectedTimeseries] = useState<
    ExtendedTimeseries | undefined
  >();

  const extendedTimeseries: ExtendedTimeseries[] = useMemo(() => {
    const assetKeyMap = arrayToObject<
      ExtendedAsset,
      keyof ExtendedAsset,
      ExtendedAsset
    >(assets, "key");

    return timeseries.map((ts) => {
      const asset =
        assetKeyMap[ts.reference.entityKey as keyof typeof assetKeyMap];
      const properties = ts.reference.childEntityKey
        ? asset?.children.find((c) => c.key === ts.reference.childEntityKey)
            ?.properties
        : asset?.properties;
      const refTimeseriesProp = properties?.find(
        (p) => p.key === ts.reference.propertyKey
      );

      return {
        ...ts,
        asset: asset as LocalAsset,
        refTimeseriesProp: refTimeseriesProp ? [refTimeseriesProp] : [],
      } as ExtendedTimeseries;
    });
  }, [assets, timeseries]);

  useEffect(() => {
    const filteredTimeseries = extendedTimeseries.filter((ts) => {
      return (
        timeseriesFilter.includes("all") ||
        timeseriesFilter.length === 0 ||
        timeseriesFilter.includes(getInferenceType(ts, selectedAsset)) ||
        (timeseriesFilter.includes("inferred") &&
          getInferenceType(ts, selectedAsset) === "external")
      );
    });

    buildAssetElements(filteredTimeseries, assets, selectedAsset).then((el) => {
      const elements = el.map((element) => {
        if (isEdge(element)) {
          return {
            ...element,
            data: {
              ...element.data,
              hovered:
                element.source === hoveredId || element.target === hoveredId,
            },
          };
        }

        return element;
      });
      setElements(elements);
    });
  }, [extendedTimeseries, assets, selectedAsset, hoveredId, timeseriesFilter]);

  const ref = useRef<{
    fitView: Function;
    zoomOut: Function;
  }>();

  const resizeFlow = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      if (!ref.current) {
        return;
      }
      ref.current.fitView();
      ref.current.zoomOut();
      setIsVisible(true);
    }, 100);
  }, []);

  const onLoad = useCallback(
    (instance: any) => {
      ref.current = instance;
      resizeFlow();
    },
    [resizeFlow]
  );

  return (
    <>
      <FloatingDropdowns>
        <DropdownButton
          items={Object.entries(groupAssetsByBuilding(assets)).flatMap(
            ([buildingId, buildingAssets]) => {
              const building = district.buildings.find(
                (b) => b.id === buildingId
              );

              return [
                {
                  divider: true,
                  label: building?.name ?? building?.key,
                } as DropdownDividerItem,
                ...buildingAssets.map((asset) => {
                  const { total, filled } = getInferenceCount(
                    extendedTimeseries,
                    asset
                  );

                  const missingInference = hasMissingInferences(
                    extendedTimeseries,
                    asset
                  );

                  return {
                    label: (
                      <DropdownItemWrapper>
                        {asset.name ?? asset.key}
                        <span
                          className={[
                            "inference-ratio",
                            missingInference ? "orange" : "",
                          ].join(" ")}
                        >
                          <span>{filled}&nbsp;</span> / {total}
                          {/* {missingInference ? (
                          <WarningIcon
                            size={22}
                            color={theme.palette.warning.color}
                          />
                        ) : null} */}
                        </span>
                      </DropdownItemWrapper>
                    ),
                    value: asset.key,
                  };
                }),
              ];
            }
          )}
          isOpen={dropdownOpen === 0}
          onOpen={() => setDropdownOpen(0)}
          onClose={() => setDropdownOpen(undefined)}
          icon={Icons.Building}
          onItemClick={(key) => {
            const asset = assets.find((a) => a.key === key) ?? sortedAssets[0];
            setSelectedAsset(asset);
            setSelectedTimeseries(undefined);
            setDropdownOpen(undefined);
            resizeFlow();
          }}
        >
          {selectedAsset?.name ?? "Anlagen"}
        </DropdownButton>
        <DropdownCheckbox
          items={[
            {
              label: "Alle",
              value: "all",
              checked: !!timeseriesFilter.includes("all"),
            },
            {
              label: "Gemessene",
              value: "measured",
              checked: !!timeseriesFilter.includes("measured"),
            },
            {
              label: "Inferierte",
              value: "inferred",
              checked: !!timeseriesFilter.includes("inferred"),
            },
            {
              label: "Fehlende",
              value: "missing",
              checked: !!timeseriesFilter.includes("missing"),
            },
          ]}
          isOpen={dropdownOpen === 1}
          onOpen={() => setDropdownOpen(1)}
          onClose={() => setDropdownOpen(undefined)}
          icon={Icons.Filter}
          onItemClick={(item) => {
            if (item === "all") {
              setTimeseriesFilter([item]);
            } else {
              let newFilterArray = removeFromArray(timeseriesFilter, "all");
              newFilterArray = addOrRemoveFromArray(
                newFilterArray,
                item!
              ).concat();

              if (newFilterArray.length === 0) {
                newFilterArray = ["all"];
              }

              setTimeseriesFilter(newFilterArray);
            }
          }}
        >
          Filter
        </DropdownCheckbox>
      </FloatingDropdowns>
      <Panel
        isOpen={!!sidebarPanelOpen}
        setIsOpen={(_sidebarPanelOpen) => {
          setSidebarPanelOpen(_sidebarPanelOpen);
          resizeFlow();
        }}
        sidebarPosition="right"
        sidebarWidth={"40%"}
        sidebarItems={[
          {
            Icon: Icons.File,
            content: (
              <InferenceOverviewForm
                selectedTimeseries={selectedTimeseries}
                selectedAsset={selectedAsset}
                timeseries={extendedTimeseries}
                assets={assets}
                district={district}
              />
            ),
          },
          {
            Icon: Icons.Pencil,
            content: <AssetNotesForm timeseries={selectedTimeseries} />,
          },
          {
            Icon: Icons.Pencil,
            content: <TimeseriesNotesForm timeseries={selectedTimeseries} />,
          },
        ]}
      >
        <FlowWrapper>
          <ReactFlowProvider>
            <StyledReactFlow
              $isVisible={isVisible}
              elements={elements}
              snapToGrid={true}
              snapGrid={[15, 15]}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onLoad={onLoad}
              onNodeMouseEnter={(e, node) => setHoveredId(node.id)}
              onNodeMouseLeave={() => setHoveredId(undefined)}
              onElementClick={(_, node) => {
                const _selectedTimeseries =
                  node.data.inputTimeseries ?? node.data.timeseries;

                if (_selectedTimeseries) {
                  setSelectedTimeseries(_selectedTimeseries);
                  setSidebarPanelOpen(true);
                  if (!sidebarPanelOpen) {
                    resizeFlow();
                  }
                }
              }}
            >
              <Controls />
              <Background color="#aaa" gap={16} />
            </StyledReactFlow>
          </ReactFlowProvider>
          <Labels>
            <Label color="#8bdb78" label="gemessene Zeitreihe" />
            <Label color="#ccefc3" label="custom inferierte Zeitreihe" />
            <Label color="#d9d9d9" label="default inferierte / optionale Zeitreihe" />
            <Label color="#4eb7cc" label="externe Zeitreihe" />
            <Label color="#ffa40e" label="fehlende Zeitreihe" />
          </Labels>
        </FlowWrapper>
      </Panel>
    </>
  );
};
