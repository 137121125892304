import { InfoAlert } from "@ampeersenergy/ampeers-ui-components";
import { useFormikContext } from "formik";
import React, { ChangeEvent, useCallback, useContext, useEffect } from "react";
import { Building } from "../../graphql/sdks/controller";
import { AddressForm } from "../address-form";
import { MultistepFormContext } from "../multistep-form/context";
import { FlexRawSelect } from "../style";
import { AssetFormValues } from "../types";

export interface AssetFormBuildingProps {
  isEditing?: boolean;
  buildings: Building[];
  displayAddress?: boolean;
}

export const AssetFormBuilding: React.FC<AssetFormBuildingProps> = ({
  isEditing,
  buildings,
  displayAddress,
}) => {
  const { values, setValues, errors } = useFormikContext<AssetFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    if (registerField) {
      registerField("buildingKey");
    }

    return () => {
      if (unregisterField) {
        unregisterField("buildingKey");
      }
    };
  }, [registerField, unregisterField]);

  const onBuildingChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      const building = buildings?.find((b) => b.key === e.target.value);

      setValues({
        ...values,
        buildingKey: e.target.value === "--" ? undefined : e.target.value,
        parentId: building?.id ?? undefined,
        address: building?.address ?? values.address,
      });
    },
    [buildings, values, setValues]
  );

  return (
    <>
      {isEditing && (
        <InfoAlert>
          Die Adresse von dem ausgewählten Gebäude wird für die Anlage benutzt.
        </InfoAlert>
      )}
      <FlexRawSelect
        id="building"
        name="buildingKey"
        label="Gebäude"
        value={values.buildingKey || ""}
        isEditing={isEditing}
        errorMsg={errors.buildingKey}
        onChange={onBuildingChange}
      >
        <option value={"null"} key="null">
          --
        </option>
        {buildings.map((building) => (
          <option key={building.key} value={building.key}>
            {building.name}
          </option>
        ))}
      </FlexRawSelect>
      {displayAddress && (
        <AddressForm
          accessor="address"
          latAndLongRequired={false}
          isEditing={false}
        />
      )}
    </>
  );
};
