import { BaseIcon, Icons } from "@ampeersenergy/ampeers-ui-components";
import { METADATA_INPUT_TYPE } from "../components/metadata-form/types";
import {
  AssetChildFormValues,
  AssetFormValues,
  ExtendedAsset,
  LocalAsset,
  LocalAssetChild,
  LocalTimeseries,
} from "../components/types";
import {
  Address,
  AssetTemplate,
  Building,
  District,
  PropertyTemplate,
} from "../graphql/sdks/controller";
import { mergePropertyTemplates } from "./properties.utils";
import { sortMetadata } from "./metadata.utils";

export type AssetsByType<T extends LocalAsset> = Map<AssetType, T[]>;
export type AssetsByGroup<T extends LocalAsset> = Map<AssetGroup, T[]>;

export function omitGraphqlType<T extends { __typename?: string }>({
  __typename,
  ...rest
}: T): Omit<T, "__typename"> {
  return rest;
}

export function flattenAssets<T extends LocalAsset>(
  district: District,
  assetTransformFn?: (asset: LocalAsset, building?: Building) => T
): (T extends LocalAsset ? T : LocalAsset)[] {
  const buildingsAssets = district.buildings?.reduce(
    (assets: LocalAsset[], building: Building) => [
      ...assets,
      ...(assetTransformFn
        ? building.assets.map((asset) => assetTransformFn(asset, building))
        : building.assets),
    ],
    []
  );

  return [
    ...(district.assets || []).map(
      (asset) => assetTransformFn?.(asset) ?? asset
    ),
    ...buildingsAssets,
  ] as (T extends LocalAsset ? T : LocalAsset)[];
}

export function getAssetAddress(
  asset: LocalAsset | Building | District,
  prefix = " ",
  withAccountingEntity = false
): string {
  let address = "";
  if (asset.address) {
    address += `${prefix}${asset.address.street}`;
  }
  if (asset.address && asset.address.streetNr?.length) {
    address += ` ${asset.address.streetNr}`;
  }
  if (withAccountingEntity && asset.address && asset.address.accountingEntity) {
    address += ` (${asset.address.accountingEntity})`;
  }
  return address;
}

export enum AssetType {
  Building = "building",
  Buildings = "buildings",

  Boiler = "boiler",
  ChargingStation = "chargingStation",
  CHP = "combinedHeatPowerUnit",
  ElectricGrid = "electricGrid",
  ElectricMeter = "electricMeter",
  ElectricStorage = "electricStorage",
  Electrolyzer = "electrolyzer",
  EnergyCenter = "energyCenter",
  FuelCell = "fuelCell",
  GasGrid = "gasGrid",
  HeatMeter = "heatMeter",
  HeatPump = "heatPump",
  HeatStorage = "heatStorage",
  HeatStorageLayered = "heatStorageLayered",
  HeatStorageTemperature = "heatStorageTemperature",
  Heater = "heater",
  HydrogenStorage = "hydrogenStorage",
  MixerValve = "mixerValve",
  PelletBoiler = "pelletBoiler",
  PelletGrid = "pelletGrid",
  Photovoltaic = "photovoltaic",
  Recooler = "recooler",
  WeatherStation = "weatherStation",

  // Internal
  Limiter = "energyLimiter",
  LockDown = "lockDown",
  Pump = "pump",
  Split = "split",
  SwitchoverValve = "switchoverValve",
  Valve = "valve",
  Weather = "weather",
}

export enum AssetChildType {
  StorageLayer = "storageLayer",
}

/**
 * delivers the name of an asset based on its type either in singular or plural
 * TODO: this is i18n and should be handled with additional tooling
 * @param type
 * @param plural
 */
export function getAssetTypeName(
  type: AssetType | AssetChildType,
  plural = false
): string {
  const index = plural ? 1 : 0;
  const TYPE_NAME_MAP: {
    [key in AssetType | AssetChildType]: [string, string];
  } = {
    [AssetType.Building]: ["Haus", "Häuser"],
    [AssetType.Buildings]: ["Gebäude", "Gebäude"],
    [AssetType.EnergyCenter]: ["Energiezentrale", "Energiezentralen"],

    [AssetType.Boiler]: ["Brennwertkessel", "Brennwertkessel"],
    [AssetType.ChargingStation]: ["Ladestation", "Ladestationen"],
    [AssetType.CHP]: ["Blockheizkraftwerk", "Blockheizkraftwerke"],
    [AssetType.ElectricGrid]: ["Netzanschluss", "Netzanschlüsse"],
    [AssetType.ElectricMeter]: ["Stromzähler", "Stromzähler"],
    [AssetType.ElectricStorage]: ["Strom-Speicher", "Strom-Speicher"],
    [AssetType.Electrolyzer]: ["Elektrolyseur", "Elektrolyseure"],
    [AssetType.FuelCell]: ["Brennstoffzelle", "Brennstoffzellen"],
    [AssetType.GasGrid]: ["Gasanschluss", "Gasanschlüsse"],
    [AssetType.HeatMeter]: ["Wärmezähler", "Wärmezähler"],
    [AssetType.HeatPump]: ["Wärmepumpe", "Wärmepumpen"],
    [AssetType.HeatStorage]: ["Wärmespeicher", "Wärmespeicher"],
    [AssetType.HeatStorageLayered]: [
      "Schichten-Wärmespeicher",
      "Schichten-Wärmespeicher",
    ],
    [AssetType.HeatStorageTemperature]: [
      "Temperatur-Wärmespeicher",
      "Temperatur-Wärmespeicher",
    ],
    [AssetType.Heater]: ["Heizstab", "Heizstäbe"],
    [AssetType.HydrogenStorage]: ["Wasserstoffspeicher", "Wasserstoffspeicher"],
    [AssetType.MixerValve]: ["Mischventil", "Mischventile"],
    [AssetType.PelletBoiler]: ["Brennwertkessel", "Brennwertkessel"],
    [AssetType.PelletGrid]: ["Pelletanschluss", "Pelletanschlüsse"],
    [AssetType.Photovoltaic]: ["PV-Anlage", "PV-Anlagen"],
    [AssetType.Recooler]: ["Rückkühler", "Rückkühler"],
    [AssetType.WeatherStation]: ["Wetterstation", "Wetterstationen"],

    // Internal
    [AssetType.Limiter]: ["Limiter", "Limiters"],
    [AssetType.LockDown]: ["Lock Down", "Lock Down"],
    [AssetType.Pump]: ["Pumpe", "Pumpen"],
    [AssetType.Split]: ["Split", "Splits"],
    [AssetType.SwitchoverValve]: ["Switchover Valve", "Switchover Valves"],
    [AssetType.Valve]: ["Valve", "Valves"],
    [AssetType.Weather]: ["Wetter", "Wetter"],

    // Child assets
    [AssetChildType.StorageLayer]: ["Speicherschicht", "Speicherschichten"],
  };

  if (!TYPE_NAME_MAP[type]?.[index]) {
    return type;
  }

  return TYPE_NAME_MAP[type][index];
}

export function getAssetKeyAndName(
  assetType: AssetType,
  assets: Pick<LocalAsset, "template" | "key">[]
) {
  const assetsOfType = assets?.filter((a) => a.template === assetType) ?? [];

  if (assetType === "weather") {
    return {
      name: getAssetTypeName(assetType),
      key: assetType,
    };
  }

  const assetNames = assetsOfType
    .map((a) => a.key)
    .sort((a1, a2) => a1.localeCompare(a2, undefined, { numeric: true }));

  const lastAssetIndex = assetNames.length
    ? +assetNames[assetNames.length - 1].slice(-2)
    : 0;

  const assetIndex = Number.isNaN(lastAssetIndex)
    ? assetsOfType.length + 1
    : lastAssetIndex + 1;

  return {
    name: `${getAssetTypeName(assetType)} ${assetIndex}`,
    key: `${assetType}${assetIndex < 10 ? "0" : ""}${assetIndex}`,
  };
}

function formatInitialChildAssetFormValues(
  parentAssetKey: string,
  initialValues?: Partial<LocalAssetChild>,
  propertyTemplates?: PropertyTemplate[],
  assets?: LocalAssetChild[],
  timeseries?: LocalTimeseries[]
): AssetChildFormValues {
  const newAsset = {
    template: "",
    ...(initialValues?.template && assets
      ? getAssetKeyAndName(initialValues?.template as AssetType, assets)
      : { key: "", name: "" }),
    ...omitGraphqlType(initialValues ?? {}),
  };

  const newProps = mergePropertyTemplates(
    initialValues?.properties ?? [],
    timeseries ?? [],
    propertyTemplates ?? [],
    undefined,
    newAsset.key,
    parentAssetKey
  );

  return {
    ...newAsset,
    ...newProps,
    properties: newProps.properties.map((p) => ({
      ...p,
      value:
        p.dataType === "scalarList"
          ? p.value.map((value: number | string, index: number) => ({
              key: index,
              value,
            }))
          : p.value,
    })),
  };
}

export function formatInitialAssetFormValues(
  initialValues?: Partial<ExtendedAsset>,
  parentId?: string,
  districtKey?: string,
  companyKey?: string,
  propertyTemplates?: AssetFormValues["propertyTemplates"],
  childPropertyTemplates?: AssetTemplate["children"][0]["properties"],
  assets?: LocalAsset[],
  address?: Address,
  timeseries?: LocalTimeseries[]
): AssetFormValues {
  const newAsset = {
    id: "",
    template: "",
    parentId: parentId ?? "",
    description: "",
    contracts: [],
    notes: [],
    children: [],
    prefilledAssets: [],
    type: "",
    displayWarning: false,

    ...(initialValues?.template && assets
      ? getAssetKeyAndName(initialValues?.template as AssetType, assets)
      : { key: "", name: "" }),
    ...omitGraphqlType(initialValues ?? {}),
    flags: initialValues?.flags
      ? omitGraphqlType(initialValues.flags)
      : {
          isOnsite: false,
          isMonitoringRelevant: false,
          isOptimizationRelevant: false,
          isEndConsumption: false,
        },
    address: initialValues?.address ?? address,
    districtKey: districtKey ?? "",
    companyKey: companyKey ?? "",
    metadata: sortMetadata(
      (initialValues?.metadata ?? []).map(({ __typename, ...m }) => {
        if (m.key === "communicationFlow") {
          return {
            ...m,
            required: !!METADATA_INPUT_TYPE[m.key],
            value: m.value ?? "AE DM ↔ Controller",
          };
        } else if (m.key === "timetableSpecification") {
          return { ...m, required: !!METADATA_INPUT_TYPE[m.key], value: false };
        }

        if (!m) return m;

        return { ...m, required: !!METADATA_INPUT_TYPE[m.key] };
      })
    ),
  };

  const newProps = mergePropertyTemplates(
    initialValues?.properties ?? [],
    timeseries ?? [],
    propertyTemplates ?? [],
    newAsset.flags,
    newAsset.key
  );

  return {
    ...newAsset,
    ...newProps,
    children: (initialValues?.children ?? []).map((child) =>
      formatInitialChildAssetFormValues(
        newAsset.key,
        child,
        childPropertyTemplates,
        initialValues?.children,
        timeseries
      )
    ),
    properties: newProps.properties.map((p) => ({
      ...p,
      value:
        p.dataType === "scalarList"
          ? p.value.map((value: number | string, index: number) => ({
              key: index,
              value,
            }))
          : p.dataType === "scalarTimeDependent"
          ? p.value.map(
              (
                value: { value: number; start: string; end: string },
                index: number
              ) => ({
                key: index,
                value: {
                  ...value,
                  value: value.value && `${value.value}`,
                },
              })
            )
          : p.value,
    })),
  };
}

export const ASSET_ICON_NAMES: Record<AssetType, typeof BaseIcon | null> = {
  [AssetType.Boiler]: Icons.Boiler,
  [AssetType.Building]: Icons.Building,
  [AssetType.Buildings]: Icons.Buildings,
  [AssetType.ChargingStation]: Icons.ChargingStation,
  [AssetType.CHP]: Icons.CHP,
  [AssetType.ElectricGrid]: Icons.ElectricGrid,
  [AssetType.ElectricMeter]: Icons.ElectricMeter,
  [AssetType.Electrolyzer]: Icons.Electrolyzer,
  [AssetType.EnergyCenter]: Icons.EnergyCenter,
  [AssetType.FuelCell]: Icons.FuelCell,
  [AssetType.GasGrid]: Icons.GasGrid,
  [AssetType.HeatMeter]: Icons.HeatMeter,
  [AssetType.HeatPump]: Icons.HeatPump,
  [AssetType.HeatStorage]: Icons.HeatStorage,
  [AssetType.HeatStorageLayered]: Icons.HeatStorage,
  [AssetType.HeatStorageTemperature]: Icons.HeatStorage,
  [AssetType.Heater]: Icons.Heater,
  [AssetType.HydrogenStorage]: Icons.HydrogenStorage,
  [AssetType.ElectricStorage]: Icons.Battery,
  [AssetType.MixerValve]: null,
  [AssetType.PelletBoiler]: Icons.Boiler,
  [AssetType.PelletGrid]: Icons.GasGrid,
  [AssetType.Photovoltaic]: Icons.Photovoltaic,
  [AssetType.Recooler]: Icons.HeatPump,
  [AssetType.Limiter]: null,
  [AssetType.LockDown]: null,
  [AssetType.Pump]: null,
  [AssetType.Split]: null,
  [AssetType.SwitchoverValve]: null,
  [AssetType.Valve]: null,
  [AssetType.Weather]: null,
  [AssetType.WeatherStation]: null,
};

/**
 * delivers the corresponding icon for the given asset type
 * @param type
 */
export const getAssetIcon = (type: AssetType): typeof BaseIcon | null => {
  return ASSET_ICON_NAMES[type];
};

export enum AssetGroup {
  Converter = "converter",
  Sink = "sink",
  Source = "source",
  Storage = "storage",
  Virtual = "virtual",
}

/**
 * maps a given asset type to its corresponding group
 * @param type
 */
export const getAssetGroup = (type: AssetType): AssetGroup | null => {
  const TYPE_GROUP_MAP: { [key in AssetType]: AssetGroup | null } = {
    [AssetType.Building]: AssetGroup.Sink,
    [AssetType.Buildings]: AssetGroup.Sink,
    [AssetType.EnergyCenter]: AssetGroup.Sink,

    [AssetType.Boiler]: AssetGroup.Converter,
    [AssetType.ChargingStation]: AssetGroup.Sink,
    [AssetType.CHP]: AssetGroup.Converter,
    [AssetType.ElectricGrid]: AssetGroup.Source,
    [AssetType.ElectricMeter]: AssetGroup.Sink,
    [AssetType.Electrolyzer]: AssetGroup.Converter,
    [AssetType.FuelCell]: AssetGroup.Converter,
    [AssetType.GasGrid]: AssetGroup.Source,
    [AssetType.HeatMeter]: AssetGroup.Sink,
    [AssetType.HeatPump]: AssetGroup.Converter,
    [AssetType.HeatStorage]: AssetGroup.Storage,
    [AssetType.HeatStorageLayered]: AssetGroup.Storage,
    [AssetType.HeatStorageTemperature]: AssetGroup.Storage,
    [AssetType.Heater]: AssetGroup.Converter,
    [AssetType.HydrogenStorage]: AssetGroup.Storage,
    [AssetType.ElectricStorage]: AssetGroup.Storage,
    [AssetType.MixerValve]: AssetGroup.Virtual,
    [AssetType.PelletBoiler]: AssetGroup.Converter,
    [AssetType.PelletGrid]: AssetGroup.Source,
    [AssetType.Photovoltaic]: AssetGroup.Source,
    [AssetType.Recooler]: AssetGroup.Converter,
    [AssetType.Limiter]: AssetGroup.Virtual,
    [AssetType.LockDown]: AssetGroup.Virtual,
    [AssetType.Pump]: AssetGroup.Virtual,
    [AssetType.Split]: AssetGroup.Virtual,
    [AssetType.SwitchoverValve]: AssetGroup.Virtual,
    [AssetType.Valve]: AssetGroup.Virtual,
    [AssetType.Weather]: AssetGroup.Virtual,
    [AssetType.WeatherStation]: AssetGroup.Virtual,
  };

  return TYPE_GROUP_MAP[type];
};

/**
 * groups all assets by their type into the corresponding group
 * @param assets
 */
export const groupAssetsByGroup = <T extends LocalAsset>(
  assets: T[]
): AssetsByGroup<T> => {
  return assets.reduce((grouped, asset) => {
    const group = getAssetGroup(asset.template as AssetType);
    if (!group) return grouped;
    // prepare group if missing
    if (!grouped.has(group)) {
      grouped.set(group, []);
    }
    // add the asset to the group
    grouped.get(group)!.push(asset);
    return grouped;
  }, new Map() as AssetsByGroup<T>);
};

export const groupAssetsByBuilding = <T extends ExtendedAsset>(
  assets: T[]
): Record<string, T[]> => {
  return assets.reduce<Record<string, T[]>>((grouped, asset) => {
    if (
      asset.buildingId &&
      !grouped[asset.buildingId]?.find((a) => a.id === asset.id)
    ) {
      grouped[asset.buildingId] = [...(grouped[asset.buildingId] ?? []), asset];
    }

    return grouped;
  }, {});
};
