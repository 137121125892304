import React from "react";

export interface MultistepFormStepProps {
  title: string;
  hasWarning?: boolean;
  hasError?: boolean;
}

export const Step: React.FC<MultistepFormStepProps> = ({ children }) => {
  return <>{children}</>;
};
