import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import {
  DynamicList,
  Icons,
  MultistepFormContext,
} from "@ampeersenergy/ampeers-ui-components";

import {
  PropertyTemplate,
  useGetInternalUnitsQuery,
} from "../../graphql/sdks/controller";

import { tensorValidationSchema } from "../validation";
import { AssetFormValues, TensorFormValue } from "../types";
import { Muted, PropListContainer } from "../style";

import { TensorEditForm } from "./tensor-edit-form";
import { registerFields } from "../../helpers/properties.utils";
import { TensorListItem } from "./tensor-list-item";

export interface TensorsFormProps {
  isEditing?: boolean;
  prefixIdentifier?: string;
  items?: TensorFormValue[];
  propertyTemplates?: PropertyTemplate[];
}

export const TensorsForm: React.FC<TensorsFormProps> = ({
  isEditing,
  prefixIdentifier,
  items,
  propertyTemplates,
}) => {
  const { data } = useGetInternalUnitsQuery();
  const { values } = useFormikContext<AssetFormValues>();
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const _propertyTemplates = useMemo(
    () => propertyTemplates ?? values.propertyTemplates,
    [propertyTemplates, values]
  );
  const tensors = useMemo(() => items ?? values.tensors, [items, values]);

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    registerFields({ tensors }, prefixIdentifier, registerField);

    return () => {
      registerFields({ tensors }, prefixIdentifier, unregisterField);
    };
  }, [tensors, prefixIdentifier, registerField, unregisterField]);

  const TensorEditComponent = useCallback(
    (props) => {
      return (
        <TensorEditForm
          {...props}
          prefixIdentifier={prefixIdentifier}
          items={items}
          propertyTemplates={_propertyTemplates}
          isEditing={isEditing}
          units={data?.internalUnits ?? []}
        />
      );
    },
    [
      _propertyTemplates,
      data?.internalUnits,
      isEditing,
      items,
      prefixIdentifier,
    ]
  );

  const TensorListItemComponent = useCallback(
    (props) => {
      return (
        <TensorListItem
          {...props}
          prefixIdentifier={prefixIdentifier}
          propertyTemplates={_propertyTemplates}
          units={data?.internalUnits ?? []}
          isEditing={isEditing}
        />
      );
    },
    [prefixIdentifier, _propertyTemplates, data?.internalUnits, isEditing]
  );

  const validateTensor = useCallback(
    (item) => tensorValidationSchema.isValidSync(item),
    []
  );

  const onAddTensor = useCallback((item) => {
    return {
      dataType: "tensor",
      dataSubType: "float",
      internal: false,
      required: false,
      value: {
        version: "v1",
        interpolations: [],
      },
    };
  }, []);

  if (!isEditing && !tensors?.length) {
    return <Muted>Keine Kennfelder vorhanden</Muted>;
  }

  return (
    <PropListContainer>
      <DynamicList
        name={`${_prefixIdentifier}tensors`}
        type={"Kennfeld"}
        items={tensors}
        validateItem={validateTensor}
        ListItemComponent={TensorListItemComponent}
        EditComponent={TensorEditComponent}
        Icon={Icons.Settings}
        interactive={isEditing}
        onNewItem={onAddTensor}
      />
    </PropListContainer>
  );
};
