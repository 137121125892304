import React, { useCallback, useState } from "react";
import { Formik, FormikConfig } from "formik";
import {
  AlertRetryable,
  FormikSubmit,
} from "@ampeersenergy/ampeers-ui-components";
import * as yup from "yup";

import {
  ButtonGroupPullRight,
  FormWrapperWithPadding,
  Muted,
  SubTitleWithMargin,
} from "../style";

import { usePatchTimeseriesMutation } from "../../graphql/sdks/controller";

import { NotesForm } from "../notes-form";
import { noteValidationSchema } from "../validation";
import { TimeseriesFormValues, ExtendedTimeseries } from "../types";
import { FormTile } from "./form-tile";
import { formatInitialTimeseriesFormValues } from "../../helpers/properties.utils";
import { getColors } from "./utils";

export interface TimeseriesNotesFormProps {
  timeseries?: ExtendedTimeseries;
}

export const TimeseriesNotesForm: React.FC<TimeseriesNotesFormProps> = ({
  timeseries,
}) => {
  const [error, setError] = useState<Error | any | undefined>();
  const [updateTimeseries] = usePatchTimeseriesMutation();

  const onSubmit = useCallback<FormikConfig<TimeseriesFormValues>["onSubmit"]>(
    async (newValues, formikProps) => {
      const { notes } = newValues;

      const { errors } = await updateTimeseries({
        variables: {
          timeseries: {
            id: timeseries!.id,
            uuid: timeseries!.uuid,
            notes: notes.map((n) => ({
              content: n.content,
              createdBy: n.createdBy,
              createdAt: n.createdAt,
            })),
          },
        },
      });

      if (errors) {
        setError({ message: errors[0]?.message });
      }
    },
    [timeseries, updateTimeseries]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={formatInitialTimeseriesFormValues(timeseries)}
      onSubmit={onSubmit}
      validationSchema={yup.object({ notes: yup.array(noteValidationSchema) })}
    >
      {({ values }) => {
        return (
          <FormWrapperWithPadding>
            {error && (
              <AlertRetryable
                message={error?.message}
                onRetry={() => setError(undefined)}
              />
            )}
            {timeseries ? (
              <FormTile
                name={values.refTimeseriesProp?.[0]?.key ?? values.name}
                colors={getColors(timeseries, timeseries.asset)}
                assetName={values.asset?.name}
              />
            ) : null}
            <SubTitleWithMargin>Notizen Zeitreihen</SubTitleWithMargin>
            {timeseries ? (
              <>
                <NotesForm isEditing />
                <ButtonGroupPullRight>
                  <FormikSubmit>Speichern</FormikSubmit>
                </ButtonGroupPullRight>
              </>
            ) : (
              <Muted>Keine Zeitreihe ausgewählt</Muted>
            )}
          </FormWrapperWithPadding>
        );
      }}
    </Formik>
  );
};
