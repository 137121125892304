import {
  AlertRetryable,
  Button,
  ColumnDefinition,
  Overlay,
  Table,
  Stepper,
  ButtonGroup,
  FormikSubmit,
} from "@ampeersenergy/ampeers-ui-components";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";

import {
  EnergyConcept,
  GetDistrictsDocument,
  useGetEnergyConceptsLazyQuery,
  useImportEnergyConceptMutation,
} from "../graphql/sdks/controller";

import { formatDate } from "../helpers/date.utils";
import { extractErrorMessage } from "../helpers/error.utils";
import { districtValidationSchema } from "./validation";

import { DistrictForm } from "./create-district-flow/district-form";
import { DateRangePicker } from "./date-range-picker";
import { dynamicPageSize } from "../helpers/html.utils";

const columns: ColumnDefinition<EnergyConcept>[] = [
  // { Header: "ID", accessor: "id" },
  { Header: "Projekt", accessor: "projectName" },
  { Header: "Szenario", accessor: "scenarioName" },
  { Header: "Beschreibung", accessor: "description" },
  { Header: "Simulator", accessor: "simulator" },
  {
    Header: "Erstellungsdatum",
    accessor: "createdAt",
    Cell: ({ value }) => formatDate(value),
  },
];

export interface EnergyConceptImportProps {}

export const EnergyConceptImport: React.FC<EnergyConceptImportProps> = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [{ startDate, endDate }, setDates] = useState({
    startDate: DateTime.now().minus({ weeks: 2 }),
    endDate: DateTime.now(),
  });
  const [saveError, setSaveError] = useState<string | undefined>();

  const [
    getEnergyConcepts,
    {
      data: energyConcepts,
      loading: loadingEnergyConcepts,
      error: energyConceptsError,
    },
  ] = useGetEnergyConceptsLazyQuery({
    variables: {
      startDate,
      endDate,
      groupByProjectName: true,
    },
  });

  const [
    getSimulations,
    {
      data: simulations,
      loading: loadingSimulations,
      error: simulationsError,
      refetch,
    },
  ] = useGetEnergyConceptsLazyQuery({
    variables: {
      startDate,
      endDate,
    },
  });

  const [importEnergyConcept] = useImportEnergyConceptMutation();

  const projects = useMemo(
    () => energyConcepts?.energyConcepts ?? [],
    [energyConcepts]
  );

  const projectSimulations = useMemo(
    () => simulations?.energyConcepts ?? [],
    [simulations]
  );

  const onClose = useCallback(({ resetForm }: { resetForm: Function }) => {
    resetForm();
    setIsOpen(false);
    setStepIndex(0);
  }, []);

  const steps = useMemo(
    () => [
      {
        label: "Projekte",
        content: ({
          values,
          setValues,
        }: {
          setValues: Function;
          values: {};
        }) => (
          <>
            <DateRangePicker
              startDate={startDate.toJSDate()}
              endDate={endDate.toJSDate()}
              setDates={({ startDate: newStartDate, endDate: newEndDate }) => {
                setDates(({ startDate: sD, endDate: eD }) => ({
                  startDate: newStartDate
                    ? DateTime.fromJSDate(newStartDate)
                    : sD,
                  endDate: newEndDate ? DateTime.fromJSDate(newEndDate) : eD,
                }));
                refetch({ startDate, endDate });
              }}
            />
            <Table
              isLoading={loadingEnergyConcepts}
              columns={[columns[0]] as any}
              filterKind="Projekte"
              data={projects}
              withFilter
              withPagination
              pageSize={dynamicPageSize()}
              onRowClick={({ original }) => {
                getSimulations({
                  variables: {
                    startDate,
                    endDate,
                    projectName: original.projectName,
                  },
                });
                setValues({
                  ...values,
                  projectName: original.projectName,
                });
                setStepIndex(stepIndex + 1);
              }}
              initialState={{
                sortBy: [
                  {
                    id: "projectName",
                    desc: false,
                  },
                ],
              }}
            />
          </>
        ),
      },
      {
        label: "Simulationen",
        content: ({
          setValues,
          values,
        }: {
          setValues: Function;
          resetForm: Function;
          values: { projectName?: string };
        }) => (
          <Table
            isLoading={loadingSimulations}
            columns={columns}
            filterKind="Simulationen"
            data={projectSimulations}
            withFilter
            withPagination
            pageSize={dynamicPageSize()}
            onRowClick={({ original }) => {
              setValues({
                ...values,
                energyConceptId: original.id,
              });
              setStepIndex(stepIndex + 1);
            }}
            initialState={{
              sortBy: [
                {
                  id: "createdAt",
                  desc: true,
                },
              ],
            }}
          />
        ),
      },
      {
        label: "Quartier",
        content: ({
          resetForm,
          isSubmitting,
        }: {
          resetForm: Function;
          isSubmitting: boolean;
        }) => (
          <>
            <DistrictForm />
            <ButtonGroup style={{ marginTop: 10 }}>
              <Button secondary onClick={() => onClose({ resetForm })}>
                Abbrechen
              </Button>
              <FormikSubmit isLoading={isSubmitting}>Speichern</FormikSubmit>
            </ButtonGroup>
          </>
        ),
      },
    ],
    [
      startDate,
      endDate,
      loadingEnergyConcepts,
      projects,
      refetch,
      getSimulations,
      stepIndex,
      loadingSimulations,
      projectSimulations,
      onClose,
    ]
  );

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      if (!values.energyConceptId) {
        throw new Error("Cannot import district: missing energy concept id");
      }

      const { key, companyKey, energyConceptId, address, name } = values;

      return importEnergyConcept({
        variables: {
          energyConceptId,
          district: {
            name,
            address,
            state: "collect",
            metadata: [],
            properties: [],
            notes: [],
          },
        },
        context: {
          headers: {
            "x-ampeers-company": companyKey,
            "x-ampeers-district": key,
          },
        },
        refetchQueries: [
          {
            query: GetDistrictsDocument,
            variables: {
              filter: {
                multipleDistricts: [
                  {
                    company: "all",
                    district: "all",
                  },
                ],
              },
            },
          },
        ],
      })
        .then(({ data }) => {
          onClose({ resetForm });
          history.push(`/districts/${data?.importEnergyConcept?.id}`);
        })
        .catch((err) => {
          setSaveError(extractErrorMessage(err));
        });
    },
    [history, importEnergyConcept, onClose]
  );

  return (
    <>
      <Button
        secondary
        onClick={() => {
          setIsOpen(true);
          getEnergyConcepts();
        }}
      >
        Import aus BST
      </Button>
      <Formik
        initialValues={{}}
        validationSchema={districtValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, setValues, resetForm }) => (
          <Overlay
            isOpen={isOpen}
            title="Import Quartier aus BST"
            onRequestClose={() => onClose({ resetForm })}
          >
            <Form>
              <Stepper
                steps={steps}
                index={stepIndex}
                onStepClick={setStepIndex}
              />
              {(energyConceptsError || simulationsError || saveError) && (
                <AlertRetryable
                  message={
                    energyConceptsError || simulationsError
                      ? extractErrorMessage(
                          (energyConceptsError || simulationsError)!
                        )
                      : saveError
                  }
                />
              )}
              {steps[stepIndex].content({
                values,
                setValues,
                resetForm,
                isSubmitting,
              })}
            </Form>
          </Overlay>
        )}
      </Formik>
    </>
  );
};
