import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import {
  DynamicList,
  Icons,
  InfoAlert,
  MultistepFormContext,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";

import { ExtendedMetadata } from "../types";
import { METADATA_INPUT_TYPE } from "./types";
import { metadataValidationSchema } from "../validation";
import { ListContainer, Muted } from "../style";

import { MetadataItemForm } from "./metadata-item-form";
import { Asset } from "../../graphql/sdks/controller";

export interface MetadataFormProps {
  isEditing?: boolean;
  templateRequired?: boolean;
}

export const MetadataForm: React.FC<MetadataFormProps> = ({
  isEditing = true,
  templateRequired = false,
}) => {
  const { values, setFieldValue } = useFormikContext<{
    template: Pick<Asset, "template">;
    metadata: ExtendedMetadata[];
  }>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    if (registerField) {
      values.metadata.forEach((_, i) => {
        registerField(`metadata[${i}].key`);
        registerField(`metadata[${i}].value`);
        registerField(`metadata[${i}].label`);
      });
    }

    return () => {
      if (unregisterField) {
        values.metadata.forEach((_, i) => {
          unregisterField(`metadata[${i}].key`);
          unregisterField(`metadata[${i}].value`);
          unregisterField(`metadata[${i}].label`);
        });
      }
    };
  }, [registerField, unregisterField, values.metadata]);

  const MetadataListItemComponent = useCallback(
    (props) => {
      return (
        <MetadataItemForm
          {...props}
          isEditing={isEditing}
          setFieldValue={setFieldValue}
        />
      );
    },
    [isEditing, setFieldValue]
  );

  const validateProperty = useCallback(
    (item) => metadataValidationSchema.isValidSync(item),
    []
  );

  const items = useMemo(
    () =>
      values.metadata.map((m, index) => ({
        ...m,
        originalKey: m.key,
        key: m.label,
        index,
        type:
          m.type ||
          (["true", "false"].includes(`${m.value}`) ? "boolean" : "string"),
        inputType: METADATA_INPUT_TYPE[m.key],
      })),
    [values.metadata]
  );

  if (!isEditing && !values.metadata?.length) {
    return <Muted>Keine Metadaten vorhanden</Muted>;
  }

  if (templateRequired && !values.template) {
    return <WarningAlert>Kein Template ausgewählt</WarningAlert>;
  }

  return (
    <>
      {isEditing && (
        <InfoAlert>
          Folgende Angaben werden nicht für die Funktionalitäten Monitoring und
          Betriebsführung herangezogen. Sie dienen ausschließlich zur
          Visualisierung in den Stammdaten in der DM App und haben
          Informationscharakter.
        </InfoAlert>
      )}
      <ListContainer>
        <DynamicList
          name={"metadata"}
          type={"Metadaten"}
          items={items}
          validateItem={validateProperty}
          ListItemComponent={MetadataListItemComponent}
          Icon={Icons.Settings}
          interactive={isEditing}
        />
      </ListContainer>
      {isEditing && <p>* Pflichtfeld</p>}
    </>
  );
};
