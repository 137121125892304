import React from "react";
import { InfoAlert } from "@ampeersenergy/ampeers-ui-components";

import { Input, Row, Select } from "./style";

export interface AddressFormProps {
  accessor: string;
  latAndLongRequired?: boolean;
  isEditing?: boolean;
  displayInfo?: boolean;
}

export const AddressForm: React.FC<AddressFormProps> = ({
  accessor,
  latAndLongRequired = true,
  isEditing = true,
  displayInfo = false,
}) => {
  return (
    <>
      {isEditing && displayInfo && (
        <InfoAlert>
          Folgende Geo-Informationen werden aktuell nicht weiter verarbeitet und
          können vorerst vom Quartier übernommen werden.
        </InfoAlert>
      )}
      <Row>
        <Input
          flex={2}
          name={`${accessor}.street`}
          label="Straße *"
          isEditing={isEditing}
        />
        <Input
          flex={1}
          name={`${accessor}.streetNr`}
          label="Nummer *"
          isEditing={isEditing}
        />
      </Row>
      <Row>
        <Input name={`${accessor}.zip`} label="PLZ *" isEditing={isEditing} />
        <Input
          name={`${accessor}.city`}
          label="Stadt *"
          isEditing={isEditing}
        />
        <Select
          id={`${accessor}.country`}
          name={`${accessor}.country`}
          label="Land *"
          isEditing={isEditing}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          <option key="deu" value="DEU">
            Deutschland
          </option>
          <option key="at" value="AT">
            Österreich
          </option>
        </Select>
      </Row>
      <Row>
        <Input
          flex={1}
          name={`${accessor}.latitude`}
          label={`Breitengrad  ${latAndLongRequired ? "*" : ""}`}
          hint={"Format 00[.000000] (World Geodetic System 1984 (WGS84))"}
          isEditing={isEditing}
        />
        <Input
          flex={1}
          name={`${accessor}.longitude`}
          label={`Längengrad ${latAndLongRequired ? "*" : ""}`}
          hint={"Format 00[.000000] (World Geodetic System 1984 (WGS84))"}
          isEditing={isEditing}
        />
      </Row>
    </>
  );
};
