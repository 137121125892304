import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import {
  Bold,
  ButtonIcon as ButtonIconComponent,
  Icons,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";
import { CustomDeleteConfirmModal } from "./delete-confirm-modal";
import { ApolloError } from "@apollo/client";
import { extractErrorMessage } from "../helpers/error.utils";

const DeleteButton = styled(ButtonIconComponent)`
  margin-right: 4px;

  svg {
    fill: ${(props) => props.theme.palette.error.color};
  }
`;

export interface BulkDeleteModalProps {
  onDelete: () => Promise<any>;
  nameExtractFn: (id: string) => string;
  entityName: string;
  removeError?: ApolloError;
  selectedRows: string[];
}

export const BulkDeleteModal: React.FC<BulkDeleteModalProps> = ({
  onDelete,
  nameExtractFn,
  entityName,
  removeError,
  selectedRows,
}) => {
  const theme = useTheme() as Theme;

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  return (
    <>
      {!!selectedRows.length && (
        <DeleteButton
          icon={Icons.Delete}
          small
          customTheme={{
            primaryColor: theme.palette.error.color,
            secondaryColor: theme.palette.error.background,
          }}
          onClick={() => setConfirmDeleteModalOpen(true)}
        >
          {entityName} löschen
        </DeleteButton>
      )}
      <CustomDeleteConfirmModal
        entityName={entityName}
        isOpen={confirmDeleteModalOpen}
        setIsOpen={setConfirmDeleteModalOpen}
        onConfirm={onDelete}
        errorMsg={removeError && extractErrorMessage(removeError)}
      >
        {selectedRows.length > 1 ? (
          <>
            Folgende {entityName} werden gelöscht:
            <br />
            <br />
            <div>
              {selectedRows
                .map(nameExtractFn)
                .filter((v) => !!v)
                .map((v) => (
                  <>
                    <Bold>{v}</Bold>
                    <br />
                  </>
                ))}
            </div>
            <br />
            Bist du sicher, dass du{" "}
            <Bold>
              {selectedRows.length} {entityName}
            </Bold>{" "}
            löschen möchtest?
            <br />
            Dies kann nicht rückgängig gemacht werden.
          </>
        ) : (
          <>
            Bist du sicher, dass du{" "}
            <Bold>{nameExtractFn(selectedRows[0]) ?? "--"}</Bold> löschen
            möchtest?
            <br />
            Dies kann nicht rückgängig gemacht werden.
          </>
        )}
      </CustomDeleteConfirmModal>
    </>
  );
};
