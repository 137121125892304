import { useMemo } from "react";
import { ExtendedContract } from "../components/types";

import { District } from "../graphql/sdks/controller";
import { flattenContracts } from "../helpers/contract.utils";

export const useContracts = (district: District) => {
  const allContracts = useMemo(
    () =>
      district
        ? flattenContracts<ExtendedContract>(district, (contract, asset) => ({
            ...contract,
            assetId: asset?.id,
            assetKey: asset?.key,
            assetName: asset?.name,
          }))
        : [],
    [district]
  );

  return allContracts;
};
