import React from "react";
import { WarningAlert } from "@ampeersenergy/ampeers-ui-components";

import { Column } from "../style";
import { EntityName, EntityNameWrapper, Messages, SuccessAlert } from "./style";
import { ExtendedDistrictStateItem } from "./types";

const routes: Record<string, string> = {
  district: "districts",
  asset: "assets",
  building: "buildings",
  timeseries: "timeseries",
};

export interface DistrictStateItemsProps {
  items: ExtendedDistrictStateItem[];
}

export const DistrictStateItems: React.FC<DistrictStateItemsProps> = ({
  items,
}) => {
  if (!items.length) {
    return <SuccessAlert>Keine Fehler gefunden</SuccessAlert>;
  }

  return (
    <Column>
      {items.map(({ reference, type, name, messages, relatedEntity }) => (
        <>
          <EntityNameWrapper>
            <EntityName to={`${routes[type]}/${reference}`}>{name}</EntityName>{" "}
            {relatedEntity && (
              <EntityName
                to={`${routes[relatedEntity.type]}/${relatedEntity.id}`}
              >
                ({relatedEntity.name || relatedEntity.key})
              </EntityName>
            )}
          </EntityNameWrapper>
          <Messages>
            {messages.map((m) => (
              <WarningAlert>{m}</WarningAlert>
            ))}
          </Messages>
        </>
      ))}
    </Column>
  );
};
