import { Icons, Theme } from "@ampeersenergy/ampeers-ui-components";
import React from "react";
import { useTheme } from "styled-components";
import { AccordionSubtitle } from "./style";

export interface AccordionItemLabelProps {
  hasError?: boolean;
}

export const AccordionItemLabel: React.FC<AccordionItemLabelProps> = ({
  children,
  hasError,
}) => {
  const theme = useTheme() as Theme;

  const Icon = hasError ? Icons.Warning : Icons.Checkmark;
  const iconColor = hasError ? theme.palette.warning.color : theme.primaryColor;

  return (
    <AccordionSubtitle>
      {children}
      <Icon size={25} color={iconColor} />
    </AccordionSubtitle>
  );
};
