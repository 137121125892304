import React, { useCallback, useContext, useMemo } from "react";
import {
  BaseIcon,
  DynamicListItemProps,
  Icons,
  Input as RawInput,
  Theme,
  TooltipLight,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";
import { PropertyTemplate } from "../../graphql/sdks/controller";
import {
  AssetFormValues,
  LocalTimeseries,
  LocalUnit,
  PropertyFormValue,
} from "../types";

import {
  DeleteButtonWithMargin,
  PropertyListItemContainer,
  PropertyListItemWrapper,
  SpacedTag,
} from "../properties-form/style";
import { MultistepFormContext } from "../multistep-form/context";
import { useFormikContext } from "formik";
import { useTheme } from "styled-components";
import { isValidPropValue } from "../../helpers/properties.utils";
import { NewItemWrapper, PlusIcon } from "../style";
import { TimeseriesEditForm } from "./timeseries-edit-form";
import { findValue } from "../../helpers/object.utils";

export interface TimeseriesListItemProps extends DynamicListItemProps {
  isEditing?: boolean;
  item: DynamicListItemProps["item"] &
    PropertyFormValue & { relativeTimeseries: LocalTimeseries[] };
  allUnits: LocalUnit[];
  internalUnits: LocalUnit[];
  prefixIdentifier?: string;
  propertyTemplates: PropertyTemplate[];
  canDuplicate?: boolean;
}

export const TimeseriesListItem: React.FC<TimeseriesListItemProps> = (
  props
) => {
  const {
    item,
    index,
    isEditing,
    allUnits,
    internalUnits,
    prefixIdentifier,
    propertyTemplates,
    canDuplicate,
  } = props;
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const { values, setFieldValue, errors } = useFormikContext<AssetFormValues>();

  const items = useMemo(
    () =>
      prefixIdentifier
        ? findValue<AssetFormValues>(prefixIdentifier, values).timeseries
        : values.timeseries,
    [prefixIdentifier, values]
  );

  const { unregisterField } = useContext(MultistepFormContext) ?? {};
  const theme = useTheme() as Theme;
  const {
    editMode,
    setEditMode,
    isValid,
    onAddItem,
    onDelete,

    Icon,
    type,
  } = useDynamicListItem({
    ...props,
  });

  const onTimeseriesDelete = useCallback(() => {
    const lastIndex = items.length - 1;
    if (unregisterField) {
      unregisterField(`${_prefixIdentifier}timeseries[${lastIndex}].propKey`);
      unregisterField(`${_prefixIdentifier}timeseries[${lastIndex}].value`);
      unregisterField(`${_prefixIdentifier}timeseries[${lastIndex}].dataType`);
      unregisterField(
        `${_prefixIdentifier}timeseries[${lastIndex}].dataSubType`
      );
      unregisterField(`${_prefixIdentifier}timeseries[${lastIndex}].unit`);
      unregisterField(
        `${_prefixIdentifier}timeseries[${lastIndex}].description`
      );
      unregisterField(`${_prefixIdentifier}timeseries[${lastIndex}].comment`);
    }
    onDelete();
  }, [items, onDelete, unregisterField, _prefixIdentifier]);

  const onDuplicateTimeseries = useCallback(() => {
    setFieldValue(`${_prefixIdentifier}timeseries`, [
      ...items,
      {
        ...item,
        relativeTimeseries: item.relativeTimeseries.map(
          ({ id, uuid, ...ts }) => ts
        ),
        propKey: undefined,
        isPlaceholder: true,
      },
    ]);
  }, [setFieldValue, items, item, _prefixIdentifier]);

  const unitKeys = useMemo(
    () =>
      !item.isPlaceholder
        ? item.unit
          ? [item.unit]
          : []
        : (internalUnits ?? []).map(({ key }) => key),
    [item, internalUnits]
  );

  const CurrentIcon: typeof BaseIcon | undefined = useMemo(() => {
    if (
      !isValid ||
      !isValidPropValue(item.value) ||
      !!errors?.timeseries?.[index]
    )
      return Icons.Warning;
    return Icon;
  }, [isValid, item.value, errors?.timeseries, index, Icon]);

  if (item.isNewValue) {
    return (
      <NewItemWrapper key={item.key} onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        {type} hinzufügen
      </NewItemWrapper>
    );
  }

  if (editMode) {
    return (
      <TimeseriesEditForm
        item={item}
        index={index}
        isEditing={isEditing}
        prefixIdentifier={prefixIdentifier}
        propertyTemplates={propertyTemplates}
        unitKeys={unitKeys}
        allUnits={allUnits ?? []}
        setEditMode={setEditMode}
        onDelete={onTimeseriesDelete}
        canDuplicate={canDuplicate}
        onDuplicate={onDuplicateTimeseries}
      />
    );
  }

  return (
    <PropertyListItemWrapper>
      <PropertyListItemContainer
        $hasError={!!errors?.timeseries?.[index]}
        onClick={() => {
          setEditMode(!editMode);
        }}
      >
        {CurrentIcon && (
          <CurrentIcon
            className="icon"
            size={20}
            color={
              !isValid ||
              !isValidPropValue(item.value) ||
              !!errors?.timeseries?.[index]
                ? item.isRequired || !!errors?.timeseries?.[index]
                  ? theme.palette.error.color
                  : theme.palette.warning.color
                : theme.primaryColor
            }
          />
        )}
        <span className="key">
          {item.propKey || item.key ? (
            <strong>
              {item.propKey || item.key}{" "}
              {values.flags?.isMonitoringRelevant &&
                item.flags?.isMonitoringRelevant && (
                  <SpacedTag color={theme.palette.warning.color}>mon</SpacedTag>
                )}
              {values.flags?.isOptimizationRelevant &&
                item.flags?.isOperationRelevant && (
                  <SpacedTag color={theme.palette.success.color}>op</SpacedTag>
                )}
            </strong>
          ) : (
            <span style={{ color: theme.palette.textSubtle }}>(key fehlt)</span>
          )}
        </span>
        <span className="value">
          {item.isRequired &&
            !item.value &&
            isEditing &&
            item.dataType !== "timeseriesList" && (
              <span style={{ color: theme.palette.textSubtle }}>
                UUID wird beim Speichern automatisch generiert
              </span>
            )}
          {isEditing && item.dataType === "timeseriesList" ? (
            <RawInput
              id={`${_prefixIdentifier}timeseries[${index}].value.length`}
              name={`${_prefixIdentifier}timeseries[${index}].value.length`}
              isEditing={isEditing}
              type="number"
              value={item.value?.length ?? 0}
              hint="Die Zeitreihen werden erst erzeugt, wenn die Anzahl
                  spezifiziert ist."
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                if (isNaN(+e.target.value)) return;
                setFieldValue(
                  `${_prefixIdentifier}timeseries[${index}].value`,
                  Array.from({ length: +e.target.value }).map(
                    (_, index) => item.value[index]
                  )
                );
              }}
            />
          ) : null}
        </span>
      </PropertyListItemContainer>
      {isEditing && canDuplicate && (
        <TooltipLight id="duplicate-timeseries" text="Zeitreihe duplizieren">
          <DeleteButtonWithMargin
            data-tip
            data-for="duplicate-timeseries"
            secondary
            icon={Icons.AccountingRecuring}
            onClick={onDuplicateTimeseries}
          />
        </TooltipLight>
      )}
      {isEditing && !item.isRequired && (
        <DeleteButtonWithMargin
          secondary
          icon={Icons.Delete}
          onClick={onTimeseriesDelete}
          color={theme.palette.error.color}
        />
      )}
    </PropertyListItemWrapper>
  );
};
