import styled from "styled-components";
import {
  SpinnerWrapper as SpinnerWrapperComponent,
  SubTitleWithMargin,
} from "../style";
import { CheckboxNew } from "@ampeersenergy/ampeers-ui-components";

export const Text = styled.p`
  margin: 12px 0;
`;

export const SubTitleGreen = styled(SubTitleWithMargin)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.primaryColor};
`;

export const Checkbox = styled(CheckboxNew)``;

export const SpinnerWrapper = styled(SpinnerWrapperComponent)`
  min-width: 800px;
  min-height: 600px;
`;
