import React, { useContext, useEffect } from "react";
import { AddressForm } from "../address-form";
import { MultistepFormContext } from "../multistep-form/context";

export interface AssetFormAddressProps {
  isEditing?: boolean;
}

export const AssetFormAddress: React.FC<AssetFormAddressProps> = ({
  isEditing,
}) => {
  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    if (registerField) {
      registerField("address.street");
      registerField("address.streetNr");
      registerField("address.zip");
      registerField("address.city");
      registerField("address.country");
      registerField("address.latitude");
      registerField("address.longitude");
    }

    return () => {
      if (unregisterField) {
        unregisterField("address.street");
        unregisterField("address.streetNr");
        unregisterField("address.zip");
        unregisterField("address.city");
        unregisterField("address.country");
        unregisterField("address.latitude");
        unregisterField("address.longitude");
      }
    };
  }, [registerField, unregisterField]);

  return (
    <AddressForm
      accessor="address"
      latAndLongRequired={false}
      isEditing={isEditing}
      displayInfo
    />
  );
};
