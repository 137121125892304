import { ApolloError } from "@apollo/client";
import { useMemo } from "react";

import {
  AssetTemplate,
  Building,
  District,
  useGetAssetsQuery,
  useGetAssetTemplatesQuery,
} from "../graphql/sdks/controller";
import { AssetListItem } from "../components/types";
import { arrayToObject } from "../helpers/array.utils";
import { AssetType } from "../helpers/asset.utils";
import { hasMissingProperties } from "../helpers/properties.utils";

export const useAssets = (
  district: District
): {
  assets: AssetListItem[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: ReturnType<typeof useGetAssetsQuery>["refetch"];
} => {
  const { data, loading, error, refetch } = useGetAssetsQuery({
    variables: {
      districtIdentifier: {
        company: district.companyKey,
        district: district.key,
      },
    },
  });

  const {
    data: templates,
    loading: loadingTemplates,
    error: templatesError,
  } = useGetAssetTemplatesQuery();

  const allAssets: AssetListItem[] = useMemo(() => {
    const assetBuildingKey: Record<string, Building> =
      district.buildings.reduce(
        (acc, building) => ({
          ...acc,
          ...building.assets.reduce(
            (keys, asset) => ({
              ...keys,
              [asset.id]: building,
            }),
            {}
          ),
        }),
        {}
      );

    const assetByTemplate: Record<AssetType, AssetTemplate> = arrayToObject(
      templates?.assetTemplates ?? [],
      "template"
    ) as Record<AssetType, AssetTemplate>;

    return data?.assets
      ? data?.assets.map((asset) => {
          const template = assetByTemplate[asset.template as AssetType];

          return {
            ...asset,
            type: asset.template,
            address:
              asset.address ??
              assetBuildingKey[asset.id]?.address ??
              district.address,
            buildingName: assetBuildingKey[asset.id]?.name,
            buildingKey: assetBuildingKey[asset.id]?.key,
            buildingId: assetBuildingKey[asset.id]?.id,
            displayWarning: hasMissingProperties(
              template?.properties ?? [],
              asset.properties,
              asset.flags
            ),
          };
        })
      : [];
  }, [data, district, templates]);

  return {
    assets: allAssets,
    loading: loading || loadingTemplates,
    error: error || templatesError,
    refetch,
  };
};
