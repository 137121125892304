import React from "react";
import styled, { useTheme } from "styled-components";
import { Icons, Theme } from "@ampeersenergy/ampeers-ui-components";
import { PlusIcon as DefaultPlusIcon } from "./style";

const Wrapper = styled.div<{ $clickable?: boolean }>`
  position: relative;
  display: inline-flex;
  ${(props) => (props.$clickable ? "cursor: pointer;" : "")}
`;

const Value = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -4px;
  right: -6px;
  background-color: ${(props) => props.theme.palette.background};
  color: ${(props) => props.theme.primaryColor};
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  width: 15px;
  height: 15px;
`;

const PlusIcon = styled(DefaultPlusIcon)`
  position: absolute;
  top: -4px;
  right: -6px;
  background-color: ${(props) => props.theme.palette.background};
  border-radius: 50%;
`;

export interface NotesIconProps {
  value: string | number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const NotesIcon: React.FC<NotesIconProps> = ({ value, onClick }) => {
  const theme = useTheme() as Theme;

  return (
    <Wrapper onClick={onClick} $clickable={!!onClick}>
      <Icons.File
        size={25}
        color={!!value ? theme.primaryColor : theme.palette.textSubtle}
      />
      {!!value && <Value>{value}</Value>}
      {!value && (
        <PlusIcon
          size={20}
          color={!!value ? theme.primaryColor : theme.palette.textMuted}
        />
      )}
    </Wrapper>
  );
};
