import React, {
  Children,
  ComponentType,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Formik, FormikConfig, FormikHelpers, FormikValues } from "formik";
import styled, { useTheme } from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemProps,
  Button,
  ButtonGroup,
  FormikSubmit,
  SuccessAlert,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import { FormWrapper, SubTitleWithMargin } from "./style";
import {
  MultistepFormMutationError,
  MultistepFormMutationErrorProps,
} from "./multistep-form/mutation-error";
import { MultistepFormValidationErrorType } from "./multistep-form/types";

const PullRight = styled(ButtonGroup)<{ $withMargin?: boolean }>`
  flex-direction: row;
  justify-content: flex-end;
  ${(props) => (props.$withMargin ? "margin-top: 24px;" : "")}
`;

const Form = styled(FormWrapper)`
  padding: 15px;
  height: 100%;
  overflow-y: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export interface AccordionFormProps {
  isEditing?: boolean;
  setIsEditing: (value: boolean) => void;
  showErrorAtBottom?: boolean;
  initialValues: FormikConfig<FormikValues>["initialValues"];
  validationSchema: FormikConfig<FormikValues>["validationSchema"];
  onSubmit: (
    {
      newValues,
      initialValues,
    }: {
      newValues: any;
      initialValues: any;
    },
    formikProps: FormikHelpers<any>
  ) => Promise<void>;
  onDelete: () => void;
}

export const AccordionForm: React.FC<AccordionFormProps> & {
  Item: ComponentType<AccordionItemProps>;
} = ({
  isEditing,
  setIsEditing,
  showErrorAtBottom,
  initialValues,
  validationSchema,
  onSubmit,
  onDelete,
  children,
}) => {
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);
  const [mutationError, setMutationError] =
    useState<MultistepFormMutationErrorProps["mutationError"]>(null);

  const onFormSubmit = useCallback<FormikConfig<FormikValues>["onSubmit"]>(
    async (newValues, formikProps) => {
      formikProps.setTouched({});
      formikProps.setSubmitting(true);

      try {
        await onSubmit(
          {
            newValues,
            initialValues,
          },
          formikProps
        ).then(() => {
          setSuccessfulUpdate(true);
          setTimeout(() => setSuccessfulUpdate(false), 5000);
        });
      } catch (error) {
        if (
          Array.isArray(error) &&
          error[0]?.__typename === "ValidationError"
        ) {
          const formattedErrors = error.reduce<Record<string, string>>(
            (
              acc: Record<string, string>,
              { pointer, message }: MultistepFormValidationErrorType
            ) => {
              return { ...acc, [pointer]: message };
            },
            {}
          );

          formikProps.setTouched({});
          formikProps.setStatus(formattedErrors);
        } else {
          setMutationError(error as string[]);
        }
      } finally {
        formikProps.setSubmitting(false);
      }
    },
    [initialValues, onSubmit]
  );

  const onFormChange = useCallback(() => {
    if (mutationError) {
      setMutationError(null);
    }
  }, [mutationError]);

  const content = useMemo(
    () =>
      Children.map(
        children as ReactElement<AccordionItemProps>[],
        (child: ReactElement<AccordionItemProps>) => {
          if (!child) return null;
          return (
            <AccordionItem label={child.props.label}>
              <ItemWrapper>{child.props.children}</ItemWrapper>
            </AccordionItem>
          );
        }
      ),
    [children]
  );

  const childrenCount = useMemo(() => Children.count(children), [children]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <Form onChange={onFormChange}>
            {successfulUpdate && (
              <SuccessAlert>Erfolgreich aktualisiert.</SuccessAlert>
            )}
            <MultistepFormMutationError mutationError={mutationError} />
            <Buttons
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isValid={isValid}
              onDelete={onDelete}
            />
            <Accordion
              withPadding={false}
              autoClose={false}
              LabelComponent={SubTitleWithMargin}
              initialOpenIds={Array.from(Array(childrenCount).keys())}
            >
              {content}
            </Accordion>
            {showErrorAtBottom && (
              <>
                {successfulUpdate && (
                  <SuccessAlert>Erfolgreich aktualisiert.</SuccessAlert>
                )}
                <MultistepFormMutationError mutationError={mutationError} />
              </>
            )}
            <Buttons
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isValid={isValid}
              onDelete={onDelete}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const Buttons: React.FC<
  Pick<AccordionFormProps, "isEditing" | "setIsEditing" | "onDelete"> & {
    isSubmitting: boolean;
    isValid: boolean;
    $withMargin?: boolean;
  }
> = ({
  isSubmitting,
  isEditing,
  setIsEditing,
  isValid,
  onDelete,
  $withMargin,
}) => {
  const theme = useTheme() as Theme;
  return (
    <PullRight $withMargin={$withMargin}>
      <Button
        secondary
        customTheme={{
          primaryColor: theme.palette.error.color,
          secondaryColor: theme.palette.error.background,
        }}
        onClick={onDelete}
      >
        Löschen
      </Button>
      {isEditing ? (
        <>
          <Button secondary onClick={() => setIsEditing(false)}>
            Abbrechen
          </Button>
          <FormikSubmit isLoading={isSubmitting} disabled={!isValid}>
            Speichern
          </FormikSubmit>
        </>
      ) : (
        <Button onClick={() => setIsEditing(true)} disabled={isEditing}>
          Bearbeiten
        </Button>
      )}
    </PullRight>
  );
};

AccordionForm.Item = AccordionItem;
