import React from "react";
import { Route, Redirect, RouteProps } from "react-router";
import { hasValidSession } from "../services/session.service";

export const AuthorizedRoute: React.FC<RouteProps> = ({
  children,
  ...routeProps
}) => {
  const isAuthorized = hasValidSession();
  return (
    <Route
      {...routeProps}
      render={({ location }) =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
