import React, { useContext, useEffect } from "react";
import {
  FormikInput,
  MultistepFormContext,
} from "@ampeersenergy/ampeers-ui-components";

import { Column, ColumnWithInputWidth, Input, Row } from "../style";
import { AddressForm } from "../address-form";
import { EmailForm } from "../edit-district-form/e-mail-form";
import { useFormikContext } from "formik";
import { DistrictFormValues } from "../types";

export interface DistrictFormProps {}

export const DistrictForm: React.FC<DistrictFormProps> = () => {
  const { values } = useFormikContext<DistrictFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    if (registerField) {
      registerField("key");
      registerField("companyKey");
      registerField("name");
      registerField("email");
      registerField("address.street");
      registerField("address.streetNr");
      registerField("address.zip");
      registerField("address.city");
      registerField("address.country");
      registerField("address.latitude");
      registerField("address.longitude");
    }

    return () => {
      if (unregisterField) {
        unregisterField("key");
        unregisterField("companyKey");
        unregisterField("name");
        unregisterField("email");
        unregisterField("address.street");
        unregisterField("address.streetNr");
        unregisterField("address.zip");
        unregisterField("address.city");
        unregisterField("address.country");
        unregisterField("address.latitude");
        unregisterField("address.longitude");
      }
    };
  }, [registerField, unregisterField]);

  return (
    <>
      <Row>
        <Column style={{ width: "60%" }}>
          <Row>
            <Input
              name="key"
              label="District Key *"
              placeholder="demo"
              hint="Einzigartiger Identifikator, der das Quartier intern indexiert. Format: districtKey01 (Camel Case, nur Zahlen und Buchstaben, keine Umlaute oder Sonderzeichen wie ß, - oder _)."
            />
            <Input
              name="companyKey"
              label="Company Key *"
              placeholder="ampeers"
              hint="Einzigartier Identifer, der die Firma intern indexiert. Format: companyKey01 (Camel Case, nur Zahlen und Buchstaben, keine Umlaute oder Sonderzeichen wie ß, - oder _)."
            />
          </Row>
          <FormikInput
            name="name"
            label="District Name *"
            placeholder="AMPEERS ENERGY Quartier"
            hint="Name, der in der District Management App angezeigt wird"
          />
        </Column>
        <ColumnWithInputWidth style={{ width: "40%" }}>
          <AddressForm accessor="address" />
        </ColumnWithInputWidth>
      </Row>
      <Row>
        <Column>
          <EmailForm
            identifier="alertingEmails"
            title="Alerting E-Mails"
            isEditing
            contact={values.contact}
          />
        </Column>
        <Column>
          <EmailForm
            identifier="reportingEmails"
            title="Reporting E-Mails"
            isEditing
            contact={values.contact}
          />
        </Column>
      </Row>
      <p>* Pflichtfeld</p>
    </>
  );
};
