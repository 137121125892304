export function sortMetadata<T extends { key: string }>(
  metadata: T[],
  sortingOrder: string[] = [
    "location",
    "interface",
    "communicationFlow",
    "timetableSpecification",
  ]
): T[] {
  const orderMap = sortingOrder.reduce<Record<string, number>>(
    (acc, key, index) => ({
      ...acc,
      [key]: index,
    }),
    {}
  );

  return [...metadata].sort((a, b) => {
    const positionA = orderMap[a.key];
    const positionB = orderMap[b.key];

    if (positionA !== undefined && positionB !== undefined) {
      return positionA - positionB;
    } else if (positionA !== undefined) {
      return -1;
    } else if (positionB !== undefined) {
      return 1;
    } else {
      return 0;
    }
  });
}
