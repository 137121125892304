import {
  ButtonGroup,
  ButtonIcon,
  CheckboxNew as CheckboxComponent,
  Tag,
} from "@ampeersenergy/ampeers-ui-components";
import styled, { css } from "styled-components";

import { DeleteButton as DeleteButtonComponent } from "../icon-button";

export const listItemContainerStyle = css<{
  $borderColor?: string;
  $hasError?: boolean;
}>`
  display: flex;
  margin-bottom: 16px;
  padding: 7px 22px;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.$hasError
        ? props.theme.palette.error.color
        : props.$borderColor ?? props.theme.palette.border};
`;

export const PropertyListItemWrapper = styled.li`
  display: flex;
`;

export const PropertyListItemContainer = styled.div<{ $hasError?: boolean }>`
  ${listItemContainerStyle}
  flex: 1;
  align-items: center;

  :hover {
    cursor: pointer;
    border: 1px solid
      ${(props) =>
        props.$hasError
          ? props.theme.palette.error.color
          : props.theme.primaryColor};
  }

  .key {
    margin: 0 8px;
    flex: 4;
  }

  .value {
    display: flex;
    align-items: center;
    flex: 4;
    overflow: hidden;
    text-overflow: ellipsis;

    .input {
      flex: 8;
    }
  }

  .unit {
    text-align: right;
    font-weight: bold;
    flex: 2;
  }
`;

export const PropertyEditComponentWrapper = styled.li`
  flex-direction: column;
  ${listItemContainerStyle}

  .unit {
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    padding-bottom: 32px;
    padding-left: 14px;
  }
`;

export const ButtonGroupPullRight = styled(ButtonGroup)`
  margin-top: 4px;
  justify-content: flex-end;
`;

export const DeleteButtonWithMargin = styled(ButtonIcon)`
  margin-left: 4px;
  margin-bottom: 16px;
  padding: 0px 12px;
`;

export const DeleteButton = styled(DeleteButtonComponent)`
  align-self: flex-end;
  margin-bottom: 4px;
`;

export const SpacedTag = styled(Tag)`
  margin-left: 5px;
  margin-top: -20px;
`;

export const Checkbox = styled(CheckboxComponent)<{ $noPadding?: boolean }>`
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 10px;

  label {
    ${(props) => (props.$noPadding ? "" : "padding-left: 0px;")}
    flex: 1;
  }
`;

export const InlineItemsWrapper = styled.div<{ $vertical?: boolean }>`
  display: flex;
  flex: ${(props) => (props.$vertical ? "0" : "1")};
  flex-direction: ${(props) => (props.$vertical ? "column" : "row")};

  & > div {
    margin-left: 0px;
  }
`;

export const ListWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
`;
