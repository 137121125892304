import { Icons, Tag, Theme } from "@ampeersenergy/ampeers-ui-components";
import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { capitalize } from "../../helpers/string.utils";
import {
  ColorSquare,
  Step,
  StepLabel,
  StepLabelWrapper,
  StepperWrapper,
} from "./style";
import { colors, steps } from "./utils";

export interface DistrictStateStepperProps {
  activeState: string;
  currentIndex: number;
  hasError: boolean;
  checkedStates: string[];
  erroredStates: string[];
  onStepClick: (value: number) => void;
}

export const DistrictStateStepper: React.FC<DistrictStateStepperProps> = ({
  activeState,
  currentIndex,
  checkedStates,
  erroredStates,
  onStepClick,
}) => {
  const theme = useTheme() as Theme;
  const activeStateIndex = useMemo(
    () => steps.findIndex((s) => s === activeState),
    [activeState]
  );

  return (
    <StepperWrapper>
      {steps.map((step, index) => {
        const hasError = erroredStates.includes(step);
        const Icon = hasError ? Icons.Warning : Icons.Checkmark;
        const iconColor = hasError
          ? theme.palette.warning.color
          : index === currentIndex
          ? theme.primaryColor
          : theme.palette.textSubtle;

        return (
          <Step
            $isActive={index === activeStateIndex}
            $isSelected={index === currentIndex}
            key={step}
            onClick={() => onStepClick(index)}
          >
            <StepLabelWrapper>
              <ColorSquare $color={colors[step] ?? theme.palette.textSubtle} />
              <StepLabel
                $isActive={index === activeStateIndex}
                $isSelected={index === currentIndex}
              >
                {capitalize(step)}
              </StepLabel>
              {index === activeStateIndex && (
                <Tag color={theme.primaryColor}>ACTIVE</Tag>
              )}
            </StepLabelWrapper>
            {checkedStates.includes(step) && step !== "deactivated" && (
              <Icon size={28} color={iconColor} />
            )}
          </Step>
        );
      })}
    </StepperWrapper>
  );
};
