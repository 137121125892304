import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import {
  DynamicListItemProps,
  MultistepFormContext,
  Table,
} from "@ampeersenergy/ampeers-ui-components";

import { AssetFormValues, LocalUnit, TensorFormValue } from "../types";
import { Column, Input, LabelWithMargin, Row } from "../style";

import { DimensionForm } from "./tensor-edit-form";
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikConsumer,
  useFormikContext,
} from "formik";
import { DeleteButton as DefaultDeleteButton } from "../icon-button";
import { findValue } from "../../helpers/object.utils";

const TensorTable = styled(Table)`
  table {
    td:first-child {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1.29px;
    }

    th {
      text-transform: none;
    }

    button {
      min-height: 40px;
    }
  }

  hr {
    border: 0;
    border-top: 1px ${(props) => props.theme.palette.borderEmphasis} solid;
  }
`;

const Header = styled(Row)`
  align-items: center;
`;

const DeleteButton = styled(DefaultDeleteButton)`
  border: 0 !important;
  padding: 0px;
  height: 32px;
  width: 34px;
  margin-left: 0px;
  margin-right: 0px;
`;

const InputWithMinWidth = styled(Input)`
  min-width: 50px;
`;

interface HeaderCellProps {
  isEditing?: boolean;
  identifier: string;
  index: number;
  unit: string;
  onRemoveRow: (index: number) => void;
}

const HeaderCell: React.FC<HeaderCellProps> = ({
  isEditing,
  identifier,
  index,
  unit,
  onRemoveRow,
}) => {
  return (
    <Header>
      {isEditing && (
        <DeleteButton borderless onClick={() => onRemoveRow(index)} />
      )}
      <InputWithMinWidth
        id={identifier}
        name={identifier}
        label=""
        isEditing={isEditing}
        required
      />
      {unit}
    </Header>
  );
};

const ArrayHelpers = ({
  identifier,
  children,
}: {
  identifier: string;
  children: (
    values: AssetFormValues,
    columnArrayHelpers: FieldArrayRenderProps,
    rowArrayHelpers: FieldArrayRenderProps,
    valuesHelpers: FieldArrayRenderProps
  ) => React.ReactNode;
}) => (
  <FormikConsumer>
    {({ values }) => (
      <FieldArray name={`${identifier}.values`}>
        {(valuesHelpers) => (
          <FieldArray name={`${identifier}.dimensionY.values`}>
            {(rowArrayHelpers) => (
              <FieldArray name={`${identifier}.dimensionX.values`}>
                {(columnArrayHelpers) =>
                  children(
                    values,
                    columnArrayHelpers,
                    rowArrayHelpers,
                    valuesHelpers
                  )
                }
              </FieldArray>
            )}
          </FieldArray>
        )}
      </FieldArray>
    )}
  </FormikConsumer>
);

export interface InterpolationEditFormProps {
  item: DynamicListItemProps["item"] & TensorFormValue;
  index: number;
  isEditing?: boolean;
  interpolationIndex: number;
  units: LocalUnit[];
  prefixIdentifier?: string;
}

export const InterpolationEditForm: React.FC<InterpolationEditFormProps> = ({
  item,
  index,
  interpolationIndex,
  isEditing,
  units,
  prefixIdentifier,
}) => {
  const { setFieldValue, values: asset } = useFormikContext<AssetFormValues>();

  const accessor = prefixIdentifier
    ? findValue<AssetFormValues>(prefixIdentifier, asset)
    : asset;

  const { dimensionX, dimensionY, values } = accessor.tensors[index]?.value
    ?.interpolations[interpolationIndex] ?? {
    dimensionX: { values: [] },
    dimensionY: { values: [] },
    values: [],
  };
  const identifier = `tensors[${index}].value.interpolations[${interpolationIndex}]`;

  const unitKeys = useMemo(() => units.map(({ key }) => key), [units]);

  const { unregisterField } = useContext(MultistepFormContext) ?? {};

  const onAddColumn = useCallback(
    (
        columnArrayHelpers: FieldArrayRenderProps,
        valuesHelpers: FieldArrayRenderProps
      ) =>
      () => {
        columnArrayHelpers.push(null);
        valuesHelpers.push([]);
      },
    []
  );

  const onAddRow = useCallback(
    (
        rowArrayHelpers: FieldArrayRenderProps,
        valuesHelpers: FieldArrayRenderProps
      ) =>
      () => {
        rowArrayHelpers.push(null);
        values.forEach((v, i) => valuesHelpers.replace(i, [...v, null]));
      },
    [values]
  );

  const onRemoveColumn = useCallback(
    (
        columnArrayHelpers: FieldArrayRenderProps,
        valuesHelpers: FieldArrayRenderProps
      ) =>
      (index: number) => {
        unregisterField?.(`${identifier}.dimensionX.values[${index}]`);
        columnArrayHelpers.remove(index);

        values[index].forEach((_, i) => {
          unregisterField?.(`${identifier}.values[${index}][${i}]`);
        });
        valuesHelpers.remove(index);
      },
    // We don't need the values here, just the array length
    // This is done to avoid remounting table cells that depend on this function,
    // which leads to the input losing focus
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [identifier, unregisterField, values.length]
  );

  const onRemoveRow = useCallback(
    (
        // Passing the values here as a parameter because when accessing it directly,
        // values becomes a dependency for this callback. Every time we edit
        // a value, the values array changes, which triggers this function to be recalculated
        // which then makes the headers and cells that depend on it also get recalculated,
        // which then leads the inputs to be re-rendered and lose focus
        values: TensorFormValue["value"]["interpolations"][0]["values"],
        rowArrayHelpers: FieldArrayRenderProps
      ) =>
      (index: number) => {
        unregisterField?.(`${identifier}.dimensionY.values[${index}]`);
        rowArrayHelpers.remove(index);

        const newValues = values.map((v: any, i: number) => {
          unregisterField?.(`${identifier}.values[${i}][${index}]`);
          const newValue = [...v];
          newValue.splice(index, 1);
          return newValue;
        });
        setFieldValue(`${identifier}.values`, newValues);
      },
    [identifier, setFieldValue, unregisterField]
  );

  const dimensionYCell = useCallback(
    () =>
      ({ row }: { row: { index: number } }) =>
        (
          <ArrayHelpers identifier={identifier}>
            {(
              allFormValues,
              columnArrayHelpers,
              rowArrayHelpers,
              valuesHelpers
            ) => (
              <HeaderCell
                isEditing={isEditing}
                identifier={`${identifier}.dimensionY.values[${row.index}]`}
                index={row.index}
                unit={`${
                  units.find((u) => u.key === dimensionY.unit)?.sign ?? ""
                }`}
                onRemoveRow={onRemoveRow(
                  (prefixIdentifier
                    ? findValue<AssetFormValues>(
                        prefixIdentifier,
                        allFormValues
                      )
                    : allFormValues
                  ).tensors[index].value?.interpolations[interpolationIndex]
                    .values ?? [],
                  rowArrayHelpers
                )}
              />
            )}
          </ArrayHelpers>
        ),
    [
      prefixIdentifier,
      dimensionY.unit,
      identifier,
      index,
      interpolationIndex,
      isEditing,
      onRemoveRow,
      units,
    ]
  );

  const dimensionXHeader = useCallback(
    (idx2: number) => () =>
      (
        <ArrayHelpers identifier={identifier}>
          {(
            allFormValues,
            columnArrayHelpers,
            rowArrayHelpers,
            valuesHelpers
          ) => (
            <HeaderCell
              isEditing={isEditing}
              identifier={`${identifier}.dimensionX.values[${idx2}]`}
              index={idx2}
              unit={`${
                units.find((u) => u.key === dimensionX.unit)?.sign ?? ""
              }`}
              onRemoveRow={onRemoveColumn(columnArrayHelpers, valuesHelpers)}
            />
          )}
        </ArrayHelpers>
      ),
    [dimensionX.unit, identifier, isEditing, onRemoveColumn, units]
  );

  const tableCell = useCallback(
    (idx2: number) =>
      ({ row }: { row: { index: number } }) =>
        (
          <InputWithMinWidth
            key={`${identifier}.values[${idx2}][${row.index}]`}
            id={`${identifier}.values[${idx2}][${row.index}]`}
            name={`${identifier}.values[${idx2}][${row.index}]`}
            label=""
            isEditing={isEditing}
          />
        ),
    [identifier, isEditing]
  );

  const columns = useMemo(() => {
    return [
      {
        accessor: "dimensionY",
        disableSortBy: true,
        Header: () => (
          <div>
            <div>{dimensionX.key ?? "--"}</div>
            <hr />
            <div>{dimensionY.key ?? "--"}</div>
          </div>
        ),
        Cell: dimensionYCell(),
      },
      ...dimensionX.values.map((_, idx2) => ({
        accessor: `dimensionX-${idx2}`,
        disableSortBy: true,
        Header: dimensionXHeader(idx2),
        Cell: tableCell(idx2),
      })),
    ];
    // We don't need the values here, just the array length
    // This is done to avoid remounting table cells that depend on this function,
    // which leads to the input losing focus
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dimensionX.key,
    dimensionX.values,
    dimensionXHeader,
    dimensionY.key,
    dimensionYCell,
    tableCell,
  ]);

  const tableData = useMemo(
    () =>
      dimensionY.values.map((v, idx1) => ({
        dimensionY: `${v} ${
          units.find((u) => u.key === dimensionY.unit)?.sign
        }`,
        ...dimensionX.values.reduce(
          (acc, x, idx2) => ({
            ...acc,
            [x]: values[idx2][idx1],
          }),
          {}
        ),
      })),
    [dimensionX.values, dimensionY.unit, dimensionY.values, units, values]
  );

  return (
    <Column>
      <DimensionForm
        item={item}
        identifier={`${identifier}.dimensionX`}
        isEditing={isEditing}
        units={unitKeys}
      />
      <DimensionForm
        item={item}
        identifier={`${identifier}.dimensionY`}
        isEditing={isEditing}
        units={unitKeys}
      />
      <LabelWithMargin>Werte</LabelWithMargin>
      {/* Using array helpers to avoid extra re renders */}
      <ArrayHelpers identifier={identifier}>
        {(_, columnArrayHelpers, rowArrayHelpers, valuesHelpers) => (
          <TensorTable
            isLoading={false}
            compact
            canAddColumns
            canAddRows
            onAddColumn={onAddColumn(columnArrayHelpers, valuesHelpers)}
            onAddRow={onAddRow(rowArrayHelpers, valuesHelpers)}
            columns={columns}
            data={tableData}
          />
        )}
      </ArrayHelpers>
    </Column>
  );
};
