import React from "react";
import { ErrorHandler as DefaultErrorHandler } from "@ampeersenergy/ampeers-ui-components";
import styled from "styled-components";

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export interface ErrorHandlerProps {}

export const ErrorHandler: React.FC<ErrorHandlerProps> = (props) => {
  return (
    <ErrorWrapper>
      <DefaultErrorHandler {...props} />
    </ErrorWrapper>
  );
};
