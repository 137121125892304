import { DynamicList, Icons } from "@ampeersenergy/ampeers-ui-components";
import React, { useCallback } from "react";
import { ListContainer } from "../style";
import { useFormikContext } from "formik";
import { AssetFormValues, LocalTimeseries } from "../types";
import { childAssetValidationSchema } from "../validation";
import { ChildrenAssetsEditForm } from "./children-assets-edit-form";
import { AssetTemplate } from "../../graphql/sdks/controller";

export interface ChildrenAssetsFormProps {
  isEditing?: boolean;
  template?: AssetTemplate;
  loadingTemplates?: boolean;
  timeseries: LocalTimeseries[];
}

export const ChildrenAssetsForm: React.FC<ChildrenAssetsFormProps> = ({
  isEditing,
  template,
  loadingTemplates,
  timeseries,
}) => {
  const { values } = useFormikContext<AssetFormValues>();

  const onNewItem = useCallback((item) => {
    return {
      ...item,
      name: "",
      template: "",
      properties: [],
      timeseries: [],
      tensors: [],
    };
  }, []);

  const ChildrenAssetEditFormComponent = useCallback(
    (props) => {
      return (
        <ChildrenAssetsEditForm
          {...props}
          template={template}
          loadingTemplates={loadingTemplates}
          isEditing={isEditing}
          timeseries={timeseries}
        />
      );
    },
    [isEditing, loadingTemplates, template, timeseries]
  );

  const validateChildAsset = useCallback((item) => {
    return childAssetValidationSchema.isValidSync(item);
  }, []);

  return (
    <ListContainer>
      <DynamicList
        name="children"
        type="Anhängende Asset"
        items={values.children}
        validateItem={validateChildAsset}
        EditComponent={ChildrenAssetEditFormComponent}
        Icon={Icons.HeatMeter}
        interactive={isEditing}
        onNewItem={onNewItem}
      />
    </ListContainer>
  );
};
