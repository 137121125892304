import React from "react";
import { DateTime } from "luxon";
import { useTheme } from "styled-components";
import {
  DynamicListItemProps,
  Theme,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";

import { DatePicker } from "../date-picker";
import { Input, NewItemWrapper, PlusIcon } from "../style";
import { DeleteButton, PropertyListItemWrapper } from "./style";
import { useFormikContext } from "formik";
import { AssetFormValues } from "../types";

export interface TimeDependentValuesListItemProps extends DynamicListItemProps {
  propIndex: number;
  isEditing?: boolean;
  item: DynamicListItemProps["item"];
}

export const TimeDependentValuesListItem: React.FC<
  TimeDependentValuesListItemProps
> = (props) => {
  const { item, index, isEditing, propIndex } = props;

  const { values, setFieldValue } = useFormikContext<AssetFormValues>();

  const theme = useTheme() as Theme;
  const { onAddItem, onDelete, type } = useDynamicListItem({
    ...props,
  });

  if (item.isNewValue) {
    return (
      <NewItemWrapper key={item.key} onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        {type} hinzufügen
      </NewItemWrapper>
    );
  }

  return (
    <PropertyListItemWrapper>
      <DatePicker
        isEditing={isEditing}
        label="Start *"
        date={
          values.properties[propIndex].value[index].start &&
          DateTime.fromISO(
            values.properties[propIndex].value[index].start
          ).toJSDate()
        }
        onDateChange={(v) =>
          setFieldValue(
            `properties[${propIndex}].value[${index}].start`,
            DateTime.fromJSDate(v).toISODate()
          )
        }
      />
      <DatePicker
        isEditing={isEditing}
        label="End *"
        date={
          values.properties[propIndex].value[index].end &&
          DateTime.fromISO(
            values.properties[propIndex].value[index].end
          ).toJSDate()
        }
        onDateChange={(v) =>
          setFieldValue(
            `properties[${propIndex}].value[${index}].end`,
            DateTime.fromJSDate(v).toISODate()
          )
        }
      />
      <Input
        label="Value *"
        isEditing={isEditing}
        name={`properties[${propIndex}].value[${index}].value`}
      />
      {isEditing && (
        <DeleteButton
          secondary
          onClick={onDelete}
          color={theme.palette.error.color}
        />
      )}
    </PropertyListItemWrapper>
  );
};
