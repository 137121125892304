import React, { useMemo } from "react";
import { MultistepForm } from "@ampeersenergy/ampeers-ui-components";

import { districtValidationSchema } from "../validation";
import { Overview } from "./overview";
import {
  DistrictInput,
  GetDistrictsDocument,
  MetadataTemplate,
  useCreateDistrictMutation,
  useGetDistrictTemplateQuery,
} from "../../graphql/sdks/controller";

import { extractErrorMessage } from "../../helpers/error.utils";
import { formatPropertiesBeforeSubmit } from "../../helpers/properties.utils";
import { omitGraphqlType } from "../../helpers/asset.utils";

import { DistrictForm } from "./district-form";
import { PropertiesStep } from "./properties-form";
import { DistrictFormValues } from "../types";
// import { MetadataForm } from "../metadata-form";

export interface CreateDistrictFlowProps {
  setIsOverlayOpen: (value: boolean) => void;
}

export const CreateDistrictFlow: React.FC<CreateDistrictFlowProps> = ({
  setIsOverlayOpen,
}) => {
  const { data: districtTemplate } = useGetDistrictTemplateQuery();
  const [createDistrict] = useCreateDistrictMutation();

  const steps = useMemo(
    () => [
      {
        title: "Quartier",
        content: <DistrictForm />,
      },
      {
        title: "Properties ",
        content: (
          <PropertiesStep
            districtTemplate={districtTemplate?.districtTemplate}
          />
        ),
      },
      // Currently metadata are not used at district level, even though the backend supports it
      // {
      //   title: "Metadaten",
      //   content: (
      //     <Accordion
      //       withPadding={false}
      //       autoClose={false}
      //       LabelComponent={SubTitleWithMargin}
      //       initialOpenIds={[0]}
      //     >
      //       <AccordionItem label="Metadaten">
      //         <MetadataForm />
      //       </AccordionItem>
      //       <></>
      //     </Accordion>
      //   ),
      // },
      {
        title: "Übersicht",
        content: <Overview />,
      },
    ],
    [districtTemplate]
  );

  return (
    <MultistepForm
      validationSchema={districtValidationSchema}
      initialValues={{
        key: undefined,
        companyKey: undefined,
        name: undefined,
        email: undefined,
        address: {
          street: undefined,
          streetNr: undefined,
          zip: undefined,
          city: undefined,
          country: undefined,
          latitude: undefined,
          longitude: undefined,
        },
        addresses: [],
        properties: [],
        timeseries: [],
        metadata: [],
        contact: {
          alertingEmails: [],
          reportingEmails: [],
        },
        propertyTemplates:
          districtTemplate?.districtTemplate.properties?.map((p) =>
            omitGraphqlType({
              ...p,
              unit: p.unit,
              value: "",
            })
          ) ?? [],
      }}
      steps={steps}
      onSubmit={({ newValues: values }) => {
        const {
          state,
          properties,
          key,
          companyKey,
          address,
          name,
          metadata,
          timeseries,
          contact,
        } = values as DistrictFormValues;
        return createDistrict({
          variables: {
            district: {
              name,
              address,
              state: state ?? "collect",
              contact: Object.keys(contact).reduce<DistrictInput["contact"]>(
                (acc, key) =>
                  ({
                    ...acc,
                    [key]: contact[key as keyof typeof contact]
                      .map(({ value }) => value)
                      .filter((email): email is string => !!email),
                  } as DistrictInput["contact"]),
                {} as DistrictInput["contact"]
              ),
              metadata: (
                metadata as (MetadataTemplate & { value?: string | boolean })[]
              ).map(({ key, label, value }) => ({
                key,
                label,
                value:
                  value === true
                    ? "true"
                    : value === false
                    ? "false"
                    : value ?? "",
              })),
              properties: formatPropertiesBeforeSubmit({
                properties,
                timeseries,
              }),
              notes: [],
            } as DistrictInput,
          },
          context: {
            headers: {
              "x-ampeers-company": companyKey,
              "x-ampeers-district": key,
            },
          },
          refetchQueries: [
            {
              query: GetDistrictsDocument,
              variables: {
                filter: {
                  multipleDistricts: [
                    {
                      company: "all",
                      district: "all",
                    },
                  ],
                },
              },
            },
          ],
        }).catch((err) => {
          throw extractErrorMessage(err);
        });
      }}
      onSuccess={() => setIsOverlayOpen(false)}
      onAbort={() => setIsOverlayOpen(false)}
    />
  );
};
