import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useFormikContext } from "formik";
import {
  Button,
  Label,
  useDialog,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";

import {
  AssetTemplate,
  GetAssetTemplateLazyQueryHookResult,
  GetPrefilledAssetsLazyQueryHookResult,
  Metadata,
  MetadataTemplate,
} from "../../graphql/sdks/controller";

import { MultistepFormContext } from "../multistep-form/context";

import { FlexRawSelect, Input, Row, Spinner } from "../style";
import {
  AssetFormValues,
  ExtendedAsset,
  ExtendedProperty,
  LocalTimeseries,
} from "../types";

import {
  AssetType,
  formatInitialAssetFormValues,
  getAssetKeyAndName,
  getAssetTypeName,
} from "../../helpers/asset.utils";
import { mergePropertyTemplates } from "../../helpers/properties.utils";
import {
  KeyValue,
  KeyValueList,
  LabelWithTopMargin,
  PrefilledAssetValues,
  ColumnWithMargin,
} from "./style";

export interface AssetFormTemplateProps {
  districtKey: string;
  companyKey: string;
  assets?: ExtendedAsset[];
  templates: AssetTemplate[];
  timeseries: LocalTimeseries[];
  getAssetTemplate: GetAssetTemplateLazyQueryHookResult[0];
  getPrefilledAssets: GetPrefilledAssetsLazyQueryHookResult[0];
  loadingTemplates?: boolean;
  existingAsset?: boolean;
  isEditing?: boolean;
}

export const AssetFormTemplate: React.FC<AssetFormTemplateProps> = ({
  companyKey,
  districtKey,
  assets,
  templates,
  timeseries,
  loadingTemplates,
  existingAsset,
  isEditing,
  getAssetTemplate,
  getPrefilledAssets,
}) => {
  const { openConfirmation } = useDialog();

  const { values, setValues, touched, errors } =
    useFormikContext<AssetFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    if (registerField) {
      registerField("template");
      registerField("name");
      registerField("key");
      registerField("companyKey");
      registerField("districtKey");
    }

    return () => {
      if (unregisterField) {
        unregisterField("template");
        unregisterField("name");
        unregisterField("key");
        unregisterField("companyKey");
        unregisterField("districtKey");
      }
    };
  }, [registerField, unregisterField]);

  const manufacturers = useMemo(
    () =>
      values.prefilledAssets.reduce<string[]>((acc, prefilledAsset) => {
        prefilledAsset.metadata
          .filter((meta) => meta.key === "manufacturer")
          .forEach((meta) => {
            if (!acc.includes(meta.value)) {
              acc.push(meta.value);
            }
          });

        return acc;
      }, []),
    [values.prefilledAssets]
  );

  const types = useMemo(
    () =>
      values.prefilledAssets.reduce<string[]>((acc, prefilledAsset) => {
        const localManufacturer = prefilledAsset.metadata.find(
          (meta) => meta.key === "manufacturer"
        )?.value;

        if (localManufacturer !== values.manufacturer) {
          return acc;
        }

        return acc.concat(
          prefilledAsset.metadata
            .filter((meta) => meta.key === "type")
            .map((meta) => meta.value)
        );
      }, []),
    [values]
  );

  const onAssetTemplateChange = useCallback(
    async (e: ChangeEvent<{ value: string }>) => {
      try {
        const assetTemplate = templates.find(
          (a) => a.template === e.target.value
        );

        if (!assetTemplate) {
          throw new Error(`Cannot find template: ${e.target.value}`);
        }

        const { data, error } = await getAssetTemplate({
          variables: {
            templateKey: assetTemplate.template,
          },
        });

        if (error) {
          throw error;
        }

        const template = data?.assetTemplate;

        if (!template) {
          throw new Error(`Cannot load template: ${e.target.value}`);
        }

        const prefilledAssets = await getPrefilledAssets({
          variables: {
            templateKey: assetTemplate.template,
            districtIdentifier: {
              company: companyKey,
              district: districtKey,
            },
          },
        });

        const assetKeyAndName = getAssetKeyAndName(
          assetTemplate.template as AssetType,
          assets ?? []
        );

        const newAssetKeyAndName = {
          key: values.key || assetKeyAndName.key,
          name: values.name || assetKeyAndName.name,
        };

        const updateForm = () => {
          setValues({
            ...values,
            ...newAssetKeyAndName,
            metadata: template.metadata.map<MetadataTemplate>((m) => {
              if (m.key === "communicationFlow") {
                return { ...m, value: "AE DM ↔ Controller" };
              } else if (m.key === "timetableSpecification") {
                return { ...m, value: false };
              }

              if (!m) return m;

              return { ...m, value: undefined };
            }),
            template: assetTemplate.template,
            description: assetTemplate.description,
            prefilledAssets: prefilledAssets.data?.prefilledAssets ?? [],
            manufacturer: "",
            manufacturerType: "",
            ...mergePropertyTemplates(
              [
                ...values.properties,
                ...values.timeseries,
                ...values.tensors,
              ] as ExtendedProperty[],
              timeseries,
              assetTemplate.properties ?? [],
              values.flags,
              newAssetKeyAndName.key
            ),
          });
        };

        if (Object.keys(touched).length || existingAsset) {
          await openConfirmation({
            title: "Bist du sicher?",
            content: (
              <>
                Bist du sicher, dass du ein neues Template auswählen möchtest?
                Die folgenden Felder werden überschrieben:
                <KeyValueList>
                  <li>Key</li>
                  <li>Name</li>
                  <li>Template</li>
                </KeyValueList>
              </>
            ),
            confirmButtonLabel: "Template auswählen",
            handleRequest: updateForm,
          });
        } else {
          updateForm();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      templates,
      getAssetTemplate,
      getPrefilledAssets,
      companyKey,
      districtKey,
      assets,
      values,
      touched,
      existingAsset,
      setValues,
      timeseries,
      openConfirmation,
    ]
  );

  const onManufacturerTypeChange = useCallback(
    (newManufacturerType: string) => {
      const prefilledAsset = values.prefilledAssets.find(
        ({ metadata }) =>
          metadata.some(
            (meta) =>
              meta.key === "manufacturer" && meta.value === values.manufacturer
          ) &&
          metadata.some(
            (meta) => meta.key === "type" && meta.value === newManufacturerType
          )
      );

      setValues({
        ...values,
        manufacturerType: newManufacturerType,
        prefilledAsset,
      });
    },
    [values, setValues]
  );

  const applyTemplate = useCallback(() => {
    setValues({
      ...values,
      ...(values.prefilledAsset
        ? formatInitialAssetFormValues(
            {
              ...values,
              metadata: [
                ...values.metadata.filter(
                  (meta) =>
                    !values.prefilledAsset!.metadata.some(
                      (_meta) => _meta.key === meta.key
                    )
                ),
                ...values.prefilledAsset.metadata,
              ] as Metadata[],
              properties: values.prefilledAsset.properties,
              description:
                values.description ?? values.prefilledAsset.description,
            },
            values.parentId,
            districtKey,
            companyKey,
            values.propertyTemplates,
            [],
            assets,
            values.address ?? undefined,
            timeseries
          )
        : {}),
      prefilledAsset: undefined,
    });
  }, [assets, companyKey, districtKey, setValues, timeseries, values]);

  const realTemplates = templates.filter((t) => t.assetType === "real");
  const virtualTemplates = templates
    .filter((t) => t.assetType === "virtual")
    .filter((t) => t.template !== "weather");

  return (
    <>
      <Row>
        <FlexRawSelect
          id="assetTemplate"
          label="Template *"
          isEditing={isEditing}
          value={values.template || ""}
          errorMsg={errors.template}
          onChange={onAssetTemplateChange}
          disabled={existingAsset}
          hint={values.description ?? "Keine Beschreibung vorhanden"}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          <optgroup label="Real assets">
            {realTemplates.map((template) => (
              <option key={template.template} value={template.template}>
                {getAssetTypeName(template.template as AssetType)} (
                {template.template})
              </option>
            ))}
          </optgroup>
          <optgroup label="Virtual assets">
            {virtualTemplates.map((template) => (
              <option key={template.template} value={template.template}>
                {getAssetTypeName(template.template as AssetType)} (
                {template.template})
              </option>
            ))}
          </optgroup>
        </FlexRawSelect>
        {loadingTemplates && <Spinner size={25} />}
      </Row>
      <Row>
        <Input
          name="key"
          label="Key *"
          placeholder="Key"
          required
          hint="Einzigartiger Identifikator, der die Anlage intern indexiert. Format: assetKey01 (Camel Case, nur Zahlen und Buchstaben, keine Umlaute oder Sonderzeichen wie ß, - oder _)."
          isEditing={isEditing}
          disabled={existingAsset}
        />
        <Input
          name="name"
          label="Name *"
          placeholder="Name"
          required
          isEditing={isEditing}
          hint="Name, der in der District Management App angezeigt wird"
        />
      </Row>
      {values.template && values.prefilledAssets?.length ? (
        <>
          <LabelWithTopMargin>
            Template nach Hersteller suchen
          </LabelWithTopMargin>
          <Row>
            <FlexRawSelect
              id="manufacturer"
              label="Hersteller"
              isEditing={isEditing}
              value={values.manufacturer}
              onChange={(e) =>
                setValues({
                  ...values,
                  manufacturer: e.target.value,
                  manufacturerType: "",
                })
              }
            >
              <option disabled value="" key={"-"}>
                Bitte wählen
              </option>
              {manufacturers.map((manufacturer) => (
                <option key={manufacturer} value={manufacturer}>
                  {manufacturer}
                </option>
              ))}
            </FlexRawSelect>
            {values.manufacturer ? (
              <FlexRawSelect
                id="manufacturerType"
                label="Typ"
                isEditing={isEditing}
                value={values.manufacturerType}
                onChange={(e) => onManufacturerTypeChange(e.target.value)}
              >
                <option disabled value="" key={"-"}>
                  Bitte wählen
                </option>
                {types.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </FlexRawSelect>
            ) : null}
          </Row>
        </>
      ) : null}
      {values.prefilledAsset ? (
        <PrefilledAssetValues>
          <WarningAlert>
            Folgende Felder werden hinzugefügt/überschrieben:
          </WarningAlert>
          <ColumnWithMargin>
            <Label>Properties</Label>
            <KeyValueList>
              {values.prefilledAsset.properties
                .filter(
                  (p) =>
                    p.dataType === "Param" ||
                    p.dataType === "scalarList" ||
                    p.dataType === "scalarTimeDependent"
                )
                .map((p) => (
                  <KeyValue>
                    <span className="key">{p.key}</span>
                    <span className="value">
                      {p.value} {p.unit}
                    </span>
                  </KeyValue>
                ))}
            </KeyValueList>
            <Label>Kennfelder</Label>
            <KeyValueList>
              {values.prefilledAsset.properties
                .filter(
                  (p) => p.dataType === "tensor" || p.dataType === "table"
                )
                .map((p) => (
                  <KeyValue>
                    <span className="key">{p.key}</span>
                  </KeyValue>
                ))}
            </KeyValueList>
            <Label>Metadaten</Label>
            <KeyValueList>
              {values.prefilledAsset.metadata.map((meta) => (
                <KeyValue>
                  <span className="key">
                    {meta.label} ({meta.key})
                  </span>
                  <span className="value">{meta.value}</span>
                </KeyValue>
              ))}
            </KeyValueList>
          </ColumnWithMargin>
          <Button secondary expand onClick={applyTemplate}>
            Template anwenden
          </Button>
        </PrefilledAssetValues>
      ) : null}
    </>
  );
};
