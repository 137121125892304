import {
  Button,
  ButtonGroup,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";
import { Formik, FormikConfig, FormikHelpers, FormikValues } from "formik";
import React, {
  Children,
  ComponentType,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { MultistepFormContext } from "./context";
import {
  MultistepFormMutationError,
  MultistepFormMutationErrorProps,
} from "./mutation-error";
import { MultistepFormStepProps, Step } from "./step";
import { Stepper, StepperProps } from "./stepper";
import {
  MultistepFormContentWrapper,
  MultistepFormWrapper,
  MultistepFormNavigationButtons,
  ArrowLeft,
  ArrowRight,
  MultistepFormContent,
  SubmitButton,
} from "./style";
import { MultistepFormValidationErrorType } from "./types";
import {
  MultistepFormValidationError,
  MultistepFormValidationErrorProps,
} from "./validation-error";

export interface MultistepFormProps {
  direction?: StepperProps["direction"];
  showSubmitInEveryStep?: boolean;
  extraActionButtons?: React.ReactNode;

  /**Form props */
  initialValues: FormikConfig<FormikValues>["initialValues"];
  validationSchema: FormikConfig<FormikValues>["validationSchema"];
  onSubmit: (
    {
      newValues,
      initialValues,
    }: {
      newValues: any;
      initialValues: any;
    },
    formikProps: FormikHelpers<any>
  ) => Promise<void>;

  children:
    | React.ReactElement<MultistepFormStepProps>
    | React.ReactElement<MultistepFormStepProps>[];
}

export const MultistepForm: React.FC<MultistepFormProps> & {
  Stepper: ComponentType<StepperProps>;
  Step: ComponentType<MultistepFormStepProps>;
} = ({
  children,
  direction = "vertical",
  showSubmitInEveryStep,
  extraActionButtons,
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  const theme = useTheme() as Theme;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [mutationError, setMutationError] =
    useState<MultistepFormMutationErrorProps["mutationError"]>(null);
  const [validationError, setValidationError] =
    useState<MultistepFormValidationErrorProps["validationError"]>(null);

  const fieldsStepMap: Record<string, number> = useMemo(() => ({}), []);

  const steps: MultistepFormStepProps[] = useMemo(() => {
    return Children.map(
      children,
      (child: React.ReactElement<MultistepFormStepProps>) => {
        return child.props;
      }
    );
  }, [children]);

  const renderedFields: { [key: string]: Set<string> } = useMemo(() => {
    return steps.reduce(
      (acc, _, index) => ({
        ...acc,
        [index]: new Set(),
      }),
      {}
    );
  }, [steps]);

  const registerField = useCallback(
    (fieldName: string) => {
      renderedFields[currentIndex].add(fieldName);
      fieldsStepMap[fieldName] = currentIndex;
    },
    [fieldsStepMap, renderedFields, currentIndex]
  );

  const unregisterField = useCallback(
    (fieldName: string) => {
      renderedFields[currentIndex].delete(fieldName);
    },
    [renderedFields, currentIndex]
  );

  const onNextStep = useCallback(() => {
    setCurrentIndex((currentIndex) => currentIndex + 1);
  }, []);

  const onPrevStep = useCallback(() => {
    if (currentIndex === 0) {
      return;
    }

    setCurrentIndex((currentIndex) => currentIndex - 1);
  }, [currentIndex]);

  const onFormSubmit = useCallback<FormikConfig<FormikValues>["onSubmit"]>(
    async (newValues, formikProps) => {
      formikProps.setTouched({});

      if (isSubmitting) {
        return;
      }

      setSubmitting(true);
      formikProps.setSubmitting(true);

      try {
        await onSubmit(
          {
            newValues,
            initialValues,
          },
          formikProps
        );
      } catch (error) {
        if (
          Array.isArray(error) &&
          error[0]?.__typename === "ValidationError"
        ) {
          const formattedErrors = error.reduce<Record<string, string>>(
            (
              acc: Record<string, string>,
              { pointer, message }: MultistepFormValidationErrorType
            ) => {
              return { ...acc, [pointer]: message };
            },
            {}
          );

          setValidationError(formattedErrors);
          formikProps.setTouched({});
          formikProps.setStatus(formattedErrors);
        } else {
          setMutationError(error as string[]);
        }
      } finally {
        formikProps.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [initialValues, isSubmitting, onSubmit]
  );

  const onValidate = useCallback(
    async (newValues: any) => {
      const fields = Array.from(renderedFields[currentIndex]);
      const errors: Record<string, string> = {};

      for (const fieldName of fields) {
        try {
          await validationSchema.validateAt(fieldName, newValues, {
            context: newValues,
          });
        } catch (err) {
          errors[fieldName] = (err as Error).message;
        }
      }

      return errors;
    },
    [currentIndex, renderedFields, validationSchema]
  );

  const onFormChange = useCallback(() => {
    if (mutationError) {
      setMutationError(null);
    }
    if (validationError) {
      setValidationError(null);
    }
  }, [mutationError, validationError]);

  const content = useMemo(
    () =>
      Children.map(children, (child, index) => {
        if (index === currentIndex) return child;
        return null;
      }),
    [children, currentIndex]
  );

  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === steps.length - 1;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <MultistepFormContext.Provider
            value={{
              registerField,
              unregisterField,
              setCurrentIndex,
              currentIndex,
              isValid,
              isSubmitting,
            }}
          >
            <MultistepFormWrapper
              autoComplete="off"
              $direction={direction}
              onChange={onFormChange}
            >
              <Stepper
                direction={direction}
                currentIndex={currentIndex}
                steps={steps}
                onStepClick={setCurrentIndex}
              />
              <MultistepFormContentWrapper>
                <MultistepFormMutationError mutationError={mutationError} />
                <MultistepFormValidationError
                  validationError={validationError}
                  fieldsStepMap={fieldsStepMap}
                  steps={steps}
                  setCurrentIndex={setCurrentIndex}
                />

                <MultistepFormContent>{content}</MultistepFormContent>

                <MultistepFormNavigationButtons>
                  {!isFirstStep ? (
                    <Button secondary onClick={onPrevStep}>
                      <ArrowLeft size={22} color={theme.primaryColor} />
                      Zurück
                    </Button>
                  ) : (
                    <div></div> // force other button to right if this button isn't shown
                  )}
                  <ButtonGroup>
                    {extraActionButtons}
                    {!isLastStep && (
                      <Button secondary onClick={onNextStep}>
                        Weiter
                        <ArrowRight size={22} color={theme.primaryColor} />
                      </Button>
                    )}
                    {(isLastStep || showSubmitInEveryStep) && (
                      <SubmitButton
                        isLoading={isSubmitting}
                        disabled={!isValid}
                      >
                        Speichern
                      </SubmitButton>
                    )}
                  </ButtonGroup>
                </MultistepFormNavigationButtons>
              </MultistepFormContentWrapper>
            </MultistepFormWrapper>
          </MultistepFormContext.Provider>
        );
      }}
    </Formik>
  );
};

MultistepForm.Step = Step;
MultistepForm.Stepper = Stepper;
