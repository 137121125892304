import { Theme } from "@ampeersenergy/ampeers-ui-components";
import React from "react";
import {
  EdgeProps,
  getMarkerEnd,
  EdgeText,
  getSmoothStepPath,
} from "react-flow-renderer";
import { useTheme } from "styled-components";

export const FlowEdge: React.FC<EdgeProps> = ({
  id,
  label,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const theme = useTheme() as Theme;

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <path
        id={id}
        className={[
          "react-flow__edge-path",
          data.hovered ? "hovered" : "",
        ].join(" ")}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeText
        x={0 + (label?.toString().length ?? 0) * 6}
        y={0 - data.offset * 22}
        label={label}
        labelStyle={{
          fill: data.hovered ? theme.primaryColor : theme.palette.textMuted,
          fontSize: 20,
          fontWeight: "bold",
          transform: `translate(1%, -1%) translate(${sourceX}px,${sourceY}px)`,
        }}
        labelShowBg
      />
      ;
    </>
  );
};
