import React, { useMemo } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import {
  ErrorAlert,
  Icons,
  InfoAlert,
  PageTitle,
  SubTitle as SubTitleComponent,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";

import { Address, Building, Metadata } from "../../graphql/sdks/controller";

import { Column, Muted, Row } from "../style";
import { PropertyFormValue } from "../types";
import { EmailForm } from "../edit-district-form/e-mail-form";

const SubTitle = styled(SubTitleComponent)`
  margin: 0;
`;

const FormElementStyle = styled.div<{ flex?: number }>`
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 15px;
  flex: ${(props) => props.flex ?? 1};
`;

const Label = styled.label`
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Section = styled(Row)`
  margin-top: 34px;
`;

const Wrapper = styled.div`
  margin-top: 34px;
`;

const Item = styled.div`
  display: flex;
  margin: 10px;

  .key {
    flex: 1;
  }

  .value {
    flex: 7;
  }
`;

const LabelValue: React.FC<{
  label: string;
  flex?: number;
  color?: string;
}> = ({ label, children, flex, color = "#ffa40e" }) => {
  return (
    <FormElementStyle key={label} flex={flex}>
      <Label>
        {label}
        {!children && <Icons.Warning size="20" color={color} />}
      </Label>
      <InputGroup>{children ?? "--"}</InputGroup>
    </FormElementStyle>
  );
};

export const Overview: React.FC = () => {
  const { values } = useFormikContext<{
    properties: PropertyFormValue[];
    timeseries: PropertyFormValue[];
    metadata: Metadata[];
    name: string;
    key: string;
    companyKey: string;
    email: string;
    contact: {
      alertingEmails: { key: string; value: string }[];
      reportingEmails: { key: string; value: string }[];
    };
    address: {
      street: string;
      streetNr: number;
      zip: number;
      city: string;
      country: string;
      latitude: number;
      longitude: number;
    };
    buildings: Building[];
    addresses: Address[];
  }>();

  const hasErrors = useMemo(() => {
    return !(
      values.name &&
      values.key &&
      values.companyKey &&
      values.address.street &&
      values.address.streetNr &&
      values.address.zip &&
      values.address.city &&
      values.address.country &&
      values.address.latitude &&
      values.address.longitude
    );
  }, [values]);

  const hasWarnings = useMemo(() => {
    return !values.email;
  }, [values]);

  return (
    <>
      <PageTitle>{values.name}</PageTitle>
      <Section>
        <Column>
          <SubTitle>Allgemein</SubTitle>
          <Row>
            <LabelValue label="District Name" color="#ae1e01">
              {values.name}
            </LabelValue>
          </Row>
          <Row>
            <LabelValue label="District Key" color="#ae1e01">
              {values.key}
            </LabelValue>
            <LabelValue label="Company Key" color="#ae1e01">
              {values.companyKey}
            </LabelValue>
          </Row>
        </Column>
        <Column>
          <SubTitle>Adresse</SubTitle>
          <Row>
            <LabelValue flex={2} label="Street" color="#ae1e01">
              {values.address?.street}
            </LabelValue>
            <LabelValue label="Street number" color="#ae1e01">
              {values.address?.streetNr}
            </LabelValue>
          </Row>
          <Row>
            <LabelValue label="Zip" color="#ae1e01">
              {values.address?.zip}
            </LabelValue>
            <LabelValue label="City" color="#ae1e01">
              {values.address?.city}
            </LabelValue>
            <LabelValue label="Country" color="#ae1e01">
              {values.address?.country}
            </LabelValue>
          </Row>
          <Row>
            <LabelValue label="Latitude" color="#ae1e01">
              {values.address?.latitude}
            </LabelValue>
            <LabelValue label="Longitude" color="#ae1e01">
              {values.address?.longitude}
            </LabelValue>
          </Row>
        </Column>
      </Section>

      <Section>
        <Column>
          <EmailForm
            identifier="alertingEmails"
            title="Alerting E-Mails"
            contact={values.contact}
            isEditing={false}
          />
        </Column>
        <Column>
          <EmailForm
            identifier="reportingEmails"
            title="Reporting E-Mails"
            contact={values.contact}
            isEditing={false}
          />
        </Column>
      </Section>

      <Section>
        <Column>
          <SubTitle>Properties</SubTitle>
          {values.properties.length ? (
            values.properties.map((p) => {
              return (
                <Item>
                  <strong className="key">{p.key || p.propKey}</strong>
                  <span className="value">
                    {p.value} {p.unit}
                  </span>
                </Item>
              );
            })
          ) : (
            <Muted>Keine Properties vorhanden</Muted>
          )}
        </Column>
      </Section>

      <Section>
        <Column>
          <SubTitle>Zeitreihen</SubTitle>
          {values.timeseries.length ? (
            values.timeseries.map((p) => {
              return (
                <Item>
                  <strong className="key">{p.key || p.propKey}</strong>
                  <span className="value">
                    {p.value} {p.unit}
                  </span>
                </Item>
              );
            })
          ) : (
            <Muted>Keine Zeitreihen vorhanden</Muted>
          )}
        </Column>
      </Section>

      {
        // Currently metadata are not used at district level, even though the backend supports it
        /* <Section>
        <Column>
          <SubTitle>Metadaten</SubTitle>
          {values.metadata.length ? (
            values.metadata.map((p) => {
              return (
                <Item>
                  <strong className="key">{p.key}</strong>
                  <span className="value">{p.value}</span>
                </Item>
              );
            })
          ) : (
            <Muted>Keine Metadaten vorhanden</Muted>
          )}
        </Column>
      </Section> */
      }

      <Wrapper>
        {hasErrors && (
          <ErrorAlert>
            Diese Felder müssen ausgefüllt werden, bevor das Quartier
            gespeichert wird.
          </ErrorAlert>
        )}
        {hasWarnings && (
          <WarningAlert>
            Diese Felder werden beim Erstellen des neuen Quartiers leer oder
            lückenhaft gespeichert. Du sollst diese Felder ausfüllen, bevor du
            das Quartier freischaltest.
          </WarningAlert>
        )}
        <InfoAlert>
          Beim <strong>Speichern</strong> wird ein neues Quartier mit den
          angegeben Daten angelegt. Das Quartier wird erst in der District
          Manager App aktiviert, wenn es einem User zugeordnet ist.
          <br />
          <br />
          Weitere relevante Informationen wie Gebäude, Anlagen, KPIs usw. kannst
          du nachher auf den Quartier-Seiten bearbeiten. Dort kannst du auch das
          neue Quartier einem User zuordnen und freischalten.
          <br />
          <br />
          Eine Aktivierung des Quartieres für die Betriebsführung ist erst dann
          möglich, wenn alle Pflichtfelder ausgefüllt sind.
        </InfoAlert>
      </Wrapper>
    </>
  );
};
