export function removeKey<T, K extends keyof T>(
  object: T | undefined,
  key: K
): Omit<T, K> | undefined {
  if (!object) return object;
  const { [key]: removedKey, ...rest } = object;
  return rest;
}

export function removeKeys<T>(
  object: T | undefined,
  keys: (keyof T)[]
): Omit<T, keyof T> | undefined {
  if (!object) return object;
  return Object.keys(object).reduce<Omit<T, keyof T>>((newObj, key) => {
    if (keys.includes(key as keyof T)) return newObj;
    return { ...newObj, [key]: newObj[key as keyof Omit<T, keyof T>] };
  }, {} as Omit<T, keyof T>);
}

export function pickKeys<T>(
  object: T | undefined,
  keys: (keyof T)[]
): Pick<T, keyof T> | undefined {
  if (!object) return object;
  return keys.reduce((newObject, key) => {
    return {
      ...newObject,
      [key]: object[key],
    };
  }, {} as Pick<T, keyof T>);
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export const findValue = <T extends object>(path: string, value: object) => {
  const accessors = path.split(".");

  const nestedValue = accessors.reduce((returnValue, accessor) => {
    if (returnValue === undefined) return returnValue;

    const index = accessor.split("[")?.[1]?.split("]")?.[0];

    if (index !== undefined) {
      return returnValue[accessor.split("[")[0] as keyof typeof value]?.[
        +index
      ];
    }

    return returnValue[accessor as keyof typeof value];
  }, value);

  return nestedValue as T;
};

export function flattenMergedObjects(
  object: Record<string, any>,
  identifier: string
) {
  return Object.keys(object).reduce(
    (acc, s) => ({
      ...acc,
      [`${identifier}.${s}`]: object[s as keyof typeof object],
    }),
    {}
  );
}
