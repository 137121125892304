export const districtStates = [
  "deactivated",
  "collect",
  "display",
  "monitor",
  "operative",
];

export const steps = [
  "deactivated",
  "collect",
  "display",
  "monitor",
  "operative",
];

export const colors: Record<string, string> = {
  collect: "#256bab",
  display: "#3cc9bd",
  monitor: "#ff9b00",
  operative: "#119142",
};

export const stateHints: Record<string, string[]> = {
  display: [
    "Quartier sollte mindestens einem User zugewiesen sein. (z.B. superuser)",
    "Mailadresse mind. eines Project Engineers sollte zum Alerting und Reporting eingetragen sein.",
    "Inferenzen müssen geprüft und befüllt sein.",
    "KPIs und Forecasts werden automatisch generiert.",
  ],
  monitor: [
    "Quartier sollte mindestens einem Kundennutzenden zugeordnet sein.",
  ],
  operative: [
    "Quartier kann nur auf 'Operative' gewechselt werden, wenn alle Vorbedingungen zwingend erfüllt sind.",
  ],
};
