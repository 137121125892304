import React from "react";
import styled, { css, useTheme } from "styled-components";
import { BaseIcon, Theme } from "@ampeersenergy/ampeers-ui-components";
import { useOutsideClickHandler } from "../hooks/useOutsideClickHandler";

const leftAlign = css`
  left: 0;
`;

const rightAlign = css`
  right: 0;
`;

export const PopOver = styled.div<{ $position?: "left" | "right" }>`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background};
  margin-top: 4px;
  position: absolute;
  z-index: 2;
  min-width: calc(100% - 4px);
  width: max-content;
  max-height: 65vh;
  overflow-y: auto;
  ${(props) => (props.$position === "right" ? rightAlign : leftAlign)}
`;

export const PopOverItem = styled.div<{ $color?: string }>`
  display: flex;
  cursor: pointer;
  padding: 7px 22px;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.$color ?? props.theme.primaryColor};

  & > :first-child {
    margin-right: 4px;
  }

  &:hover {
    background-color: #dbffd4;
    font-weight: bold;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:first-child:last-child {
    border-radius: 4px 4px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Divider = styled.div`
  flex: 1;
  height: 1px;
  border-bottom: 1px solid ${(props) => props.theme.palette.textSubtle};
  margin: 8px 12px;
`;

const DividerWithLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  margin-left: 14px;
  color: ${(props) => props.theme.palette.textSubtle};
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.15px;
`;

export type DropdownDividerItem = {
  label?: React.ReactNode;
  divider: true;
};

export type DropdownValueItem = {
  label: React.ReactNode;
  value?: string;
  Icon?: typeof BaseIcon;
  color?: string;
  CustomPopOverItem?: React.FC<React.HTMLProps<HTMLDivElement>>;
  onClick?: (value?: string) => void;
};

export type DropdownItem = DropdownValueItem | DropdownDividerItem;

export interface DropdownProps {
  items: DropdownItem[];
  isOpen: boolean;
  onClose: () => void;
  onItemClick?: (value?: string) => void;
  CustomPopOverItem?: React.FC<any>;
  children: React.ReactElement;
  position?: "left" | "right";
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  items,
  isOpen,
  onClose,
  onItemClick,
  CustomPopOverItem,
  children,
  position,
  className,
}) => {
  const theme = useTheme() as Theme;

  const { targetRef } = useOutsideClickHandler({
    onOutsideClick: () => isOpen && onClose(),
  });

  const Item = CustomPopOverItem ?? PopOverItem;

  return (
    <Wrapper ref={targetRef} className={className}>
      {children}
      {!!items.length && isOpen && (
        <PopOver className="popover" $position={position}>
          {items.map((item, index) => {
            const dividerItem = item as DropdownDividerItem;

            if (dividerItem.divider) {
              if (dividerItem.label) {
                return (
                  <DividerWithLabel
                    key={`divider-${index}-${dividerItem.label}`}
                  >
                    {item.label}
                    <Divider />
                  </DividerWithLabel>
                );
              }
              return <Divider key={`divider-${index}`} />;
            }

            const { label, color, Icon, value } = item as DropdownValueItem;
            return (
              <Item
                key={value}
                className="popover-item"
                onClick={(e) => {
                  (item as DropdownValueItem).onClick?.();
                  onItemClick?.(value);
                  e?.stopPropagation();
                }}
                $color={color}
              >
                {Icon && <Icon size={15} color={color ?? theme.primaryColor} />}
                {label}
              </Item>
            );
          })}
        </PopOver>
      )}
    </Wrapper>
  );
};
