import { ApolloError } from "@apollo/client";
/**
 * returns parsed error messages for a given Apollo Error
 * @param error
 *
 */
export const extractErrorMessage = (error: ApolloError): string | undefined => {
  if (error.graphQLErrors.length) {
    return error.graphQLErrors.reduce(
      (errMsg, { message }) =>
        errMsg.length ? `${errMsg}\n${message}` : message,
      ""
    );
  }

  // if (error.networkError) {
  //   if ((error.networkError as ServerError).result) {
  //     return (error.networkError as ServerError)?.result?.errors?.reduce(
  //       (errMsg: string, { message }: { message: string }) =>
  //         errMsg.length ? `${errMsg}\n${message}` : message,
  //       ""
  //     );
  //   }
  //   return error.networkError.message;
  // }
};
