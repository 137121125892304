import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  AccordionItem,
  Button,
  ButtonGroup,
  ButtonIcon,
  Icons,
  SpinnerDark,
} from "@ampeersenergy/ampeers-ui-components";

import { LocalAsset, LocalDistrict } from "../../components/types";
import {
  Building,
  DistrictStateItem,
  useCheckDistrictStateLazyQuery,
  useGenerateForecastsMutation,
  useUpdateDistrictStateMutation,
} from "../../graphql/sdks/controller";

import { Column } from "../../components/style";
import {
  Accordion,
  AccordionSubtitle,
  AlertInfo,
  AlertRetryable,
  ColumnFullHeight,
  RowFullHeight,
  StateNotCheckedAlert,
  Wrapper,
} from "./style";

import { stateHints, steps } from "./utils";
import { extractErrorMessage } from "../../helpers/error.utils";
import { DistrictStateItems } from "./district-state-items";
import { DistrictStateStepper } from "./district-state-stepper";
import { AccordionItemLabel } from "./accordion-item-label";
import { ExtendedDistrictStateItem } from "./types";

const ForecastButtonGroup = styled(ButtonGroup)`
  justify-content: end;
`;

const ForecastButton = styled(ButtonIcon)`
  margin-right: 4px;
  padding: 4px;
`;

export interface DistrictStateProps {
  district: LocalDistrict;
  assets: LocalAsset[];
  buildings: Building[];
  loading?: boolean;
}

export const DistrictState: React.FC<DistrictStateProps> = ({
  district,
  assets,
  buildings,
  loading,
}) => {
  const [
    checkDistrictState,
    { error: districtStateCheckError, loading: districtStateCheckLoading },
  ] = useCheckDistrictStateLazyQuery();
  const [
    updateDistrictState,
    { error: districtStateUpdateError, loading: districtStateUpdateLoading },
  ] = useUpdateDistrictStateMutation();
  const [generateForecasts, { error: generateForecastsError }] =
    useGenerateForecastsMutation();

  const [checkedStates, setCheckedStates] = useState<
    Record<string, ExtendedDistrictStateItem[]>
  >({});

  const [currentIndex, setCurrentIndex] = useState(
    steps.findIndex((s) => s === district.state) ?? 0
  );

  const checkState = useCallback(() => {
    checkDistrictState({
      fetchPolicy: "cache-and-network",
      variables: {
        districtIdentifier: {
          company: district.companyKey,
          district: district.key,
        },
        state: steps[currentIndex],
      },
    }).then((res) => {
      if (res.data?.checkDistrictState) {
        setCheckedStates((states) => ({
          ...states,
          [steps[currentIndex]]: res.data!.checkDistrictState,
        }));
      }
    });
  }, [district, checkDistrictState, currentIndex]);

  const updateState = useCallback(() => {
    updateDistrictState({
      variables: {
        state: steps[currentIndex],
      },
    });
  }, [updateDistrictState, currentIndex]);

  useEffect(() => {
    if (loading || !district) {
      return;
    }

    setCurrentIndex(
      (_currentIndex) =>
        steps.findIndex((s) => s === district.state) ?? _currentIndex
    );

    checkState();
    // run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, district]);

  const onGenerateForecasts = useCallback(async () => {
    await generateForecasts();
  }, [generateForecasts]);

  const itemsBySection: Record<string, DistrictStateItem[]> = useMemo(() => {
    return (checkedStates[steps[currentIndex]] ?? []).reduce(
      (acc, item) => {
        let relatedEntity:
          | ExtendedDistrictStateItem["relatedEntity"]
          | undefined;

        if (item.type === "asset") {
          const building: Building | undefined = buildings.find((b) =>
            b.assets.some((a) => a.id === item.reference)
          );

          if (building) {
            relatedEntity = {
              ...building,
              type: "building",
            };
          }
        }

        return {
          ...acc,
          [item.type]: [
            ...(acc[item.type as keyof typeof acc] ?? []),
            { ...item, relatedEntity },
          ],
        };
      },
      {
        district: [],
        asset: [],
        contract: [],
        timeseries: [],
      }
    );
  }, [checkedStates, currentIndex, buildings]);

  const stateChecked = checkedStates[steps[currentIndex]];

  const hints = stateHints[steps[currentIndex]];

  return (
    <Wrapper>
      {districtStateCheckError ||
      districtStateUpdateError ||
      generateForecastsError ? (
        <AlertRetryable
          message={extractErrorMessage(
            (districtStateCheckError ||
              districtStateUpdateError ||
              generateForecastsError)!
          )}
        />
      ) : null}

      {loading ? (
        <SpinnerDark size={45} />
      ) : (
        <RowFullHeight>
          <Column flex={0}>
            <DistrictStateStepper
              activeState={district.state}
              currentIndex={currentIndex}
              onStepClick={setCurrentIndex}
              checkedStates={Object.keys(checkedStates)}
              erroredStates={Object.keys(checkedStates).filter(
                (key) => checkedStates[key]?.length
              )}
              hasError={!!checkedStates[steps[currentIndex]]?.length}
            />
          </Column>
          <ColumnFullHeight>
            <div>
              {["display", "monitor", "operative"].includes(
                steps[currentIndex]
              ) && (
                <ForecastButtonGroup>
                  <ForecastButton
                    icon={Icons.Sparks}
                    key="generate-forecasts"
                    secondary
                    onClick={onGenerateForecasts}
                  >
                    Generate Forecasts
                  </ForecastButton>
                </ForecastButtonGroup>
              )}
              {!stateChecked ? (
                <StateNotCheckedAlert>
                  State noch nicht validiert
                </StateNotCheckedAlert>
              ) : (
                <div>
                  {hints && (
                    <AlertInfo title="Hinweis">
                      {hints.length === 1 ? (
                        hints[0]
                      ) : (
                        <ul>
                          {hints.map((hint) => (
                            <li>{hint}</li>
                          ))}
                        </ul>
                      )}
                    </AlertInfo>
                  )}
                  <Accordion
                    withPadding={false}
                    LabelComponent={AccordionSubtitle}
                  >
                    <AccordionItem
                      label={
                        <AccordionItemLabel
                          hasError={!!itemsBySection.district.length}
                        >
                          Quartier
                        </AccordionItemLabel>
                      }
                    >
                      <DistrictStateItems items={itemsBySection.district} />
                    </AccordionItem>
                    <AccordionItem
                      label={
                        <AccordionItemLabel
                          hasError={!!itemsBySection.asset.length}
                        >
                          Anlagen
                        </AccordionItemLabel>
                      }
                    >
                      <DistrictStateItems items={itemsBySection.asset} />
                    </AccordionItem>
                    <AccordionItem
                      label={
                        <AccordionItemLabel
                          hasError={!!itemsBySection.contract.length}
                        >
                          Verträge
                        </AccordionItemLabel>
                      }
                    >
                      <DistrictStateItems items={itemsBySection.contract} />
                    </AccordionItem>
                    <AccordionItem
                      label={
                        <AccordionItemLabel
                          hasError={!!itemsBySection.timeseries.length}
                        >
                          Zeitreihen
                        </AccordionItemLabel>
                      }
                    >
                      <DistrictStateItems items={itemsBySection.timeseries} />
                    </AccordionItem>
                  </Accordion>
                </div>
              )}
            </div>
            <ButtonGroup expand vertical gap="4px">
              <Button
                secondary
                expand
                isLoading={
                  districtStateCheckLoading || districtStateUpdateLoading
                }
                onClick={checkState}
              >
                State validieren
              </Button>
              <Button
                expand
                isLoading={districtStateUpdateLoading}
                disabled={
                  !stateChecked || district.state === steps[currentIndex]
                }
                onClick={updateState}
              >
                State auf {steps[currentIndex]} setzen
              </Button>
            </ButtonGroup>
          </ColumnFullHeight>
        </RowFullHeight>
      )}
    </Wrapper>
  );
};
