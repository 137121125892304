/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from "react";
import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  Background,
  Elements,
  Connection,
  Edge,
  ReactFlowProvider,
} from "react-flow-renderer";

import { Building, District } from "../../graphql/sdks/controller";

import { COLORS, getLayoutedElements } from "./utils";
import { ElementType, Entity } from "./types";
import { LocalAsset, LocalContract } from "../types";

import { Labels } from "../style";
import { FlowWrapper } from "./style";
import FloatingEdge from "../floating-edge";
import FloatingConnectionLine from "../floating-connection-line";
import { Tile } from "./tile";
import { Label } from "../label";
import { groupAssetsByGroup } from "../../helpers/asset.utils";

export interface GraphViewProps {
  district: District;
  openId?: string;
  setOpenId: (value?: string | null) => void;
  onElementClick: (e: any, node: any) => void;
  openAssetForm?: () => void;
  openBuildingForm?: () => void;
  openContractForm?: () => void;
  setSelectedBuilding?: (id: string) => void;
}

export const GraphView: React.FC<GraphViewProps> = ({
  district,
  openId,
  setOpenId,
  onElementClick,
  openAssetForm,
  openBuildingForm,
  openContractForm,
  setSelectedBuilding,
}) => {
  const [elements, setElements] = React.useState<Elements>([]);

  const popOverElements = useMemo(
    () => [
      {
        label: "Asset",
        onClick: () => {
          setOpenId(null);
          openAssetForm?.();
        },
        displayOn: ["district", "building"],
      },
      {
        label: "Building",
        onClick: () => {
          openBuildingForm?.();
          setOpenId(null);
        },
        displayOn: ["district"],
      },
      {
        label: "Contract",
        onClick: () => {
          openContractForm?.();
          setOpenId(null);
        },
        displayOn: ["district", "asset"],
      },
    ],
    [openAssetForm, openBuildingForm]
  );

  const groupAndOrderAssets = useCallback(
    (
      assets: LocalAsset[]
    ): (LocalAsset & {
      index: number;
      groupSize: number;
      maxGroupWidth: number;
    })[] => {
      let allAssets: (LocalAsset & {
        index: number;
        groupSize: number;
        maxGroupWidth: number;
      })[] = [];

      const groups = groupAssetsByGroup(assets);

      const maxWidth = Math.max(...assets.map((a) => a.name.length), 0);

      groups.forEach((assetsInGroup, group) => {
        const maxGroupWidth = Math.max(
          ...assetsInGroup.map((a) => a.name.length)
        );
        allAssets = allAssets.concat(
          assetsInGroup
            .concat()
            .sort((a1, a2) => a1.name.localeCompare(a2.name))
            .map((a, index) => ({
              ...a,
              index,
              groupSize: assetsInGroup.length,
              maxGroupWidth: maxGroupWidth ?? maxWidth,
            }))
        );
      });

      return allAssets;
    },
    []
  );

  const orderContracts = useCallback(
    (
      contracts: LocalContract[]
    ): (LocalContract & { maxGroupWidth: number })[] => {
      const maxWidth = Math.max(
        ...contracts.map((c) => (c.name || c.key).length),
        0
      );
      return contracts.map((contract) => ({
        ...contract,
        maxGroupWidth: maxWidth,
      }));
    },
    []
  );

  const orderBuildings = useCallback(
    (
      buildings: Building[]
    ): (Building & { index: number; maxGroupHeight: number })[] => {
      return buildings.map((b, index) => {
        const groups = groupAssetsByGroup(b.assets);

        const maxGroupHeight = Math.max(
          ...new Array(groups).map((g) => g.size)
        );
        return { ...b, index, maxGroupHeight };
      });
    },
    []
  );

  const getElements = useCallback(
    <T extends object>(
        parentId: string,
        config: {
          withEdge?: boolean;
          withLabel?: boolean;
          style?: {};
          iconSize?: number;
        } = { withEdge: true, withLabel: true, style: {}, iconSize: undefined }
      ) =>
      ({
        id,
        name,
        key,
        elementType,
        template,
        isVirtual,
        index = 0,
        groupSize = 0,
        maxGroupWidth,
        maxGroupHeight,
      }: Entity<T>): Elements =>
        [
          {
            id,
            style: { ...config.style },
            data: {
              key,
              elementType,
              name,
              parentId,
              template,
              index,
              groupSize,
              maxGroupWidth,
              maxGroupHeight,
              label: (
                <Tile
                  elementKey={key}
                  type={template}
                  name={name || key}
                  {...(config.withLabel ? { label: key } : {})}
                  color={COLORS[elementType]}
                  elementType={elementType}
                  open={id === openId}
                  setOpen={(id) => {
                    if (
                      setSelectedBuilding &&
                      elementType === ElementType.Building
                    ) {
                      setSelectedBuilding(key);
                    }
                    setOpenId(id);
                  }}
                  id={id}
                  popOverElements={popOverElements}
                  iconSize={config.iconSize}
                  isVirtual={isVirtual}
                />
              ),
            },
            position: { x: 0, y: 0 },
          },
          ...(config.withEdge
            ? [
                {
                  id: `${parentId}->${id}`,
                  type: "floating",
                  source: parentId,
                  target: id,
                },
              ]
            : []),
        ],
    [openId, setOpenId]
  );

  const getAssetElements = useCallback(
    (parentId: string) =>
      ({
        id,
        key,
        name,
        template,
        contracts,
        flags,
        index,
        groupSize,
        maxGroupWidth,
      }: LocalAsset & {
        index: number;
        groupSize: number;
        maxGroupWidth: number;
      }): Elements =>
        [
          ...getElements(parentId, { withLabel: false, withEdge: true })({
            id,
            key,
            name,
            elementType: ElementType.Asset,
            template,
            isVirtual: !flags.isOnsite,
            index,
            groupSize,
            maxGroupWidth,
          }),
          ...orderContracts(contracts).flatMap((e, index) =>
            getElements(id, { withEdge: true, withLabel: false })({
              ...e,
              index,
              elementType: ElementType.Contract,
            })
          ),
        ],
    [openId]
  );

  const getBuildingElements = useCallback(
    (parentId: string) =>
      (
        {
          id,
          key,
          name,
          template,
          assets,
          maxGroupHeight,
        }: Building & { maxGroupHeight: number },
        index: number
      ): Elements =>
        [
          ...getElements(parentId, { withLabel: true, withEdge: false })({
            id,
            key,
            name,
            elementType: ElementType.Building,
            template,
            index,
            maxGroupHeight,
          }),
          ...groupAndOrderAssets(assets).flatMap(getAssetElements(id)),
        ],
    [openId]
  );

  const getDistrictElements = useCallback(
    ({ id, key, name, buildings, assets }: District): Elements => [
      ...getElements(id, {
        withEdge: true,
        withLabel: true,
        style: { fontSize: "28px" },
        iconSize: 45,
      })({
        id,
        key,
        name,
        elementType: ElementType.District,
        index: 0,
      }),
      ...orderBuildings(buildings).flatMap(getBuildingElements(id)),
      ...groupAndOrderAssets(assets).flatMap(getAssetElements(id)),
    ],
    [openId]
  );

  useEffect(() => {
    if (district) {
      Promise.resolve(district)
        .then(getDistrictElements)
        .then((elements) =>
          getLayoutedElements(
            elements,
            district.buildings.length > 5 ? "layered" : "stress"
          )
        )
        .then(setElements);
    }
  }, [district, openId]);

  const onLoad = useCallback((reactFlowInstance: any) => {
    setTimeout(() => reactFlowInstance.fitView(), 1000);
  }, []);

  const onElementsRemove = useCallback(
    (elementsToRemove: Elements<any>) =>
      setElements((els) => removeElements(elementsToRemove, els)),
    []
  );

  const onConnect = useCallback(
    (params: Edge<any> | Connection) =>
      setElements((els) => addEdge(params, els)),
    []
  );

  return (
    <FlowWrapper>
      <ReactFlowProvider>
        <ReactFlow
          elements={elements}
          edgeTypes={{
            floating: FloatingEdge,
          }}
          connectionLineComponent={FloatingConnectionLine}
          onElementsRemove={onElementsRemove}
          onConnect={onConnect}
          onElementClick={onElementClick}
          onLoad={onLoad}
          snapToGrid={true}
          snapGrid={[15, 15]}
          onPaneClick={() => setOpenId(null)}
        >
          <MiniMap
            nodeStrokeColor={(n) => {
              if (n.style?.background) return String(n.style.background);
              if (n.type === "input") return "#0041d0";
              if (n.type === "output") return "#ff0072";
              if (n.type === "default") return "#1a192b";
              return "#eee";
            }}
            nodeColor={(n) => {
              if (n.style?.background) return String(n.style.background);
              return "#fff";
            }}
          />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </ReactFlowProvider>
      <Labels>
        <Label color={COLORS.district} label="Quartier" />
        <Label color={COLORS.asset} label="Anlage" />
        <Label color={COLORS.virtual} label="Virtuelle Anlage" />
        <Label color={COLORS.building} label="Gebäude" />
        <Label color={COLORS.contract} label="Vertrag" />
      </Labels>
    </FlowWrapper>
  );
};
