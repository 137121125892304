import { LocalTimeseries } from "../components/types";
import { DistrictFormValues, LocalDistrict } from "../components/types";
import { Address, MetadataTemplate } from "../graphql/sdks/controller";
import { mergePropertyTemplates } from "./properties.utils";

export function formatInitialDistrictFormValues(
  initialValues?: Partial<LocalDistrict>,
  propertyTemplates?: DistrictFormValues["propertyTemplates"],
  timeseries?: LocalTimeseries[]
): DistrictFormValues {
  const newProps = mergePropertyTemplates(
    initialValues?.properties ?? [],
    timeseries ?? [],
    propertyTemplates ?? [],
    {
      isMonitoringRelevant: true,
      isOptimizationRelevant: true,
      isOnsite: false,
    }
  );

  return {
    id: "",
    key: "",
    companyKey: "",
    name: "",
    state: "",
    address: {} as Address,
    buildings: [],
    assets: [],
    notes: [],
    ...initialValues,
    contact: {
      alertingEmails:
        initialValues?.contact?.alertingEmails.map((email, index) => ({
          key: `alerting-email-${index}`,
          value: email,
        })) ?? [],
      reportingEmails:
        initialValues?.contact?.reportingEmails.map((email, index) => ({
          key: `reporting-email-${index}`,
          value: email,
        })) ?? [],
    },
    metadata: (initialValues?.metadata ?? []) as (MetadataTemplate & {
      value?: string | boolean;
    })[],
    ...newProps,
    properties: newProps.properties.map((p) => ({
      ...p,
      value:
        p.dataType === "scalarList"
          ? p.value.map((value: number | string, index: number) => ({
              key: index,
              value,
            }))
          : p.dataType === "scalarTimeDependent"
          ? (p.value ?? []).map(
              (
                value: { value: number; start: string; end: string },
                index: number
              ) => ({
                key: index,
                ...value,
                value: value.value && `${value.value}`,
              })
            )
          : p.value,
    })),
  };
}
