import React, { ChangeEvent, useCallback, useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { AlertInfo, FormikInput } from "@ampeersenergy/ampeers-ui-components";

import {
  ContractTemplateListItem,
  GetContractTemplateQueryResult,
  GetContractTemplateQueryVariables,
} from "../../graphql/sdks/controller";

import { ContractFormValues, ExtendedContract } from "../types";
import { FlexRawSelect, Input, Row, Spinner } from "../style";
import {
  ContractType,
  getContractKeyAndName,
} from "../../helpers/contract.utils";
import { mergePropertyTemplates } from "../../helpers/properties.utils";
import { MultistepFormContext } from "../multistep-form/context";

export interface ContractFormTemplateProps {
  isEditing?: boolean;
  templates: ContractTemplateListItem[];
  contracts: ExtendedContract[];
  existingContract: boolean;
  loadingTemplate: boolean;
  getContractTemplate: (
    variables: GetContractTemplateQueryVariables
  ) => Promise<Pick<GetContractTemplateQueryResult, "data" | "error">>;
}

export const ContractFormTemplate: React.FC<ContractFormTemplateProps> = ({
  isEditing,
  templates,
  contracts,
  loadingTemplate,
  existingContract,
  getContractTemplate,
}) => {
  const { values, errors, setValues } = useFormikContext<ContractFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    if (registerField) {
      registerField("template");
      registerField("name");
      registerField("key");
      registerField("description");
    }

    return () => {
      if (unregisterField) {
        unregisterField("template");
        unregisterField("name");
        unregisterField("key");
        unregisterField("description");
      }
    };
  }, [registerField, unregisterField]);

  const onContractTemplateChange = useCallback(
    async (e: ChangeEvent<{ value: string }>) => {
      try {
        const { data, error } = await getContractTemplate({
          templateKey: e.target.value,
        });

        if (error) {
          throw error;
        }

        const template = data?.contractTemplate;

        if (!template) {
          throw new Error(`Cannot load template: ${e.target.value}`);
        }

        setValues({
          ...values,
          ...getContractKeyAndName(
            template.template as ContractType,
            contracts ?? []
          ),
          template: template.template,
          templateDescription: template.description || "",
          ...mergePropertyTemplates(
            values.properties,
            [],
            template.properties ?? [],
            {
              isMonitoringRelevant: true,
              isOptimizationRelevant: true,
              isOnsite: false,
            },
            values.key
          ),
        });
      } catch (error) {
        console.error(error);
      }
    },
    [values, contracts, setValues, getContractTemplate]
  );

  return (
    <>
      <Row>
        <FlexRawSelect
          id="template"
          name="template"
          label="Template *"
          value={values.template || ""}
          errorMsg={errors.template}
          isEditing={isEditing}
          onChange={onContractTemplateChange}
          disabled={existingContract}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          {templates.map(({ template }) => (
            <option key={template} value={template}>
              {template}
            </option>
          ))}
        </FlexRawSelect>
        {loadingTemplate && <Spinner size={25} />}
      </Row>

      {values.templateDescription && (
        <AlertInfo title="Beschreibung">{values.templateDescription}</AlertInfo>
      )}

      <Row>
        <Input
          name="key"
          label="Key *"
          placeholder="Key"
          required
          hint="Einzigartiger Identifikator, der den Vertrag intern indexiert. Format: contractKey01 (Camel Case, nur Zahlen und Buchstaben, keine Umlaute oder Sonderzeichen wie ß, - oder _)."
          isEditing={isEditing}
          disabled={existingContract}
        />
        <Input
          name="name"
          label="Name"
          placeholder="Name"
          isEditing={isEditing}
          hint="Name, der in der District Management App angezeigt wird"
        />
      </Row>

      <FormikInput
        name="description"
        label="Beschreibung"
        placeholder="Beschreibung"
        isEditing={isEditing}
      />
    </>
  );
};
