import React, {
  ChangeEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
} from "react";
import { useFormikContext } from "formik";
import styled, { useTheme } from "styled-components";
import { DateTime } from "luxon";
import {
  ErrorAlert,
  DynamicListItemProps,
  Select as RawSelect,
  FormikInput,
  Button,
  Theme,
  Label,
} from "@ampeersenergy/ampeers-ui-components";

import { PropertyTemplate } from "../../graphql/sdks/controller";

import { AssetFormValues, PropertyFormValue } from "../types";

import { UnitsForm } from "../units-form";
import { InlineCheckbox, Input, InputWithOverflow, Select } from "../style";
import {
  ButtonGroupPullRight,
  Checkbox,
  InlineItemsWrapper,
  ListWithLabel,
  PropertyEditComponentWrapper,
} from "./style";
import {
  isPropertyRequired,
  isValidPropValue,
} from "../../helpers/properties.utils";
import { ValuesList } from "./values-list";
import { findValue } from "../../helpers/object.utils";
import { DatePicker } from "../date-picker";
import { TimeDependentValuesList } from "./time-dependent-value-list";

const InputWrapper = styled.div<{ flex?: number }>`
  flex: ${(props) => props.flex ?? 1};
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 20px;
`;

const InputHint = styled.span`
  color: #5d5d5d;
  font-size: 12px;
  margin-top: 5px;
`;

export interface PropertyEditFormProps {
  item: DynamicListItemProps["item"] & PropertyFormValue;
  index: number;
  isEditing?: boolean;
  propertyTemplates: PropertyTemplate[];
  unitKeys: (string | null | undefined)[];
  prefixIdentifier?: string;
  canDuplicate?: boolean;
  setEditMode: (value: boolean) => void;
  onDelete: () => void;
  onDuplicate: MouseEventHandler;
}

export const PropertyEditForm: React.FC<PropertyEditFormProps> = (props) => {
  const {
    item,
    index,
    isEditing,
    propertyTemplates,
    unitKeys,
    prefixIdentifier,
    canDuplicate,
    setEditMode,
    onDelete,
    onDuplicate,
  } = props;
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const theme = useTheme() as Theme;
  const { values, setFieldValue } = useFormikContext<AssetFormValues>();

  const items = useMemo(
    () =>
      prefixIdentifier
        ? findValue<AssetFormValues>(prefixIdentifier, values).properties
        : values.properties,
    [prefixIdentifier, values]
  );

  const onPropertyTemplateChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      const template = propertyTemplates.find(
        ({ key }) => key === e.target.value
      );

      if (template)
        setFieldValue(`${_prefixIdentifier}properties[${index}]`, {
          ...template,
          key: template.key,
          propKey: template.key,
          value: item.value,
          unit: template.unit,
        });
    },
    [index, propertyTemplates, item, _prefixIdentifier, setFieldValue]
  );

  const propertyExists = useCallback(
    (propKey: string): boolean => {
      return items.some((p) => p.key === propKey);
    },
    [items]
  );

  return (
    <PropertyEditComponentWrapper $borderColor={theme.primaryColor}>
      {item.isRequired && !isValidPropValue(item.value) && (
        <ErrorAlert>
          Diese Property ist erforderlich, hat aber keinen Wert.
        </ErrorAlert>
      )}
      {isEditing && (
        <RawSelect
          id={`${_prefixIdentifier}properties[${index}].key`}
          name={`${_prefixIdentifier}properties[${index}].key`}
          label="Template"
          onChange={onPropertyTemplateChange}
          value={item.key}
          hint={item.description || "Keine Beschreibung vorhanden"}
        >
          <option value={"null"} key="null">
            --
          </option>
          {propertyTemplates
            ?.filter((p) => p.dataType === "Param")
            .map(({ key, flags }) => (
              <option key={key} value={key} disabled={propertyExists(key)}>
                {key}
                {isPropertyRequired(values.flags, flags) ? " *" : ""}
              </option>
            ))}
        </RawSelect>
      )}
      <InlineItemsWrapper>
        <InputWrapper flex={2}>
          <Input
            id={`${_prefixIdentifier}properties[${index}].propKey`}
            name={`${_prefixIdentifier}properties[${index}].propKey`}
            label="Key *"
            isEditing={isEditing}
            hint={
              item.key !== item.propKey
                ? `Benutzerdefinierte Eigenschaften müssen mit "custom_" anfangen.`
                : ""
            }
          />
          {!isEditing && <InputHint>{item.description}</InputHint>}
        </InputWrapper>
        {item.dataType === "Param" &&
          (item.dataSubType === "boolean" ? (
            <InlineCheckbox
              $margin="10px"
              flex={1}
              id={`${_prefixIdentifier}properties[${index}].value`}
              key={`${_prefixIdentifier}properties[${index}].value`}
              label={`${item.value}`}
              checked={item.value === "true"}
              onChange={(v) =>
                setFieldValue(
                  `${_prefixIdentifier}properties[${index}].value`,
                  v ? "true" : "false"
                )
              }
              disabled={!isEditing}
            />
          ) : item.dataSubType === "date" ? (
            <DatePicker
              isEditing={isEditing}
              label="Value *"
              date={values.properties[index].value}
              onDateChange={(v) =>
                setFieldValue(
                  `${_prefixIdentifier}properties[${index}].value`,
                  DateTime.fromJSDate(v).toISODate()
                )
              }
            />
          ) : (
            <InputWithOverflow
              flex={1}
              id={`${_prefixIdentifier}properties[${index}].value`}
              name={`${_prefixIdentifier}properties[${index}].value`}
              label="Value *"
              isEditing={isEditing}
              hint={
                item.dataSubType === "float"
                  ? "Zahlen Format: 1234.1234"
                  : item.dataSubType === "integer"
                  ? "Zahlen Format: 1234"
                  : ""
              }
            />
          ))}
        <UnitsForm
          flex={1}
          identifier={`${_prefixIdentifier}properties[${index}].unit`}
          isEditing={isEditing}
          units={unitKeys}
          label="Internal Unit"
          disabled={
            !unitKeys.length || (item.unit !== null && unitKeys.length < 2)
          }
        />
        <Select
          id={`${_prefixIdentifier}properties[${index}].dataSubType`}
          name={`${_prefixIdentifier}properties[${index}].dataSubType`}
          label="Data sub type *"
          isEditing={isEditing}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          {item.dataType !== "scalarList" && (
            <option value="boolean">Boolean</option>
          )}
          <option value="date">Datum</option>
          <option value="float">Float</option>
          <option value="integer">Integer</option>
          <option value="string">String</option>
        </Select>
      </InlineItemsWrapper>
      {(item.isPlaceholder || item.propKey.includes("custom_")) && (
        <FormikInput
          id={`${_prefixIdentifier}properties[${index}].description`}
          name={`${_prefixIdentifier}properties[${index}].description`}
          label="Beschreibung *"
          isEditing={isEditing}
        />
      )}
      {item.dataType === "scalarTimeDependent" && (
        <ListWithLabel>
          <Label>Values *</Label>
          <TimeDependentValuesList isEditing={isEditing} index={index} />
        </ListWithLabel>
      )}
      {item.dataType === "scalarList" && (
        <ListWithLabel>
          <Label>Values *</Label>
          <ValuesList index={index} isEditing={isEditing} />
        </ListWithLabel>
      )}
      <Checkbox
        $noPadding={isEditing}
        id={`${_prefixIdentifier}properties[${index}].internal`}
        key={`${_prefixIdentifier}properties[${index}].internal`}
        label={`Property wird ${
          !item.internal ? "" : "nicht "
        }in der District Management App angezeigt.`}
        checked={!item.internal}
        onChange={(v) =>
          setFieldValue(`${_prefixIdentifier}properties[${index}].internal`, !v)
        }
        disabled={!isEditing}
      />
      <ButtonGroupPullRight>
        {isEditing && !!canDuplicate && (
          <Button secondary onClick={onDuplicate}>
            Duplizieren
          </Button>
        )}
        {isEditing && !item.isRequired && (
          <Button
            secondary
            customTheme={{
              primaryColor: theme.palette.error.color,
              secondaryColor: theme.palette.error.background,
            }}
            onClick={onDelete}
          >
            Löschen
          </Button>
        )}
        <Button secondary onClick={() => setEditMode(false)}>
          Schliessen
        </Button>
      </ButtonGroupPullRight>
    </PropertyEditComponentWrapper>
  );
};
