export const ProtocolOptions = () => (
  <>
    <option key="null" value={"null"}>
      (null)
    </option>
    <option key="se-enterprise-server" value="se-enterprise-server">
      SE Enterprise Server
    </option>
    <option key="se-connector-box" value="se-connector-box">
      SE ConnectorBox
    </option>
    <option key="vcom" value="vcom">
      VCOM (MeteoControl)
    </option>
    <option key="wattline" value="wattline">
      Wattline
    </option>
    <option key="ocpi" value="ocpi">
      OCPI
    </option>
    <option key="discovergy" value="discovergy">
      Discovergy
    </option>
  </>
);
