export function replaceAt<T>(
  array: Array<T>,
  item: T,
  matcher: keyof T | Function
) {
  const index = array.findIndex(
    matcher instanceof Function
      ? (matcher as any)
      : (i: T) => i[matcher] === item[matcher]
  );

  array.splice(index, 1, item);
  return array;
}
/**
 * converts an array to a hashmap
 * @param array
 * @param accessor
 * @param value
 */
export function arrayToObject<
  T extends object,
  K extends keyof T,
  R extends T | T[K],
>(
  array: Array<T>,
  accessor: string | number | ((object: T) => string),
  value?: string,
  overwrite: boolean = true
) {
  return array.reduce((obj: Record<string, R>, item: T) => {
    const indexer = ["string", "number"].includes(typeof accessor)
      ? item[accessor as keyof typeof item]
      : (accessor as Function)(item);

    if (!overwrite && obj[indexer]) {
      return obj;
    }

    return {
      ...obj,
      [indexer]: value ? item[value as keyof typeof item] : item,
    };
  }, {});
}

export function addToArray<T>(array: Array<T>, value: T, accessor?: string) {
  const existsIndex = array.findIndex(
    (item) =>
      item === value ||
      (accessor &&
        (item as unknown as Record<string, T>)[accessor] ===
          (value as unknown as Record<string, T>)[accessor])
  );
  if (existsIndex !== -1) {
    array.splice(existsIndex, 1, value);
    return array;
  }

  return array.concat(value);
}

export function removeFromArray(
  array: Array<any>,
  value: string | Record<string, any>,
  accessor?: string
) {
  const removeIndex = array.findIndex(
    (item) =>
      item === value ||
      (accessor && item[accessor] === (value as Record<string, any>)[accessor])
  );

  if (removeIndex !== -1) {
    array.splice(removeIndex, 1);
    return array;
  }

  return array;
}

export function addOrRemoveFromArray(
  array: Array<any>,
  value: string | Record<string, any>,
  accessor?: string
) {
  const removeIndex = array.findIndex(
    (item) =>
      item === value ||
      (accessor && item[accessor] === (value as Record<string, any>)[accessor])
  );

  if (removeIndex !== -1) {
    array.splice(removeIndex, 1);
    return array;
  }

  return array.concat(value);
}
