import { useMemo } from "react";

import { isValidPropValue } from "../helpers/properties.utils";
import { PropertyFormValue } from "../components/types";

export const usePropertyFormValidation = (properties: PropertyFormValue[]) => {
  return useMemo(() => {
    return properties.reduce(
      (acc, p) => {
        return {
          hasErrors:
            acc.hasErrors || (p.isRequired && !isValidPropValue(p.value)),
          hasWarnings: acc.hasWarnings || !isValidPropValue(p.value),
        };
      },
      { hasErrors: false, hasWarnings: false }
    );
  }, [properties]);
};
