import React, { useMemo, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { DateTime, Duration } from "luxon";
import { TimeSeriesChart } from "@ampeersenergy/dm-chart-components";
import {
  DataEntry,
  extractErrorMessage,
  formatTimeRange,
  formatTime,
  formatDate,
  UiAsset,
  useReadTimeSeries,
  getSeriesData,
} from "@ampeersenergy/dm-core-lib";
import {
  AlertRetryable,
  DatePicker,
  SpinnerDark,
  WarningAlert as WarningAlertComponent,
} from "@ampeersenergy/ampeers-ui-components";
import { EmptyResult as EmptyResultComponent } from "./table";
import { ExtendedTimeseries } from "./types";
import { UiAssetChartType } from "../graphql/sdks/controller";
import { useTimeseriesChartFilters } from "../hooks";

const COLORS: string[] = ["#E656D9", "#FF9065", "#3AB4B5"];

const EmptyResult = styled(EmptyResultComponent)`
  border: 1px solid ${(props) => props.theme.palette.border};
`;

const WarningAlert = styled(WarningAlertComponent)`
  margin-bottom: 12px;
`;

const DateRangeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  label {
    text-transform: uppercase;
    user-select: none;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: ${(props) => props.theme.primaryColor};
  }
`;

const ChartWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 14px;
  background-color: ${(props) => props.theme.palette.background};
  border-radius: 4px;
`;

const SpinnerWithPadding = styled(SpinnerDark)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 64px 0;
`;

const generateTimeMarker = (resolution?: Duration, date = new Date()) => {
  if (resolution?.minutes) {
    const roundedMinutes =
      Math.floor(date.getMinutes() / resolution.minutes) * resolution.minutes;
    date.setMinutes(roundedMinutes);
  }

  return date;
};

export interface TimeseriesLineChartProps {
  timeseries: (ExtendedTimeseries & { sourceName?: string })[];
}

export const TimeseriesLineChart: React.FC<
  TimeseriesLineChartProps &
    Pick<ReturnType<typeof useTimeseriesChartFilters>, "chartFilter">
> = ({ timeseries, chartFilter }) => {
  const [{ startDate, endDate }, setDates] = useState({
    startDate: DateTime.local().startOf("day"),
    endDate: DateTime.local().startOf("day").plus({ days: 1 }),
  });

  const limitedTimeseries = useMemo(
    () => timeseries.slice(0, 25),
    [timeseries]
  );

  const uiAssetFromTimeseries = useMemo(() => {
    return limitedTimeseries.reduce<UiAsset>(
      (uiAsset, ts) => {
        return {
          ...uiAsset,
          series: [
            ...uiAsset.series,
            {
              id: ts.id,
              label: `${timeseries.length > 1 ? ts.sourceName : ""} ${
                ts.refTimeseriesProp[0]?.propKey ?? ""
              }`,
              unit: ts.unit,
              uuids: {
                ...(chartFilter.measurements
                  ? { measurements: [ts.uuid] }
                  : {}),
                ...(chartFilter.forecast ? { forecast: [ts.uuid] } : {}),
                ...(chartFilter.scheduled ? { scheduled: [ts.uuid] } : {}),
              },
            },
          ],
        };
      },
      {
        id: "placeholder",
        series: [],
        type: UiAssetChartType.LineChart,
      }
    );
  }, [timeseries.length, limitedTimeseries, chartFilter]);

  const { series, loading, error } = useReadTimeSeries({
    uiAsset: uiAssetFromTimeseries,
    startDate: startDate,
    endDate: endDate,
  });

  const visibleData = useRef<Record<string, boolean> | undefined>();

  const onVisibleDataChange = useCallback(
    (
      schema: {
        id: string;
        name: string;
        color: string;
        unit: string;
      }[]
    ) =>
      (d: Record<string, boolean>) => {
        const newVisibleData = Object.keys(d).reduce((acc, key) => {
          const seriesId = schema.find(({ name }) => name === key)?.id;
          if (!seriesId) return acc;

          return { ...acc, [seriesId]: d[key] };
        }, {});
        visibleData.current = newVisibleData;
      },
    []
  );

  const schema = useMemo(
    () =>
      series.map((s, index) => {
        const { label, unit, id } = s;
        return {
          id,
          name: label,
          color: COLORS[index % COLORS.length],
          unit: unit!,
        };
      }),
    [series]
  );

  const data = useMemo(() => {
    if (loading) return [];

    return series.map((s) => {
      // some ui assets have two series for measurements and forecast/scheduled
      // so we merge match and merge them here
      const measurementsSeries = series.find(
        (ms) =>
          ms.id !== s.id &&
          (s.uuids.scheduled || s.uuids.forecast)?.every((uuid) => {
            return ms.uuids.measurements?.includes(uuid);
          })
      );

      return getSeriesData(s.values, measurementsSeries?.values).map<DataEntry>(
        ([d, v]) => [d, v && parseFloat(v.toFixed(2))]
      );
    });
  }, [loading, series]);

  const chartMinValue = useMemo(
    () =>
      Math.min(
        0,
        ...data
          .flatMap((d) => d.map(([_, v]) => v))
          .filter((v): v is number => !!v)
      ),
    [data]
  );

  // check if all series have values before rendering the chart, otherwise the chart will be rendered empty
  const hasValues = useMemo(
    () =>
      !(
        !loading &&
        (!series ||
          !series.length ||
          series
            .flatMap((s) => Object.values(s.values))
            .filter((d): d is DataEntry[] => typeof d !== "number" && !!d)
            .every((d) => !d.length))
      ),
    [loading, series]
  );

  if (error) return <AlertRetryable message={extractErrorMessage(error)} />;

  if (loading) return <SpinnerWithPadding size={25} />;

  return (
    <ChartWrapper>
      {limitedTimeseries.length < timeseries.length && (
        <WarningAlert>
          Nur die ersten {limitedTimeseries.length} von {timeseries.length}{" "}
          Zeitreihen werden angezeigt.
        </WarningAlert>
      )}
      <DateRangeWrapper>
        <label>Von: </label>
        <DatePicker
          withTime
          date={startDate.toJSDate()}
          maxDate={endDate.minus({ days: 1 }).toJSDate()}
          onDateChange={(value) =>
            setDates({ startDate: DateTime.fromJSDate(value), endDate })
          }
        />
        <label> Bis: </label>
        <DatePicker
          withTime
          date={endDate.toJSDate()}
          minDate={startDate.plus({ days: 1 }).toJSDate()}
          onDateChange={(value) =>
            setDates({ endDate: DateTime.fromJSDate(value), startDate })
          }
        />
      </DateRangeWrapper>
      {hasValues ? (
        <TimeSeriesChart
          data={data}
          schema={schema}
          interactive
          showLegend="interactive"
          height={400}
          loading={<SpinnerWithPadding size={25} />}
          timeMarker={generateTimeMarker()}
          axisConfig={{
            x: {
              format: formatTimeRange,
              min: startDate.toJSDate(),
              max: endDate.toJSDate(),
            },
            y: {
              axisType: "linear",
              left: {
                showGrid: true,
                label: schema[0].unit,
                min: chartMinValue,
              },
              right: { showGrid: false, min: chartMinValue },
            },
          }}
          tooltipConfig={{
            snap: false,
            dateFormat: (d) => formatDate(d.toISOString()),
            timeFormat: (d) => formatTime(d.toISOString()),
          }}
          onVisibleDataChange={onVisibleDataChange(schema)}
        />
      ) : (
        <EmptyResult>Keine Daten vorhanden</EmptyResult>
      )}
    </ChartWrapper>
  );
};
