import React, { useCallback, useContext, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTheme } from "styled-components";
import {
  BaseIcon,
  DynamicListItemProps,
  Icons,
  Theme,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";

import { PropertyTemplate } from "../../graphql/sdks/controller";
import { AssetFormValues, LocalUnit, PropertyFormValue } from "../types";
import {
  DeleteButtonWithMargin,
  PropertyListItemContainer,
  PropertyListItemWrapper,
  SpacedTag,
} from "../properties-form/style";
import { NewItemWrapper, PlusIcon } from "../style";
import { findValue } from "../../helpers/object.utils";
import { isValidTensorValue } from "../../helpers/properties.utils";
import { TensorEditForm } from "./tensor-edit-form";
import { MultistepFormContext } from "../multistep-form/context";

export interface TensorListItemProps extends DynamicListItemProps {
  isEditing?: boolean;
  item: DynamicListItemProps["item"] & PropertyFormValue;
  units: LocalUnit[];
  prefixIdentifier?: string;
  propertyTemplates: PropertyTemplate[];
}

export const TensorListItem: React.FC<TensorListItemProps> = (props) => {
  const { item, index, isEditing, units, prefixIdentifier, propertyTemplates } =
    props;

  const { values } = useFormikContext<AssetFormValues>();
  const { unregisterField } = useContext(MultistepFormContext) ?? {};
  const theme = useTheme() as Theme;
  const {
    editMode,
    setEditMode,
    isValid,
    onAddItem,
    onDelete,

    Icon,
    type,
  } = useDynamicListItem({
    ...props,
  });
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const items = useMemo(
    () =>
      prefixIdentifier
        ? findValue<AssetFormValues>(prefixIdentifier, values).tensors
        : values.tensors,
    [prefixIdentifier, values]
  );

  const onPropDelete = useCallback(() => {
    const lastIndex = items.length - 1;
    if (unregisterField) {
      unregisterField(`${_prefixIdentifier}tensors[${lastIndex}].propKey`);
      unregisterField(`${_prefixIdentifier}tensors[${lastIndex}].value`);
      unregisterField(`${_prefixIdentifier}tensors[${lastIndex}].dataType`);
      unregisterField(`${_prefixIdentifier}tensors[${lastIndex}].dataSubType`);
      unregisterField(`${_prefixIdentifier}tensors[${lastIndex}].unit`);
      unregisterField(`${_prefixIdentifier}tensors[${lastIndex}].description`);
      unregisterField(`${_prefixIdentifier}tensors[${lastIndex}].comment`);
    }
    onDelete();
  }, [items, _prefixIdentifier, onDelete, unregisterField]);

  const unitKeys = useMemo(
    () =>
      !item.isPlaceholder
        ? item.unit
          ? [item.unit]
          : []
        : (units ?? []).map(({ key }) => key),
    [item, units]
  );

  const CurrentIcon: typeof BaseIcon | undefined = useMemo(() => {
    if (!isValid || !isValidTensorValue(item.value)) return Icons.Warning;
    return Icon;
  }, [isValid, Icon, item]);

  if (item.isNewValue) {
    return (
      <NewItemWrapper key={item.key} onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        {type} hinzufügen
      </NewItemWrapper>
    );
  }

  if (editMode) {
    return (
      <TensorEditForm
        item={item}
        index={index}
        isEditing={isEditing}
        prefixIdentifier={prefixIdentifier}
        propertyTemplates={propertyTemplates}
        unitKeys={unitKeys}
        units={units}
        setEditMode={setEditMode}
        onDelete={onPropDelete}
        isValid={isValid}
      />
    );
  }

  return (
    <PropertyListItemWrapper>
      <PropertyListItemContainer onClick={() => setEditMode(!editMode)}>
        {CurrentIcon && (
          <CurrentIcon
            className="icon"
            size={20}
            color={
              !isValid || !isValidTensorValue(item.value)
                ? item.isRequired
                  ? theme.palette.error.color
                  : theme.palette.warning.color
                : theme.primaryColor
            }
          />
        )}
        <span className="key">
          {item.propKey || item.key ? (
            <strong>
              {item.propKey || item.key}{" "}
              {values.flags?.isMonitoringRelevant &&
                item.flags?.isMonitoringRelevant && (
                  <SpacedTag color={theme.palette.warning.color}>mon</SpacedTag>
                )}
              {values.flags?.isOptimizationRelevant &&
                item.flags?.isOperationRelevant && (
                  <SpacedTag color={theme.palette.success.color}>op</SpacedTag>
                )}
            </strong>
          ) : (
            <span style={{ color: theme.palette.textSubtle }}>(key fehlt)</span>
          )}
        </span>
      </PropertyListItemContainer>
      {isEditing && (
        <DeleteButtonWithMargin
          secondary
          icon={Icons.Delete}
          onClick={onPropDelete}
          color={theme.palette.error.color}
        />
      )}
    </PropertyListItemWrapper>
  );
};
