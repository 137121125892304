import React, { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { DynamicList, Icons } from "@ampeersenergy/ampeers-ui-components";

import {
  PropertyTemplate,
  useGetInternalUnitsQuery,
} from "../../graphql/sdks/controller";

import { AssetFormValues, PropertyFormValue } from "../types";
import { ListContainer, Muted } from "../style";
import { propertyValidationSchema } from "../validation";

import { PropertyListItem } from "./property-list-item";

export interface PropertiesFormProps {
  isEditing?: boolean;
  prefixIdentifier?: string;
  items?: PropertyFormValue[];
  propertyTemplates?: PropertyTemplate[];
  canDuplicate?: boolean;
}

export const PropertiesForm: React.FC<PropertiesFormProps> = ({
  isEditing = true,
  prefixIdentifier,
  items,
  propertyTemplates,
  canDuplicate,
}) => {
  const { data } = useGetInternalUnitsQuery();
  const { values } = useFormikContext<AssetFormValues>();
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const _propertyTemplates = useMemo(
    () => propertyTemplates ?? values.propertyTemplates,
    [propertyTemplates, values]
  );
  const properties = useMemo(
    () => items ?? values.properties,
    [items, values.properties]
  );

  const onNewItem = useCallback((item) => {
    return { ...item, dataType: "Param", flags: {} };
  }, []);

  const PropertyListItemComponent = useCallback(
    (props) => {
      return (
        <PropertyListItem
          {...props}
          prefixIdentifier={prefixIdentifier}
          propertyTemplates={_propertyTemplates}
          isEditing={isEditing}
          units={data?.internalUnits ?? []}
          canDuplicate={canDuplicate}
        />
      );
    },
    [isEditing, data, prefixIdentifier, _propertyTemplates, canDuplicate]
  );

  const validateProperty = useCallback(
    (item) => propertyValidationSchema.isValidSync(item),
    []
  );

  if (!isEditing && !properties?.length) {
    return <Muted>Keine Properties vorhanden</Muted>;
  }

  return (
    <ListContainer>
      <DynamicList
        name={`${_prefixIdentifier}properties`}
        type="Property"
        items={properties}
        validateItem={validateProperty}
        ListItemComponent={PropertyListItemComponent}
        Icon={Icons.Settings}
        interactive={isEditing}
        onNewItem={onNewItem}
      />
    </ListContainer>
  );
};
