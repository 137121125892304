import { ErrorAlert } from "@ampeersenergy/ampeers-ui-components";
import React from "react";
import { MultistepFormStepProps } from "./step";
import { AlertLink } from "./style";

export interface MultistepFormValidationErrorProps {
  validationError: Record<string, string> | null;
  fieldsStepMap: Record<string, number>;
  steps: MultistepFormStepProps[];
  setCurrentIndex: (value: number) => void;
}

export const MultistepFormValidationError: React.FC<
  MultistepFormValidationErrorProps
> = ({ validationError, fieldsStepMap, steps, setCurrentIndex }) => {
  if (!validationError) return null;

  return (
    <>
      {Object.keys(validationError).map((error) => {
        const index = fieldsStepMap[error as keyof typeof fieldsStepMap];
        if (!index) return null;

        const stepLabel = steps[index]?.title;

        if (!stepLabel) return null;

        return (
          <ErrorAlert>
            Im Schritt{" "}
            <AlertLink
              role="button"
              tabIndex={0}
              onClick={() => setCurrentIndex(index)}
            >
              {stepLabel}
            </AlertLink>{" "}
            ist das Feld <strong>{error}</strong> fehlerhaft.
          </ErrorAlert>
        );
      })}
    </>
  );
};
