import { Icons } from "@ampeersenergy/ampeers-ui-components";
import React, { useCallback, useMemo } from "react";

import {
  AssetTemplate,
  GetAssetTemplatesQuery,
} from "../graphql/sdks/controller";
import {
  ExtendedAsset,
  LocalContract,
  LocalDistrict,
  TimeseriesTableData,
} from "./types";

import { addOrRemoveFromArray, removeFromArray } from "../helpers/array.utils";
import { useTimeseriesFilters } from "../hooks";

import { DropdownItem } from "./dropdown";
import { QuickFilter } from "./quick-filter";
import { AssetType, getAssetIcon } from "../helpers/asset.utils";

const filterAssetTemplates = (
  districtTemplates: string[],
  rowFilter: Record<string, string[]>,
  assetTemplates: AssetTemplate[]
) => {
  return assetTemplates
    .filter((template) => districtTemplates.includes(template.template))
    .filter((template) => template.template !== "weather")
    .map((assetTemplate) => ({
      value: assetTemplate.template,
      label: assetTemplate.name,
      checked: rowFilter.assetTemplate.includes(assetTemplate.template),
    }))
    .sort((a1, a2) => (a1?.label > a2?.label ? 1 : -1));
};

export interface TimeseriesFiltersProps
  extends Pick<
    ReturnType<typeof useTimeseriesFilters>,
    | "rowFilter"
    | "setRowFilter"
    | "setAllRowFilters"
    | "filterChanged"
    | "saveFilter"
    | "resetFilter"
    | "onSavedFilterChanged"
    | "savedFilters"
  > {
  loading: boolean;
  tableData: TimeseriesTableData[];
  assets: ExtendedAsset[];
  contracts: LocalContract[];
  district: LocalDistrict;
  assetTemplates?: GetAssetTemplatesQuery;
}

export const TimeseriesFilters: React.FC<TimeseriesFiltersProps> = ({
  rowFilter,
  setRowFilter,
  setAllRowFilters,
  loading,
  tableData,
  assets,
  contracts,
  district,
  assetTemplates,
}) => {
  const onMultiselectClick = useCallback(
    (
      rowFilterEntry: keyof typeof rowFilter,
      extraFilters: Record<string, string[]> = {}
    ) =>
      (item: string) => {
        if (item === "all") {
          setAllRowFilters({
            [rowFilterEntry]: [item],
            ...extraFilters,
          });
        } else {
          let newFilterArray = removeFromArray(
            rowFilter[rowFilterEntry],
            "all"
          );
          newFilterArray = addOrRemoveFromArray(newFilterArray, item);

          if (newFilterArray.length === 0) {
            newFilterArray = ["all"];
          }

          setAllRowFilters({
            [rowFilterEntry]: newFilterArray,
            ...extraFilters,
          });
        }
      },
    [rowFilter, setAllRowFilters]
  );

  const templateIcon = useMemo(() => {
    if (
      rowFilter.assetTemplate.length === 1 &&
      rowFilter.assetTemplate[0] !== "all"
    ) {
      return getAssetIcon(rowFilter.assetTemplate[0] as AssetType) ?? Icons.Box;
    }
    return Icons.Box;
  }, [rowFilter.assetTemplate]);

  const districtTemplates = useMemo(
    () => assets.reduce<string[]>((acc, asset) => [...acc, asset.template], []),
    [assets]
  );

  return (
    <>
      <QuickFilter
        icon={templateIcon}
        items={[
          {
            value: "all",
            label: "Alle Templates",
            checked: rowFilter.assetTemplate[0] === "all",
          },
          ...filterAssetTemplates(
            districtTemplates,
            rowFilter,
            assetTemplates?.assetTemplates ?? []
          ),
        ]}
        selectedFilter={rowFilter.assetTemplate}
        onItemClick={onMultiselectClick("assetTemplate", { asset: ["all"] })}
        hasItems={
          loading ||
          rowFilter.assetTemplate.includes("all") ||
          !!tableData.length
        }
        closeOnClick={false}
        multiselect
        genericLabel="Templates"
      />
      <QuickFilter
        icon={Icons.Buildings}
        items={
          [
            {
              value: "all",
              label: "Alle Assets",
              checked: rowFilter.asset[0] === "all",
            },
            { divider: true, label: "Quartier" },
            ...(rowFilter.assetTemplate.includes("all")
              ? [
                  {
                    value: district.key,
                    label: district.name,
                    checked: rowFilter.asset.includes(district.key),
                  },
                  { divider: true, label: "Verträge" },
                  ...contracts
                    .map(({ id, key, name }) => ({
                      value: id,
                      label: name || key,
                      checked: rowFilter.asset.includes(id),
                    }))
                    .sort((c1, c2) => (c1.label > c2.label ? 1 : -1)),
                ]
              : []),
            { divider: true, label: "Anlagen" },
            ...assets
              .filter(({ key }) => key !== "weather")
              .filter(
                ({ template }) =>
                  rowFilter.assetTemplate.includes("all") ||
                  rowFilter.assetTemplate.includes(template)
              )
              .map(({ id, name, buildingName }) => ({
                value: id,
                label: `${name}${buildingName ? ` (${buildingName})` : ""}`,
                checked: rowFilter.asset.includes(id),
              }))
              .sort((a1, a2) => a1.label.localeCompare(a2.label)),
            { divider: true, label: "Gebäuden" },
            ...district.buildings.map(({ key, name }) => ({
              value: key,
              label: name,
              checked: rowFilter.asset.includes(key),
            })),
          ] as DropdownItem[]
        }
        selectedFilter={rowFilter.asset}
        onItemClick={onMultiselectClick("asset")}
        closeOnClick={false}
        multiselect
        genericLabel="Anlagen"
      />
      <QuickFilter
        items={[
          { value: "all", label: "Alle Typen" },
          { divider: true },
          { value: "read", label: "Read" },
          { value: "write", label: "Read & Write" },
        ]}
        selectedFilter={rowFilter.flowType}
        onItemClick={(item) => setRowFilter("flowType", [item])}
        hasItems={
          loading || rowFilter.flowType.includes("all") || !!tableData.length
        }
      />
      <QuickFilter
        items={[
          { value: "all", label: "Alle Target/Quelltypen" },
          { divider: true },
          { value: "district", label: "District" },
          { value: "inference", label: "Inference" },
        ]}
        selectedFilter={rowFilter.sourceOrTargetType}
        onItemClick={(item) => {
          setAllRowFilters({
            sourceOrTargetType: [item],
            ...(item === "district" ? {} : { flowType: ["all"] }),
          });
        }}
        hasItems={
          loading ||
          rowFilter.sourceOrTargetType.includes("all") ||
          !!tableData.length
        }
      />
      <QuickFilter
        items={
          [
            { value: "all", label: "Alle Protokolle" },
            { divider: true },
            { value: null, label: "(leer)" },
            { value: "se-enterprise-server", label: "SE Enterprise Server" },
            { value: "se-connector-box", label: "SE ConnectorBox" },
            { value: "vcom", label: "VCOM (MeteoControl)" },
            { value: "wattline", label: "Wattline" },
            { value: "ocpi", label: "OCPI" },
            { value: "discovergy", label: "Discovergy" },
          ].map((item) =>
            item.divider
              ? item
              : {
                  ...item,
                  checked: rowFilter.protocol.includes(item.value ?? ""),
                }
          ) as DropdownItem[]
        }
        hasItems={
          loading || rowFilter.protocol.includes("all") || !!tableData.length
        }
        selectedFilter={rowFilter.protocol}
        onItemClick={onMultiselectClick("protocol")}
        closeOnClick={false}
        multiselect
        genericLabel="Protokolle"
      />
    </>
  );
};
