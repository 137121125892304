import styled from "styled-components";

export const FlowWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  height: 100%;
  background: #fff;

  .react-flow__renderer,
  .react-flow__controls {
    z-index: 1;
  }
  .react-flow__handle {
    display: none;
  }
  .react-flow__minimap {
    display: none;
  }
  .react-flow__node-default {
    box-shadow: none;
    border: none;
    padding: 0;
    width: auto;
    background: unset !important;
    font-size: 22px;
  }
  .react-flow__node-default.selected {
    border: none;
  }
  .react-flow__node-default:hover {
    border: none;
    box-shadow: none;
  }
`;
