import { GetAssetsDocument, GetTimeseriesDocument, useCloneAssetMutation } from "../graphql/sdks/controller";

const useCloneAsset = (companyKey: string, districtKey: string) => useCloneAssetMutation({
  refetchQueries: [
    {
      query: GetAssetsDocument,
      variables: {
        districtIdentifier: {
          company: companyKey,
          district: districtKey,
        },
      },
    },
    {
      query: GetTimeseriesDocument,
      variables: {
        districtIdentifier: {
          company: companyKey,
          district: districtKey,
        },
        filter: {
          sourceType: ["district", "inference"],
        },
      },
    },
  ],
});

export default useCloneAsset;
