import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApolloError } from "@apollo/client";
import {
  AlertRetryable,
  Button,
  Modal,
  Pagination,
  Spinner,
  SuccessAlert,
  WarningAlert,
} from "@ampeersenergy/ampeers-ui-components";

import { usePatchTimeseriesMutation } from "../../graphql/sdks/controller";
import { LocalAsset, LocalTimeseries, ReducedTimeseries } from "../types";
import { UpdatedTimeseriesExternalIds } from "./types";
import { ButtonGroupPullRight } from "../style";
import { Checkbox, SpinnerWrapper, SubTitleGreen, Text } from "./style";
import { usePagination } from "../../hooks/usePagination";
import { TimeseriesExternalIdsTable } from "./timeseries-external-ids-table";
import {
  externalIdChanged,
  formatTimeseriesBeforeSubmit,
} from "../../helpers/properties.utils";
import { extractErrorMessage } from "../../helpers/error.utils";

const pageSize = 3;

export interface TimeseriesExternalIdsModalProps {
  isOpen: boolean;
  assets: LocalAsset[];
  timeseries: LocalTimeseries[];
  updatedTimeseries: ReducedTimeseries[];
  setIsOpen: (value: boolean) => void;
}

export const TimeseriesExternalIdsModal: React.FC<
  TimeseriesExternalIdsModalProps
> = ({ isOpen, assets, timeseries, updatedTimeseries, setIsOpen }) => {
  const [updateTimeseries, { loading }] = usePatchTimeseriesMutation();

  const [error, setError] = useState<Error | any | undefined>();
  const [successfulGenerateIds, setSuccessfulGenerateIds] = useState<
    null | number
  >(null);
  const [timeseriesToUpdate, setTimeseriesToUpdate] = useState<
    UpdatedTimeseriesExternalIds[]
  >([]);

  useEffect(() => {
    setTimeseriesToUpdate(
      updatedTimeseries.reduce<UpdatedTimeseriesExternalIds[]>(
        (acc, updatedTs) => {
          const originalTs = timeseries.find(
            (ts) => ts.uuid === updatedTs.uuid
          );

          if (originalTs && externalIdChanged(updatedTs, originalTs)) {
            acc.push({
              ...originalTs,
              source: {
                ...originalTs.source,
                updatedExternalId: updatedTs.source.externalId ?? null,
                updatedExternalIdToBeCommited:
                  originalTs.source.externalId ?? null,
              },
              target: {
                ...originalTs.target,
                updatedExternalId: updatedTs.target.externalId ?? null,
                updatedExternalIdToBeCommited:
                  originalTs.target.externalId ?? null,
              },
            });
          }

          return acc;
        },
        []
      )
    );
  }, [timeseries, updatedTimeseries]);

  const hasTimeseriesToOverwrite = useMemo(
    () =>
      timeseriesToUpdate.some(
        (ts) => ts.source.externalId || ts.target.externalId
      ),
    [timeseriesToUpdate]
  );

  const assetGroups = useMemo(() => {
    return timeseriesToUpdate.reduce<
      Record<string, UpdatedTimeseriesExternalIds[]>
    >((groups, ts) => {
      if (!ts.reference.entityKey) {
        return groups;
      }

      if (!groups[ts.reference.entityKey]) {
        groups = { ...groups, [ts.reference.entityKey]: [] };
      }

      groups[ts.reference.entityKey] = [...groups[ts.reference.entityKey], ts];
      return groups;
    }, {});
  }, [timeseriesToUpdate]);

  const { pageIndex, pageCount, pages, nextPage, previousPage } = usePagination(
    Object.keys(assetGroups),
    pageSize
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
    setSuccessfulGenerateIds(null);
    setError(undefined);
  }, [setIsOpen, setSuccessfulGenerateIds, setError]);

  const onTimeseriesChange = useCallback(
    (
      timeseries: UpdatedTimeseriesExternalIds,
      sourceOrTarget: "source" | "target",
      value: string
    ) => {
      setTimeseriesToUpdate((_updatedTimeseries) => {
        const tsIndex = _updatedTimeseries.findIndex(
          (ts) => ts.uuid === timeseries.uuid
        );

        if (tsIndex === -1) {
          return _updatedTimeseries;
        }

        const updatedTimeseriesWithNewValue = _updatedTimeseries.concat();

        updatedTimeseriesWithNewValue.splice(tsIndex, 1, {
          ...timeseries,
          [sourceOrTarget]: {
            ...timeseries[sourceOrTarget],
            updatedExternalIdToBeCommited: value,
          },
        });

        return updatedTimeseriesWithNewValue;
      });
    },
    [setTimeseriesToUpdate]
  );

  const onSelectAllForAsset = useCallback(
    (assetKey: string, value: boolean) => {
      setTimeseriesToUpdate((_updatedTimeseries) => {
        const updatedTimeseriesWithNewValue = _updatedTimeseries.concat();

        _updatedTimeseries.forEach((ts, index) => {
          if (ts.reference.entityKey === assetKey) {
            updatedTimeseriesWithNewValue.splice(index, 1, {
              ...ts,
              source: {
                ...ts.source,
                updatedExternalIdToBeCommited:
                  (value
                    ? ts.source.updatedExternalId
                    : ts.source.externalId) ?? null,
              },
              target: {
                ...ts.target,
                updatedExternalIdToBeCommited:
                  (value
                    ? ts.target.updatedExternalId
                    : ts.target.externalId) ?? null,
              },
            });
          }
        });

        return updatedTimeseriesWithNewValue;
      });
    },
    []
  );

  const onIgnoreAll = useCallback(() => {
    onClose();
  }, [onClose]);

  const onOverwriteAll = useCallback(() => {
    Promise.all(
      updatedTimeseries.map((ts) =>
        updateTimeseries({
          variables: {
            timeseries: formatTimeseriesBeforeSubmit(ts as LocalTimeseries),
          },
        })
      )
    ).then((result) => {
      const error = result.find((r) => r.errors?.[0]) as
        | ApolloError
        | undefined;
      if (error) {
        setError(extractErrorMessage(error));
      } else {
        setSuccessfulGenerateIds(result.length);
        setError(undefined);
      }
    });
  }, [updatedTimeseries, updateTimeseries]);

  const onOverwriteSelection = useCallback(() => {
    Promise.all(
      timeseriesToUpdate
        .filter(
          (ts) =>
            ts.source.externalId !== ts.source.updatedExternalIdToBeCommited ||
            ts.target.externalId !== ts.target.updatedExternalIdToBeCommited
        )
        .map((ts) =>
          updateTimeseries({
            variables: {
              timeseries: {
                id: ts.id,
                uuid: ts.uuid,
                ...["source", "target"].reduce((acc, key) => {
                  const {
                    __typename,
                    updatedExternalId,
                    updatedExternalIdToBeCommited,
                    ...rest
                  } = ts[key as "source" | "target"];
                  return {
                    ...acc,
                    [key]: {
                      ...rest,
                      externalId: updatedExternalIdToBeCommited,
                    },
                  };
                }, {}),
              },
            },
          })
        )
    ).then((result) => {
      const error = result.find((r) => r.errors?.[0]) as
        | ApolloError
        | undefined;
      if (error) {
        setError(extractErrorMessage(error));
      } else {
        setSuccessfulGenerateIds(result.length);
        setError(undefined);
      }
    });
  }, [timeseriesToUpdate, updateTimeseries]);

  useEffect(() => {
    if (!!updatedTimeseries.length && !hasTimeseriesToOverwrite) {
      onOverwriteAll();
      setIsOpen(false);
    }
  }, [
    hasTimeseriesToOverwrite,
    onOverwriteAll,
    setIsOpen,
    updatedTimeseries.length,
  ]);

  if (!!updatedTimeseries.length && !hasTimeseriesToOverwrite) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="timeseries-external-ids-modal"
      title="External IDs"
      onRequestClose={onClose}
    >
      {error && (
        <AlertRetryable
          message={error ?? "Unknown Error"}
          onRetry={() => setError(undefined)}
        />
      )}
      {loading ? (
        <SpinnerWrapper>
          <Spinner size={25} />
        </SpinnerWrapper>
      ) : successfulGenerateIds ? (
        <SuccessAlert>
          {successfulGenerateIds} External IDs wurden erfolgreich überschrieben.
        </SuccessAlert>
      ) : !timeseriesToUpdate.length ? (
        <>
          <WarningAlert>Keine External IDs wurden generiert.</WarningAlert>
          <ButtonGroupPullRight>
            <Button secondary onClick={onClose}>
              Schliessen
            </Button>
          </ButtonGroupPullRight>
        </>
      ) : (
        <>
          <Text>
            Im Folgenden werden alle neuen external IDs aufgelistet plus solche,
            bei denen Änderungen überschrieben werden können:
          </Text>
          {pages[pageIndex]?.map((assetKey: string) => {
            const groupTitle =
              assets.find((asset) => asset.key === assetKey)?.name ?? assetKey;
            return (
              <>
                <SubTitleGreen>
                  {groupTitle}
                  <Checkbox
                    id={assetKey}
                    key={assetKey}
                    label="Alle generieren"
                    checked={undefined as any}
                    onChange={(v) => {
                      onSelectAllForAsset(assetKey, v);
                    }}
                  />
                </SubTitleGreen>
                <TimeseriesExternalIdsTable
                  timeseries={assetGroups[assetKey]}
                  onTimeseriesChange={onTimeseriesChange}
                />
              </>
            );
          })}
          <Pagination
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < pageCount - 1}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            page={{ length: pages[0]?.length }}
            rowCount={Object.keys(assetGroups).length}
          />
          <ButtonGroupPullRight>
            <Button secondary onClick={onIgnoreAll}>
              Alle ignorieren
            </Button>
            <Button secondary onClick={onOverwriteAll}>
              Alle generieren
            </Button>
            <Button secondary onClick={onOverwriteSelection}>
              Auswahl generieren
            </Button>
          </ButtonGroupPullRight>
        </>
      )}
    </Modal>
  );
};
