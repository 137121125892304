import React, { useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import {
  Icons,
  LinkedTab,
  LinkedTabs,
  PageTitleLayout,
  SpinnerDark,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import {
  Building,
  District,
  useCheckDistrictStateQuery,
  useGetContractTemplatesQuery,
  useGetDistrictsQuery,
  useGetDistrictTemplateQuery,
  useGetTimeseriesQuery,
} from "../../graphql/sdks/controller";

import { Buildings } from "./buildings";
import { Assets } from "./assets";
import { Contracts } from "./contracts";
import { TimeseriesTable } from "./timeseries";
import OverviewFlow from "../../components/district-overview";
import { InferencesOverview } from "../../components/inferences-overview";
import { InferencesOverview as InferencesOverviewNew } from "../../components/inferences-overview/index";
import { SnapshotOverview } from "../../components/snapshot-overview";
import { DistrictState } from "../../components/district-state";

import { hasMissingProperties } from "../../helpers/properties.utils";
import { AssetType } from "../../helpers/asset.utils";
import {
  AssetListItem,
  ExtendedContract,
  TimeseriesFormValues,
} from "../../components/types";
import { useAssets, useContracts, useTimeseriesFilters } from "../../hooks";
import { useGetElementFromRoute } from "../../hooks/useGetElementFromRoute";

import { EditDistrictForm } from "../../components/edit-district-form";
import { CreateEditContractFlowModal } from "../../components/create-edit-contract-flow";
import { CreateEditBuildingFlowModal } from "../../components/create-edit-building-flow";
import { CreateEditTimeseriesFlowModal } from "../../components/create-edit-timeseries-flow";
import { CreateEditAssetFlowModal } from "../../components/create-edit-asset-flow";
import { useTimeseriesChartFilters } from "../../hooks/useTimeseriesChartFilters";
import { Documents } from "./documents";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74vh;
  width: 100%;
`;

const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin: -4px 0;
  }
`;

export const DistrictPage: React.FC = () => {
  const match = useRouteMatch();
  const { companyDistrict } = useParams() as { companyDistrict: string };

  const theme = useTheme() as Theme;

  const [isCreateAssetOpen, setIsCreateAssetOpen] = useState(false);
  const [isCreateBuildingOpen, setIsCreateBuildingOpen] = useState(false);
  const [isCreateContractOpen, setIsCreateContractOpen] = useState(false);

  const [selectedBuilding, setSelectedBuilding] = useState<
    string | undefined
  >();
  const [selectedAsset] = useState<string | undefined>();

  const [assetToEdit, setAssetToEdit] = useState<
    Partial<AssetListItem> | undefined
  >();
  const [buildingToEdit, setBuildingToEdit] = useState<Building | undefined>();
  const [contractToEdit, setContractToEdit] = useState<
    ExtendedContract | undefined
  >();
  const [timeseriesToEdit, setTimeseriesToEdit] = useState<
    TimeseriesFormValues | undefined
  >();

  const { data: contractTemplates } = useGetContractTemplatesQuery();
  const { data: districtTemplate } = useGetDistrictTemplateQuery();
  const { data: districts, loading: loadingDistrict } = useGetDistrictsQuery({
    fetchPolicy: "cache-first",
    variables: {
      filter: {
        multipleDistricts: [
          {
            company: "all",
            district: "all",
          },
        ],
      },
    },
  });

  const [companyKey, districtKey] = useMemo(
    () => companyDistrict.split("-"),
    [companyDistrict]
  );

  const district: District = useMemo(
    () =>
      (districts?.districts?.find(
        (d) => d.companyKey === companyKey && d.key === districtKey
      ) as District) ??
      ({
        id: "",
        key: "",
        companyKey: "",
        name: "",
        company: "",
        district: "",
        buildings: [],
        contracts: [],
        contact: {
          alertingEmails: [],
          reportingEmails: [],
        },
        address: {
          city: "",
          street: "",
          streetNr: "",
          zip: "",
          country: "DE",
        },
        properties: [],
        metadata: [],
        notes: [],
        assets: [],
        state: "",
      } as District),
    [districts?.districts, companyKey, districtKey]
  );

  const { data: districtState } = useCheckDistrictStateQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      districtIdentifier: {
        company: district.companyKey,
        district: district.key,
      },
      state: district.state,
    },
  });

  const {
    assets: allAssets,
    loading: loadingAssets,
    refetch: reloadAssets,
  } = useAssets(district);

  const allContracts = useContracts(district);

  const {
    data,
    loading: loadingTimeseries,
    error: timeseriesError,
    refetch: reloadTimeseries,
  } = useGetTimeseriesQuery({
    variables: {
      districtIdentifier: {
        company: district.companyKey,
        district: district.key,
      },
      filter: {
        sourceType: ["district", "inference"],
      },
    },
  });

  const energyCenterId = useMemo(
    () =>
      district.buildings.find((b) => b.assetType === AssetType.EnergyCenter)
        ?.id,
    [district]
  );

  const districtHasMissingProperties = useMemo(
    () =>
      hasMissingProperties(
        districtTemplate?.districtTemplate.properties ?? [],
        district.properties,
        {
          isMonitoringRelevant: true,
          isOptimizationRelevant: true,
          isOnsite: false,
        },
        "Param"
      ),
    [district, districtTemplate]
  );

  const districtHasMissingTimeseries = useMemo(
    () =>
      hasMissingProperties(
        districtTemplate?.districtTemplate.properties ?? [],
        district.properties,
        {
          isMonitoringRelevant: true,
          isOptimizationRelevant: true,
          isOnsite: false,
        },
        "ts"
      ),
    [district, districtTemplate]
  );

  const assetsHaveMissingProperties = useMemo(
    () => allAssets.some((a) => a.displayWarning),
    [allAssets]
  );

  // setting this state here instead of inside timeseries.tsx to preserve
  // the filter state when changing tabs
  const filterFunctions = useTimeseriesFilters({
    sourceOrTargetType: ["all"],
    assetTemplate: ["all"],
    asset: ["all"],
    flowType: ["all"],
    protocol: ["all"],
  });

  const chartFilterFunctions = useTimeseriesChartFilters();

  const elementFromRoute = useGetElementFromRoute({
    assets: allAssets,
    buildings: district.buildings,
    contracts: allContracts,
    timeseries: data?.timeseries ?? [],
  });

  useEffect(() => {
    if (elementFromRoute) {
      switch (elementFromRoute.__typename) {
        case "Asset":
          setAssetToEdit(elementFromRoute);
          break;
        case "Building":
          setBuildingToEdit(elementFromRoute);
          break;
        case "Contract":
          setContractToEdit(elementFromRoute);
          break;
        case "Timeseries":
          setTimeseriesToEdit({ ...elementFromRoute, refTimeseriesProp: [] });
          break;
        default:
          break;
      }
    }
  }, [elementFromRoute, setAssetToEdit]);

  // TODO: instead of passing district, assets, contracts etc to all components, we could use a context
  return (
    <PageTitleLayout
      isLoading={loadingDistrict || loadingAssets || !district}
      levelsUpToParent={10}
      title={district.name}
    >
      <LinkedTabs basePath={match.url}>
        <LinkedTab title="Übersicht" path="/overview">
          {loadingDistrict || loadingAssets || !district ? (
            <SpinnerWrapper>
              <SpinnerDark size={30} />
            </SpinnerWrapper>
          ) : (
            <OverviewFlow
              district={district}
              assets={allAssets}
              contracts={allContracts}
              timeseries={data?.timeseries ?? []}
              buildings={district.buildings}
              openAssetForm={() => {
                setIsCreateAssetOpen(true);
              }}
              openBuildingForm={() => {
                setIsCreateBuildingOpen(true);
              }}
              openContractForm={() => {
                setIsCreateContractOpen(true);
              }}
              setSelectedBuilding={setSelectedBuilding}
              energyCenterId={energyCenterId}
              reloadTimeseries={reloadTimeseries}
            />
          )}
        </LinkedTab>
        <LinkedTab
          title={
            <CenteredRow>
              Quartier
              {districtHasMissingProperties || districtHasMissingTimeseries ? (
                <Icons.Warning size={25} color={theme.palette.warning.color} />
              ) : null}
            </CenteredRow>
          }
          path="/district"
        >
          {loadingDistrict || !district ? (
            <SpinnerWrapper>
              <SpinnerDark size={30} />
            </SpinnerWrapper>
          ) : (
            <EditDistrictForm
              district={district}
              timeseries={data?.timeseries ?? []}
              reloadTimeseries={reloadTimeseries}
            />
          )}
        </LinkedTab>
        <LinkedTab title="Gebäude" path="/buildings">
          <Buildings
            buildings={district.buildings}
            loading={loadingDistrict}
            onBuildingRowClick={(building) => setBuildingToEdit(building)}
            onCreateBuildingClick={setIsCreateBuildingOpen}
          />
        </LinkedTab>
        <LinkedTab
          title={
            <CenteredRow>
              Anlagen
              {!!assetsHaveMissingProperties ? (
                <Icons.Warning size={25} color={theme.palette.warning.color} />
              ) : null}
            </CenteredRow>
          }
          path="/assets"
        >
          <Assets
            district={district}
            assets={allAssets}
            loading={loadingDistrict}
            setAssetToEdit={setAssetToEdit}
            onCreateAssetClick={setIsCreateAssetOpen}
          />
        </LinkedTab>
        <LinkedTab title="Verträge" path="/contracts">
          <Contracts
            contracts={allContracts}
            loading={loadingDistrict}
            onContractRowClick={(contract) => setContractToEdit(contract)}
            onCreateContractClick={setIsCreateContractOpen}
          />
        </LinkedTab>
        <LinkedTab title="Zeitreihen" path="/timeseries">
          <TimeseriesTable
            district={district}
            assets={allAssets}
            contracts={allContracts}
            buildings={district!.buildings}
            timeseries={data?.timeseries ?? []}
            setAssetToEdit={setAssetToEdit}
            contractTemplates={contractTemplates?.contractTemplates ?? []}
            districtTemplate={districtTemplate?.districtTemplate}
            setBuildingToEdit={setBuildingToEdit}
            setContractToEdit={setContractToEdit}
            onTimeseriesRowClick={(timeseries) =>
              setTimeseriesToEdit(timeseries)
            }
            loading={loadingDistrict || loadingAssets || loadingTimeseries}
            timeseriesError={timeseriesError}
            reloadTimeseries={reloadTimeseries}
            {...filterFunctions}
            {...chartFilterFunctions}
          />
        </LinkedTab>
        <LinkedTab title="Inferenzen" path="/inferences">
          <InferencesOverview district={district} assets={allAssets} />
        </LinkedTab>
        <LinkedTab title="Inferenzen (Neu)" path="/inferences-new">
          <InferencesOverviewNew
            district={district}
            assets={allAssets}
            timeseries={data?.timeseries ?? []}
            loading={loadingDistrict || loadingAssets || loadingTimeseries}
          />
        </LinkedTab>
        <LinkedTab title="Snapshots" path="/snapshots">
          <SnapshotOverview district={district} />
        </LinkedTab>
        <LinkedTab
          title={
            <CenteredRow>
              State
              {!!districtState?.checkDistrictState.length ? (
                <Icons.Warning size={25} color={theme.palette.warning.color} />
              ) : null}
            </CenteredRow>
          }
          path="/state"
        >
          <DistrictState
            district={district}
            assets={allAssets}
            buildings={district.buildings}
            loading={loadingDistrict || loadingAssets || loadingTimeseries}
          />
        </LinkedTab>
        <LinkedTab title="Dokumente" path="/documents">
          <Documents
            buildings={district.buildings}
            assets={allAssets}
            districtId={district.id}
            districtKey={district.key}
            companyKey={district.companyKey}
            loadingDistrict={loadingDistrict || loadingAssets}
          />
        </LinkedTab>
      </LinkedTabs>
      <CreateEditBuildingFlowModal
        renderAsMultistepForm
        isOpen={!!buildingToEdit || isCreateBuildingOpen}
        onClose={() => {
          setBuildingToEdit(undefined);
          setIsCreateBuildingOpen(false);
        }}
        title={buildingToEdit?.name || buildingToEdit?.key || "Neues Gebäude"}
        building={buildingToEdit}
        districtKey={district.key}
        companyKey={district.companyKey}
        districtId={district.id}
        selectedParentId={district.id}
        address={district.address}
        startOnEdit
        energyCenterId={energyCenterId}
      />
      <CreateEditAssetFlowModal
        renderAsMultistepForm
        isOpen={!!assetToEdit || isCreateAssetOpen}
        onClose={() => {
          setIsCreateAssetOpen(false);
          setAssetToEdit(undefined);
        }}
        title={
          isCreateAssetOpen || !assetToEdit?.name
            ? "Neue Anlage"
            : assetToEdit?.name
        }
        asset={assetToEdit}
        districtId={district.id}
        districtKey={district.key}
        companyKey={district.companyKey}
        buildings={district.buildings}
        assets={allAssets}
        timeseries={data?.timeseries ?? []}
        address={
          selectedBuilding
            ? district.buildings.find((b) => b.key === selectedBuilding)
                ?.address
            : district.address
        }
        setAssetToEdit={setAssetToEdit}
        isNewAsset={isCreateAssetOpen}
        reloadTimeseries={reloadTimeseries}
        startOnEdit
      />
      <CreateEditContractFlowModal
        renderAsMultistepForm
        isOpen={!!contractToEdit || isCreateContractOpen}
        onClose={() => {
          setContractToEdit(undefined);
          setIsCreateContractOpen(false);
        }}
        title={contractToEdit?.name || contractToEdit?.key || "Neuer Vertrag"}
        contract={contractToEdit}
        assets={allAssets}
        contracts={allContracts}
        assetId={contractToEdit?.assetId ?? selectedAsset ?? ""}
        startOnEdit
      />
      <CreateEditTimeseriesFlowModal
        district={district}
        isOpen={!!timeseriesToEdit}
        onClose={() => {
          setTimeseriesToEdit(undefined);
        }}
        title={timeseriesToEdit?.name || "Neue Zeitreihe"}
        timeseries={timeseriesToEdit}
        setIsOverlayOpen={() => {
          setTimeseriesToEdit(undefined);
        }}
        reloadTimeseries={() => {
          reloadTimeseries();
          reloadAssets();
        }}
        startOnEdit
      />
    </PageTitleLayout>
  );
};
