import { AlertRetryable } from "@ampeersenergy/ampeers-ui-components";
import { ApolloError } from "@apollo/client";
import React from "react";

export interface MultistepFormMutationErrorProps {
  mutationError: (string | ApolloError)[] | null;
}

export const MultistepFormMutationError: React.FC<
  MultistepFormMutationErrorProps
> = ({ mutationError }) => {
  if (!mutationError) return null;

  if (Array.isArray(mutationError)) {
    return (
      <>
        {mutationError.map((error) => (
          <AlertRetryable title="Fehler" error={error} retryable={false} />
        ))}
      </>
    );
  }

  return (
    <AlertRetryable
      title="Fehler"
      message={String(mutationError)}
      retryable={false}
    />
  );
};
