import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { Theme } from "@ampeersenergy/ampeers-ui-components";

import { AssetType, getAssetIcon } from "../helpers/asset.utils";

export const TableIcon: React.FC<{
  type: AssetType;
  size?: number;
  color?: string;
}> = ({ type, size, color }) => {
  const theme = useTheme() as Theme;

  const Icon = useMemo(() => getAssetIcon(type), [type]);

  if (!Icon) return null;

  return <Icon size={size ?? 25} color={color ?? theme.primaryColor} />;
};
