import { AssetDocumentType, DistrictDocumentType } from "../components/types";

export const documentTypes: {
  district: Record<DistrictDocumentType, string>;
  asset: Record<AssetDocumentType, string>;
} = {
  district: {
    district_heat_diagram: "Wärmeschema",
    district_electricity_diagram: "Messkonzept Stromzähler",
    district_contract: "Vertrag",
    district_other: "Sonstige",
  },
  asset: {
    asset_datasheet: "Technisches Datenblatt",
    asset_contract: "Vertrag",
    asset_interface_documentation: "Schnittstellendokumentation",
    asset_construction_documentation: "Ausführungsplanung",
    asset_other: "Sonstige",
  },
};

export const documentGroupIds = [
  ...Object.keys(documentTypes.district),
  ...Object.keys(documentTypes.asset),
];
