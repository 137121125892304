import { Form } from "formik";
import styled, { css } from "styled-components";
import {
  CheckboxNew as CheckboxComponent,
  Select as RawSelect,
  SubTitle,
  SpinnerDark,
  Icons,
  FormikInput,
  FormikSelect,
  Label,
  FlexRow,
  ButtonGroup,
  CollapsiblePanel,
} from "@ampeersenergy/ampeers-ui-components";

export const SubTitleWithMargin = styled(SubTitle)`
  margin-top: 24px;
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
`;

export const SubTitleWithButton = styled(SubTitle)<{
  $underlined?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  ${(props) =>
    props.$underlined
      ? `border-bottom: 1px solid ${props.theme.palette.border};`
      : ""}
`;

export const Muted = styled.p`
  color: ${(props) => props.theme.palette.textMuted};
`;

export const Spinner = styled(SpinnerDark)`
  align-self: flex-end;
  margin-bottom: 12px;
  margin-left: 6px;
`;

export const margins = css`
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FormWrapperWithPadding = styled(FormWrapper)`
  padding: 10px 14px;
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto;
`;

export const Input = styled(FormikInput)<{ flex?: number }>`
  flex: ${(props) => props.flex ?? 1};
  margin-left: 6px;
  margin-right: 6px;
  ${margins}
`;

export const Select = styled(FormikSelect)<{ flex?: number }>`
  flex: ${(props) => props.flex ?? 1};
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 6px;
  margin-right: 6px;
  ${margins}
`;

export const Column = styled.div<{ flex?: number }>`
  flex: ${(props) => props.flex ?? 1};
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  ${margins}
`;

export const ColumnWithInputWidth = styled(Column)`
  input {
    width: 100%;
  }
`;

export const Row = styled.div<{ flex?: number; $small?: boolean }>`
  flex: ${(props) => props.flex ?? 1};
  display: flex;
  flex-direction: ${(props) => (props.$small ? "column" : "row")};

  ${(props) =>
    props.$small &&
    css`
      & > div {
        margin: 0;
      }
    `}
`;

export const InlineCheckbox = styled(CheckboxComponent)<{
  flex?: number;
  $margin?: string;
}>`
  label {
    display: flex;
  }
  flex: ${(props) => props.flex ?? 1} !important;
  align-self: auto;
  ${(props) => (props.$margin ? `margin: ${props.$margin};` : "")}

  & > label {
    flex: 1;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  width: 100%;
  min-width: 0;
`;

export const PropListContainer = styled(ListContainer)`
  flex: 1;
  overflow: hidden;
`;

export const PlusIcon = styled(Icons.Plus)`
  transform: rotate(45deg) scale(0.8);
`;

export const NewItemWrapper = styled.li`
  order: 199;
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 12px 0px;
  border-radius: 4px;

  svg {
    margin-left: -4px;
    margin-right: 4px;
    fill: ${(props) => props.theme.primaryColor};
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.backgroundMuted};
  }
`;

export const LabelWithMargin = styled(Label)`
  margin-bottom: 10px;
`;

export const CenteredRow = styled(FlexRow)`
  align-items: center;
`;

export const FormSectionHeader = styled.h3`
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => props.theme.palette.text};
  margin-top: 0;
  margin-bottom: 12px;
`;

export const FormSection = styled.section`
  margin-bottom: 36px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 16px;

  :last-child {
    margin-right: 0;
  }

  .tag {
    margin-right: 4px;
  }

  .label {
    color: ${(props) => props.theme.palette.textMuted};
    font-weight: bold;
  }
`;

export const InputWithOverflow = styled(Input)`
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const MultistepWrapper = styled.div`
  padding: 30px;
  height: 100%;
  overflow-y: hidden;
`;

export const ButtonGroupPullRight = styled(ButtonGroup)`
  justify-content: flex-end;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Panel = styled(CollapsiblePanel)`
  height: 86vh;
`;

export const FlexRawSelect = styled(RawSelect)`
  flex: 1;
  margin-left: 6px;
  margin-right: 6px;
  ${margins}
`;

export const Labels = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 12px 16px;
  position: absolute;
  bottom: 0;
  left: 28px;
`;
