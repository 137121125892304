import React, { useCallback, useState } from "react";
import { FormikHelpers } from "formik";
import { ApolloError } from "@apollo/client";
import { Button, Theme } from "@ampeersenergy/ampeers-ui-components";

import {
  Address,
  Building,
  GetDistrictsDocument,
  Metadata,
  Note,
  useCreateBuildingMutation,
  useRemoveBuildingsMutation,
  useUpdateBuildingMutation,
} from "../../graphql/sdks/controller";

import { omitGraphqlType } from "../../helpers/asset.utils";
import { extractErrorMessage } from "../../helpers/error.utils";
import { buildingValidationSchema } from "../validation";

import { DeleteConfirmModal } from "../delete-confirm-modal";
import { MultistepForm } from "../multistep-form";
import { BuildingFormTemplate } from "./template";
import { useTheme } from "styled-components";
import { AccordionForm } from "../accordion-form";
import { FormSection, FormSectionHeader } from "../style";
import { NotesForm } from "../notes-form";
import { BuildingFormAddress } from "./address";

const refetchQueries = [
  {
    query: GetDistrictsDocument,
    variables: {
      filter: {
        multipleDistricts: [
          {
            company: "all",
            district: "all",
          },
        ],
      },
    },
  },
];

export interface BuildingFormValues
  extends Omit<Building, "metadata" | "address"> {
  parentId?: string | null | undefined;
  districtKey?: string;
  companyKey?: string;
  elementType?: string;
  address: Address;
  shortAddress?: string;
  metadata: (Omit<Metadata, "value"> & { value?: string | boolean })[];
  notes: Note[];
}

export interface CreateEditBuildingFlowProps {
  building?: Building;
  districtKey: string;
  companyKey: string;
  districtId: string;
  startOnEdit?: boolean;
  selectedParentId?: string;
  address?: Address;
  energyCenterId?: string;
  renderAsMultistepForm?: boolean;

  onClose?: () => void;
  onDelete?: () => void;
  onSuccess?: () => void;
}

export const CreateEditBuildingFlow: React.FC<CreateEditBuildingFlowProps> = ({
  building,
  districtKey,
  companyKey,
  districtId,
  startOnEdit = false,
  selectedParentId,
  address,
  energyCenterId,
  renderAsMultistepForm,
  onClose,
  onSuccess,
  onDelete,
}) => {
  const theme = useTheme() as Theme;
  const [isEditing, setIsEditing] = useState(startOnEdit);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const [createBuilding] = useCreateBuildingMutation({
    errorPolicy: "all",
    refetchQueries,
  });

  const [updateBuilding] = useUpdateBuildingMutation({
    errorPolicy: "all",
    refetchQueries,
  });

  const [removeBuilding, { error: removeError }] = useRemoveBuildingsMutation({
    errorPolicy: "all",
    refetchQueries,
  });

  const formatInitialValues = useCallback(
    (initialBuilding?: Building): BuildingFormValues => {
      return {
        id: "",
        key: "",
        name: "",
        district: "",
        company: "",
        template: "building",
        assetType: "building",
        assets: [],
        parentId: selectedParentId || districtId,
        metadata: [],
        notes: [],
        ...(initialBuilding ? omitGraphqlType(initialBuilding) : {}),
        address: initialBuilding?.address
          ? omitGraphqlType(initialBuilding.address)
          : address
          ? omitGraphqlType(address)
          : ({} as Address),
        districtKey,
        companyKey,
      };
    },
    [districtKey, companyKey, selectedParentId, districtId, address]
  );

  const onSubmit = useCallback(
    async (
      { newValues: values }: { newValues: BuildingFormValues },
      { setValues }: FormikHelpers<any>
    ) => {
      try {
        const {
          elementType,
          companyKey,
          districtKey,
          company,
          district,
          parentId,
          id,
          assets,
          metadata,
          template,
          shortAddress,
          notes,
          ...restValues
        } = values;

        if (!companyKey || !districtKey || !parentId) {
          throw new Error(`Company key, district key or parent id missing`);
        }

        const buildingValues = {
          ...restValues,
          metadata: metadata.map(({ key, label, value }) => ({
            key,
            label,
            value:
              value === true ? "true" : value === false ? "false" : value ?? "",
          })),
          notes: notes.map((n) => ({
            content: n.content,
            createdBy: n.createdBy,
            createdAt: n.createdAt,
          })),
        };

        let newBuildingValues;
        if (!building) {
          const { data, errors } = await createBuilding({
            variables: {
              parentId: parentId || districtId,
              building: buildingValues,
            },
          });

          newBuildingValues = data?.createBuilding;
          if (errors?.length) {
            throw errors[0].message ?? "Unknown Error";
          }
        } else {
          const { data, errors } = await updateBuilding({
            variables: {
              parentId: parentId || districtId,
              building: buildingValues,
            },
          });

          newBuildingValues = data?.updateBuilding;
          if (errors?.length) {
            throw errors[0]?.message ?? "Unknown Error";
          }
        }

        setIsEditing(false);
        onClose?.();

        if (newBuildingValues) {
          setValues(formatInitialValues(newBuildingValues as Building));
        }

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          throw extractErrorMessage(e);
        } else if (typeof e === "string") {
          throw e;
        } else {
          throw e;
        }
      }
    },
    [
      building,
      createBuilding,
      districtId,
      formatInitialValues,
      onClose,
      onSuccess,
      updateBuilding,
    ]
  );

  return (
    <>
      {renderAsMultistepForm && (
        <MultistepForm
          initialValues={formatInitialValues(building)}
          validationSchema={buildingValidationSchema}
          showSubmitInEveryStep
          extraActionButtons={
            !!building && (
              <Button
                secondary
                customTheme={{
                  primaryColor: theme.palette.error.color,
                  secondaryColor: theme.palette.error.background,
                }}
                onClick={() => setConfirmDeleteModalOpen(true)}
              >
                Löschen
              </Button>
            )
          }
          onSubmit={onSubmit}
        >
          <MultistepForm.Step title="Template">
            <FormSection>
              <FormSectionHeader>Template</FormSectionHeader>
              <BuildingFormTemplate
                isEditing={isEditing}
                existingBuilding={!!building}
                energyCenterId={energyCenterId}
              />
            </FormSection>
          </MultistepForm.Step>
          <MultistepForm.Step title="Adresse">
            <FormSection>
              <FormSectionHeader>Adresse</FormSectionHeader>
              <BuildingFormAddress isEditing={isEditing} />
            </FormSection>
          </MultistepForm.Step>
          <MultistepForm.Step title="Notizen">
            <FormSection>
              <FormSectionHeader>Notizen</FormSectionHeader>
              <NotesForm isEditing={isEditing} />
            </FormSection>
          </MultistepForm.Step>
        </MultistepForm>
      )}
      {!renderAsMultistepForm && (
        <AccordionForm
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          initialValues={formatInitialValues(building)}
          validationSchema={buildingValidationSchema}
          onSubmit={onSubmit}
          onDelete={() => setConfirmDeleteModalOpen(true)}
        >
          <AccordionForm.Item label="Template">
            <BuildingFormTemplate
              isEditing={isEditing}
              existingBuilding={!!building}
              energyCenterId={energyCenterId}
            />
          </AccordionForm.Item>
          <AccordionForm.Item label="Adresse">
            <BuildingFormAddress isEditing={isEditing} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Notizen">
            <NotesForm isEditing={isEditing} />
          </AccordionForm.Item>
        </AccordionForm>
      )}
      {!!building && (
        <DeleteConfirmModal
          entityName={building.name}
          isOpen={confirmDeleteModalOpen}
          setIsOpen={setConfirmDeleteModalOpen}
          onConfirm={() => {
            removeBuilding({ variables: { ids: [building.id] } });
            onClose?.();
            onDelete?.();
          }}
          errorMsg={removeError && extractErrorMessage(removeError)}
        />
      )}
    </>
  );
};
