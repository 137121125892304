import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  Login,
  LoginLayout as LoginLayoutComponent,
  ResetPasswordRequest,
} from "@ampeersenergy/ampeers-ui-components";

import ProductLogo from "../assets/ae-logo.png";
import { useLoginUserMutation } from "../graphql/sdks/auth";
import styled from "styled-components";

const initialValues = { email: "", password: "" };

const LoginLayout = styled(LoginLayoutComponent)`
  img {
    width: 100px;
  }
`;

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const [loginUserMutation] = useLoginUserMutation();
  const [loginState, setLoginState] = useState<"login" | "passwordReset">(
    "login"
  );

  return (
    <LoginLayout ProductLogo={ProductLogo}>
      {loginState === "passwordReset" && (
        <ResetPasswordRequest
          setLoginState={setLoginState}
          loading={false}
          sendPasswordResetEmail={() => Promise.resolve({ data: {} })}
        />
      )}
      {loginState === "login" && (
        <Login
          setLoginState={setLoginState}
          initialValues={initialValues}
          onLoginSuccess={(data) => {
            history.replace({ pathname: "/districts" });
          }}
          login={(values) =>
            loginUserMutation({
              variables: {
                ...values,
                twoFactorAuthCallbackURL: `${window.location.origin}/login/2fa`,
              },
            })
          }
        />
      )}
    </LoginLayout>
  );
};
