import React from "react";
import { useTheme } from "styled-components";
import { FlexRow, Icons, Theme } from "@ampeersenergy/ampeers-ui-components";

import {
  HorizontalLine,
  Step,
  StepIcon,
  StepLabel,
  StepLabelWrapper,
  StepperWrapper,
  VerticalLine,
} from "./style";

import { MultistepFormStepProps } from "./step";

export interface StepperProps {
  direction?: "horizontal" | "vertical";
  steps: MultistepFormStepProps[];
  currentIndex: number;
  onStepClick: (value: number) => void;
}

export const Stepper: React.FC<StepperProps> = ({
  steps,
  currentIndex,
  direction = "horizontal",
  onStepClick,
}) => {
  const theme = useTheme() as Theme;

  return (
    <StepperWrapper $direction={direction}>
      {steps.map(({ title, hasWarning, hasError }, index) => (
        <Step
          key={title}
          $direction={direction}
          onClick={() => onStepClick(index)}
        >
          {direction === "horizontal" ? (
            <>
              <FlexRow>
                <HorizontalLine
                  className="line"
                  $done={index <= currentIndex}
                />
                {hasWarning || hasError ? (
                  <Icons.Warning
                    size={32}
                    color={
                      hasError
                        ? theme.palette.error.color
                        : theme.palette.warning.color
                    }
                  />
                ) : index < currentIndex ? (
                  <Icons.Checkmark size={32} color={theme.primaryColor} />
                ) : (
                  <StepIcon $isCurrent={index === currentIndex}>
                    {index + 1}
                  </StepIcon>
                )}
                <HorizontalLine className="line" $done={index < currentIndex} />
              </FlexRow>
              <StepLabel $direction={direction} $done={index <= currentIndex}>
                {title}
              </StepLabel>
            </>
          ) : (
            <>
              {index > 0 && (
                <VerticalLine className="line" $done={index <= currentIndex} />
              )}
              <StepLabelWrapper>
                {hasWarning || hasError ? (
                  <Icons.Warning
                    size={32}
                    color={
                      hasError
                        ? theme.palette.error.color
                        : theme.palette.warning.color
                    }
                  />
                ) : index < currentIndex ? (
                  <Icons.Checkmark size={32} color={theme.primaryColor} />
                ) : (
                  <StepIcon $isCurrent={index === currentIndex}>
                    {index + 1}
                  </StepIcon>
                )}
                <StepLabel $direction={direction} $done={index <= currentIndex}>
                  {title}
                </StepLabel>
              </StepLabelWrapper>
              {index < steps.length - 1 && (
                <VerticalLine className="line" $done={index < currentIndex} />
              )}
            </>
          )}
        </Step>
      ))}
    </StepperWrapper>
  );
};
