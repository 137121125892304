import React from "react";
import { Handle, Position } from "react-flow-renderer";
import {
  FlexColumn,
  Icons,
  Theme,
  TooltipLight,
} from "@ampeersenergy/ampeers-ui-components";

import { Color, Label } from "./style";
import { useTheme } from "styled-components";

export interface TileProps {
  data: {
    name: string;
    colors: [string, string];
    assetName?: string;
    type?: string;
    description?: string;
  };
}

export const Tile: React.FC<TileProps> = ({
  data: { name, colors, assetName, type, description },
}) => {
  const theme = useTheme() as Theme;

  return (
    <>
      <Handle
        style={{ opacity: 0 }}
        isConnectable={false}
        type="target"
        position={Position.Left}
      />
      <Label>
        <Color className="color" $colors={colors} />
        <FlexColumn className="column">
          <span className="nameInfo">
            <span className="name">{name}</span>
            {description ? (
              <TooltipLight
                id={`inference-tile-info-${name}-${type}-${assetName}`}
                text={description}
              >
                <Icons.Info
                  data-tip
                  data-for={`inference-tile-info-${name}-${type}-${assetName}`}
                  size={32}
                  color={theme.palette.gray[400]}
                />
              </TooltipLight>
            ) : null}
          </span>
          {assetName ? <span className="assetName">{assetName}</span> : null}
          {type ? <span className="type">{type}</span> : null}
        </FlexColumn>
      </Label>
      <Handle
        style={{ opacity: 0 }}
        isConnectable={false}
        type="source"
        position={Position.Right}
        id="a"
      />
    </>
  );
};
