import React, {
  ChangeEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { useFormikContext } from "formik";
import {
  Button,
  DynamicListItemProps,
  FormikInput,
  Input as RawInput,
  Select as RawSelect,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import { PropertyTemplate } from "../../graphql/sdks/controller";
import {
  AssetFormValues,
  LocalTimeseries,
  LocalUnit,
  PropertyFormValue,
} from "../types";

import {
  ButtonGroupPullRight,
  InlineItemsWrapper,
  PropertyEditComponentWrapper,
} from "../properties-form/style";
import { Input, InputWithOverflow, Select } from "../style";

import { UnitsForm } from "../units-form";
import { TimeseriesForm } from "../create-edit-timeseries-flow/timeseries-form";
import { isPropertyRequired } from "../../helpers/properties.utils";
import { findValue } from "../../helpers/object.utils";

export interface TimeseriesEditFormProps {
  item: DynamicListItemProps["item"] &
    PropertyFormValue & { relativeTimeseries: LocalTimeseries[] };
  index: number;
  isEditing?: boolean;
  propertyTemplates: PropertyTemplate[];
  allUnits: LocalUnit[];
  unitKeys: (string | null | undefined)[];
  identifier?: string;
  prefixIdentifier?: string;
  vertical?: boolean;
  hideButtons?: boolean;
  canDuplicate?: boolean;
  setEditMode: (value: boolean) => void;
  onDelete: () => void;
  onDuplicate: MouseEventHandler;
}

export const TimeseriesEditForm: React.FC<TimeseriesEditFormProps> = (
  props
) => {
  const {
    item,
    index,
    isEditing,
    propertyTemplates,
    allUnits,
    unitKeys,
    identifier = "timeseries",
    prefixIdentifier,
    vertical,
    hideButtons,
    canDuplicate,
    onDuplicate,
    setEditMode,
    onDelete,
  } = props;
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const theme = useTheme() as Theme;
  const { values, setFieldValue } = useFormikContext<AssetFormValues>();

  const [relativeTsIndex, setRelativeTsIndex] = useState(0);

  const items = useMemo(
    () =>
      prefixIdentifier
        ? findValue<AssetFormValues>(prefixIdentifier, values).timeseries
        : values.timeseries,
    [prefixIdentifier, values]
  );

  const onTemplateChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      const template = propertyTemplates.find(
        ({ key }) => key === e.target.value
      );

      if (template)
        setFieldValue(`${_prefixIdentifier}${identifier}[${index}]`, {
          ...item,
          ...template,
          key: template.key,
          propKey: template.key,
          value: item.value,
          unit: template.unit,
        });
    },
    [
      item,
      propertyTemplates,
      setFieldValue,
      identifier,
      index,
      _prefixIdentifier,
    ]
  );

  const propertyExists = useCallback(
    (propKey: string): boolean => {
      return items.some((p) => p.key === propKey);
    },
    [items]
  );

  return (
    <PropertyEditComponentWrapper $borderColor={theme.primaryColor}>
      {item.dataType === "ts" && (
        <InputWithOverflow
          id={`${_prefixIdentifier}${identifier}[${index}].relativeTimeseries[${relativeTsIndex}].uuid`}
          name={`${_prefixIdentifier}${identifier}[${index}].relativeTimeseries[${relativeTsIndex}].uuid`}
          label="UUID"
          isEditing={isEditing}
          placeholder="wird automatisch generiert"
          disabled
        />
      )}
      {isEditing && (
        <RawSelect
          id={`${_prefixIdentifier}${identifier}[${index}].key`}
          name={`${_prefixIdentifier}${identifier}[${index}].key`}
          label="Template"
          onChange={onTemplateChange}
          value={item.key}
          hint={item.description || "Keine Beschreibung vorhanden"}
          disabled={item.propKey === "operatingStatus"}
        >
          <option value={"null"} key="null">
            --
          </option>
          {propertyTemplates
            ?.filter(
              (p) => p.dataType === "ts" || p.dataType === "timeseriesList"
            )
            .map(({ key, flags }) => (
              <option key={key} value={key} disabled={propertyExists(key)}>
                {key}
                {isPropertyRequired(values.flags, flags) ? " *" : ""}
              </option>
            ))}
        </RawSelect>
      )}
      <InlineItemsWrapper $vertical={vertical}>
        <Input
          flex={3}
          id={`${_prefixIdentifier}${identifier}[${index}].propKey`}
          name={`${_prefixIdentifier}${identifier}[${index}].propKey`}
          label="Key *"
          isEditing={isEditing}
          hint={
            item.key !== item.propKey
              ? `Benutzerdefinierte Eigenschaften müssen mit "custom_" anfangen.`
              : ""
          }
          disabled={item.propKey === "operatingStatus"}
        />
        <UnitsForm
          flex={2}
          identifier={`${_prefixIdentifier}${identifier}[${index}].unit`}
          isEditing={isEditing}
          units={unitKeys}
          label="Internal Unit"
          disabled={
            !unitKeys.length ||
            (item.unit !== null && unitKeys.length < 2) ||
            item.propKey === "operatingStatus"
          }
        />
        <Select
          flex={2}
          id={`${_prefixIdentifier}${identifier}[${index}].dataSubType`}
          name={`${_prefixIdentifier}${identifier}[${index}].dataSubType`}
          label="Data sub type *"
          isEditing={isEditing}
          disabled={item.propKey === "operatingStatus"}
        >
          <option disabled value="" key={"-"}>
            Bitte wählen
          </option>
          <option value="float">Float</option>
          <option value="integer">Integer</option>
          <option value="string">String</option>
          <option value="boolean">Boolean</option>
        </Select>
      </InlineItemsWrapper>
      {(item.isPlaceholder ||
        (item.propKey ?? item.key)?.includes("custom_")) && (
        <FormikInput
          id={`${_prefixIdentifier}${identifier}[${index}].description`}
          name={`${_prefixIdentifier}${identifier}[${index}].description`}
          label="Beschreibung *"
          isEditing={isEditing}
        />
      )}
      {item.dataType === "timeseriesList" && (
        <>
          <RawInput
            id={`${_prefixIdentifier}${identifier}[${index}].value.length`}
            name={`${_prefixIdentifier}${identifier}[${index}].value.length`}
            label="Anzahl Zeitreihen"
            isEditing={isEditing}
            type="number"
            value={item.value?.length ?? 0}
            onChange={(e) => {
              if (isNaN(+e.target.value)) return;
              setFieldValue(
                `${_prefixIdentifier}${identifier}[${index}].value`,
                Array.from({ length: +e.target.value }).map(
                  (_, index) => item.value[index]
                )
              );
            }}
          />
          <RawSelect
            id={`${_prefixIdentifier}${identifier}[${index}].relativeTsIndex`}
            name={`${_prefixIdentifier}${identifier}[${index}].relativeTsIndex`}
            label="Zeitreihe"
            onChange={(e) => {
              const index = item.relativeTimeseries?.findIndex(
                (ts) => ts.uuid === e.target.value
              );
              setRelativeTsIndex(index === -1 ? 0 : index);
            }}
            value={
              item.relativeTimeseries?.length
                ? item.relativeTimeseries[relativeTsIndex]?.uuid ?? "null"
                : "null"
            }
            disabled={!item.relativeTimeseries?.length}
          >
            {item.relativeTimeseries?.length ? (
              item.relativeTimeseries.map((ts) => (
                <option key={ts.uuid} value={ts.uuid}>
                  {ts.uuid}
                </option>
              ))
            ) : (
              <option value={"null"} key="null" disabled>
                Keine Zeitreihen vorhanden
              </option>
            )}
          </RawSelect>
        </>
      )}
      {!!item.relativeTimeseries?.length && (
        <>
          <TimeseriesForm
            isEditing={isEditing}
            units={allUnits}
            flowType="read"
            identifier={`${_prefixIdentifier}${identifier}[${index}].relativeTimeseries[${relativeTsIndex}]`}
          />
          {items[index].relativeTimeseries?.[relativeTsIndex]?.target.type &&
            items[index].relativeTimeseries?.[relativeTsIndex]?.target.type !==
              "none" && (
              <TimeseriesForm
                isEditing={isEditing}
                units={allUnits}
                flowType="write"
                identifier={`${_prefixIdentifier}${identifier}[${index}].relativeTimeseries[${relativeTsIndex}]`}
              />
            )}
        </>
      )}
      {!hideButtons && (
        <ButtonGroupPullRight>
          {isEditing && !!canDuplicate && (
            <Button secondary onClick={onDuplicate}>
              Duplizieren
            </Button>
          )}
          {isEditing && !item.isRequired && (
            <Button
              secondary
              customTheme={{
                primaryColor: theme.palette.error.color,
                secondaryColor: theme.palette.error.background,
              }}
              onClick={onDelete}
            >
              Löschen
            </Button>
          )}
          <Button secondary onClick={() => setEditMode(false)}>
            Schliessen
          </Button>
        </ButtonGroupPullRight>
      )}
    </PropertyEditComponentWrapper>
  );
};
