/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Anything: any;
  Date: Date;
  DateTime: any;
  JSON: any;
  JSONObject: any;
  Number: any;
  UUID: any;
  Void: void;
};

export type AccountingObject = {
  accountingObjectEnd: Scalars['DateTime'];
  accountingObjectStart: Scalars['DateTime'];
  basicPriceValue: Scalars['Float'];
  consumptionRate: Scalars['Float'];
  days: Scalars['Float'];
  eegPrice?: InputMaybe<Scalars['Float']>;
  energyPriceValueBasic?: InputMaybe<Scalars['Float']>;
  energyPriceValueLocal?: InputMaybe<Scalars['Float']>;
  energyPriceValueResidual?: InputMaybe<Scalars['Float']>;
  meterConverterFactor: Scalars['Float'];
  meterNumber: Scalars['String'];
  meterReadingDifference: Scalars['Float'];
  meterReadingInfo: MeterReadingInfo;
  meterReadingNew: Scalars['Float'];
  meterReadingOld: Scalars['Float'];
  priceSheetId: Scalars['Float'];
  tariffBasicPrice: Scalars['Float'];
  tariffEnergyPriceBasic?: InputMaybe<Scalars['Float']>;
  tariffEnergyPriceLocal?: InputMaybe<Scalars['Float']>;
  tariffEnergyPriceResidual?: InputMaybe<Scalars['Float']>;
  tariffId: Scalars['Float'];
};

export type ActiveTariff = {
  basicPrice: Scalars['Float'];
  energyPriceBasic?: InputMaybe<Scalars['Float']>;
  energyPriceLocal?: InputMaybe<Scalars['Float']>;
  energyPriceResidual?: InputMaybe<Scalars['Float']>;
  minimumDuration?: InputMaybe<Scalars['Float']>;
  minimumDurationExtension?: InputMaybe<Scalars['Float']>;
  noticePeriod: NoticePeriod;
  noticePeriodExtension: NoticePeriod;
  priceGuarantee?: InputMaybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  accountingEntity?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  streetNr: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  accountingEntity?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  streetNr: Scalars['String'];
  zip: Scalars['String'];
};

export enum AggregatorChoices {
  Average = 'AVERAGE',
  Maximum = 'MAXIMUM',
  Minimum = 'MINIMUM',
  Sum = 'SUM'
}

export type Asset = {
  __typename?: 'Asset';
  address?: Maybe<Address>;
  /** @deprecated Will be removed after 01/08/2023 */
  available?: Maybe<Scalars['Boolean']>;
  children: Array<AssetChild>;
  company: Scalars['String'];
  contracts: Array<Contract>;
  description?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  flags: AssetFlags;
  id: Scalars['String'];
  key: Scalars['String'];
  metadata: Array<Metadata>;
  name: Scalars['String'];
  notes: Array<Note>;
  properties: Array<Property>;
  template: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  uiAssets?: Maybe<AssetUiAssets>;
};


export type AssetPropertiesArgs = {
  filter?: InputMaybe<Filter>;
};

export type AssetChild = {
  __typename?: 'AssetChild';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  name: Scalars['String'];
  properties: Array<Property>;
  template: Scalars['String'];
};

export type AssetChildInput = {
  description?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  name: Scalars['String'];
  properties: Array<PropertyInput>;
  template: Scalars['String'];
};

export type AssetFlags = {
  __typename?: 'AssetFlags';
  isEndConsumption: Scalars['Boolean'];
  isMonitoringRelevant: Scalars['Boolean'];
  isOnsite: Scalars['Boolean'];
  isOptimizationRelevant: Scalars['Boolean'];
};

export type AssetFlagsInput = {
  isEndConsumption: Scalars['Boolean'];
  isMonitoringRelevant: Scalars['Boolean'];
  isOnsite: Scalars['Boolean'];
  isOptimizationRelevant: Scalars['Boolean'];
};

export type AssetInput = {
  address?: InputMaybe<AddressInput>;
  children: Array<AssetChildInput>;
  description?: InputMaybe<Scalars['String']>;
  flags: AssetFlagsInput;
  key: Scalars['String'];
  metadata: Array<MetadataInput>;
  name: Scalars['String'];
  notes: Array<NoteInput>;
  properties: Array<PropertyInput>;
  template: Scalars['String'];
};

export type AssetPatchInput = {
  address?: InputMaybe<AddressInput>;
  children?: InputMaybe<Array<AssetChildInput>>;
  description?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<AssetFlagsInput>;
  key: Scalars['String'];
  metadata?: InputMaybe<Array<MetadataInput>>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Array<NoteInput>>;
  properties?: InputMaybe<Array<PropertyInput>>;
};

export type AssetReport = {
  charts: Array<ReportChart>;
  tables: Array<ReportTable>;
  title: Scalars['String'];
};

export type AssetTemplate = {
  __typename?: 'AssetTemplate';
  assetType: Scalars['String'];
  children: Array<AssetTemplateChild>;
  description: Scalars['String'];
  metadata: Array<MetadataTemplate>;
  name: Scalars['String'];
  properties: Array<PropertyTemplate>;
  template: Scalars['String'];
};

export type AssetTemplateChild = {
  __typename?: 'AssetTemplateChild';
  description: Scalars['String'];
  name: Scalars['String'];
  properties: Array<PropertyTemplate>;
  template: Scalars['String'];
};

export type AssetUiAssets = {
  __typename?: 'AssetUiAssets';
  current?: Maybe<UiAsset>;
  monitoring: Array<UiAsset>;
  nodeValues: Array<UiAsset>;
  operatingStatus: UiAsset;
  report: Array<UiAsset>;
  warnings: Array<UiAsset>;
};

export type BaseMaloExcel = {
  contractFirstName: Scalars['String'];
  contractName: Scalars['String'];
  contractStreet: Scalars['String'];
  contractStreetNumber: Scalars['String'];
  contractTown: Scalars['String'];
  contractZIP: Scalars['String'];
  deliveryStartDate: Scalars['String'];
  lagezusatz?: InputMaybe<Scalars['String']>;
  malo?: InputMaybe<Scalars['String']>;
  melo?: InputMaybe<Scalars['String']>;
  meterNumber: Scalars['String'];
  meterinPointOperatorGLN: Scalars['String'];
  meterinPointOperatorName: Scalars['String'];
  plantLabel: Scalars['String'];
  plantMalo: Scalars['String'];
  plantStreet: Scalars['String'];
  plantStreetNumber: Scalars['String'];
  plantTown: Scalars['String'];
  plantZIP: Scalars['String'];
};

export type Building = {
  __typename?: 'Building';
  address: Address;
  assetType: Scalars['String'];
  assets: Array<Asset>;
  available?: Maybe<Scalars['Boolean']>;
  company: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
  metadata: Array<Metadata>;
  name: Scalars['String'];
  notes: Array<Note>;
  template: Scalars['String'];
  uiAssets?: Maybe<BuildingUiAssets>;
};


export type BuildingAssetsArgs = {
  filter?: InputMaybe<Filter>;
};

export type BuildingInput = {
  address: AddressInput;
  assetType: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  metadata: Array<MetadataInput>;
  name: Scalars['String'];
  notes: Array<NoteInput>;
};

export type BuildingUiAssets = {
  __typename?: 'BuildingUiAssets';
  report: Array<UiAsset>;
};

export type Check2FAuthReponse = ServiceNotReachableError | TwoFactorSessionExpired | TwoFactorSessionInvalid | UnknownError | User;

export type CheckResetPasswordTokenResponse = CheckResetPasswordTokenSuccess | NoPasswordResetSessionFound | ServiceNotReachableError | UnknownError | WrongPasswordResetFlow;

export type CheckResetPasswordTokenSuccess = {
  __typename?: 'CheckResetPasswordTokenSuccess';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CommonPriceComponent = {
  concessionLevy: Scalars['Float'];
  deferrableLoad: Scalars['Float'];
  electricityTax: Scalars['Float'];
  feeCHtPo: Scalars['Float'];
  feeReEnLa: Scalars['Float'];
  offshore: Scalars['Float'];
  strNEV: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
};

export type CompanyDistrict = {
  __typename?: 'CompanyDistrict';
  clientId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  company: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
  metadata: Array<Metadata>;
  name: Scalars['String'];
  notes: Array<Note>;
  properties: Array<Property>;
  template: Scalars['String'];
};


export type ContractPropertiesArgs = {
  filter?: InputMaybe<Filter>;
};

export type ContractAddress = {
  city: Scalars['String'];
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

export type ContractConfirmationLetter = {
  aJV: Scalars['Float'];
  billingAddress?: InputMaybe<DeliveryAddress>;
  contractAddress: ContractAddress;
  contractDeliveryStartDate: Scalars['DateTime'];
  contractLabel: Scalars['String'];
  contractSigningDate: Scalars['DateTime'];
  customerLabel: Scalars['String'];
  customerPrefix?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  iban?: InputMaybe<Scalars['String']>;
  invoiceCycle?: InputMaybe<Scalars['String']>;
  meterNumber: Scalars['String'];
  name: Scalars['String'];
  paymentType: PaymentType;
  reduction: Scalars['Float'];
  reductionStartDate: Scalars['DateTime'];
  salutation: Scalars['String'];
  shippingAddress?: InputMaybe<DeliveryAddress>;
  tariffPricesheetDetails: TariffPricesheetDetails;
};

export type ContractInput = {
  description?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  metadata: Array<MetadataInput>;
  name: Scalars['String'];
  notes: Array<NoteInput>;
  properties: Array<PropertyInput>;
  template: Scalars['String'];
};

export type ContractTemplate = {
  __typename?: 'ContractTemplate';
  description?: Maybe<Scalars['String']>;
  properties: Array<PropertyTemplate>;
  template: Scalars['String'];
};

export type ContractTemplateListItem = {
  __typename?: 'ContractTemplateListItem';
  description?: Maybe<Scalars['String']>;
  properties: Array<PropertyTemplate>;
  template: Scalars['String'];
};

export type CoveringLetter = {
  contractAddress: ContractAddress;
  customerPrefix?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  meterNumber: Scalars['String'];
  name: Scalars['String'];
};

export type CreateDocumentsInput = {
  clientId: Scalars['String'];
  contractId: Scalars['String'];
  documentStorageOptions?: InputMaybe<DocumentStorageOptions>;
  hasBusinessRecipient?: InputMaybe<Scalars['Boolean']>;
  payload: TemplatePayload;
  receiver?: InputMaybe<Scalars['String']>;
  workspace: Scalars['String'];
};

export type DeliveryAddress = {
  additionalName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type District = {
  __typename?: 'District';
  address: Address;
  assets: Array<Asset>;
  buildings: Array<Building>;
  company: Scalars['String'];
  companyKey: Scalars['String'];
  contact: DistrictContact;
  district: Scalars['String'];
  id: Scalars['String'];
  internal?: Maybe<Scalars['Anything']>;
  key: Scalars['String'];
  metadata: Array<Metadata>;
  name: Scalars['String'];
  notes: Array<Note>;
  properties: Array<Property>;
  state: Scalars['String'];
  uiAssets?: Maybe<DistrictUiAssets>;
};


export type DistrictAssetsArgs = {
  filter?: InputMaybe<Filter>;
};


export type DistrictPropertiesArgs = {
  filter?: InputMaybe<Filter>;
};

export type DistrictContact = {
  __typename?: 'DistrictContact';
  alertingEmails: Array<Scalars['String']>;
  reportingEmails: Array<Scalars['String']>;
};

export type DistrictContactInput = {
  alertingEmails: Array<Scalars['String']>;
  reportingEmails: Array<Scalars['String']>;
};

export type DistrictIdentifier = {
  company: Scalars['String'];
  district: Scalars['String'];
};

export type DistrictIdentifierInput = {
  company: Scalars['String'];
  district: Scalars['String'];
};

export type DistrictIdentifierOutput = {
  __typename?: 'DistrictIdentifierOutput';
  company: Scalars['String'];
  district: Scalars['String'];
};

export type DistrictInput = {
  address: AddressInput;
  contact?: InputMaybe<DistrictContactInput>;
  metadata: Array<MetadataInput>;
  name: Scalars['String'];
  notes: Array<NoteInput>;
  properties: Array<PropertyInput>;
  state: Scalars['String'];
};

export type DistrictReport = {
  accountingEntities: Array<InputMaybe<Scalars['String']>>;
  assetReports: Array<AssetReport>;
  charts: Array<ReportChart>;
  company: ReportCompany;
  date: Scalars['Date'];
  district: ReportDistrict;
  energyOrigin: ReportEnergyOrigin;
  kpiTables: ReportKpiTables;
  overviewEntities: Array<ReportOverviewEntity>;
};

export type DistrictStateItem = {
  __typename?: 'DistrictStateItem';
  messages: Array<Scalars['String']>;
  name: Scalars['String'];
  reference: Scalars['String'];
  type: Scalars['String'];
};

export type DistrictTemplate = {
  __typename?: 'DistrictTemplate';
  properties: Array<PropertyTemplate>;
};

export type DistrictUiAssets = {
  __typename?: 'DistrictUiAssets';
  dashboard: Array<UiAsset>;
  monitoring: Array<UiAsset>;
  optimization: Array<UiAsset>;
  projectOverview: Array<UiAsset>;
  report: Array<UiAsset>;
  secondDashboard?: Maybe<Array<UiAsset>>;
};

export type Document = {
  __typename?: 'Document';
  contentType: Scalars['String'];
  createdAt: Scalars['String'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentGroup?: Maybe<Scalars['String']>;
  userUpload?: Maybe<Scalars['Boolean']>;
};

export type DocumentInfo = {
  __typename?: 'DocumentInfo';
  contractId: Scalars['String'];
  hash: Scalars['String'];
  url: Scalars['String'];
};

export type DocumentStorageOptions = {
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  groupId: Scalars['String'];
  meta?: InputMaybe<Scalars['JSON']>;
  parentGroup?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
};

export type DocumentStorageOptionsUpdate = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
};

export type DocumentsForEntityResponse = DocumentsResponse | UnknownError;

export type DocumentsRemovedResponse = {
  __typename?: 'DocumentsRemovedResponse';
  deletedDocumentIds: Array<Maybe<Scalars['String']>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DocumentsResponse = {
  __typename?: 'DocumentsResponse';
  documents: Array<Document>;
};

export type Downpayment = {
  dueAt?: InputMaybe<Scalars['Date']>;
  total: Scalars['Float'];
};

export type EmailNotFound = {
  __typename?: 'EmailNotFound';
  message: Scalars['String'];
};

export type EnergyConcept = {
  __typename?: 'EnergyConcept';
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['String'];
  projectName: Scalars['String'];
  scenarioName: Scalars['String'];
  simulator: Scalars['String'];
};

export type Entity = {
  __typename?: 'Entity';
  collection: Scalars['String'];
  company: Scalars['String'];
  district: Scalars['String'];
  entity: Scalars['Anything'];
  id: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
};

export type ExcelPayload = {
  deregistrationExcel?: InputMaybe<BaseMaloExcel>;
  registrationExcel?: InputMaybe<BaseMaloExcel>;
};

export type ExcelResult = {
  __typename?: 'ExcelResult';
  url: Scalars['String'];
};

export type ExceptionResponse = {
  __typename?: 'ExceptionResponse';
  message?: Maybe<Scalars['String']>;
  suggestion?: Maybe<Scalars['String']>;
};

export type ExternalDocumentMetadata = {
  type: Scalars['String'];
};

export type ExternalImports = {
  __typename?: 'ExternalImports';
  weather: Array<Asset>;
};

export type Filter = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  entityKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  keys?: InputMaybe<Array<Scalars['String']>>;
  multipleDistricts?: InputMaybe<Array<DistrictIdentifier>>;
  name?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Array<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<Array<Scalars['String']>>;
  template?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['String']>;
  withProp?: InputMaybe<Scalars['String']>;
};

export type Forecast = {
  __typename?: 'Forecast';
  forecastTimestamp: Scalars['DateTime'];
  timeseriesId: Scalars['UUID'];
  timestamps: Array<Scalars['DateTime']>;
  values: Array<Scalars['Float']>;
};

export type ForecastMutation = {
  forecastTimestamp?: InputMaybe<Scalars['DateTime']>;
  timeseriesId: Scalars['UUID'];
  timestamps: Array<Scalars['DateTime']>;
  values: Array<Scalars['Float']>;
};

export type ForecastTask = {
  __typename?: 'ForecastTask';
  active: Scalars['Boolean'];
  id: Scalars['String'];
  key: Scalars['String'];
  timeRange: ForecastTimeRange;
  timeseries: Array<ForecastTimeseries>;
};

export type ForecastTaskInput = {
  active: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  timeRange: ForecastTimeRangeInput;
  timeseries: Array<ForecastTimeseriesInput>;
};

export type ForecastTimeRange = {
  __typename?: 'ForecastTimeRange';
  start?: Maybe<Scalars['String']>;
  stop: Scalars['String'];
};

export type ForecastTimeRangeInput = {
  start?: InputMaybe<Scalars['String']>;
  stop: Scalars['String'];
};

export type ForecastTimeseries = {
  __typename?: 'ForecastTimeseries';
  id: Scalars['String'];
  inputType: Scalars['String'];
  key: Scalars['String'];
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type ForecastTimeseriesInput = {
  id: Scalars['String'];
  inputType: Scalars['String'];
  key: Scalars['String'];
  type: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type Forecasts = {
  __typename?: 'Forecasts';
  forecasts: Array<Forecast>;
};

export type ForecastsArgs = {
  measurementPeriodEnd?: InputMaybe<Scalars['DateTime']>;
  measurementPeriodStart?: InputMaybe<Scalars['DateTime']>;
  resolution?: InputMaybe<ResolutionChoices>;
  timeseriesIds: Array<Scalars['UUID']>;
  timestampLocf?: InputMaybe<Scalars['DateTime']>;
};

export type ForecastsHistoryArgs = {
  aggregator?: InputMaybe<AggregatorChoices>;
  gapFill?: InputMaybe<Scalars['Boolean']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart: Scalars['DateTime'];
  resolution?: InputMaybe<ResolutionChoices>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type ForecastsResponse = Forecasts | ValueErrorResponse;

export type GapFinderArgs = {
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  resolution?: InputMaybe<Scalars['Int']>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type GapInfo = {
  __typename?: 'GapInfo';
  gapDurations: Array<Scalars['Float']>;
  gapEnds: Array<Scalars['DateTime']>;
  gapStarts: Array<Scalars['DateTime']>;
  timeseriesId: Scalars['UUID'];
};

export type GapsInfoList = {
  __typename?: 'GapsInfoList';
  gapInfos: Array<GapInfo>;
};

export type GapsInfoListResponse = GapsInfoList | ValueErrorResponse;

export type GenerateAndPreviewResult = Error | ExcelResult | MissingAttributeInContractDocumentError | PdfResult;

export type GenerateAndSendResult = Error | MissingAttributeInContractDocumentError | PdfResult;

export type GenerateDocumentError = {
  __typename?: 'GenerateDocumentError';
  contractId: Scalars['String'];
  reason: Scalars['String'];
};

export type GenerateDocumentsData = DocumentInfo | GenerateDocumentError;

export type GenerateMultipleAndSendResult = Error | MissingAttributeInContractDocumentError | PdfResults;

export type GenerateMultipleDocumentsResult = {
  __typename?: 'GenerateMultipleDocumentsResult';
  documents?: Maybe<Array<GenerateDocumentsData>>;
  jobId: Scalars['String'];
  jobName: Scalars['String'];
  progress: Scalars['Int'];
  status: Status;
  total: Scalars['Int'];
};

export type GenericLetter = {
  content: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GetDocumentsResponse = DocumentsResponse | NotFoundError | UnknownError;

export type IError = {
  /** error summary */
  message: Scalars['String'];
  /** a JSON Pointer [RFC6901] pointing to the error causing entity */
  pointer?: Maybe<Array<Scalars['String']>>;
  /** is the request with the same payload retryable */
  retryable?: Maybe<Scalars['Boolean']>;
};

export type InferenceInput = {
  __typename?: 'InferenceInput';
  factor: Scalars['Float'];
  timeseriesId: Scalars['String'];
};

export type InferenceTask = {
  __typename?: 'InferenceTask';
  inputs: Array<InferenceInput>;
  offset: Scalars['Float'];
  thresholdType?: Maybe<Scalars['Int']>;
  timeseriesId: Scalars['String'];
};

export type InferenceTaskGroup = {
  __typename?: 'InferenceTaskGroup';
  inferences: Array<InferenceTask>;
  key: Scalars['String'];
};

export enum InferenceTypeEnum {
  CustomSummation = 'CUSTOM_SUMMATION',
  DefaultDerivative = 'DEFAULT_DERIVATIVE',
  DefaultSummation = 'DEFAULT_SUMMATION',
  Optional = 'OPTIONAL'
}

export type InputSlimProperty = {
  key: Scalars['String'];
  origin: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export enum IntervalChoices {
  Daily = 'DAILY',
  Forever = 'FOREVER',
  Monthly = 'MONTHLY',
  ThirtyDays = 'THIRTY_DAYS',
  Yearly = 'YEARLY',
  YearlyDays = 'YEARLY_DAYS'
}

export type InvoiceDraftsLetter = {
  documentUrl: Scalars['String'];
};

export type InvoiceLetter = {
  accountingObjects: Array<AccountingObject>;
  accountingPeriodEnd: Scalars['DateTime'];
  accountingPeriodStart: Scalars['DateTime'];
  accountingType: Scalars['String'];
  activeTariff: ActiveTariff;
  addressBilling: DeliveryAddress;
  addressPlant: PlantAddress;
  addressShipping: DeliveryAddress;
  autarky: Scalars['Float'];
  backpayment: Scalars['Boolean'];
  commonPriceComponent: CommonPriceComponent;
  consumptionDayRate: Scalars['Float'];
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  customerPrefix?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  debtorLabel?: InputMaybe<Scalars['String']>;
  downpayment: Downpayment;
  iban?: InputMaybe<Scalars['String']>;
  invoice: Payment;
  invoiceCycle: Scalars['String'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  malo: Scalars['String'];
  measuringStationOperatorDetails: MeasuringStationOperatorDetails;
  melo: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  meterNumber: Scalars['String'];
  name: Scalars['String'];
  networkCharges: NetworkCharges;
  networkOperatorCode: Scalars['String'];
  nextCancelationDate: Scalars['String'];
  prepaymentSum: Payment;
  prepayments: Array<PrePayments>;
  salutation: Scalars['String'];
  sepa: Scalars['Boolean'];
  totalConsumptionRate: Scalars['Float'];
  totalDays: Scalars['Float'];
  totalDiff: Payment;
  totalOwnConsumptionRate: Scalars['Float'];
  totalRestConsumptionRate: Scalars['Float'];
  totalYearConsumptionRate: Scalars['Float'];
  vatRate: Scalars['Float'];
  voucherSum?: InputMaybe<Payment>;
  vouchers?: InputMaybe<Array<Vouchers>>;
};

export type Kpi = {
  __typename?: 'Kpi';
  kpiId: Scalars['UUID'];
  value: Scalars['Float'];
};

export type KpiReadArgs = {
  currentDate?: InputMaybe<Scalars['Date']>;
  interval?: InputMaybe<IntervalChoices>;
  kpiIds: Array<Scalars['UUID']>;
};

export type Kpis = {
  __typename?: 'Kpis';
  kpis: Array<Kpi>;
};

export type KpisMutation = {
  currentDate?: InputMaybe<Scalars['Date']>;
};

export type KpisResponse = Kpis;

export type LastTimeseries = {
  __typename?: 'LastTimeseries';
  earliestSameValueTimestamp?: Maybe<Scalars['DateTime']>;
  timeseriesId: Scalars['UUID'];
  timestamp?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type LastTimeseriesList = {
  __typename?: 'LastTimeseriesList';
  timeseries: Array<LastTimeseries>;
};

export type LoginResponse = ServiceNotReachableError | TwoFactorEmailFailed | TwoFactorRequired | UnknownError | User | WrongCredentialsError;

export type MailResult = {
  __typename?: 'MailResult';
  success: Scalars['Boolean'];
};

export type MeasurementsArgs = {
  aggregator?: InputMaybe<AggregatorChoices>;
  gapFill?: InputMaybe<Scalars['Boolean']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  resolution?: InputMaybe<ResolutionChoices>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type MeasurementsSumArgs = {
  aggregator?: InputMaybe<AggregatorChoices>;
  factors?: InputMaybe<Array<Scalars['Float']>>;
  gapFill?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Float']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  resolution?: InputMaybe<ResolutionChoices>;
  threshold?: InputMaybe<Scalars['Boolean']>;
  thresholdType?: InputMaybe<Scalars['Int']>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type MeasuringStationOperatorDetails = {
  address: ContractAddress;
  code: Scalars['String'];
  name: Scalars['String'];
};

export type Metadata = {
  __typename?: 'Metadata';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type MetadataInput = {
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type MetadataTemplate = {
  __typename?: 'MetadataTemplate';
  key: Scalars['String'];
  label: Scalars['String'];
  required: Scalars['Boolean'];
};

export type MeterReadingInfo = {
  beginHint: Scalars['String'];
  beginReason: Scalars['String'];
  endHint: Scalars['String'];
  endReason: Scalars['String'];
};

export type MissingAttributeInContractDocumentError = {
  __typename?: 'MissingAttributeInContractDocumentError';
  attribute: Scalars['String'];
};

export type Modelled = {
  __typename?: 'Modelled';
  timeseriesId: Scalars['UUID'];
  timestamps: Array<Scalars['DateTime']>;
  updatedAts: Array<Scalars['DateTime']>;
  values: Array<Scalars['Float']>;
};

export type ModelledArgs = {
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  resolution?: InputMaybe<ResolutionChoices>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type ModelledResponse = Modelleds | ValueErrorResponse;

export type Modelleds = {
  __typename?: 'Modelleds';
  modelleds: Array<Modelled>;
};

export type Mutation = {
  __typename?: 'Mutation';
  check2FAuth: Check2FAuthReponse;
  checkResetPasswordToken: CheckResetPasswordTokenResponse;
  clone?: Maybe<Scalars['Void']>;
  cloneAsset: Asset;
  /** This endpoint copys the measurements from 'copy_from_date' toeach date in the period [copy_to_period_start, copy_to_period_end]. */
  copyMeasurements: MutationResponse;
  createAsset: Asset;
  createBuilding: Building;
  createContract: Contract;
  createDistrict: District;
  /**
   * This endpoint inserts the given forecast data as well as the forecast meta dat into the database.
   * It creates a new forecast_id and sets the forecast_timestamp to now if not provided. If there is a (timestamp, forecast_id) conflict in the data, the conflicting data will not be added.
   */
  createForecast: MutationResponse;
  createForecastTask: ForecastTask;
  /** This endpoint inserts the given measurements into the database. On a (timestamp, timeseries_id) conflict it will not add the conflicting data. */
  createMeasurements: MutationResponse;
  /** This endpoint tries to insert all given modelled measurements. In case of a (timestamp, timeseries_id) conflict, it updates the values and updated_at field. */
  createModelled: MutationResponse;
  createSnapshot: Scalars['Anything'];
  executeMigrations?: Maybe<Scalars['Anything']>;
  generateAndPreviewPdf: GenerateAndPreviewResult;
  generateAndSend: GenerateAndSendResult;
  generateAndSendMultiple: GenerateMultipleDocumentsResult;
  generateDistrictReport?: Maybe<Scalars['String']>;
  generateExcel: GenerateAndPreviewResult;
  generateExternalIds: Array<Timeseries>;
  generateForecasts: Array<ForecastTask>;
  generateGrafanaDashboard: Scalars['Anything'];
  generateMultipleAndSend: GenerateMultipleAndSendResult;
  generateReport?: Maybe<Scalars['String']>;
  generateTimeseries: Array<Timeseries>;
  importEnergyConcept: District;
  importSnapshot: Scalars['Anything'];
  /** This endpoint tries to calculate all kpis for a company and a district given in the header of the request. If calculation fails due to missing or corrupted data, this will be reported in the return message. */
  kpisAll: MutationResponse;
  loginUser: LoginResponse;
  logoutUser: Scalars['Boolean'];
  migrateTimeseries?: Maybe<Scalars['Void']>;
  notificationSeen: Notification;
  patchAsset: Asset;
  patchTimeseries: Timeseries;
  publishTimeseries: Scalars['Anything'];
  relocateDocuments: DocumentsForEntityResponse;
  removeAssets?: Maybe<Scalars['Void']>;
  removeBuildings?: Maybe<Scalars['Void']>;
  removeContractDocuments: RemoveContractDocumentsResponse;
  removeContracts?: Maybe<Scalars['Void']>;
  removeDocuments: RemoveDocumentsResponse;
  removeForecastTasks?: Maybe<Scalars['Void']>;
  removeFullDistrict?: Maybe<Scalars['Void']>;
  removeSnapshots?: Maybe<Scalars['Void']>;
  removeTimeseries?: Maybe<Scalars['Void']>;
  resetPassword?: Maybe<ResetPasswordResponse>;
  restoreSnapshot: Scalars['Anything'];
  sendB2cMail: SendMailResult;
  sendMail: SendMailResult;
  sendNotification: Notification;
  sendPasswordResetEmail: SendPasswordResetEmailResponse;
  triggerForecasts: DistrictIdentifierOutput;
  triggerInferenceJob: Array<DistrictIdentifierOutput>;
  triggerKpisJob: Array<DistrictIdentifierOutput>;
  triggerOptimization: DistrictIdentifierOutput;
  triggerReportsJob: Array<DistrictIdentifierOutput>;
  triggerSnapshotsJob: Array<DistrictIdentifierOutput>;
  unpublishTimeseries: Scalars['Anything'];
  updateAsset: Asset;
  updateBuilding: Building;
  updateContract: Contract;
  updateDistrict: District;
  updateDistrictState: District;
  updateDocument: UpdateDocumentResponse;
  updateForecastTask: ForecastTask;
  updatePassword?: Maybe<UpdatePasswordResponse>;
  updateTimeseries: Timeseries;
  uploadTimeseriesExport: Scalars['String'];
  uploadWarningsExport: Scalars['String'];
  validateFullDistrict: Scalars['Anything'];
};


export type MutationCheck2FAuthArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckResetPasswordTokenArgs = {
  token: Scalars['String'];
};


export type MutationCloneArgs = {
  targetCompany: Scalars['String'];
  targetDistrict: Scalars['String'];
};


export type MutationCloneAssetArgs = {
  sourceKey: Scalars['String'];
  targetKey: Scalars['String'];
};


export type MutationCopyMeasurementsArgs = {
  inputs: TimeseriesCopyMutation;
};


export type MutationCreateAssetArgs = {
  asset: AssetInput;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBuildingArgs = {
  building: BuildingInput;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateContractArgs = {
  contract: ContractInput;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDistrictArgs = {
  district: DistrictInput;
};


export type MutationCreateForecastArgs = {
  inputs: ForecastMutation;
};


export type MutationCreateForecastTaskArgs = {
  forecast: ForecastTaskInput;
};


export type MutationCreateMeasurementsArgs = {
  inputs: TimeseriesListMutation;
};


export type MutationCreateModelledArgs = {
  inputs: TimeseriesListMutation;
};


export type MutationCreateSnapshotArgs = {
  description?: InputMaybe<Scalars['String']>;
};


export type MutationGenerateAndPreviewPdfArgs = {
  clientId: Scalars['String'];
  forceGeneric?: InputMaybe<Scalars['Boolean']>;
  payload: TemplatePayload;
  workspace: Scalars['String'];
};


export type MutationGenerateAndSendArgs = {
  bccReceiver?: InputMaybe<Scalars['String']>;
  clientId: Scalars['String'];
  documentStorageOptions?: InputMaybe<DocumentStorageOptions>;
  hasBusinessRecipient?: InputMaybe<Scalars['Boolean']>;
  payload: TemplatePayload;
  receivers?: InputMaybe<Array<Scalars['String']>>;
  workspace: Scalars['String'];
};


export type MutationGenerateAndSendMultipleArgs = {
  inputs: Array<CreateDocumentsInput>;
  receiver?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  zipName?: InputMaybe<Scalars['String']>;
};


export type MutationGenerateDistrictReportArgs = {
  districtId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationGenerateExcelArgs = {
  documentStorageOptions?: InputMaybe<DocumentStorageOptions>;
  payload: ExcelPayload;
};


export type MutationGenerateExternalIdsArgs = {
  pointList?: InputMaybe<Array<PointListItem>>;
};


export type MutationGenerateMultipleAndSendArgs = {
  clientId: Scalars['String'];
  documentStorageOptions?: InputMaybe<Array<DocumentStorageOptions>>;
  hasBusinessRecipient?: InputMaybe<Scalars['Boolean']>;
  payload: TemplatePayload;
  receiver?: InputMaybe<Scalars['String']>;
  workspace: Scalars['String'];
};


export type MutationGenerateReportArgs = {
  clientId: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationImportEnergyConceptArgs = {
  district: DistrictInput;
  energyConceptId: Scalars['String'];
};


export type MutationImportSnapshotArgs = {
  snapshot: Scalars['Anything'];
};


export type MutationKpisAllArgs = {
  inputs: KpisMutation;
};


export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  twoFactorAuthCallbackURL: Scalars['String'];
};


export type MutationNotificationSeenArgs = {
  id: Scalars['String'];
  seenAt: Scalars['DateTime'];
};


export type MutationPatchAssetArgs = {
  asset: AssetPatchInput;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationPatchTimeseriesArgs = {
  timeseries: TimeseriesPatchInput;
};


export type MutationPublishTimeseriesArgs = {
  filter?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationRelocateDocumentsArgs = {
  from: DocumentStorageOptions;
  to: DocumentStorageOptions;
};


export type MutationRemoveAssetsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRemoveBuildingsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRemoveContractDocumentsArgs = {
  contractId: Scalars['String'];
};


export type MutationRemoveContractsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRemoveDocumentsArgs = {
  hash: Scalars['String'];
};


export type MutationRemoveForecastTasksArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRemoveSnapshotsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRemoveTimeseriesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRestoreSnapshotArgs = {
  id: Scalars['String'];
};


export type MutationSendB2cMailArgs = {
  clientId: Scalars['String'];
  payload: TemplatePayload;
  receiver: Scalars['String'];
  subject: Scalars['String'];
  workspace: Scalars['String'];
};


export type MutationSendMailArgs = {
  payload: TemplatePayload;
  receiver: Scalars['String'];
};


export type MutationSendNotificationArgs = {
  notification: NotificationInput;
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
  resetPasswordCallbackUrl: Scalars['String'];
};


export type MutationUpdateAssetArgs = {
  asset: AssetInput;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBuildingArgs = {
  building: BuildingInput;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateContractArgs = {
  contract: ContractInput;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDistrictArgs = {
  district: DistrictInput;
};


export type MutationUpdateDistrictStateArgs = {
  state: Scalars['String'];
};


export type MutationUpdateDocumentArgs = {
  documentStorageOptionsUpdate: DocumentStorageOptionsUpdate;
  hash: Scalars['String'];
};


export type MutationUpdateForecastTaskArgs = {
  forecast: ForecastTaskInput;
};


export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  updatePasswordCallbackUrl: Scalars['String'];
};


export type MutationUpdateTimeseriesArgs = {
  timeseries: TimeseriesInput;
};


export type MutationUploadTimeseriesExportArgs = {
  assetId?: InputMaybe<Scalars['ID']>;
  data: Array<Scalars['JSON']>;
  districtId: Scalars['ID'];
  fileName: Scalars['String'];
  metadata: Array<TimeseriesExportMetadata>;
};


export type MutationUploadWarningsExportArgs = {
  assetId?: InputMaybe<Scalars['ID']>;
  data: Array<Scalars['JSON']>;
  districtId: Scalars['ID'];
  districtName: Scalars['String'];
  fileName: Scalars['String'];
};

export type MutationResponse = ExceptionResponse | SuccessResponse;

export type NetworkCharges = {
  chargeBill: Scalars['Float'];
  chargeMeterop: Scalars['Float'];
  chargeMeterread: Scalars['Float'];
  energyPrice: Scalars['Float'];
  fixedPrice: Scalars['Float'];
  networkChargesEnd: Scalars['DateTime'];
  networkChargesStart: Scalars['DateTime'];
};

export type NoPasswordResetSessionFound = {
  __typename?: 'NoPasswordResetSessionFound';
  message: Scalars['String'];
};

export type NotFoundError = {
  __typename?: 'NotFoundError';
  message: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
};

export type NoteInput = {
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
};

export type NoticePeriod = {
  count: Scalars['Float'];
  period: Scalars['String'];
  to: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  company: Scalars['String'];
  createdAt: Scalars['DateTime'];
  district: Scalars['String'];
  id: Scalars['String'];
  message: Scalars['String'];
  reference: NotificationReference;
  seenAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type NotificationInput = {
  company: Scalars['String'];
  createdAt: Scalars['DateTime'];
  district: Scalars['String'];
  message: Scalars['String'];
  reference: NotificationReferenceInput;
  seenAt?: InputMaybe<Scalars['DateTime']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type NotificationReference = {
  __typename?: 'NotificationReference';
  entityKey: Scalars['String'];
  type: Scalars['String'];
};

export type NotificationReferenceInput = {
  entityKey: Scalars['String'];
  type: Scalars['String'];
};

export type OperatingStatusOkay = {
  asset: Scalars['String'];
  district: Scalars['String'];
  errorMessage?: InputMaybe<Scalars['String']>;
  time: Scalars['DateTime'];
};

export type OperatingStatusWarning = {
  asset: Scalars['String'];
  district: Scalars['String'];
  message: Scalars['String'];
  time: Scalars['DateTime'];
};

export type Optimization = {
  __typename?: 'Optimization';
  components: Scalars['Anything'];
  connections: Scalars['Anything'];
  globals: Scalars['Anything'];
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  objectiveConstraint: Scalars['String'];
  objectiveConstraints: Array<Scalars['String']>;
  objectiveSense: Scalars['Float'];
  refTimeFrequency: Scalars['String'];
  shape: Scalars['String'];
  solverSettings: Scalars['Anything'];
  thresholdExportByTerminationCondition?: Maybe<Scalars['Int']>;
  thresholdSolverGapForTerminationCondition?: Maybe<Scalars['Float']>;
  timeseriesMappings: Scalars['Anything'];
};

export type Payment = {
  net?: InputMaybe<Scalars['Float']>;
  tax?: InputMaybe<Scalars['Float']>;
  total: Scalars['Float'];
};

export enum PaymentType {
  Cash = 'CASH',
  Sepa = 'SEPA'
}

export type PdfResult = {
  __typename?: 'PdfResult';
  hash: Scalars['String'];
  url: Scalars['String'];
};

export type PdfResults = {
  __typename?: 'PdfResults';
  results: Array<PdfResult>;
};

export type Plant = {
  address: PlantAddress;
  balancingAreaAccount: PlantNameNumberPair;
  balancingAreaGroupAccount: PlantNameNumberPair;
  id: Scalars['ID'];
  measurementConcept: Scalars['String'];
  measuringPoints: Scalars['Int'];
  meteringPointOperator: PlantNameNumberPair;
  name: Scalars['String'];
  projectId: Scalars['String'];
  supplierAccount: PlantNameNumberPair;
  transmissionSystemOperator: PlantNameNumberPair;
};

export type PlantAddress = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type PlantNameNumberPair = {
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type PlantReport = {
  date: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  energy: PlantReportEnergy;
  plant: Plant;
  salesCounts: PlantReportSalesCounts;
  salesRatios: PlantReportSalesRatios;
  startDate: Scalars['DateTime'];
};

export type PlantReportEnergy = {
  autarkyLevel?: InputMaybe<Scalars['Float']>;
  consumptionBHKW?: InputMaybe<Scalars['Float']>;
  consumptionBuilding?: InputMaybe<Scalars['Float']>;
  consumptionEVcharging?: InputMaybe<Scalars['Float']>;
  consumptionHeatPump?: InputMaybe<Scalars['Float']>;
  consumptionTenants?: InputMaybe<Scalars['Float']>;
  consumptionTotal?: InputMaybe<Scalars['Float']>;
  generationAmountBHKW?: InputMaybe<Scalars['Float']>;
  generationAmountPV?: InputMaybe<Scalars['Float']>;
  generationAmountTotal?: InputMaybe<Scalars['Float']>;
  gridConsumption?: InputMaybe<Scalars['Float']>;
  gridFeedIn?: InputMaybe<Scalars['Float']>;
  lastAccountingPeriodEndDate?: InputMaybe<Scalars['DateTime']>;
  lastAccountingPeriodStartDate?: InputMaybe<Scalars['DateTime']>;
  plantFullSupply: Scalars['Boolean'];
};

export type PlantReportSalesCounts = {
  endedContractsInEvaluationPeriod: Scalars['Int'];
  newContractsInSupplyInEvaluationPeriod: Scalars['Int'];
  newContractsSignedInEvaluationPeriod: Scalars['Int'];
};

export type PlantReportSalesRatios = {
  participationActiveAndSignedTotal: Scalars['Int'];
  participationActiveTotal: Scalars['Int'];
  participationBuildingActive: Scalars['Int'];
  participationBuildingActiveAndSigned: Scalars['Int'];
  participationRatioActiveAndSignedTotal: Scalars['Float'];
  participationRatioActiveTotal: Scalars['Float'];
  participationRatioBuildingActive: Scalars['Float'];
  participationRatioBuildingActiveAndSigned: Scalars['Float'];
  participationRatioTenantsActive: Scalars['Float'];
  participationRatioTenantsActiveAndSigned: Scalars['Float'];
  participationTenantsActive: Scalars['Int'];
  participationTenantsActiveAndSigned: Scalars['Int'];
  plantMeterCount: Scalars['Int'];
};

export type PoaCancellationLetter = {
  customerPrefix?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  shippingAddress: ContractAddress;
  supplierName: Scalars['String'];
};

export type PointListItem = {
  cov?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eboDeviceId?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  iotDeviceId?: InputMaybe<Scalars['String']>;
  objectName?: InputMaybe<Scalars['String']>;
  pointId: Scalars['String'];
  propertyPath: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  unitCategory?: InputMaybe<Scalars['String']>;
  valueType?: InputMaybe<Scalars['String']>;
  writeable?: InputMaybe<Scalars['String']>;
};

export type PrePayments = {
  net?: InputMaybe<Scalars['Float']>;
  paidAt: Scalars['String'];
  tax?: InputMaybe<Scalars['Float']>;
  total: Scalars['Float'];
};

export type PrefilledAsset = {
  __typename?: 'PrefilledAsset';
  children: Array<AssetChild>;
  company: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
  metadata: Array<Metadata>;
  name: Scalars['String'];
  properties: Array<Property>;
  template: Scalars['String'];
};

export type PricesheetChangeLetter = {
  billingAddress: DeliveryAddress;
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  date: Scalars['DateTime'];
  meterNumber: Scalars['String'];
  name: Scalars['String'];
  priceGuaranteeActive: Scalars['Boolean'];
  priceGuaranteeEndDate: Scalars['DateTime'];
  pricesheetName: Scalars['String'];
  pricesheetStartDate: Scalars['DateTime'];
  reason: Scalars['String'];
  salutation: Scalars['String'];
  tariffName: Scalars['String'];
  tariffNewPricesheetDetails: TariffPricesheetDetails;
  tariffOldPricesheetDetails: TariffPricesheetDetails;
};

export type PricesheetLetter = {
  basicPrice: Scalars['Float'];
  customerPrefix?: InputMaybe<Scalars['String']>;
  energyPrice?: InputMaybe<Scalars['Float']>;
  energyPriceLocal?: InputMaybe<Scalars['Float']>;
  energyPriceResidual?: InputMaybe<Scalars['Float']>;
  hasMultiplePrices: Scalars['Boolean'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type Property = {
  __typename?: 'Property';
  dataSubType: Scalars['String'];
  dataType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  internal: Scalars['Boolean'];
  key: Scalars['String'];
  optimization: PropertyOptimization;
  unit?: Maybe<Scalars['String']>;
  value: Scalars['Anything'];
};

export type PropertyInput = {
  dataSubType: Scalars['String'];
  dataType: Scalars['String'];
  description: Scalars['String'];
  internal: Scalars['Boolean'];
  key: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Anything']>;
};

export type PropertyOptimization = {
  __typename?: 'PropertyOptimization';
  inputAggregation?: Maybe<Scalars['String']>;
  isInput: Scalars['Boolean'];
  isOutput?: Maybe<Scalars['Boolean']>;
};

export type PropertyTemplate = {
  __typename?: 'PropertyTemplate';
  dataSubType: Scalars['String'];
  dataType: Scalars['String'];
  defaultValue?: Maybe<Scalars['Anything']>;
  description: Scalars['String'];
  flags: PropertyTemplateFlags;
  key: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type PropertyTemplateFlags = {
  __typename?: 'PropertyTemplateFlags';
  isMonitoringRelevant: Scalars['Boolean'];
  isOperationRelevant: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  activeWarnings: Array<Warning>;
  assetTemplate: AssetTemplate;
  assetTemplates: Array<AssetTemplate>;
  assets: Array<Asset>;
  authorizeTimeseries: Array<Scalars['String']>;
  bstProperties: Scalars['Anything'];
  buildings: Array<Building>;
  checkDistrictState: Array<DistrictStateItem>;
  constants: Scalars['Anything'];
  contractTemplate: ContractTemplate;
  contractTemplates: Array<ContractTemplateListItem>;
  contracts: Array<Contract>;
  district: District;
  districtTemplate: DistrictTemplate;
  districts: Array<District>;
  documentsForEntity: DocumentsForEntityResponse;
  energyConcepts: Array<EnergyConcept>;
  externalImports: ExternalImports;
  find: Array<Maybe<Entity>>;
  /** This endpoint returns the latest forecasts before forecast_timestamp. measurement_period_start and measurement_period_end are optinal, if not set the endpoint returns the complete forecasts. */
  forecast: ForecastsResponse;
  /**
   * This endpoint returns the sum of the time weighted averages of the latest forecasts for each given timeseries.
   * measurement_period_start and measurement_period_end are optinal, if not given they will be set to the earliest/latests measurement of the given forecasts.
   */
  forecastSum: TimeseriesResponse;
  forecastTasks: Array<ForecastTask>;
  /**
   * If resolution and aggregator are set, each forecast will be resampled in its own time period respectively.
   * The timesteps of the resampling will be chosen acording to the Forward-Filling logic.
   * If gapfilling is set, the missing values will be filled by linear interpolation.
   */
  forecasts: ForecastsResponse;
  getAllNotifications: Array<Notification>;
  getDocuments?: Maybe<GetDocumentsResponse>;
  getDocumentsForEntities: DocumentsForEntityResponse;
  /** This endpoint finds all data gaps within the given time period for each timeseries and provides the start and end timestamp as well as the duration of each gap. */
  getGaps: GapsInfoListResponse;
  getGenerateMultipleDocumentsResult: GenerateMultipleDocumentsResult;
  /**
   * This endpoint returns the values and ids of the requested kpis.
   * If a monthly kpi is requested the given current_date can be any date withhin the desired month. Same holds true for yearly kpis. For 30 day kpis current_date should be the last of the 30 days.
   */
  getKpis: KpisResponse;
  importInferences: Array<InferenceTaskGroup>;
  internalUnits: Array<Unit>;
  kafkaInferences: Array<InferenceTaskGroup>;
  /** If periodStart is not, it will be set to 'now - 30days'. If periodEnd is not set, the upper limit will be unbounded. */
  lastMeasurements: TimeseriesLastListResponse;
  /**
   * If aggregator is None, the endpoint returns raw measurement values. Only in this case periodStart and periodEnd are allowed to be None.
   * If aggregator is not None, but resolution is None the endpoint will aggregate each timeseries into one value over the complete timeperiod.
   * If both aggregator and resolution are not None, the measurements will be resampled. If gapfilling is set, the missing values will be filled by linear interpolation.
   */
  measurements: TimeseriesListResponse;
  /** This endpoint calculates the sum of the time weighted averages of all given timeseries. It fills all gaps in the data in the chosen time period. Resolution, aggregator and gapfill are not used in this endpoint. */
  measurementsSum: TimeseriesResponse;
  /** This endpoint returns the data of all given moddeld timeseries within the given period. */
  modelled: ModelledResponse;
  /** This endpoint calculates the sum of the time weighted averages of all given timeseries. It fills all gaps in the data in the chosen time period.  */
  modelledSum: TimeseriesResponse;
  notifications: Array<Notification>;
  optimizations: Array<Optimization>;
  prefilledAssets: Array<PrefilledAsset>;
  readCurrentUser: User;
  readDocuments: ReadDocumentsResponse;
  regulatoryLoadProfiles: Scalars['Anything'];
  snapshot: Scalars['Anything'];
  snapshots: Array<Scalars['Anything']>;
  timeseries: Array<Timeseries>;
  timeseriesState: Array<Scalars['String']>;
  unit: Unit;
  units: Array<Unit>;
  warnings: Array<Warning>;
  workConfig: Scalars['Anything'];
};


export type QueryActiveWarningsArgs = {
  entityKey?: InputMaybe<Scalars['String']>;
  statusTypes?: InputMaybe<Array<WarningStatusType>>;
};


export type QueryAssetTemplateArgs = {
  templateKey: Scalars['String'];
};


export type QueryAssetsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryAuthorizeTimeseriesArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  ids: Array<Scalars['String']>;
};


export type QueryBuildingsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryCheckDistrictStateArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  state: Scalars['String'];
};


export type QueryContractTemplateArgs = {
  templateKey: Scalars['String'];
};


export type QueryContractsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryDistrictArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
};


export type QueryDistrictsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryDocumentsForEntityArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryEnergyConceptsArgs = {
  endDate: Scalars['DateTime'];
  groupByProjectName?: InputMaybe<Scalars['Boolean']>;
  projectName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};


export type QueryFindArgs = {
  id?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};


export type QueryForecastArgs = {
  inputs: ForecastsArgs;
};


export type QueryForecastSumArgs = {
  inputs: ForecastsArgs;
};


export type QueryForecastTasksArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryForecastsArgs = {
  inputs: ForecastsHistoryArgs;
};


export type QueryGetDocumentsArgs = {
  hashes: Array<Scalars['String']>;
};


export type QueryGetDocumentsForEntitiesArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetGapsArgs = {
  inputs: GapFinderArgs;
};


export type QueryGetGenerateMultipleDocumentsResultArgs = {
  jobId?: InputMaybe<Scalars['String']>;
};


export type QueryGetKpisArgs = {
  inputs: KpiReadArgs;
};


export type QueryImportInferencesArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
};


export type QueryKafkaInferencesArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
};


export type QueryLastMeasurementsArgs = {
  inputs: LastMeasurementsArgs;
};


export type QueryMeasurementsArgs = {
  inputs: MeasurementsArgs;
};


export type QueryMeasurementsSumArgs = {
  inputs: MeasurementsSumArgs;
};


export type QueryModelledArgs = {
  inputs: ModelledArgs;
};


export type QueryModelledSumArgs = {
  inputs: ModelledArgs;
};


export type QueryNotificationsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryOptimizationsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryPrefilledAssetsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  templateKey: Scalars['String'];
};


export type QueryReadDocumentsArgs = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
};


export type QuerySnapshotArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
};


export type QuerySnapshotsArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
};


export type QueryTimeseriesArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  filter?: InputMaybe<Filter>;
};


export type QueryTimeseriesStateArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
};


export type QueryUnitArgs = {
  key: Scalars['String'];
};


export type QueryWarningsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  entityKey?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  statusTypes?: InputMaybe<Array<WarningStatusType>>;
};


export type QueryWorkConfigArgs = {
  districtIdentifier?: InputMaybe<DistrictIdentifier>;
  id: Scalars['String'];
};

export type ReadDocumentsResponse = DocumentsResponse | UnknownError;

export type RemoveContractDocumentsResponse = DocumentsRemovedResponse | UnknownError;

export type RemoveDocumentsResponse = RemoveResponse | UnknownError;

export type RemoveResponse = {
  __typename?: 'RemoveResponse';
  success: Scalars['Boolean'];
};

export type ReportChart = {
  datasets: Array<ReportChartDataset>;
  title: Scalars['String'];
};

export type ReportChartData = {
  date: Scalars['Date'];
  value: Scalars['Float'];
};

export type ReportChartDataset = {
  color: Scalars['String'];
  data: Array<ReportChartData>;
  label: Scalars['String'];
};

export type ReportCompany = {
  address: ContractAddress;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type ReportDistrict = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type ReportDonutChartData = {
  color: Scalars['String'];
  label: Scalars['String'];
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type ReportEnergyOrigin = {
  charts: ReportEnergyOriginCharts;
  tables: Array<ReportTable>;
};

export type ReportEnergyOriginCharts = {
  electricity: Array<ReportDonutChartData>;
  heat: Array<ReportDonutChartData>;
};

export type ReportKpiTables = {
  electricity?: InputMaybe<ReportTable>;
  gas?: InputMaybe<ReportTable>;
  heat?: InputMaybe<ReportTable>;
};

export type ReportOverviewEntity = {
  label: Scalars['String'];
  type: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type ReportTable = {
  columns: Array<ReportTableColumn>;
  data: Array<Scalars['JSON']>;
  showHeaders: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
};

export type ReportTableColumn = {
  field: Scalars['String'];
  title: Scalars['String'];
};

export type ResetPasswordLink = {
  confirmLink: Scalars['String'];
};

export type ResetPasswordResponse = NoPasswordResetSessionFound | ResetPasswordSuccess | ServiceNotReachableError | UnknownError | WrongPasswordResetFlow;

export type ResetPasswordSuccess = {
  __typename?: 'ResetPasswordSuccess';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum ResolutionChoices {
  Daily = 'DAILY',
  FifteenMins = 'FIFTEEN_MINS',
  FiveMins = 'FIVE_MINS',
  Monthly = 'MONTHLY',
  OneHour = 'ONE_HOUR',
  OneMin = 'ONE_MIN',
  ThirtyDays = 'THIRTY_DAYS',
  ThirtyMins = 'THIRTY_MINS',
  TwoHours = 'TWO_HOURS'
}

export type ResolvedInference = {
  __typename?: 'ResolvedInference';
  inputs: Array<InferenceInput>;
  offset?: Maybe<Scalars['Float']>;
  thresholdType?: Maybe<Scalars['Int']>;
  type: InferenceTypeEnum;
};

export type ReversalInvoiceLetter = {
  addressBilling: DeliveryAddress;
  addressShipping: DeliveryAddress;
  backpayment: Scalars['Boolean'];
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  customerPrefix?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  debtorLabel?: InputMaybe<Scalars['String']>;
  diffAmount: Scalars['Float'];
  downpayment: Downpayment;
  hasPaid: Scalars['Boolean'];
  iban?: InputMaybe<Scalars['String']>;
  invoice: Payment;
  invoiceDate: Scalars['DateTime'];
  invoiceNumber: Scalars['String'];
  name: Scalars['String'];
  originalInvoiceDate: Scalars['DateTime'];
  originalInvoiceNumber: Scalars['String'];
  prepaymentSum: Payment;
  prepayments?: InputMaybe<Array<PrePayments>>;
  sepa: Scalars['Boolean'];
  totalDiff: Payment;
  vatRate: Scalars['Float'];
  voucherSum: Payment;
  vouchers?: InputMaybe<Array<Vouchers>>;
};

export type SendMailResult = Error | MailResult;

export type SendPasswordResetEmailResponse = SendPasswordResetEmailSuccess | ServiceNotReachableError | UnknownError | WrongPasswordResetFlow;

export type SendPasswordResetEmailSuccess = {
  __typename?: 'SendPasswordResetEmailSuccess';
  email: Scalars['String'];
};

export type ServiceNotReachableError = {
  __typename?: 'ServiceNotReachableError';
  message: Scalars['String'];
};

export enum Status {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type Subscription = {
  __typename?: 'Subscription';
  notificationAdded?: Maybe<Notification>;
};


export type SubscriptionNotificationAddedArgs = {
  districtIdentifiers: Array<DistrictIdentifier>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  message?: Maybe<Scalars['String']>;
};

export type SummationArgument = {
  __typename?: 'SummationArgument';
  entityKey: Scalars['String'];
  factor: Scalars['Float'];
  propertyKey: Scalars['String'];
};

export type SummationArgumentInput = {
  entityKey: Scalars['String'];
  factor: Scalars['Float'];
  propertyKey: Scalars['String'];
};

export type SummationInference = {
  __typename?: 'SummationInference';
  inputs: Array<SummationArgument>;
  offset: Scalars['Float'];
  thresholdType?: Maybe<Scalars['Int']>;
};

export type SummationInferenceInput = {
  inputs: Array<SummationArgumentInput>;
  offset: Scalars['Float'];
  thresholdType?: InputMaybe<Scalars['Int']>;
};

export type SupplierCancellationLetter = {
  billingAddress: ContractAddress;
  contractLabelOldSupplier?: InputMaybe<Scalars['String']>;
  meterNumber: Scalars['String'];
  name: Scalars['String'];
  shippingAddress: ContractAddress;
  supplierAddress: ContractAddress;
  supplierName: Scalars['String'];
};

export type SupplierContractLetter = {
  aJV?: InputMaybe<Scalars['Float']>;
  billingAddress: ContractAddress;
  customerPrefix?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  meterNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  reduction?: InputMaybe<Scalars['Float']>;
  shippingAddress: ContractAddress;
  startOfDelivery?: InputMaybe<Scalars['DateTime']>;
  supplierName: Scalars['String'];
};

export type TariffPricesheetDetails = {
  basicPrice: Scalars['Float'];
  workingPrice: Scalars['Float'];
};

export type TemplatePayload = {
  contractConfirmationLetter?: InputMaybe<ContractConfirmationLetter>;
  coveringLetter?: InputMaybe<CoveringLetter>;
  districtReport?: InputMaybe<DistrictReport>;
  genericLetter?: InputMaybe<GenericLetter>;
  invoiceDraftsLetter?: InputMaybe<InvoiceDraftsLetter>;
  invoiceLetter?: InputMaybe<InvoiceLetter>;
  operatingStatusOkay?: InputMaybe<OperatingStatusOkay>;
  operatingStatusWarning?: InputMaybe<OperatingStatusWarning>;
  plantReport?: InputMaybe<PlantReport>;
  poaCancellationLetter?: InputMaybe<PoaCancellationLetter>;
  pricesheetChangeLetter?: InputMaybe<PricesheetChangeLetter>;
  pricesheetLetter?: InputMaybe<PricesheetLetter>;
  resetPasswordLink?: InputMaybe<ResetPasswordLink>;
  reversalInvoiceLetter?: InputMaybe<ReversalInvoiceLetter>;
  supplierCancellationLetter?: InputMaybe<SupplierCancellationLetter>;
  supplierContractLetter?: InputMaybe<SupplierContractLetter>;
  twoFactorAuthLetter?: InputMaybe<TwoFactorAuthLetter>;
  updatePasswordLetter?: InputMaybe<UpdatePasswordLetter>;
  welcomeLetter?: InputMaybe<WelcomeLetter>;
  withdrawalLetter?: InputMaybe<WithdrawalLetter>;
};

export type Timeseries = {
  __typename?: 'Timeseries';
  comment?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  constraints: Array<TimeseriesConstraint>;
  description: Scalars['String'];
  district: Scalars['String'];
  id: Scalars['String'];
  inference?: Maybe<TimeseriesInference>;
  metadata: TimeseriesMetadata;
  name: Scalars['String'];
  notes: Array<Note>;
  reference: TimeseriesReference;
  source: TimeseriesSource;
  target: TimeseriesTarget;
  timeseriesId: Scalars['UUID'];
  timestamps: Array<Scalars['DateTime']>;
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  values: Array<Scalars['Float']>;
};

export type TimeseriesConstraint = {
  __typename?: 'TimeseriesConstraint';
  type: Scalars['String'];
  value: Scalars['Float'];
};

export type TimeseriesCopyMutation = {
  copyFromDate: Scalars['Date'];
  copyToPeriodEnd: Scalars['Date'];
  copyToPeriodStart: Scalars['Date'];
  timeseriesIds: Array<Scalars['UUID']>;
};

export type TimeseriesExportMetadata = {
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type TimeseriesInference = {
  __typename?: 'TimeseriesInference';
  active: ResolvedInference;
  default?: Maybe<ResolvedInference>;
};

export type TimeseriesInput = {
  comment?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  metadata?: InputMaybe<TimeseriesMetadataInput>;
  name: Scalars['String'];
  notes: Array<NoteInput>;
  source: TimeseriesSourceInput;
  target: TimeseriesTargetInput;
  unit?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type TimeseriesLastListResponse = LastTimeseriesList | ValueErrorResponse;

export type TimeseriesList = {
  __typename?: 'TimeseriesList';
  timeseries: Array<Timeseries>;
};

export type TimeseriesListMutation = {
  timeseries: Array<TimeseriesMutation>;
};

export type TimeseriesListResponse = TimeseriesList | ValueErrorResponse;

export type TimeseriesMetadata = {
  __typename?: 'TimeseriesMetadata';
  ingressDataNote?: Maybe<Scalars['String']>;
  isIngressConnectionActive: Scalars['Boolean'];
  isIngressDataPlausible: Scalars['Boolean'];
  isMsrTestSuccessful: Scalars['Boolean'];
};

export type TimeseriesMetadataInput = {
  ingressDataNote?: InputMaybe<Scalars['String']>;
  isIngressConnectionActive?: InputMaybe<Scalars['Boolean']>;
  isIngressDataPlausible?: InputMaybe<Scalars['Boolean']>;
  isMsrTestSuccessful?: InputMaybe<Scalars['Boolean']>;
};

export type TimeseriesMutation = {
  timeseriesId: Scalars['UUID'];
  timestamps: Array<Scalars['DateTime']>;
  values: Array<Scalars['Float']>;
};

export type TimeseriesPatchInput = {
  comment?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Array<NoteInput>>;
  source?: InputMaybe<TimeseriesSourceInput>;
  target?: InputMaybe<TimeseriesTargetInput>;
  unit?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type TimeseriesReference = {
  __typename?: 'TimeseriesReference';
  childEntityKey?: Maybe<Scalars['String']>;
  childTemplateKey?: Maybe<Scalars['String']>;
  entityKey?: Maybe<Scalars['String']>;
  propertyIndex?: Maybe<Scalars['Int']>;
  propertyKey?: Maybe<Scalars['String']>;
  templateKey: Scalars['String'];
  type: Scalars['String'];
};

export type TimeseriesResponse = Timeseries | ValueErrorResponse;

export type TimeseriesSource = {
  __typename?: 'TimeseriesSource';
  additionalExternalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  isInverted?: Maybe<Scalars['Boolean']>;
  protocol?: Maybe<Scalars['String']>;
  summation?: Maybe<SummationInference>;
  timezone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type TimeseriesSourceInput = {
  additionalExternalId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  isInverted?: InputMaybe<Scalars['Boolean']>;
  protocol?: InputMaybe<Scalars['String']>;
  summation?: InputMaybe<SummationInferenceInput>;
  timezone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type TimeseriesTarget = {
  __typename?: 'TimeseriesTarget';
  additionalExternalId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  protocol?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type TimeseriesTargetInput = {
  additionalExternalId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  protocol?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type TwoFactorAuthLetter = {
  confirmLink: Scalars['String'];
};

export type TwoFactorEmailFailed = {
  __typename?: 'TwoFactorEmailFailed';
  message: Scalars['String'];
};

export type TwoFactorRequired = {
  __typename?: 'TwoFactorRequired';
  email: Scalars['String'];
};

export type TwoFactorSessionExpired = {
  __typename?: 'TwoFactorSessionExpired';
  message: Scalars['String'];
};

export type TwoFactorSessionInvalid = {
  __typename?: 'TwoFactorSessionInvalid';
  message: Scalars['String'];
};

export type UiAsset = {
  __typename?: 'UiAsset';
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  series: Array<UiAssetTimeSeries>;
  type: UiAssetChartType;
};

export enum UiAssetChartType {
  BarChart = 'barChart',
  DonutChart = 'donutChart',
  KpiAdvantage = 'kpiAdvantage',
  KpiDonut = 'kpiDonut',
  KpiGrid = 'kpiGrid',
  KpiNumber = 'kpiNumber',
  KpiTable = 'kpiTable',
  KpiTile = 'kpiTile',
  KpiTree = 'kpiTree',
  LineChart = 'lineChart',
  NodeValue = 'nodeValue',
  OperatingStatus = 'operatingStatus',
  Warning = 'warning',
  Weather = 'weather'
}

export type UiAssetTimeSeries = {
  __typename?: 'UiAssetTimeSeries';
  color?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interval?: Maybe<UiAssetTimeSeriesInterval>;
  label: Scalars['String'];
  mapping?: Maybe<Scalars['Anything']>;
  type?: Maybe<UiAssetWarningType>;
  unit?: Maybe<Scalars['String']>;
  uuids: UiAssetTimeSeriesUuids;
};

export enum UiAssetTimeSeriesInterval {
  Daily = 'DAILY',
  Forever = 'FOREVER',
  Monthly = 'MONTHLY',
  ThirtyDays = 'THIRTY_DAYS',
  Yearly = 'YEARLY',
  YearlyDays = 'YEARLY_DAYS'
}

export type UiAssetTimeSeriesUuids = {
  __typename?: 'UiAssetTimeSeriesUuids';
  forecast?: Maybe<Array<Scalars['String']>>;
  kpi?: Maybe<Array<Scalars['String']>>;
  measurements?: Maybe<Array<Scalars['String']>>;
  scheduled?: Maybe<Array<Scalars['String']>>;
  static?: Maybe<Scalars['Float']>;
};

export enum UiAssetWarningType {
  BitMap = 'bitMap',
  Numeric = 'numeric'
}

export type Unit = {
  __typename?: 'Unit';
  conversions: Scalars['Anything'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sign: Scalars['String'];
};

export type UnknownError = IError & {
  __typename?: 'UnknownError';
  message: Scalars['String'];
  pointer?: Maybe<Array<Scalars['String']>>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export type UpdateDocumentResponse = UnknownError | UpdateResponse;

export type UpdatePasswordEmailFailed = {
  __typename?: 'UpdatePasswordEmailFailed';
  message: Scalars['String'];
};

export type UpdatePasswordLetter = {
  date: Scalars['DateTime'];
  updatePasswordLink: Scalars['String'];
};

export type UpdatePasswordResponse = EmailNotFound | ServiceNotReachableError | UnknownError | UpdatePasswordEmailFailed | UpdatePasswordSuccess | WrongCredentialsError;

export type UpdatePasswordSuccess = {
  __typename?: 'UpdatePasswordSuccess';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  company: Company;
  districts: Array<Maybe<CompanyDistrict>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
};

export type ValueErrorResponse = {
  __typename?: 'ValueErrorResponse';
  message?: Maybe<Scalars['String']>;
  suggestion?: Maybe<Scalars['String']>;
};

export type Vouchers = {
  label?: InputMaybe<Scalars['String']>;
  paidAt?: InputMaybe<Scalars['Date']>;
  total: Scalars['Float'];
};

export type Warning = {
  __typename?: 'Warning';
  entityKey?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  multiplexIndex: Scalars['Number'];
  operatingStatus: Scalars['String'];
  timeseriesId: Scalars['String'];
  timestamp: Scalars['String'];
};

export enum WarningStatusType {
  Error = 'ERROR',
  Inactive = 'INACTIVE',
  Okay = 'OKAY',
  Warning = 'WARNING'
}

export type WelcomeLetter = {
  billingAddress: DeliveryAddress;
  contractAddress: ContractAddress;
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  customerPrefix?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  meterNumber: Scalars['String'];
  name: Scalars['String'];
  salutation: Scalars['String'];
};

export type WithdrawalLetter = {
  customerPrefix?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  shippingAddress: ContractAddress;
  supplierName: Scalars['String'];
};

export type WrongCredentialsError = {
  __typename?: 'WrongCredentialsError';
  message: Scalars['String'];
};

export type WrongPasswordResetFlow = {
  __typename?: 'WrongPasswordResetFlow';
  message: Scalars['String'];
};

export type LastMeasurementsArgs = {
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  twoFactorAuthCallbackURL: Scalars['String'];
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser: { __typename: 'ServiceNotReachableError', message: string } | { __typename: 'TwoFactorEmailFailed', message: string } | { __typename: 'TwoFactorRequired', email: string } | { __typename: 'UnknownError', message: string } | { __typename: 'User', firstName: string, lastName: string, email: string, id: string, districts: Array<{ __typename?: 'CompanyDistrict', company?: string | null, district?: string | null, clientId?: string | null } | null>, company: { __typename?: 'Company', secondaryColor: string, primaryColor: string, logo?: string | null } } | { __typename: 'WrongCredentialsError', message: string } };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser: boolean };


export const LoginUserDocument = gql`
    mutation loginUser($email: String!, $password: String!, $twoFactorAuthCallbackURL: String!) {
  loginUser(
    email: $email
    password: $password
    twoFactorAuthCallbackURL: $twoFactorAuthCallbackURL
  ) {
    __typename
    ... on User {
      firstName
      lastName
      email
      id
      districts {
        company
        district
        clientId
      }
      company {
        secondaryColor
        primaryColor
        logo
      }
    }
    ... on TwoFactorRequired {
      email
    }
    ... on TwoFactorEmailFailed {
      message
    }
    ... on WrongCredentialsError {
      message
    }
    ... on ServiceNotReachableError {
      message
    }
    ... on UnknownError {
      message
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      twoFactorAuthCallbackURL: // value for 'twoFactorAuthCallbackURL'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LogoutUserDocument = gql`
    mutation logoutUser {
  logoutUser
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;