import { DynamicList, Icons } from "@ampeersenergy/ampeers-ui-components";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";
import {
  PropertyTemplate,
  useGetInternalUnitsQuery,
  useGetUnitsQuery,
} from "../../graphql/sdks/controller";
import { ListContainer, Muted } from "../style";
import { AssetFormValues } from "../types";
import { timeseriesValidationSchema } from "../validation";
import { TimeseriesListItem } from "./timeseries-list-item";

export interface TimeseriesFormProps {
  isEditing?: boolean;
  prefixIdentifier?: string;
  items?: AssetFormValues["timeseries"];
  propertyTemplates?: PropertyTemplate[];
  canDuplicate?: boolean;
}

export const TimeseriesForm: React.FC<TimeseriesFormProps> = ({
  isEditing,
  prefixIdentifier,
  items,
  propertyTemplates,
  canDuplicate,
}) => {
  const { data: allUnits } = useGetUnitsQuery();
  const { data: internalUnits } = useGetInternalUnitsQuery();
  const { values } = useFormikContext<AssetFormValues>();
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const _propertyTemplates = useMemo(
    () => propertyTemplates ?? values.propertyTemplates,
    [propertyTemplates, values]
  );
  const timeseries = useMemo(() => items ?? values.timeseries, [items, values]);

  const onNewItem = useCallback(
    (item) => {
      return {
        ...item,
        dataType: "ts",
        flags: {},
        relativeTimeseries: [
          {
            source: {
              type:
                values.flags?.isOnsite && item.key !== "operatingStatus"
                  ? "district"
                  : "inference",
            },
            target: {
              type: "none",
            },
          },
        ],
      };
    },
    [values.flags?.isOnsite]
  );

  const TimeseriesListItemComponent = useCallback(
    (props) => {
      return (
        <TimeseriesListItem
          {...props}
          prefixIdentifier={prefixIdentifier}
          propertyTemplates={_propertyTemplates}
          isEditing={isEditing}
          internalUnits={internalUnits?.internalUnits ?? []}
          allUnits={allUnits?.units ?? []}
          canDuplicate={canDuplicate}
        />
      );
    },
    [
      prefixIdentifier,
      _propertyTemplates,
      isEditing,
      internalUnits,
      allUnits,
      canDuplicate,
    ]
  );

  const validateTimeseries = useCallback(
    (item) => timeseriesValidationSchema.isValidSync(item),
    []
  );

  if (!isEditing && !values.timeseries?.length) {
    return <Muted>Keine Zeitreihen vorhanden</Muted>;
  }

  return (
    <ListContainer>
      <DynamicList
        name={`${_prefixIdentifier}timeseries`}
        type="Zeitreihe"
        items={timeseries}
        validateItem={validateTimeseries}
        ListItemComponent={TimeseriesListItemComponent}
        Icon={Icons.Settings}
        interactive={isEditing}
        onNewItem={onNewItem}
      />
    </ListContainer>
  );
};
