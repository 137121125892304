import styled from "styled-components";
import { Select as RawSelect } from "@ampeersenergy/ampeers-ui-components";

import { DeleteButton as DeleteButtonComponent } from "../icon-button";

export const NewItemEditWrapper = styled.li`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.border};
  padding: 0 12px 12px 12px;
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const DeleteButton = styled(DeleteButtonComponent)`
  height: 40px;
  align-self: flex-end;
  margin-bottom: 3px;

  svg {
    transform: scale(1.2);
  }
`;

export const FlexRawSelect = styled(RawSelect)`
  flex: 1;
  margin-left: 6px;
  margin-right: 6px;
`;
