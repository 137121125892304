import { useCallback, useEffect, useState } from "react";

import {
  Document,
  useGetDocumentsForEntityLazyQuery,
} from "../graphql/sdks/document-manager";

import { documentGroupIds } from "../helpers/document.utils";

type UseGetDocumentsResult = {
  loading: boolean;
  data: Document[];
  refetch: ReturnType<typeof useGetDocumentsForEntityLazyQuery>[0];
};

export const useDocuments = ({ entityIds }: { entityIds: string[] }) => {
  const [getDocuments] = useGetDocumentsForEntityLazyQuery({
    fetchPolicy: "network-only",
  });

  const refetch = useCallback<typeof getDocuments>(
    (args) => {
      return getDocuments(args).then((result) => {
        setBatchedResult(({ data: existingFiles, ...r }) => {
          return {
            ...r,
            data: [
              ...existingFiles.filter(
                (file) => file.groupId !== args?.variables?.groupId
              ),
              ...((result.data?.documentsForEntity as any).documents ?? []),
            ],
          };
        });
        return result;
      });
    },
    [getDocuments]
  );

  const [batchedResult, setBatchedResult] = useState<UseGetDocumentsResult>({
    loading: false,
    data: [],
    refetch,
  });

  useEffect(() => {
    setBatchedResult({ loading: true, data: [], refetch });
    Promise.all(
      documentGroupIds.map((groupId) =>
        getDocuments({
          variables: { groupId },
        })
      )
    )
      .then((results) =>
        results.reduce<Document[]>((documents, result) => {
          if (result.error) {
            console.warn(result.error);
            return documents;
          } else if (
            result.data?.documentsForEntity.__typename === "UnknownError"
          ) {
            console.warn(result.data?.documentsForEntity.message);
            return documents;
          }

          return [
            ...documents,
            ...(result.data?.documentsForEntity.documents ?? []),
          ];
        }, [])
      )
      .then((combinedResult) =>
        setBatchedResult({
          loading: false,
          data: combinedResult,
          refetch,
        })
      )
      .catch((error) => {
        console.error("Error executing batched readMeasurementsSum:", error);
      });
  }, [getDocuments, refetch]);

  return {
    ...batchedResult,
    data: batchedResult.data.filter((d) =>
      entityIds.includes(d.entityId ?? "")
    ),
  };
};
