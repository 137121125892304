import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import {
  ButtonGroup,
  ButtonIcon,
  Icons,
  MultistepFormContext,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

const PullRight = styled(ButtonGroup)`
  flex: 1;
  margin-right: 2px;
  margin-left: 4px;
  flex-direction: row;
  justify-content: flex-end;
`;

export interface ActionButtonsProps {
  onDuplicate: () => void;
  setConfirmDeleteModalOpen: (value: boolean) => void;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  onDuplicate,
  setConfirmDeleteModalOpen,
}) => {
  const theme = useTheme() as Theme;
  const { setStepIndex } = useContext(MultistepFormContext) ?? {};

  return (
    <PullRight>
      <ButtonIcon
        icon={Icons.Delete}
        color={theme.palette.error.color}
        secondary
        customTheme={{
          primaryColor: theme.palette.error.color,
          secondaryColor: theme.palette.error.background,
        }}
        onClick={() => setConfirmDeleteModalOpen(true)}
      >
        Anlage löschen
      </ButtonIcon>
      <ButtonIcon
        icon={Icons.AccountingRecuring}
        secondary
        onClick={() => {
          onDuplicate();
          setStepIndex?.(0);
        }}
      >
        Anlage duplizieren
      </ButtonIcon>
    </PullRight>
  );
};
