import React from "react";
import styled, { useTheme } from "styled-components";
import {
  FlexColumn,
  FlexRow,
  Icons,
  Theme,
  useHover,
} from "@ampeersenergy/ampeers-ui-components";
import { ElementType } from "./types";
import { AssetType, getAssetIcon } from "../../helpers/asset.utils";
import { COLORS } from "./utils";

const Color = styled.div<{ $color: string }>`
  margin: -12px 12px -12px -12px;
  width: 32px;
  background-color: ${(props) => props.$color};
  border-radius: 4px 0 0 4px;
`;

const Plus = styled(Icons.Plus)<{ $open?: boolean; $isHovered?: boolean }>`
  transform: rotate(45deg);
  align-self: center;

  cursor: pointer;
  display: ${(props) => (props.$isHovered || props.$open ? "block" : "none")};
  border: 0;
  outline: 0;
  z-index: 1;
  margin: -4px 0 -4px 6px;
  background: ${(props) => props.theme.palette.background};
  order: 4;
`;

const PopOver = styled.div<{ open: boolean }>`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  margin-top: 4px;
`;

const PopOverItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 12px 16px;
  letter-spacing: 1.15px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  color: #119142;

  &:hover {
    background-color: #dbffd4;
    font-weight: bold;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:first-child:last-child {
    border-radius: 4px 4px;
  }
`;

const Label = styled(FlexRow)<{
  $color?: string;
  $iconPosition?: "left" | "right";
}>`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 12px;
  background-color: #fff;
  z-index: 2;
  ${(props) => (props.$color ? `color: ${props.$color};` : "")}

  .name {
    font-weight: bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .icon {
    align-self: center;
    ${(props) =>
      props.$iconPosition === "right"
        ? `
            margin-left: 12px;
            order: 1;
          `
        : `
            margin-right: 12px;
          `}
  }

  .column {
    align-items: flex-start;
    letter-spacing: 1.5px;
  }
`;

export interface TileProps {
  name: string;
  elementKey: string;
  color?: string;
  elementType: string;
  open?: boolean;
  setOpen: (id: string) => void;
  id: string;
  popOverElements: {
    displayOn: string[];
    label: string;
    onClick: () => void;
  }[];
  iconSize?: number;
  type?: string;
  isVirtual?: boolean;
}

export const Tile: React.FC<TileProps> = ({
  name,
  elementKey,
  color,
  elementType,
  open,
  setOpen,
  id,
  popOverElements,
  iconSize = 35,
  type,
  isVirtual = false,
}) => {
  const theme = useTheme() as Theme;
  const { isHovered, hoverHandlers } = useHover();
  const filteredPopOverElements = popOverElements.filter(({ displayOn }) =>
    displayOn.includes(elementType)
  );

  const Icon = !!type && getAssetIcon(type as AssetType);

  return (
    <FlexColumn>
      <FlexRow {...hoverHandlers}>
        <Label
          {...(elementType === ElementType.Asset
            ? {}
            : { $iconPosition: "right" })}
          {...(elementType === ElementType.District
            ? {}
            : { $color: theme.palette.gray[800] })}
        >
          {!!color && <Color $color={isVirtual ? COLORS.virtual : color} />}
          {Icon && <Icon className="icon" size={40} color={color} />}
          <FlexColumn className="column">
            <span className="name">{name}</span>
            {elementKey && <span>{elementKey}</span>}
          </FlexColumn>
          {!!filteredPopOverElements.length && (
            <Plus
              $open={open}
              $isHovered={isHovered}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(id);
              }}
              size={iconSize}
              color={theme.primaryColor}
            />
          )}
        </Label>
      </FlexRow>
      {!!filteredPopOverElements.length && open && (
        <PopOver open={!!open}>
          {filteredPopOverElements.map(({ label, onClick }) => (
            <PopOverItem onClick={onClick} key={label}>
              {label}
            </PopOverItem>
          ))}
        </PopOver>
      )}
    </FlexColumn>
  );
};
