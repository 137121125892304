/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useReducer } from "react";
import { Icons } from "@ampeersenergy/ampeers-ui-components";

import {
  Building,
  District,
  GetTimeseriesQueryHookResult,
} from "../../graphql/sdks/controller";

import { ExtendedAsset, ExtendedContract, LocalTimeseries } from "../types";
import { ElementType, Entity } from "./types";

import { EditDistrictForm } from "../edit-district-form";
import { JsonEditor } from "./json-editor";
import { GraphView } from "./graph-view";
import { CreateEditAssetFlow } from "../create-edit-asset-flow";
import { CreateEditContractFlow } from "../create-edit-contract-flow";
import { CreateEditBuildingFlow } from "../create-edit-building-flow";
import { Panel } from "../style";

enum ActionType {
  SELECT_ITEM,
  CLOSE_SIDEBAR_PANEL,
  OPEN_ITEM,
}

interface State {
  entity?: Entity<
    ExtendedAsset | Building | ExtendedContract | District
  > | null;
  selectedType: null | ElementType;
  openId?: null | string;
  sidebarPanelOpen?: boolean;
}

interface SelectItemAction {
  type: ActionType.SELECT_ITEM;
  payload: {
    entity: State["entity"];
    selectedType: State["selectedType"];
  };
}

interface SetSidebarOpenAction {
  type: ActionType.CLOSE_SIDEBAR_PANEL;
  payload: {
    sidebarPanelOpen: boolean;
  };
}

interface SetOpenItemAction {
  type: ActionType.OPEN_ITEM;
  payload: {
    openId: State["openId"];
  };
}

const initialState: State = {
  entity: null,
  selectedType: null,
  openId: null,
  sidebarPanelOpen: false,
};

const reducer = (
  state: State,
  action: SelectItemAction | SetSidebarOpenAction | SetOpenItemAction
) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.SELECT_ITEM:
      return {
        ...state,
        entity: payload.entity,
        selectedType: payload.selectedType,
        sidebarPanelOpen: true,
      };
    case ActionType.CLOSE_SIDEBAR_PANEL:
      return { ...state, sidebarPanelOpen: payload.sidebarPanelOpen };
    case ActionType.OPEN_ITEM:
      return { ...state, openId: payload.openId };
    default:
      return state;
  }
};

export interface DistrictOverviewProps {
  district: District;
  buildings: Building[];
  assets: ExtendedAsset[];
  contracts: ExtendedContract[];
  timeseries: LocalTimeseries[];
  energyCenterId?: string;

  openAssetForm?: () => void;
  openBuildingForm?: () => void;
  openContractForm?: () => void;
  setSelectedBuilding?: (id: string) => void;
  reloadTimeseries: GetTimeseriesQueryHookResult["refetch"];
}

const DistrictOverview: React.FC<DistrictOverviewProps> = ({
  district,
  buildings,
  contracts,
  assets,
  timeseries,
  energyCenterId,
  openAssetForm = () => {},
  openBuildingForm = () => {},
  openContractForm = () => {},
  setSelectedBuilding,
  reloadTimeseries,
}) => {
  useEffect(() => {
    // Manually setting the main body overflow here because the asset form
    // inside the collapsible panel was causing the page to render a scroll bar
    // even though all child elements have correct heights and overflows
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  const [{ entity, selectedType, sidebarPanelOpen, openId }, dispatch] =
    useReducer(reducer, initialState);

  const onElementClick = useCallback(
    (
      e,
      node: { id: string; data: { elementType: ElementType; index: number } }
    ) => {
      let entity:
        | ExtendedAsset
        | Building
        | ExtendedContract
        | District
        | undefined;

      switch (node.data.elementType) {
        case ElementType.Asset:
          entity = assets.find((a) => a.id === node.id);
          break;
        case ElementType.Building:
          entity = buildings.find((a) => a.id === node.id);
          break;
        case ElementType.Contract:
          entity = contracts.find((a) => a.id === node.id);
          break;
        default:
          entity = district;
          break;
      }

      dispatch({
        type: ActionType.SELECT_ITEM,
        payload: {
          entity: {
            ...(entity ?? district),
            index: node.data.index,
            elementType: node.data.elementType,
          },
          selectedType: node.data.elementType,
        },
      });
    },
    []
  );

  return (
    <Panel
      isOpen={!!sidebarPanelOpen}
      setIsOpen={(value) =>
        dispatch({
          type: ActionType.CLOSE_SIDEBAR_PANEL,
          payload: {
            sidebarPanelOpen: value,
          },
        })
      }
      sidebarPosition="right"
      sidebarWidth={"40%"}
      sidebarItems={[
        {
          Icon: Icons.File,
          content:
            selectedType === ElementType.Asset ? (
              <CreateEditAssetFlow
                assets={assets}
                asset={entity as ExtendedAsset}
                buildings={buildings}
                timeseries={timeseries}
                districtId={district.id}
                districtKey={district.key}
                companyKey={district.companyKey}
                reloadTimeseries={reloadTimeseries}
                onDelete={() =>
                  dispatch({
                    type: ActionType.SELECT_ITEM,
                    payload: {
                      entity: {
                        ...district,
                        elementType: ElementType.District,
                      },
                      selectedType: ElementType.District,
                    },
                  })
                }
              />
            ) : selectedType === ElementType.Building ? (
              <CreateEditBuildingFlow
                building={entity as Building}
                districtKey={district.key}
                companyKey={district.companyKey}
                districtId={district.id}
                energyCenterId={energyCenterId}
                onDelete={() =>
                  dispatch({
                    type: ActionType.SELECT_ITEM,
                    payload: {
                      entity: {
                        ...district,
                        elementType: ElementType.District,
                      },
                      selectedType: ElementType.District,
                    },
                  })
                }
              />
            ) : selectedType === ElementType.Contract ? (
              <CreateEditContractFlow
                contract={entity as ExtendedContract}
                assets={assets}
                contracts={contracts}
                assetId={(entity as ExtendedContract).assetId}
                onDelete={() =>
                  dispatch({
                    type: ActionType.SELECT_ITEM,
                    payload: {
                      entity: {
                        ...district,
                        elementType: ElementType.District,
                      },
                      selectedType: ElementType.District,
                    },
                  })
                }
              />
            ) : (
              <EditDistrictForm
                district={district}
                timeseries={timeseries}
                reloadTimeseries={reloadTimeseries}
              />
            ),
        },
        {
          Icon: Icons.Pencil,
          content: <JsonEditor entity={entity ?? {}} />,
        },
      ]}
    >
      <GraphView
        district={district}
        openId={openId ?? undefined}
        setOpenId={(value) =>
          dispatch({
            type: ActionType.OPEN_ITEM,
            payload: {
              openId: value,
            },
          })
        }
        onElementClick={onElementClick}
        openAssetForm={openAssetForm}
        openBuildingForm={openBuildingForm}
        openContractForm={openContractForm}
        setSelectedBuilding={setSelectedBuilding}
      />
    </Panel>
  );
};

export default DistrictOverview;
