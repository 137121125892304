import React, { useCallback } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { CheckboxNew } from "@ampeersenergy/ampeers-ui-components";

import { AssetFormValues, ExtendedProperty, LocalTimeseries } from "../types";
import { mergePropertyTemplates } from "../../helpers/properties.utils";

const Wrapper = styled.div``;

const Checkbox = styled(CheckboxNew)`
  justify-content: flex-start;

  label {
    flex: 1;
  }

  label > span:nth-child(2) {
    width: 80%;
  }
`;

const assetFlags: Record<
  keyof Omit<AssetFormValues["flags"], "__typename">,
  string
> = {
  isOnsite: "Existiert das Asset physisch im Distrikt?",
  isMonitoringRelevant:
    "Soll das Asset im Frontend angezeigt und für KPI Berechnungen genutzt werden?",
  isOptimizationRelevant: "Soll das Asset in der Optimierung genutzt werden?",
  isEndConsumption:
    "(Flag nur relevant für Zähler, isEndConsumption) Ist der Zähler ein Endverbraucher? → Einbeziehen für KPIs und Prognose",
};

export interface AssetFormFlagsProps {
  isEditing?: boolean;
  timeseries: LocalTimeseries[];
}

export const AssetFormFlags: React.FC<AssetFormFlagsProps> = ({
  isEditing,
  timeseries,
}) => {
  const { values, setValues } = useFormikContext<AssetFormValues>();

  const onValueChange = useCallback(
    (flag: keyof Omit<AssetFormValues["flags"], "__typename">) => {
      const newFlags = {
        ...values.flags,
        [flag]: !values.flags[flag],
      };

      setValues({
        ...values,
        flags: newFlags,
        ...mergePropertyTemplates(
          [
            ...values.properties,
            ...values.timeseries,
            ...values.tensors,
          ] as ExtendedProperty[],
          timeseries,
          values.propertyTemplates ?? [],
          newFlags,
          values.key
        ),
      });
    },
    [values, setValues, timeseries]
  );

  return (
    <Wrapper>
      {Object.keys(assetFlags).map((key) => {
        const flag = key as keyof Omit<AssetFormValues["flags"], "__typename">;
        const description = assetFlags[flag];

        return (
          <Checkbox
            id={`flags.${flag}`}
            key={`flags.${flag}`}
            label={`${description} (${flag})`}
            checked={!!values.flags[flag]}
            onChange={() => {
              onValueChange(flag);
            }}
            disabled={!isEditing}
          />
        );
      })}
    </Wrapper>
  );
};
