import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import styled, { useTheme } from "styled-components";
import { ApolloError } from "@apollo/client";
import {
  Accordion,
  AccordionItem,
  AlertRetryable,
  Button,
  ButtonGroup,
  FlexRow,
  FormikSubmit,
  Modal,
  SuccessAlert,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import {
  GetAssetsDocument,
  GetTimeseriesDocument,
  useGetUnitsQuery,
  useRemoveTimeseriesMutation,
  useUpdateTimeseriesMutation,
} from "../../graphql/sdks/controller";

import { extractErrorMessage } from "../../helpers/error.utils";
import {
  formatInitialTimeseriesFormValues,
  formatTimeseriesBeforeSubmit,
} from "../../helpers/properties.utils";
import { extTimeseriesValidationSchema } from "../validation";
import {
  Column,
  FormWrapperWithPadding,
  ListContainer,
  Row,
  SubTitleWithMargin,
} from "../style";
import { LocalDistrict, TimeseriesFormValues } from "../types";

import { NotesForm } from "../notes-form";
import { TimeseriesForm } from "./timeseries-form";
import { omitGraphqlType } from "../../helpers/asset.utils";
import { DeleteConfirmModal } from "../delete-confirm-modal";
import { TimeseriesEditForm } from "../timeseries-form/timeseries-edit-form";
import { TimeseriesLineChart } from "../timeseries-line-chart";

const Buttons = styled(FlexRow)`
  justify-content: space-between;
`;

const Scroll = styled.div`
  overflow-y: auto;
`;

export interface CreateEditTimeseriesFlowProps {
  district: LocalDistrict;
  timeseries?: TimeseriesFormValues;
  startOnEdit?: boolean;
  reloadTimeseries?: () => void;
  setIsOverlayOpen?: (value: boolean) => void;
}

export const CreateEditTimeseriesFlow: React.FC<
  CreateEditTimeseriesFlowProps
> = ({
  district,
  timeseries,
  startOnEdit = false,
  reloadTimeseries,
  setIsOverlayOpen,
}) => {
  const theme = useTheme() as Theme;
  const [error, setError] = useState<Error | any | undefined>();
  const [isEditing, setIsEditing] = useState(startOnEdit);
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const { data } = useGetUnitsQuery();

  const [updateTimeseries] = useUpdateTimeseriesMutation({
    refetchQueries: [
      {
        query: GetTimeseriesDocument,
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
          filter: {
            sourceType: ["district", "inference"],
          },
        },
      },
      {
        query: GetAssetsDocument,
        variables: {
          districtIdentifier: {
            company: district.companyKey,
            district: district.key,
          },
        },
      },
    ],
  });
  const [removeTimeseries, { error: removeError }] =
    useRemoveTimeseriesMutation();

  const onSubmit = useCallback(
    async (values: TimeseriesFormValues, { setValues }) => {
      try {
        const {
          id,
          uuid,
          name,
          reference,
          unit,
          description,
          target,
          source,
          notes,
          comment,
          metadata,
        } = values;

        const timeseriesValues = formatTimeseriesBeforeSubmit({
          id,
          uuid,
          name,
          reference,
          unit,
          description,
          comment,
          target,
          source,
          notes,
          metadata,
        });

        let newTimeseriesValues;

        const { data, errors } = await updateTimeseries({
          variables: {
            timeseries: timeseriesValues,
          },
        });
        newTimeseriesValues = data?.updateTimeseries;
        if (errors?.length) {
          setError(errors[0].message);
          throw errors[0]?.message ?? "Unknown Error";
        }

        if (newTimeseriesValues) {
          setValues(
            formatInitialTimeseriesFormValues<TimeseriesFormValues>({
              ...newTimeseriesValues,
              refTimeseriesProp: timeseries!.refTimeseriesProp,
            })
          );
        }
        setTimeout(() => setSuccessfulUpdate(false), 5000);

        setIsEditing(false);
        setError(undefined);
        setSuccessfulUpdate(true);
        setIsOverlayOpen?.(false);

        return { data: newTimeseriesValues };
      } catch (e) {
        if (e instanceof ApolloError) {
          setError({ message: extractErrorMessage(e) });
          throw extractErrorMessage(e);
        } else if (typeof e === "string") {
          setError({ name: "Error", message: e });
          throw e;
        } else {
          setError(e as Error);
          throw e;
        }
      }
    },
    [updateTimeseries, setIsOverlayOpen, timeseries]
  );

  const onDelete = useCallback(
    (closeModal: () => void) => {
      return removeTimeseries({
        variables: {
          ids: [timeseries!.id],
        },
      }).then(() => {
        closeModal();
        setIsOverlayOpen?.(false);
        reloadTimeseries?.();
      });
    },
    [timeseries, removeTimeseries, setIsOverlayOpen, reloadTimeseries]
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formatInitialTimeseriesFormValues(timeseries)}
        validationSchema={extTimeseriesValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <FormWrapperWithPadding>
              {successfulUpdate && (
                <SuccessAlert>
                  {values.name} erfolgreich aktualisiert.
                </SuccessAlert>
              )}
              {error && (
                <AlertRetryable
                  message={error?.message}
                  onRetry={() => setError(undefined)}
                />
              )}
              <Scroll>
                <Row>
                  {values.refTimeseriesProp[0] && (
                    <Column style={{ minWidth: 0 }}>
                      <SubTitleWithMargin>Property</SubTitleWithMargin>
                      <ListContainer>
                        <TimeseriesEditForm
                          item={omitGraphqlType({
                            ...values.refTimeseriesProp[0],
                            propKey: values.refTimeseriesProp[0]?.key,
                            relativeTimeseries: [],
                          })}
                          index={0}
                          identifier="refTimeseriesProp"
                          isEditing={false}
                          allUnits={data?.units ?? []}
                          unitKeys={[]}
                          propertyTemplates={[]}
                          onDelete={() => {}}
                          setEditMode={() => {}}
                          onDuplicate={() => {}}
                          vertical
                          hideButtons
                        />
                      </ListContainer>
                    </Column>
                  )}
                  <Column flex={3}>
                    <TimeseriesForm
                      isEditing={isEditing}
                      units={data?.units ?? []}
                      flowType="read"
                    />
                    {values.target.type !== "none" && (
                      <TimeseriesForm
                        isEditing={isEditing}
                        units={data?.units ?? []}
                        flowType="write"
                      />
                    )}
                  </Column>
                </Row>
                <Accordion
                  withPadding={false}
                  autoClose={false}
                  LabelComponent={SubTitleWithMargin}
                  initialOpenIds={[0]}
                >
                  <AccordionItem label="Notizen">
                    <NotesForm isEditing={isEditing} />
                  </AccordionItem>
                  <></>
                </Accordion>
              </Scroll>
              <Buttons>
                <ButtonGroup style={{ marginTop: 10 }}>
                  <Button
                    secondary
                    customTheme={{
                      primaryColor: theme.palette.error.color,
                      secondaryColor: theme.palette.error.background,
                    }}
                    onClick={() => setConfirmDeleteModalOpen(true)}
                  >
                    Löschen
                  </Button>
                </ButtonGroup>
                <ButtonGroup style={{ marginTop: 10 }}>
                  {isEditing ? (
                    <>
                      <Button
                        secondary
                        onClick={() => {
                          if (!timeseries || startOnEdit) {
                            setIsOverlayOpen?.(false);
                          } else {
                            setIsEditing(false);
                          }
                        }}
                      >
                        Abbrechen
                      </Button>
                      <FormikSubmit
                        isLoading={isSubmitting}
                        disabled={!isValid}
                      >
                        Speichern
                      </FormikSubmit>
                    </>
                  ) : (
                    <Button
                      onClick={() => setIsEditing(true)}
                      disabled={isEditing}
                    >
                      Bearbeiten
                    </Button>
                  )}
                </ButtonGroup>
              </Buttons>
            </FormWrapperWithPadding>
          );
        }}
      </Formik>
      {timeseries ? (
        <>
          <TimeseriesLineChart
            timeseries={[timeseries]}
            chartFilter={{
              all: true,
              measurements: true,
              forecast: true,
              scheduled: true,
            }}
          />
        </>
      ) : null}
      <DeleteConfirmModal
        entityName={timeseries?.name || "Zeitreihe"}
        isOpen={confirmDeleteModalOpen}
        setIsOpen={setConfirmDeleteModalOpen}
        onConfirm={onDelete}
        errorMsg={removeError && extractErrorMessage(removeError)}
      />
    </>
  );
};

export interface CreateEditTimeseriesFlowModalProps
  extends CreateEditTimeseriesFlowProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
}

export const CreateEditTimeseriesFlowModal: React.FC<
  CreateEditTimeseriesFlowModalProps
> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      title={props.title}
      minWidth={1024}
      maxWidth="xl"
    >
      <CreateEditTimeseriesFlow {...props} />
    </Modal>
  );
};
