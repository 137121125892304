import { useState } from "react";
import { TimeseriesChartFilter } from "../components/types";

export const useTimeseriesChartFilters = () => {
  const [chartFilter, setChartFilter] = useState({
    all: true,
    measurements: true,
    forecast: true,
    scheduled: true,
  });

  return {
    chartFilter,
    setAllChartFilters: () =>
      setChartFilter((values) => ({
        all: true,
        measurements: !values.all,
        forecast: !values.all,
        scheduled: !values.all,
      })),
    setChartFilter: (key: keyof TimeseriesChartFilter) =>
      setChartFilter((values) => ({
        ...values,
        all: false,
        [key]: !values[key],
      })),
  };
};
