import React from "react";
import styled from "styled-components";
import {
  DatePicker as DatePickerComponent,
  DatePickerProps as DatePickerComponentProps,
  Label,
} from "@ampeersenergy/ampeers-ui-components";
import { DateTime } from "luxon";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 6px;

  & > div:first-child {
    margin-bottom: 5px;
  }

  & > div:last-child {
    padding: 6px 6px;
  }
`;

export interface DatePickerProps extends DatePickerComponentProps {
  label: string;
  isEditing?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { label, isEditing, ...datePickerProps } = props;

  return (
    <Wrapper>
      <Label>{label}</Label>
      {isEditing ? (
        <DatePickerComponent {...datePickerProps} />
      ) : datePickerProps.date ? (
        <span>
          {DateTime.fromJSDate(datePickerProps.date).toLocaleString()}
        </span>
      ) : null}
    </Wrapper>
  );
};
