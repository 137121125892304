import React, { ChangeEvent, useCallback, useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import {
  FormikInput,
  Select as RawSelect,
} from "@ampeersenergy/ampeers-ui-components";
import { ContractFormValues, LocalAsset } from "../types";
import { MultistepFormContext } from "../multistep-form/context";

export interface ContractFormAssetProps {
  isEditing?: boolean;
  assets: LocalAsset[];
}

export const ContractFormAsset: React.FC<ContractFormAssetProps> = ({
  isEditing,
  assets,
}) => {
  const { values, errors, setValues } = useFormikContext<ContractFormValues>();

  const { registerField, unregisterField } =
    useContext(MultistepFormContext) ?? {};

  useEffect(() => {
    if (registerField) {
      registerField("assetId");
    }

    return () => {
      if (unregisterField) {
        unregisterField("assetId");
      }
    };
  }, [registerField, unregisterField]);

  const onAssetChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      if (e.target.value === "null") {
        setValues({ ...values, parentId: null });
        return;
      }

      const asset = assets?.find(({ id }) => e.target.value === id);

      if (!asset) {
        console.error(`Cannot find asset with id: ${e.target.value}`);
        return;
      }

      setValues({
        ...values,
        parentId: asset.id,
        assetKey: asset.key,
        assetId: asset.id,
        assetName: asset.name,
      });
    },
    [assets, setValues, values]
  );

  if (!isEditing) {
    return <FormikInput name="assetName" label="Anlage *" isEditing={false} />;
  }

  return (
    <RawSelect
      id="assetId"
      name="assetId"
      label="Anlage *"
      value={values.assetId || ""}
      isEditing={isEditing}
      errorMsg={errors.assetId}
      onChange={onAssetChange}
    >
      <option disabled value="" key={"-"}>
        Bitte wählen
      </option>
      {(assets ?? [])
        .concat()
        .sort((a1, a2) =>
          a1.name.localeCompare(a2.name, undefined, { numeric: true })
        )
        .map((asset) => (
          <option key={asset.id} value={asset.id}>
            {asset.name} ({asset.key})
          </option>
        ))}
    </RawSelect>
  );
};
