import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";
import styled, { useTheme } from "styled-components";
import { DateTime } from "luxon";
import { saveAs } from "file-saver";
import { ApolloError } from "@apollo/client";
import {
  AlertRetryable,
  ColumnDefinition,
  Icons,
  Link,
  Textarea as TextAreaComponent,
  SuccessAlert as SuccessAlertComponent,
  FilterBar,
  Button,
  ButtonGroup as ButtonGroupComponent,
  Theme,
} from "@ampeersenergy/ampeers-ui-components";

import {
  Building,
  ContractTemplateListItem,
  District,
  DistrictTemplate,
  FullAssetChildFragment,
  GetAssetsDocument,
  GetTimeseriesDocument,
  useGenerateExternalIdsMutation,
  useGenerateGrafanaDashboardMutation,
  useGetAssetTemplatesQuery,
  useGetUnitsQuery,
  useRemoveTimeseriesMutation,
  useUpdateTimeseriesMutation,
} from "../../graphql/sdks/controller";

import {
  ExtendedAsset,
  ExtendedContract,
  LocalTimeseries,
  TimeseriesFormValues,
  TimeseriesTableData,
} from "../../components/types";
import { FlexRawSelect, InlineCheckbox } from "../../components/style";
import { ProtocolOptions } from "../../components/shared-select-options";
import { NotesIcon } from "../../components/notes-icon";
import { FilePreview } from "../../components/file-preview";
import { BulkDeleteModal } from "../../components/bulk-delete-modal";
import { TimeseriesTableActions } from "../../components/timeseries-table-actions";
import { TimeseriesFilters } from "../../components/timeseries-filters";
import { Table as TableComponent } from "../../components/table";
import { TimeseriesExternalIdsModal } from "../../components/timeseries-external-ids-modal";
import { TimeseriesLineChart } from "../../components/timeseries-line-chart";
import { useTimeseriesChartFilters } from "../../hooks/useTimeseriesChartFilters";

import { useTimeseriesFilters } from "../../hooks/useTimeseriesFilters";

import { formatTimeseriesBeforeSubmit } from "../../helpers/properties.utils";
import { capitalize, formatDistrictName } from "../../helpers/string.utils";
import { extractErrorMessage } from "../../helpers/error.utils";
import { compose, curry, debounce } from "../../helpers/function.utils";
import { addToArray } from "../../helpers/array.utils";
import { flattenMergedObjects } from "../../helpers/object.utils";
import { dynamicPageSize } from "../../helpers/html.utils";
import { AssetType } from "../../helpers/asset.utils";

import { CSVService } from "../../services/csv.service";
import { _ae_env_ } from "../../env";
import {
  filterByAsset,
  filterByAssetTemplate,
  filterByFlowType,
  filterByProtocol,
  filterBySourceOrTargetType,
  filterByTextSearch,
  filterOutWeatherTimeseries,
  pushSortGroup,
  sortProperties,
} from "../../helpers/timeseries.filters";

const Row = styled.div`
  display: flex;
  margin: 20px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuccessAlert = styled(SuccessAlertComponent)`
  margin: 0;
  padding: 0 15px;
`;

const Table: typeof TableComponent = styled(TableComponent)`
  margin-top: 12px;

  .wrapper {
    overflow-x: auto;
  }
`;

const Checkbox = styled(InlineCheckbox)`
  margin: 0;
  padding: 0;
`;

const TextArea = styled(TextAreaComponent)`
  textarea {
    min-height: 0px;
  }
`;

const ButtonGroup = styled(ButtonGroupComponent)`
  margin-left: 4px;
`;

export interface TimeseriesTableProps
  extends ReturnType<typeof useTimeseriesFilters>,
    ReturnType<typeof useTimeseriesChartFilters> {
  district: District;
  assets: ExtendedAsset[];
  contracts: ExtendedContract[];
  buildings: Building[];
  timeseries: LocalTimeseries[];
  loading?: boolean;
  timeseriesError?: ApolloError;
  districtTemplate?: DistrictTemplate;
  contractTemplates?: ContractTemplateListItem[];
  reloadTimeseries?: () => void;
  onTimeseriesRowClick: (original: TimeseriesFormValues | undefined) => void;
  setAssetToEdit: (asset: ExtendedAsset) => void;
  setBuildingToEdit: (building: Building) => void;
  setContractToEdit: (contract: ExtendedContract) => void;
}

export const TimeseriesTable: React.FC<TimeseriesTableProps> = ({
  district,
  assets,
  contracts,
  buildings,
  timeseries,
  loading,
  timeseriesError,
  districtTemplate,
  contractTemplates,
  reloadTimeseries,
  rowFilter,
  setRowFilter,
  setAllRowFilters,
  columnFilter,
  visibleColumns,
  setVisibleColumns,
  onTimeseriesRowClick,
  setAssetToEdit,
  setBuildingToEdit,
  setContractToEdit,
  textSearch,
  setTextSearch,
  filterChanged,
  savedFilters,
  saveFilter,
  loadFilter,
  deleteFilter,
  resetFilter,
  onSavedFilterChanged,
  chartFilter,
  setChartFilter,
  setAllChartFilters,
}) => {
  const theme = useTheme() as Theme;
  const history = useHistory();
  const [error, setError] = useState<Error | any | undefined>();
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);
  const [
    successfulGenerateGrafanaDashboard,
    setSuccessfulGenerateGrafanaDashboard,
  ] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [fieldsToUpdate, setFieldsToUpdate] = useState<LocalTimeseries[]>([]);
  const [externalIdsModalOpen, setExternalIdsModalOpen] = useState(false);
  const [yamlPreviewModalOpen, setYamlPreviewModalOpen] = useState(false);
  const [view, setView] = useState<"table" | "graph">("table");

  const {
    data: assetTemplates,
    error: templatesError,
    loading: loadingTemplates,
  } = useGetAssetTemplatesQuery();
  const { data: units } = useGetUnitsQuery();
  const [updateTimeseries] = useUpdateTimeseriesMutation();
  const [removeTimeseries, { error: removeError }] =
    useRemoveTimeseriesMutation({
      refetchQueries: [
        {
          query: GetTimeseriesDocument,
          variables: {
            districtIdentifier: {
              company: district.companyKey,
              district: district.key,
            },
            filter: {
              sourceType: ["district", "inference"],
            },
          },
        },
        {
          query: GetAssetsDocument,
          variables: {
            districtIdentifier: {
              company: district.companyKey,
              district: district.key,
            },
          },
        },
      ],
    });
  const [
    generateExternalIds,
    { data: generatedExternalIds, error: generateExternalIdsError },
  ] = useGenerateExternalIdsMutation({
    fetchPolicy: "no-cache",
  });
  const [generateGrafanaDashboard, { error: generateGrafanaDashboardError }] =
    useGenerateGrafanaDashboardMutation();

  useEffect(() => reloadTimeseries?.(), [reloadTimeseries]);

  const onSourceClick = useCallback(
    ({ originElement, origin }: TimeseriesTableData) => {
      if (origin === "asset") setAssetToEdit(originElement);
      if (origin === "building") setBuildingToEdit(originElement);
      if (origin === "contract") setContractToEdit(originElement);
      if (origin === "district")
        history.push(
          `${history.location.pathname
            .split("/")
            .slice(0, -1)
            .join("/")}/district`
        );
    },
    [setAssetToEdit, setBuildingToEdit, setContractToEdit, history]
  );

  const onUpdateTimeseries = useCallback(
    async (newTimeseriesValues: LocalTimeseries) => {
      try {
        await updateTimeseries({
          variables: {
            timeseries: formatTimeseriesBeforeSubmit(
              newTimeseriesValues as LocalTimeseries
            ),
          },
        });

        setSuccessfulUpdate(true);
        setError(undefined);
        setTimeout(() => setSuccessfulUpdate(false), 5000);
      } catch (e) {
        if (e instanceof ApolloError) {
          setError({ message: extractErrorMessage(e) });
          setTimeout(() => setError(undefined), 5000);
          throw extractErrorMessage(e);
        } else if (typeof e === "string") {
          setError({ name: "Error", message: e });
          setTimeout(() => setError(undefined), 5000);
          throw e;
        } else {
          setError(e as Error);
          setTimeout(() => setError(undefined), 5000);
          throw e;
        }
      }
    },
    [updateTimeseries]
  );

  const onUpdateTextField = useCallback(
    async (
      id: string,
      field:
        | "source.externalId"
        | "source.additionalExternalId"
        | "target.externalId"
        | "target.additionalExternalId"
        | "metadata.ingressDataNote",
      value: number | string | boolean
    ) => {
      const ts = timeseries.find((t) => t.id === id);

      if (!ts) {
        setError({ message: `Cannot find timeseries ${id}` });
        return;
      }

      const [editField, subEditField] = field.split(".") as [
        "source" | "target" | "metadata",
        "externalId" | "additionalExternalId" | "ingressDataNote",
      ];
      const newTimeseriesValues = {
        ...ts,
        [editField]: { ...ts[editField], [subEditField]: value as string },
      };

      setFieldsToUpdate((_fieldsToUpdate) =>
        addToArray(_fieldsToUpdate, newTimeseriesValues, "id")
      );
    },
    [timeseries, setFieldsToUpdate]
  );

  const onUpdateField = useCallback(
    async (
      id: string,
      field:
        | "protocol"
        | "unit"
        | "isInverted"
        | "flowType"
        | "sourceType"
        | "targetType"
        | "isIngressConnectionActive"
        | "isMsrTestSuccessful"
        | "isIngressDataPlausible",
      value: number | string | boolean,
      editField: "source" | "target" | "metadata"
    ) => {
      const ts = timeseries.find((t) => t.id === id);

      if (!ts) {
        setError({ message: `Cannot find timeseries ${id}` });
        return;
      }

      let newTimeseriesValues = ts;
      switch (field) {
        case "protocol":
          newTimeseriesValues = {
            ...newTimeseriesValues,
            [editField]: {
              ...ts[editField],
              protocol: value === "null" ? null : (value as string),
            },
          };
          break;
        case "unit":
          newTimeseriesValues = {
            ...newTimeseriesValues,
            [editField]: { ...ts[editField], unit: value as string },
          };
          break;
        case "isInverted":
          newTimeseriesValues = {
            ...newTimeseriesValues,
            source: { ...ts.source, isInverted: !!value as boolean },
          };
          break;
        case "flowType":
          switch (value) {
            case "read":
              newTimeseriesValues = {
                ...newTimeseriesValues,
                target: { type: "none" },
              };
              break;
            case "write":
              newTimeseriesValues = {
                ...newTimeseriesValues,
                source: {
                  ...newTimeseriesValues.source,
                  timezone: newTimeseriesValues.source.timezone ?? "UTC",
                },
                target: {
                  type: "district",
                  protocol:
                    newTimeseriesValues.target.protocol ||
                    newTimeseriesValues.source.protocol,
                  externalId:
                    newTimeseriesValues.target.externalId ||
                    newTimeseriesValues.source.externalId,
                  additionalExternalId:
                    (newTimeseriesValues.target.additionalExternalId ||
                      newTimeseriesValues.source.additionalExternalId) ??
                    null,
                  unit:
                    (newTimeseriesValues.target.unit ||
                      newTimeseriesValues.source.unit) ??
                    null,
                  timezone: newTimeseriesValues.source.timezone ?? "UTC",
                },
              };
              break;
            default:
              return;
          }
          break;
        case "sourceType":
          newTimeseriesValues = {
            ...newTimeseriesValues,
            source: {
              ...ts.source,
              timezone: ts.source.timezone ?? "UTC",
              isInverted: !!ts.source.isInverted,
              type: value as string,
            },
          };
          break;
        case "targetType":
          newTimeseriesValues = {
            ...newTimeseriesValues,
            target: {
              ...ts.target,
              timezone: ts.target.timezone ?? "UTC",
              type: value as string,
            },
          };
          break;
        case "isIngressConnectionActive":
        case "isMsrTestSuccessful":
        case "isIngressDataPlausible":
          newTimeseriesValues = {
            ...newTimeseriesValues,
            metadata: {
              ...newTimeseriesValues.metadata,
              [field]: !!value as boolean,
            },
          };
          break;
        default:
          setError(undefined);
          return;
      }

      onUpdateTimeseries(newTimeseriesValues);
    },
    [timeseries, onUpdateTimeseries]
  );

  const allColumns = useMemo(() => {
    const columns: (ColumnDefinition<TimeseriesTableData> | null)[] = [
      {
        Header: "Quelle",
        accessor: "sourceName",
        width: "10%",
        Cell: ({ value, row }) => (
          <Link
            onClick={(e) => {
              e.stopPropagation();
              onSourceClick(row.original);
            }}
          >
            {value}
          </Link>
        ),
        sortType: (rowA, rowB) => {
          if (!rowA.original.sourceName) return -1;
          if (!rowB.original.sourceName) return 1;

          const sortWeatherStation = pushSortGroup(
            rowA.original.originElement?.template ?? "",
            rowB.original.originElement?.template ?? "",
            [AssetType.WeatherStation as string],
            "bottom"
          );
          if (sortWeatherStation) return sortWeatherStation;

          return rowA.original.sourceName.localeCompare(
            rowB.original.sourceName
          );
        },
      },
      {
        Header: "Gebäude",
        accessor: "buildingName",
        width: "1%",
        Cell: ({ value, row }) =>
          value ? (
            <Link
              onClick={() =>
                row.original.building &&
                setBuildingToEdit(row.original.building)
              }
            >
              {value}
            </Link>
          ) : (
            "--"
          ),
        sortType: (rowA, rowB) => {
          if (!rowA.original.building) return -1;
          if (!rowB.original.building) return 1;
          return rowA.original.building?.name.localeCompare(
            rowB.original.building?.name
          );
        },
      },
      {
        Header: "Property",
        accessor: "reference.propertyKey",
        width: "5%",
        Cell: ({ value, row }) => (
          <Link onClick={() => onTimeseriesRowClick(row.original)}>
            {value ?? "--"}
          </Link>
        ),
        sortType: (rowA, rowB) => {
          return sortProperties(
            rowA.original.reference.propertyKey,
            rowB.original.reference.propertyKey,
            rowA.original.originElementTemplate,
            rowB.original.originElementTemplate
          );
        },
      },
      {
        Header: "Typ",
        accessor: "flowType",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <FlexRawSelect
              id="timeseriesType"
              name="timeseriesType"
              value={row.original.target.type === "none" ? "read" : "write"}
              onChange={(e: ChangeEvent<{ value: string }>) => {
                onUpdateField(
                  row.original.id,
                  "flowType",
                  e.target.value,
                  "source"
                ); // editField is irrelevant here
              }}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
            >
              <option disabled value="" key={"-"}>
                Bitte wählen
              </option>
              <option value="read">Read</option>
              <option value="write">Read & Write</option>
            </FlexRawSelect>
          );
        },
      },
      {
        Header: "Beschreibung",
        accessor: "description",
      },
      {
        Header: "Quelltyp",
        accessor: "source.type",
        width: "8%",
        Cell: ({ value, row }) => (
          <FlexRawSelect
            id="timeseriesType"
            name="timeseriesType"
            value={value}
            onChange={(e: ChangeEvent<{ value: string }>) => {
              onUpdateField(
                row.original.id,
                "sourceType",
                e.target.value,
                "source"
              ); // editField is irrelevant here
            }}
            disabled={row.original.reference?.propertyKey === "operatingStatus"}
          >
            <option disabled value="" key={"-"}>
              Bitte wählen
            </option>
            <option value="district">District</option>
            <option value="inference">Inference</option>
          </FlexRawSelect>
        ),
      },
      {
        Header: "Protokoll (Quelle)",
        accessor: "source.protocol",
        width: "10%",
        Cell: ({ value, row }) =>
          row.original.source.type === "district" ? (
            <FlexRawSelect
              id="source.protocol"
              name="source.protocol"
              value={value ?? "null"}
              onChange={(e: ChangeEvent<{ value: string }>) =>
                onUpdateField(
                  row.original.id,
                  "protocol",
                  e.target.value,
                  "source"
                )
              }
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
            >
              <ProtocolOptions />
            </FlexRawSelect>
          ) : null,
      },
      {
        Header: "Unit (Quelle)",
        accessor: "source.unit",
        Cell: ({ value, row }) =>
          row.original.source.type === "district" ? (
            <FlexRawSelect
              id="source.unit"
              name="source.unit"
              value={value ?? "null"}
              onChange={(e: ChangeEvent<{ value: string }>) =>
                onUpdateField(row.original.id, "unit", e.target.value, "source")
              }
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
            >
              <option key="null" value={"null"}>
                (null)
              </option>
              {(units?.units ?? [])
                .filter((unit) => !!unit.key)
                .sort((u1, u2) => u1.key.localeCompare(u2.key))
                .map((unit) => (
                  <option key={unit.key} value={unit.key || ""}>
                    {unit.key}
                  </option>
                ))}
            </FlexRawSelect>
          ) : null,
      },
      {
        Header: "Is Inverted",
        accessor: "source.isInverted",
        width: "5%",
        Cell: ({ value, row }) =>
          row.original.source.type === "district" ? (
            <Checkbox
              id={`${row.original.id}.source.isInverted`}
              key={`${row.original.id}.source.isInverted`}
              label={value ? "Ja" : "Nein"}
              checked={value}
              onChange={(value) => {
                onUpdateField(row.original.id, "isInverted", value, "source");
              }}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
            />
          ) : null,
      },
      {
        Header: "External ID (Quelle)",
        accessor: "source.externalId",
        Cell: ({ value, row }) =>
          row.original.source.type === "district" ? (
            <TextArea
              id="source.externalId"
              name="source.externalId"
              defaultValue={value}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
              onChange={(v) => {
                debounce(
                  () =>
                    onUpdateTextField(
                      row.original.id,
                      "source.externalId",
                      v.target.value
                    ),
                  200
                )();
              }}
            />
          ) : null,
      },
      {
        Header: "Additional External Information (Quelle)",
        accessor: "source.additionalExternalId",
        Cell: ({ value, row }) =>
          row.original.source.type === "district" ? (
            <TextArea
              id="source.additionalExternalId"
              name="source.additionalExternalId"
              defaultValue={value}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
              onChange={(v) => {
                debounce(
                  () =>
                    onUpdateTextField(
                      row.original.id,
                      "source.additionalExternalId",
                      v.target.value
                    ),
                  200
                )();
              }}
            />
          ) : null,
      },
      {
        Header: "Target Typ",
        accessor: "target.type",
        width: "8%",
        Cell: ({ value, row }) =>
          row.original.target.type !== "none" ? (
            <FlexRawSelect
              id="timeseriesTargetType"
              name="timeseriesTargetType"
              value={value}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
              onChange={(e: ChangeEvent<{ value: string }>) => {
                onUpdateField(
                  row.original.id,
                  "targetType",
                  e.target.value,
                  "target"
                ); // editField is irrelevant here
              }}
            >
              <option disabled value="" key={"-"}>
                Bitte wählen
              </option>
              <option value="district">District</option>
              <option value="inference">Inference</option>
            </FlexRawSelect>
          ) : null,
      },
      {
        Header: "Protokoll (Target)",
        accessor: "target.protocol",
        width: "5%",
        Cell: ({ value, row }) =>
          row.original.target.type !== "none" ? (
            <FlexRawSelect
              id="target.protocol"
              name="target.protocol"
              value={value ?? "null"}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
              onChange={(e: ChangeEvent<{ value: string }>) =>
                onUpdateField(
                  row.original.id,
                  "protocol",
                  e.target.value,
                  "target"
                )
              }
            >
              <ProtocolOptions />
            </FlexRawSelect>
          ) : null,
      },
      {
        Header: "Unit (Target)",
        accessor: "target.unit",
        Cell: ({ value, row }) =>
          row.original.target.type !== "none" ? (
            <FlexRawSelect
              id="target.unit"
              name="target.unit"
              value={value ?? "null"}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
              onChange={(e: ChangeEvent<{ value: string }>) =>
                onUpdateField(row.original.id, "unit", e.target.value, "target")
              }
            >
              <option key="null" value={"null"}>
                (null)
              </option>
              {(units?.units ?? [])
                .filter((unit) => !!unit.key)
                .sort((u1, u2) => u1.key.localeCompare(u2.key))
                .map((unit) => (
                  <option key={unit.key} value={unit.key || ""}>
                    {unit.key}
                  </option>
                ))}
            </FlexRawSelect>
          ) : null,
      },
      {
        Header: "External ID (Target)",
        accessor: "target.externalId",
        Cell: ({ value, row }) =>
          row.original.target.type !== "none" ? (
            <TextArea
              id="target.externalId"
              name="target.externalId"
              defaultValue={value}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
              onChange={(v) => {
                debounce(
                  () =>
                    onUpdateTextField(
                      row.original.id,
                      "target.externalId",
                      v.target.value
                    ),
                  200
                )();
              }}
            />
          ) : null,
      },
      {
        Header: "Additional External Information (Target)",
        accessor: "target.additionalExternalId",
        Cell: ({ value, row }) =>
          row.original.target.type !== "none" ? (
            <TextArea
              id="target.additionalExternalId"
              name="target.additionalExternalId"
              defaultValue={value}
              disabled={
                row.original.reference?.propertyKey === "operatingStatus"
              }
              onChange={(v) => {
                debounce(
                  () =>
                    onUpdateTextField(
                      row.original.id,
                      "target.additionalExternalId",
                      v.target.value
                    ),
                  200
                )();
              }}
            />
          ) : null,
      },
      {
        Header: "Datatype",
        accessor: "refTimeseriesProp[0].dataSubType",
        Cell: ({ value }) => (value ? capitalize(value) : null),
      },
      {
        Header: "Datenverbindung aktiv",
        accessor: "metadata.isIngressConnectionActive",
        Cell: ({ value, row }) => (
          <Checkbox
            id={`${row.original.id}.metadata.isIngressConnectionActive`}
            key={`${row.original.id}.metadata.isIngressConnectionActive`}
            label={value ? "Ja" : "Nein"}
            checked={value}
            onChange={(value) => {
              onUpdateField(
                row.original.id,
                "isIngressConnectionActive",
                value,
                "metadata"
              );
            }}
          />
        ),
      },
      {
        Header: "Test MSR erfolgt & erfolgreich",
        accessor: "metadata.isMsrTestSuccessful",
        Cell: ({ value, row }) => (
          <Checkbox
            id={`${row.original.id}.metadata.isMsrTestSuccessful`}
            key={`${row.original.id}.metadata.isMsrTestSuccessful`}
            label={value ? "Ja" : "Nein"}
            checked={value}
            onChange={(value) => {
              onUpdateField(
                row.original.id,
                "isMsrTestSuccessful",
                value,
                "metadata"
              );
            }}
          />
        ),
      },
      {
        Header: "Datenpunkt plausibel",
        accessor: "metadata.isIngressDataPlausible",
        Cell: ({ value, row }) => (
          <Checkbox
            id={`${row.original.id}.metadata.isIngressDataPlausible`}
            key={`${row.original.id}.metadata.isIngressDataPlausible`}
            label={value ? "Ja" : "Nein"}
            checked={value}
            onChange={(value) => {
              onUpdateField(
                row.original.id,
                "isIngressDataPlausible",
                value,
                "metadata"
              );
            }}
          />
        ),
      },
      {
        Header: "Notiz Dateningress",
        accessor: "metadata.ingressDataNote",
        Cell: ({ value, row }) => (
          <TextArea
            id="metadata.ingressDataNote"
            name="metadata.ingressDataNote"
            defaultValue={value}
            onChange={(v) => {
              debounce(
                () =>
                  onUpdateTextField(
                    row.original.id,
                    "metadata.ingressDataNote",
                    v.target.value
                  ),
                200
              )();
            }}
          />
        ),
      },
      {
        Header: "Uuid",
        accessor: "uuid",
      },
      {
        Header: "",
        accessor: "notes.length",
        width: "1%",
        Cell: ({
          value,
          row,
        }: {
          value: number;
          row: { original: TimeseriesTableData };
        }) => (
          <NotesIcon
            onClick={() => onTimeseriesRowClick(row.original)}
            value={value}
          />
        ),
      },
      {
        id: "referenceType",
        Header: "",
        accessor: "reference.type",
      },
    ];

    return columns.filter(
      (v): v is ColumnDefinition<TimeseriesTableData> => !!v
    );
  }, [
    onSourceClick,
    setBuildingToEdit,
    onUpdateField,
    onTimeseriesRowClick,
    units?.units,
    onUpdateTextField,
  ]);

  const filteredColumns = useMemo(() => {
    return allColumns.filter(
      (v): v is ColumnDefinition<TimeseriesTableData> => {
        let accessor = v.accessor as keyof typeof visibleColumns;

        if (accessor === "buildingName") {
          accessor = "building";
        } else if (accessor.includes("additionalExternalId")) {
          accessor = accessor.replace("additionalExternalId", "externalId");
        }

        const shouldDisplayColumn = visibleColumns[accessor];
        return shouldDisplayColumn === undefined || shouldDisplayColumn;
      }
    );
  }, [allColumns, visibleColumns]);

  const tableData: TimeseriesTableData[] = useMemo(
    () =>
      timeseries.map((ts, index) => {
        const { type, entityKey } = ts.reference;

        let originElement: TimeseriesTableData["originElement"] = undefined;
        let origin: TimeseriesTableData["origin"] = "unknown";
        let originElementTemplate: TimeseriesTableData["originElementTemplate"] =
          undefined;
        let childAsset: FullAssetChildFragment | undefined = undefined;
        let sourceName = "Unbekannt";
        if (["asset", "kpi"].includes(type)) {
          const asset = assets.find((a) => a.key === entityKey);
          origin = "asset";
          originElement = asset;
          childAsset = asset?.children.find(
            (childAsset) => childAsset.key === ts.reference.childEntityKey
          );
          if (asset && childAsset) {
            sourceName = `${asset.name || asset.key || "Unbekannt"} (${
              childAsset.name || childAsset.key || "Unbekannt"
            })`;
          } else {
            sourceName = asset?.name || asset?.key || entityKey || "Unbekannt";
          }
          originElementTemplate =
            (childAsset ?? asset) &&
            assetTemplates?.assetTemplates.find(
              (at) => at.template === (childAsset ?? asset)?.template
            );
        } else if (["contract", "kpi"].includes(type)) {
          const contract = contracts.find((c) => c.key === entityKey);
          origin = "contract";
          originElement = contract;
          sourceName =
            contract?.name || contract?.key || entityKey || "Unbekannt";
          originElementTemplate = contractTemplates?.find(
            (ct) => ct.template === contract?.template
          );
        } else if (
          ["district", "kpi"].includes(type) &&
          district.key === entityKey
        ) {
          origin = "district";
          sourceName = district.name;
          originElement = district && { ...district, template: "district" };
          originElementTemplate = districtTemplate && {
            properties: districtTemplate.properties,
            template: "district",
          };
        } else if (["building", "kpi"].includes(type)) {
          const building = buildings.find((b) => b.key === entityKey);
          origin = "building";
          originElement = building;
          sourceName = building?.name || entityKey || "Unbekannt";
        }

        const building = buildings.find(
          (b) =>
            origin === "asset" &&
            b.key === (originElement as ExtendedAsset)?.buildingKey
        );

        const property =
          (originElement as ExtendedAsset)?.properties?.find(
            (p) => p.key === ts.reference.propertyKey
          ) ??
          childAsset?.properties.find(
            (p) => p.key === ts.reference.propertyKey
          );

        const refTimeseriesProp = property && {
          ...property,
          propKey: property.key,
          units: (units?.units ?? []).map(({ key }) => key),
          flags: {
            isMonitoringRelevant: true,
            isOperationRelevant: true,
            isOnsite: false,
          },
          isRequired: true,
        };

        return {
          ...ts,
          index,
          buildingName: building?.name,
          building,
          flowType: ts.target.type === "none" ? "read" : "write",
          origin,
          originElement,
          originElementTemplate,
          sourceName,
          refTimeseriesProp: [
            { ...refTimeseriesProp, relativeTimeseries: [ts] } as any,
          ],
        } as TimeseriesTableData;
      }) ?? [],
    [
      timeseries,
      district,
      assets,
      contracts,
      buildings,
      units,
      assetTemplates,
      contractTemplates,
      districtTemplate,
    ]
  );

  const filteredTableData: TimeseriesTableData[] = useMemo(
    () =>
      filterByTextSearch(
        textSearch,
        compose(
          ...[
            filterBySourceOrTargetType,
            filterByAssetTemplate,
            filterByFlowType,
            filterByAsset,
            filterByProtocol,
            filterOutWeatherTimeseries,
          ].map((filterFn) => curry(filterFn)(rowFilter))
        )(tableData)
      ),
    [tableData, rowFilter, textSearch]
  );

  const filteredGraphData: TimeseriesTableData[] = useMemo(
    () =>
      filteredTableData.filter(
        (ts) =>
          !["errorMessage", "warningMessage", "operatingStatus"].some(
            (key) =>
              ts.refTimeseriesProp?.[0]?.key
                ?.toLowerCase()
                .includes(key.toLowerCase())
          )
      ),
    [filteredTableData]
  );

  const onGenerateExternalIds = useCallback(async () => {
    await generateExternalIds();
    setExternalIdsModalOpen(true);
  }, [generateExternalIds]);

  const onGenerateGrafanaDashboard = useCallback(async () => {
    try {
      const result = await generateGrafanaDashboard();
      if (result.data?.generateGrafanaDashboard) {
        setSuccessfulGenerateGrafanaDashboard(true);
        setError(undefined);
        setTimeout(() => setSuccessfulGenerateGrafanaDashboard(false), 5000);
      } else if (result.errors?.[0]) {
        throw result.errors[0];
      } else {
        throw new Error("Unbekannter Fehler");
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        setError({ message: extractErrorMessage(e) });
        setTimeout(() => setError(undefined), 5000);
        throw extractErrorMessage(e);
      } else if (typeof e === "string") {
        setError({ name: "Error", message: e });
        setTimeout(() => setError(undefined), 5000);
        throw e;
      } else {
        setError(e as Error);
        setTimeout(() => setError(undefined), 5000);
        throw e;
      }
    }
  }, [generateGrafanaDashboard]);

  const onExport = useCallback(() => {
    const expectedColumns = filteredColumns.filter(
      (c) => !["notes.length", "reference.type"].includes(c.accessor! as string)
    );

    const fields = expectedColumns.map<string>(
      (column) => column.accessor! as string
    );
    const headers = expectedColumns.map<string>(
      (column) => column.Header as string
    );

    const sortedTableData = filteredTableData.sort((a, b) => {
      return (
        b.reference.type.localeCompare(a.reference.type) ||
        a.sourceName.localeCompare(b.sourceName) ||
        b.source.type.localeCompare(a.source.type) ||
        a.reference.propertyKey?.localeCompare(b.reference.propertyKey ?? "") ||
        0
      );
    });

    CSVService.downloadCSV(
      sortedTableData.map(
        ({
          building,
          notes,
          unit,
          reference,
          source,
          originElement,
          originElementTemplate,
          comment,
          target,
          index,
          refTimeseriesProp,
          inference,
          metadata,
          ...t
        }) => {
          return {
            ...t,
            refTimeseriesProp: refTimeseriesProp?.[0]?.dataSubType ?? "",
            building: building?.name ?? "",
            comment: comment ?? "",
            ...flattenMergedObjects(source, "source"),
            ...flattenMergedObjects(target, "target"),
            ...flattenMergedObjects(reference, "reference"),
          };
        }
      ),
      {
        fields,
        headers,
        fileName: `${DateTime.now()
          .setZone("Europe/Berlin")
          .toFormat("yyyyMMdd")}_${formatDistrictName(
          district.name
        )}_Zeitereihenübersicht`,
      }
    );
  }, [filteredColumns, filteredTableData, district.name]);

  const onExportHashes = useCallback(() => {
    const hashes = filteredTableData.map(({ uuid }) => uuid);

    const hashesString = hashes.join("\n");

    saveAs(new Blob([hashesString], { type: "charset=utf-8" }), "hashes.txt");
  }, [filteredTableData]);

  const onRowSelect = useCallback(
    (rowIds: string[]) => {
      setSelectedRows(
        rowIds
          .map((rowId) => filteredTableData.find((_, i) => i === +rowId)?.id)
          .filter((v): v is string => !!v)
      );
    },
    [setSelectedRows, filteredTableData]
  );

  const onDelete = useCallback(() => {
    return removeTimeseries({
      variables: {
        ids: selectedRows,
      },
    });
  }, [removeTimeseries, selectedRows]);

  const emptyTableMessage = useMemo(() => {
    let message = "";
    if (rowFilter.sourceOrTargetType[0] !== "all")
      message = `${capitalize(rowFilter.sourceOrTargetType[0])}`;
    if (rowFilter.asset[0] !== "all") {
      const entity =
        assets.find(({ id }) => rowFilter.asset.includes(id)) ??
        contracts.find(({ id }) => rowFilter.asset.includes(id)) ??
        buildings.find(({ id }) => rowFilter.asset.includes(id)) ??
        (rowFilter.asset.includes(district.key) ? district : undefined);

      if (entity) message = `${message} für ${entity?.name || entity?.key}`;
    }

    return `${message}${message.length ? " " : ""}Zeitreihen`;
  }, [assets, contracts, buildings, district, rowFilter]);

  const initialState = useMemo(
    () => ({
      hiddenColumns: ["referenceType"],
      sortBy: [
        {
          id: "referenceType",
          desc: true,
        },
        {
          id: "sourceName",
          desc: false,
        },
        {
          id: "source.type",
          desc: true,
        },
        {
          id: "reference.propertyKey",
          desc: false,
        },
      ],
    }),
    []
  );

  return (
    <>
      <FilePreview
        isOpen={yamlPreviewModalOpen}
        setIsOpen={setYamlPreviewModalOpen}
        withPreview
        withIcon={false}
        name="YAML Export"
        documentType="yaml"
        fileName={`${DateTime.now()
          .setZone("Europe/Berlin")
          .toFormat("yyyyMMdd")}-${district.companyKey}-${
          district.key
        }-se-connector-box.yaml`}
        url={`${_ae_env_.REACT_APP_BACKEND_URL}/exports/se-connector-box`}
      />
      <Column>
        <FilterBar
          filters={textSearch}
          columns={filteredColumns.filter((c) => !!c.Header) as any}
          kind={emptyTableMessage}
          setAllFilters={setTextSearch}
        />
        <Row>
          <TimeseriesTableActions
            view={view}
            setView={setView}
            columnItems={columnFilter.map(({ accessor, label }) => ({
              label: label!,
              value: accessor,
              checked: visibleColumns[accessor],
            }))}
            onColumnItemClick={(accessor) => {
              if (accessor?.split("-")[0] === "filter") {
                loadFilter(+accessor?.split("-")[1]);
              } else if (!!accessor) {
                setVisibleColumns({
                  ...visibleColumns,
                  [accessor]: !visibleColumns[accessor],
                });
              }
            }}
            exportItems={[
              { value: "grafana", label: "Grafana", Icon: Icons.File },
              { value: "csv", label: "CSV", Icon: Icons.File },
              {
                value: "yaml",
                label: "YAML",
                Icon: Icons.File,
              },
              {
                value: "hashes",
                label: "Hashes",
                Icon: Icons.File,
              },
            ]}
            onGenerate={onGenerateExternalIds}
            onExportItemClick={(item) => {
              switch (item) {
                case "grafana":
                  onGenerateGrafanaDashboard();
                  break;
                case "csv":
                  onExport();
                  break;
                case "yaml":
                  setYamlPreviewModalOpen(true);
                  break;
                case "hashes":
                  onExportHashes();
                  break;
                default:
                  break;
              }
            }}
            deleteFilter={deleteFilter}
            savedFilters={savedFilters}
            filterChanged={filterChanged}
            resetFilter={resetFilter}
            saveFilter={saveFilter}
          >
            <TimeseriesFilters
              rowFilter={rowFilter}
              setRowFilter={setRowFilter}
              setAllRowFilters={setAllRowFilters}
              loading={loading || loadingTemplates}
              assets={assets}
              contracts={contracts}
              district={district}
              assetTemplates={assetTemplates}
              tableData={filteredTableData}
              filterChanged={filterChanged}
              savedFilters={savedFilters}
              saveFilter={saveFilter}
              resetFilter={resetFilter}
              onSavedFilterChanged={onSavedFilterChanged}
            />
            {successfulUpdate && (
              <SuccessAlert>Zeitreihe erfolgreich aktualisiert.</SuccessAlert>
            )}
            {successfulGenerateGrafanaDashboard && (
              <SuccessAlert>
                Grafana Dashboard wurde erfolgreich generiert.
              </SuccessAlert>
            )}
          </TimeseriesTableActions>
          {!!fieldsToUpdate.length && (
            <ButtonGroup>
              <Button
                onClick={() => {
                  Promise.all(
                    fieldsToUpdate.map((ts) => {
                      return onUpdateTimeseries(ts);
                    })
                  ).then(() => setFieldsToUpdate([]));
                }}
              >
                Speichern
              </Button>
              <Button
                onClick={() => setFieldsToUpdate([])}
                customTheme={{
                  primaryColor: theme.palette.error.color,
                  secondaryColor: theme.palette.error.background,
                  logo: "",
                }}
              >
                Abbrechen
              </Button>
            </ButtonGroup>
          )}
          {!!selectedRows.length && (
            <ButtonGroup>
              <BulkDeleteModal
                entityName="Zeitreihen"
                nameExtractFn={(id) => {
                  const ts = timeseries.find((b) => b.id === id);
                  return `${ts?.reference.entityKey ?? "--"}-${ts?.reference
                    .propertyKey}`;
                }}
                removeError={timeseriesError}
                onDelete={onDelete}
                selectedRows={selectedRows}
              />
            </ButtonGroup>
          )}
        </Row>
        {(error ||
          timeseriesError ||
          templatesError ||
          removeError ||
          generateExternalIdsError ||
          generateGrafanaDashboardError) && (
          <AlertRetryable
            message={
              error?.message ||
              (timeseriesError ||
              templatesError ||
              removeError ||
              generateExternalIdsError ||
              generateGrafanaDashboardError
                ? extractErrorMessage(
                    (timeseriesError ||
                      templatesError ||
                      removeError ||
                      generateExternalIdsError ||
                      generateGrafanaDashboardError)!
                  )
                : "Unknown Error")
            }
            onRetry={() => setError(undefined)}
          />
        )}
      </Column>
      {view === "table" ? (
        <Table
          filterKind={emptyTableMessage}
          compact
          canSelectRows
          withPagination
          isLoading={loading || loadingTemplates}
          columns={filteredColumns as any}
          data={filteredTableData}
          pageSize={dynamicPageSize(15, 20)}
          initialState={initialState}
          onSelect={onRowSelect}
        />
      ) : (
        <TimeseriesLineChart
          timeseries={filteredGraphData}
          chartFilter={chartFilter}
        />
      )}
      <TimeseriesExternalIdsModal
        isOpen={externalIdsModalOpen}
        setIsOpen={setExternalIdsModalOpen}
        assets={assets}
        timeseries={timeseries}
        updatedTimeseries={generatedExternalIds?.generateExternalIds ?? []}
      />
    </>
  );
};
