import ELK, { ElkNode } from "elkjs";
import { Elements, isNode, isEdge, Node } from "react-flow-renderer";
import { Building, District } from "../../graphql/sdks/controller";
import { AssetGroup, getAssetGroup } from "../../helpers/asset.utils";
import { composeAndMerge } from "../../helpers/function.utils";
import { LocalAsset, LocalContract } from "../types";
import { ElementType, Entity } from "./types";

const NODE_HEIGHT = 100;
const NODE_DISTANCE_X = 1200;
const NODE_DISTANCE_Y = 800;
const AREA_PADDING = 30;
const NODE_WIDTH_MUL_FACTOR = 16;
const NODE_HEIGHT_MUL_FACTOR = 116;
const NODE_WIDTH_PADDING = 100;
const NODE_HEIGHT_PADDING = 0;

export const COLORS: Record<ElementType | "virtual", string> = {
  [ElementType.District]: "#119142",
  [ElementType.Building]: "#f8612a",
  [ElementType.Asset]: "#8bdb78",
  [ElementType.Contract]: "#4eb7cc",
  virtual: "#dbffd4",
};

export enum GridQuadrant {
  TopLeft = 0,
  TopCenter = 1,
  TopRight = 2,
  CenterRight = 3,
  BottomRight = 4,
  BottomCenter = 5,
  BottomLeft = 6,
  CenterLeft = 7,
}

export const findInDistrict = (
  district: District,
  selectedId: string
):
  | Partial<Entity<LocalAsset | Building | LocalContract | District>>
  | undefined => {
  const item = [
    district,
    ...district.buildings,
    ...district.buildings.flatMap((b) => b.assets),
    ...district.buildings.flatMap((b) => b.assets.flatMap((a) => a.contracts)),
    ...district.assets,
    ...district.assets.flatMap((a) => a.contracts),
  ].find((e) => e.id === selectedId);

  return item;
};

const calculatePositionReferences = (
  element: Node<any>,
  node: ElkNode,
  parent: ElkNode & Pick<Node, "data">
) => {
  return {
    parentX: parent.x || 0,
    parentY: parent.y || 0,
    parentHeight: parent.height || 0,
    parentWidth: parent.width || 0,
    maxGroupWidth: element.data.maxGroupWidth
      ? element.data.maxGroupWidth * NODE_WIDTH_MUL_FACTOR + NODE_WIDTH_PADDING
      : 0,
    maxGroupHeight: element.data.maxGroupHeight
      ? element.data.maxGroupHeight * NODE_HEIGHT_MUL_FACTOR +
        NODE_HEIGHT_PADDING
      : 0,
    maxParentGroupWidth: parent.data?.maxGroupWidth
      ? parent.data.maxGroupWidth * NODE_WIDTH_MUL_FACTOR + NODE_WIDTH_PADDING
      : 0,
    indexedHeight: element.data.index * (node.height || 0),
    groupOffset: Math.floor(element.data.groupSize / 2) * (parent.height || 0),
  };
};

const calculateBuildingPosition = (
  element: Node<any>,
  node: ElkNode,
  parent?: ElkNode
) => {
  const { maxGroupHeight } = calculatePositionReferences(
    element,
    node,
    parent!
  );
  const { index } = element.data;

  const quadrant = (index % 8) as GridQuadrant;

  const centerX = parent?.x || 0;
  const centerY = parent?.y || 0;

  const distanceX = (Math.floor(index / 8) + 1) * NODE_DISTANCE_X;
  const distanceY = (Math.floor(index / 8) + 1) * NODE_DISTANCE_Y;

  switch (quadrant) {
    case GridQuadrant.TopLeft:
      return {
        x: centerX - distanceX,
        y: centerY - distanceY - maxGroupHeight,
      };
    case GridQuadrant.TopCenter:
      return {
        x: centerX,
        y: centerY - distanceY - maxGroupHeight,
      };
    case GridQuadrant.TopRight:
      return {
        x: centerX + distanceX,
        y: centerY - distanceY - maxGroupHeight,
      };
    case GridQuadrant.CenterRight:
      return {
        x: centerX + distanceX,
        y: centerY,
      };
    case GridQuadrant.BottomRight:
      return {
        x: centerX + distanceX,
        y: centerY + distanceY + maxGroupHeight,
      };
    case GridQuadrant.BottomCenter:
      return {
        x: centerX,
        y: centerY + distanceY + maxGroupHeight,
      };
    case GridQuadrant.BottomLeft:
      return {
        x: centerX - distanceX,
        y: centerY + distanceY + maxGroupHeight,
      };
    case GridQuadrant.CenterLeft:
      return {
        x: centerX - distanceX,
        y: centerY,
      };
    default:
      return {
        x: centerX,
        y: centerY,
      };
  }
};

const calculateAssetPosition = (
  element: Node<any>,
  node: ElkNode,
  parent?: ElkNode
) => {
  const assetGroup = getAssetGroup(element.data.template);

  if (!parent) {
    return {
      x: node.x || 0,
      y: node.y || 0,
    };
  } else {
    const {
      parentX,
      parentY,
      parentHeight,
      maxGroupWidth,
      indexedHeight,
      groupOffset,
    } = calculatePositionReferences(element, node, parent);

    switch (assetGroup) {
      case AssetGroup.Storage:
        return {
          x: parentX,
          y: parentY + parentHeight + AREA_PADDING + indexedHeight,
        };
      case AssetGroup.Sink:
        return {
          x: parentX + maxGroupWidth + AREA_PADDING,
          y: parentY - indexedHeight + groupOffset,
        };
      case AssetGroup.Source:
        return {
          x: parentX - maxGroupWidth - AREA_PADDING,
          y: parentY + indexedHeight - groupOffset,
        };
      case AssetGroup.Converter:
      case AssetGroup.Virtual:
      default:
        return {
          x: parentX,
          y: parentY - parentHeight - AREA_PADDING - indexedHeight,
        };
    }
  }
};

const calculateContractPosition = (
  element: Node<any>,
  node: ElkNode,
  parent?: ElkNode & Pick<Node, "data">
) => {
  if (!parent) {
    return {
      x: node.x || 0,
      y: node.y || 0,
    };
  } else {
    const assetGroup = getAssetGroup(parent.data.template);
    const {
      parentX,
      parentY,
      maxGroupWidth,
      maxParentGroupWidth,
      indexedHeight,
      groupOffset,
    } = calculatePositionReferences(element, node, parent);

    if (!assetGroup) {
      return {
        x: parent.x || 0,
        y: parent.y || 0,
      };
    }
    if (assetGroup === AssetGroup.Source) {
      return {
        x: parentX - maxGroupWidth - AREA_PADDING,
        y: parentY + indexedHeight - groupOffset,
      };
    } else {
      return {
        x: parentX + maxParentGroupWidth + AREA_PADDING,
        y: parentY - indexedHeight + groupOffset,
      };
    }
  }
};

const calculateElementPosition =
  (type: ElementType) =>
  (
    parentElements: Node[] = [],
    children: ElkNode[] = [],
    elements: Elements
  ): Node[] => {
    return elements
      .filter((e): e is Node => !isEdge(e) && e.data.elementType === type)
      .map((e) => {
        const node = children.find((c) => c.id === e.id);

        if (!node) {
          return e;
        }

        const parent: ElkNode | undefined = children.find(
          (c) => c.id === e.data.parentId
        );
        const calculatedParent: Node | undefined = parentElements.find(
          (p) => p.id === e.data.parentId
        );

        if (!parent) {
          e.position = {
            x: node.x || 0,
            y: node.y || 0,
          };
          return e;
        }

        const mergedParent = {
          ...parent,
          ...calculatedParent,
          x: calculatedParent?.position.x || 0,
          y: calculatedParent?.position.y || 0,
        };

        switch (type) {
          case ElementType.District:
            e.position = {
              x: 0,
              y: 0,
            };
            break;
          case ElementType.Building:
            e.position = calculateBuildingPosition(e, node, mergedParent);
            break;
          case ElementType.Asset:
            e.position = calculateAssetPosition(e, node, mergedParent);
            break;
          case ElementType.Contract:
            e.position = calculateContractPosition(e, node, mergedParent);
            break;
          default:
            e.position = {
              x: node.x || 0,
              y: node.y || 0,
            };
            break;
        }

        return e;
      });
  };

export const getLayoutedElements = async (
  elements: Elements,
  layout = "stress"
): Promise<Elements> => {
  const edges = elements.filter(isEdge);
  const nodes = elements.filter(isNode);

  const graph = {
    id: "root",
    layoutOptions: { "elk.algorithm": "fixed" },
    children: nodes.map((e) => {
      return {
        id: e.id,
        width:
          (e.data.name.length || 1) * NODE_WIDTH_MUL_FACTOR +
          NODE_WIDTH_PADDING,
        height: NODE_HEIGHT,
      };
    }),
    edges: edges.map((e) => ({
      id: e.id,
      sources: [e.source],
      targets: [e.target],
    })),
  };

  const elk = new ELK();
  const result = await elk.layout(graph as any);

  return [
    ...edges,
    ...composeAndMerge(
      calculateElementPosition(ElementType.District),
      calculateElementPosition(ElementType.Building),
      calculateElementPosition(ElementType.Asset),
      calculateElementPosition(ElementType.Contract)
    )([], result.children, elements),
  ];
};
