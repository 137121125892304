import styled from "styled-components";
import { Label } from "@ampeersenergy/ampeers-ui-components";
import { Column } from "../style";

export const LabelWithTopMargin = styled(Label)`
  margin-top: 32px;
`;

export const ColumnWithMargin = styled(Column)`
  margin: 22px 0;
`;

export const PrefilledAssetValues = styled.div`
  padding: 14px 0;
`;

export const KeyValueList = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-top: 6px;
  margin-bottom: 14px;
  padding-top: 6px;
  border-top: 1px solid ${(props) => props.theme.palette.border};
`;

export const KeyValue = styled.li`
  display: flex;

  .key {
    flex: 1;
    font-weight: bold;
  }

  .value {
    flex: 1;
  }
`;
