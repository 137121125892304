import React, { useCallback, useMemo, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";
import * as yup from "yup";
import { ApolloError } from "@apollo/client";
import {
  AlertRetryable,
  Button,
  ButtonGroup,
  FormikInput,
  FormikSubmit,
  SuccessAlert,
  useDialog,
  withDialogProvider,
} from "@ampeersenergy/ampeers-ui-components";

import { FormWrapperWithPadding } from "../style";
import {
  District,
  DistrictInput,
  GetDistrictsDocument,
  GetTimeseriesQueryHookResult,
  useGetDistrictTemplateQuery,
  useRemoveDistrictMutation,
  useUpdateDistrictMutation,
  useUpdateTimeseriesMutation,
} from "../../graphql/sdks/controller";

import { districtValidationSchema } from "../validation";
import {
  formatPropertiesBeforeSubmit,
  syncTimeseriesAfterUpdate,
} from "../../helpers/properties.utils";

import { extractErrorMessage } from "../../helpers/error.utils";

import { DeleteConfirmModalWithForm } from "../delete-confirm-modal";
// import { MetadataForm } from "../metadata-form";
import { DistrictForm } from "./district-form";
import { DistrictFormValues, LocalTimeseries } from "../types";
import { formatInitialDistrictFormValues } from "../../helpers/district.utils";

const FormWrapper = styled(FormWrapperWithPadding)`
  background: ${(props) => props.theme.palette.background};
`;

const refetchQueries = [
  {
    query: GetDistrictsDocument,
    variables: {
      filter: {
        multipleDistricts: [
          {
            company: "all",
            district: "all",
          },
        ],
      },
    },
  },
];

export interface EditDistrictFormProps {
  district: District;
  timeseries: LocalTimeseries[];
  reloadTimeseries: GetTimeseriesQueryHookResult["refetch"];
  onSuccess?: () => void;
}

export const EditDistrictForm = withDialogProvider<EditDistrictFormProps>(
  ({ district, timeseries, reloadTimeseries, onSuccess }) => {
    const history = useHistory();
    const [error, setError] = useState<Error | any | undefined>();
    const [isEditing, setIsEditing] = useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [successfulUpdate, setSuccessfulUpdate] = useState<
      Error | any | undefined
    >();

    const { data: districtTemplate } = useGetDistrictTemplateQuery();

    const [updateDistrict] = useUpdateDistrictMutation({
      refetchQueries,
    });

    const [removeDistrict, { error: removeError }] = useRemoveDistrictMutation({
      refetchQueries,
    });

    const [updateTimeseries] = useUpdateTimeseriesMutation();

    const { openConfirmation } = useDialog();

    const onCancel = useCallback(
      async ({
        resetForm,
        touched,
        onConfirm,
      }: {
        resetForm: Function;
        touched: object;
        onConfirm?: Function;
      }) => {
        if (Object.keys(touched).length) {
          await openConfirmation({
            title: "Änderungen verwerfen",
            content: (
              <>
                Es gibt Änderungen, die noch <strong>nicht gespeichert</strong>{" "}
                wurden.
                <br />
                <br />
                Bist du sicher, dass du abbrechen möchtest?
              </>
            ),
            confirmButtonLabel: "Änderungen verwerfen",
            handleRequest: () => {
              setIsEditing(false);
              resetForm();
              onConfirm?.();
            },
            dangerButton: true,
          });
        } else {
          setIsEditing(false);
        }
      },
      [openConfirmation]
    );

    const onSubmit = useCallback(
      async (values: DistrictFormValues) => {
        setError(undefined);
        try {
          const {
            state,
            name,
            properties,
            timeseries,
            contact,
            address,
            metadata,
            notes,
          } = values;

          const { __typename, ...addressInput } = address;

          await updateDistrict({
            variables: {
              district: {
                name,
                state,
                properties: formatPropertiesBeforeSubmit({
                  properties,
                  timeseries,
                }),
                contact: Object.keys(contact).reduce<DistrictInput["contact"]>(
                  (acc, key) =>
                    ({
                      ...acc,
                      [key]: contact[key as keyof typeof contact]
                        .map(({ value }) => value)
                        .filter((email): email is string => !!email),
                    } as DistrictInput["contact"]),
                  {} as DistrictInput["contact"]
                ),
                address: addressInput,
                metadata: metadata.map(({ key, label, value }) => ({
                  key,
                  label,
                  value:
                    value === true
                      ? "true"
                      : value === false
                      ? "false"
                      : value ?? "",
                })),
                notes: notes.map((n) => ({
                  content: n.content,
                  createdBy: n.createdBy,
                  createdAt: n.createdAt,
                })),
              },
            },
          });

          // RELOAD AND UPDATE TIMESERIES
          await syncTimeseriesAfterUpdate(
            district.companyKey,
            district.key,
            district.key,
            timeseries,
            updateTimeseries,
            reloadTimeseries,
            (errors) => {
              if (errors?.length) {
                setError(errors[0].message);
                throw errors[0]?.message ?? "Unknown Error";
              }
            }
          );

          setIsEditing(false);
          setSuccessfulUpdate(true);
          setTimeout(() => setSuccessfulUpdate(false), 5000);

          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          if (e instanceof ApolloError) {
            setError({ message: extractErrorMessage(e) });
          } else {
            setError(e as Error);
          }
        }
      },
      [
        district.companyKey,
        district.key,
        onSuccess,
        reloadTimeseries,
        updateDistrict,
        updateTimeseries,
      ]
    );

    const initialValues = useMemo(() => {
      return formatInitialDistrictFormValues(
        district,
        districtTemplate?.districtTemplate.properties,
        timeseries
      );
    }, [district, districtTemplate, timeseries]);

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={districtValidationSchema}
          onSubmit={onSubmit}
          validateOnChange
          validateOnBlur
          validateOnMount
        >
          {({ isSubmitting, isValid, touched, resetForm }) => {
            return (
              <>
                <FormWrapper>
                  {successfulUpdate && (
                    <SuccessAlert>
                      {district.name} erfolgreich aktualisiert.
                    </SuccessAlert>
                  )}
                  {error && (
                    <AlertRetryable
                      message={error?.message}
                      onRetry={() => setError(undefined)}
                    />
                  )}
                  <DistrictForm
                    isEditing={isEditing}
                    district={district}
                    setIsEditing={setIsEditing}
                    setConfirmDeleteModalOpen={setConfirmDeleteModalOpen}
                    onCancel={onCancel}
                  />
                  {isEditing && (
                    <ButtonGroup style={{ marginTop: 10 }}>
                      <Button
                        secondary
                        onClick={() => onCancel({ resetForm, touched })}
                      >
                        {Object.keys(touched).length
                          ? "Änderungen verwerfen"
                          : "Abbrechen"}
                      </Button>
                      <FormikSubmit
                        isLoading={isSubmitting}
                        disabled={!isValid}
                      >
                        Speichern
                      </FormikSubmit>
                    </ButtonGroup>
                  )}
                </FormWrapper>
                <Prompt
                  when={!!Object.keys(touched).length}
                  message={() =>
                    `Es gibt Änderungen, die noch nicht gespeichert wurden.

Bist du sicher, dass du abbrechen möchtest?`
                  }
                />
              </>
            );
          }}
        </Formik>

        <DeleteConfirmModalWithForm
          entityName={district.name}
          isOpen={confirmDeleteModalOpen}
          setIsOpen={setConfirmDeleteModalOpen}
          onConfirm={(submitForm) => submitForm()}
          errorMsg={removeError && extractErrorMessage(removeError)}
          initialValues={{
            district: undefined,
            company: undefined,
          }}
          validationSchema={yup.object().shape({
            company: yup
              .string()
              .required()
              .test(
                "company-equal",
                "Muss gleich companyKey sein",
                (value) => value === district.companyKey
              ),
            district: yup
              .string()
              .required()
              .test(
                "district-equal",
                "Muss gleich districtKey sein",
                (value) => value === district.key
              ),
          })}
          onSubmit={() => {
            return removeDistrict().then(() => history.push("/districts"));
          }}
        >
          <FormikInput name="company" label="Company key" />
          <FormikInput name="district" label="District key" />
        </DeleteConfirmModalWithForm>
      </>
    );
  }
);
