export const capitalize = (string?: string) =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

export const formatDistrictName = (districtName: string) =>
  districtName.replaceAll("/", "").replaceAll(".", "");

export const insertStringNTimes = (string: string, length: number) =>
  Array.from({ length })
    .map(() => string)
    .join("");

export const formatFormErrors = (
  errors: string | object | any[] | undefined,
  indent: number = 0
): string | undefined => {
  if (!errors) {
    return undefined;
  }

  if (typeof errors === "string") {
    return `${errors}`;
  }

  if (Array.isArray(errors)) {
    return errors
      .map((value, index) =>
        value
          ? `${insertStringNTimes("\t", indent)}[${index}]: ${formatFormErrors(
              value,
              indent + 1
            )}`
          : ""
      )
      .filter((error): error is string => !!error)
      .join("\n");
  }

  return Object.entries(errors).reduce(
    (acc, [key, value]) =>
      value
        ? `${acc}${insertStringNTimes("\t", indent)}${key}:${
            typeof value === "string" ? " " : "\n"
          }${formatFormErrors(value, indent + 1)}\n`
        : "",
    ""
  );
};
