import React, { useMemo } from "react";
import styled from "styled-components";

import { CheckboxNew, Icons } from "@ampeersenergy/ampeers-ui-components";

import {
  DropdownItem,
  DropdownDividerItem,
  Dropdown,
  PopOverItem,
  DropdownValueItem,
  DropdownProps,
} from "./dropdown";
import { DropdownButtonProps, DropdownButtonWrapper } from "./dropdown-button";

export type DropdownCheckboxItem = DropdownValueItem & {
  checked: boolean;
};

const Checkbox = styled(CheckboxNew)`
  flex: 1;
  justify-content: flex-start;

  &:first-child label {
    border-radius: 4px 4px 0 0;
  }

  &:last-child label {
    border-radius: 0 0 4px 4px;
  }

  &:first-child:last-child label {
    border-radius: 4px 4px;
  }

  label {
    flex: 1;
    display: flex;
    cursor: pointer;
    padding: 7px 22px;
    font-size: 13px;
    font-weight: bold;
    color: ${(props) => props.theme.primaryColor};
    border-radius: 0;

    &:hover {
      background-color: ${(props) => props.theme.secondaryColor};
    }
  }
`;

export const renderAsCheckbox =
  (onClick: DropdownProps["onItemClick"]) => (item: DropdownItem) => {
    if ((item as DropdownDividerItem).divider) {
      return item;
    }

    const { value, label, checked, CustomPopOverItem, ...i } =
      item as DropdownCheckboxItem;

    if (CustomPopOverItem) {
      return {
        label: (
          <CustomPopOverItem
            key={value}
            className="popover-item"
            onClick={(e) => {
              onClick?.(value);
              e?.stopPropagation();
            }}
          >
            {label}
          </CustomPopOverItem>
        ),
      };
    }

    if (value === "all") {
      return {
        label: (
          <PopOverItem
            key={value}
            className="popover-item"
            onClick={(e) => {
              onClick?.(value);
              e?.stopPropagation();
            }}
          >
            {label}
          </PopOverItem>
        ),
      };
    }

    return {
      ...i,
      value,
      originalLabel: label,
      label: (
        <Checkbox
          id={`${label}-checkbox`}
          key={`${label}-checkbox`}
          label={label as string}
          checked={checked}
          onChange={() => onClick?.(value)}
        />
      ),
    };
  };

export interface DropdownCheckboxProps extends DropdownButtonProps {
  items: DropdownCheckboxItem[];
}

export const DropdownCheckbox: React.FC<DropdownCheckboxProps> = ({
  items,
  icon,
  buttonColor,
  onItemClick,
  children,
  ...props
}) => {
  const checkboxItems = useMemo(
    () => items.map(renderAsCheckbox(onItemClick)),
    [items, onItemClick]
  );

  const Chevron = props.isOpen ? Icons.ChevronUp : Icons.ChevronDown;

  return (
    <Dropdown
      {...props}
      items={checkboxItems}
      CustomPopOverItem={({ children }) => children}
    >
      <DropdownButtonWrapper
        secondary
        small
        icon={icon}
        onClick={props.isOpen ? props.onClose : props.onOpen}
        color={buttonColor}
      >
        {children}
        <Chevron size={22} />
      </DropdownButtonWrapper>
    </Dropdown>
  );
};
