import deepEqual from "deep-equal";
import {
  AssetFormValues,
  ExtendedProperty,
  LocalProperty,
  LocalTimeseries,
  PropertyFormValue,
  ReducedTimeseries,
  TensorFormValue,
  TimeseriesFormValues,
} from "../components/types";
import {
  AssetFlags,
  GetTimeseriesQueryHookResult,
  PropertyTemplate,
} from "../graphql/sdks/controller";
import { arrayToObject } from "./array.utils";
import { omitGraphqlType } from "./asset.utils";
import { isNullOrUndefined, pickKeys } from "./object.utils";
import { useUpdateTimeseriesMutation } from "../graphql/sdks/controller";
import { GetTimeseriesDocument } from "../graphql/sdks/controller";
import { GraphQLError } from "graphql";

export type RequiredAssetFlags = Pick<
  AssetFlags,
  "isMonitoringRelevant" | "isOptimizationRelevant" | "isOnsite"
>;

export type DataType =
  | "Param"
  | "ts"
  | "table"
  | "tensor"
  | "scalarList"
  | "scalarTimeDependent"
  | "timeseriesList";

export type DataSubType = "float" | "integer" | "string" | "boolean";

export const parsePropertyValue = (
  dataType: DataType,
  dataSubType?: DataSubType,
  value?:
    | string
    | TensorFormValue["value"]
    | { key: string; value: string }[]
    | { key: string; value: string; start: string; end: string }[]
) => {
  if (dataType === "ts" || dataType === "table") return value;

  if (dataType === "tensor") {
    const tensorValue = value as TensorFormValue["value"];

    return {
      ...(tensorValue ?? {}),
      version: tensorValue?.version ?? "v1",
      interpolations: (tensorValue?.interpolations ?? []).map(
        ({ dimensionX, dimensionY, values }) => ({
          dimensionX: {
            ...dimensionX,
            unit: dimensionX.unit || null,
            values: dimensionX.values.map((v) => {
              if (v === null || (v as unknown) === "" || v === undefined)
                return null;
              return +v;
            }),
          },
          dimensionY: {
            ...dimensionY,
            unit: dimensionY.unit || null,
            values: dimensionY.values.map((v) => {
              if (v === null || (v as unknown) === "" || v === undefined)
                return null;
              return +v;
            }),
          },
          values: values.map((valueArray) =>
            valueArray.map((v) => {
              if (v === null || (v as unknown) === "" || v === undefined)
                return null;
              return +v;
            })
          ),
        })
      ),
    };
  }

  if (dataType === "timeseriesList") {
    return value ?? [];
  }

  if (dataType === "scalarTimeDependent") {
    return (
      (value ?? []) as Array<{
        key: string;
        value: string;
        start: string;
        end: string;
      }>
    ).map(({ start, end, value }) => ({ start, end, value: value && +value }));
  }

  if (value === null || value === undefined || value === "") return null;

  if (dataType === "scalarList") {
    switch (dataSubType) {
      case "float":
      case "integer":
        return (value as { key: string; value: string }[]).map(
          ({ value }) => +value
        );
      default:
        return (value as { key: string; value: string }[]).map(
          ({ value }) => value
        );
    }
  }

  if (!dataSubType) return value;

  const stringValue = value as string;

  switch (dataSubType) {
    case "boolean":
      return stringValue === "true";
    case "float":
      return parseFloat(stringValue);
    case "integer":
      return parseInt(stringValue);
    case "string":
    default:
      return stringValue;
  }
};

export const isPropertyRequired = (
  assetFlags: RequiredAssetFlags | undefined,
  propFlags: ExtendedProperty["flags"],
  propTemplateFlags?: PropertyTemplate["flags"]
) => {
  const isMonitoringRelevant =
    (!assetFlags || assetFlags.isMonitoringRelevant) &&
    (propFlags?.isMonitoringRelevant ??
      propTemplateFlags?.isMonitoringRelevant ??
      false);
  const isOperationRelevant =
    (!assetFlags || assetFlags.isOptimizationRelevant) &&
    (propFlags?.isOperationRelevant ??
      propTemplateFlags?.isOperationRelevant ??
      false);

  return isMonitoringRelevant || isOperationRelevant;
};

export const mergePropertyTemplates = (
  existingProperties: LocalProperty[],
  existingTimeseries: LocalTimeseries[],
  templates: PropertyTemplate[],
  flags?: RequiredAssetFlags,
  assetKey?: string,
  parentAssetKey?: string
): {
  propertyTemplates: PropertyTemplate[];
  properties: ExtendedProperty[];
  timeseries: (ExtendedProperty & { relativeTimeseries: LocalTimeseries[] })[];
  tensors: ExtendedProperty[];
} => {
  const existingPropertiesMap: Record<string, LocalProperty> = arrayToObject(
    existingProperties,
    "key"
  );

  const propTemplatesByKey: Record<string, PropertyTemplate> = arrayToObject(
    templates,
    "key"
  );

  const mergedPropertyTemplates: ExtendedProperty[] = templates.map((p) => {
    return {
      ...omitGraphqlType(p),
      propKey: p.key,
      internal: false,
      unit: p.unit,
      isRequired: isPropertyRequired(flags, p.flags),
      warnings: [],
      aliases: [],
      defaultValue: p.defaultValue,
      value: existingPropertiesMap[p.key]?.value,
    };
  });

  const existingPropertyKeys = existingProperties.map(({ key }) => key);

  const allProperties: ExtendedProperty[] = [
    ...mergedPropertyTemplates.filter(
      ({ key }) => !existingPropertyKeys.includes(key)
    ),
    ...existingProperties.map((p) => {
      const property = p as ExtendedProperty;
      const isMonitoringRelevant =
        property.flags?.isMonitoringRelevant ??
        propTemplatesByKey[p.key]?.flags?.isMonitoringRelevant ??
        false;
      const isOperationRelevant =
        property.flags?.isOperationRelevant ??
        propTemplatesByKey[p.key]?.flags?.isOperationRelevant ??
        false;

      return {
        ...p,
        propKey: p.key,
        units: [property.unit ?? propTemplatesByKey[p.key]?.unit].filter(
          (v): v is string => !!v
        ),
        isRequired: isPropertyRequired(
          flags,
          property.flags,
          propTemplatesByKey[p.key]?.flags
        ),
        flags: {
          isMonitoringRelevant,
          isOperationRelevant,
        },
      };
    }),
  ];

  const filteredPropertiesByFlag = allProperties.filter((p) => {
    return (
      !flags ||
      !isNullOrUndefined(p.value) ||
      p.propKey.includes("custom") ||
      (flags.isMonitoringRelevant && p.flags.isMonitoringRelevant) ||
      (flags.isOptimizationRelevant && p.flags.isOperationRelevant)
    );
  });

  const sortedProperties = sortProperties(filteredPropertiesByFlag);

  const mergedTimeseries = sortedProperties
    .filter((p) => p.dataType === "ts" || p.dataType === "timeseriesList")
    .map((ts) => {
      const relativeTimeseries = existingTimeseries.filter((t) => {
        if (parentAssetKey && assetKey) {
          return (
            t.reference.entityKey === parentAssetKey &&
            t.reference.childEntityKey === assetKey &&
            t.reference.propertyKey === ts.key
          );
        } else if (assetKey) {
          return (
            t.reference.entityKey === assetKey &&
            t.reference.propertyKey === ts.key
          );
        } else {
          return t.reference.propertyKey === ts.key;
        }
      });

      let newRelativeTimeseries = relativeTimeseries.concat();

      if (ts.key === "operatingMode") {
        if (relativeTimeseries[0]) {
          const { target } = relativeTimeseries[0];

          newRelativeTimeseries.push({
            ...relativeTimeseries[0],
            target:
              relativeTimeseries[0].id ||
              target.additionalExternalId ||
              target.externalId ||
              target.protocol ||
              target.timezone ||
              target.unit
                ? target
                : { type: "district" },
          });
        } else {
          newRelativeTimeseries.push({
            reference: {
              propertyKey: ts.key,
            },
            source: {
              type: flags?.isOnsite ? "district" : "inference",
            },
            target: {
              type: "district",
            },
          } as LocalTimeseries);
        }
      }

      if (!relativeTimeseries[0] && ts.dataType !== "timeseriesList") {
        newRelativeTimeseries.push({
          reference: {
            propertyKey: ts.key,
          },
          source: {
            type:
              flags?.isOnsite && ts.key !== "operatingStatus"
                ? "district"
                : "inference",
          },
          target: {
            type: "none",
          },
        } as LocalTimeseries);
      }

      return {
        ...ts,
        relativeTimeseries: newRelativeTimeseries,
      };
    });

  return {
    propertyTemplates: templates,
    properties: sortedProperties.filter((p) =>
      ["Param", "scalarList", "scalarTimeDependent"].includes(p.dataType)
    ),
    timeseries: mergedTimeseries,
    tensors: sortedProperties.filter((p) => p.dataType === "tensor"),
  };
};

export function isValidPropValue(
  value: string | number | boolean | undefined | null
) {
  return value !== undefined && value !== null && value !== "";
}

export function isValidTensorValue(value: TensorFormValue["value"]) {
  return !!value?.interpolations.length;
}

export function sortProperties(properties: ExtendedProperty[]) {
  return properties.sort((p1, p2) => {
    // Sort by required
    if (p1.isRequired && !p2.isRequired) return -1;
    if (!p1.isRequired && p2.isRequired) return 1;

    // Sort by flags
    if (p1.flags?.isMonitoringRelevant && !p2.flags?.isMonitoringRelevant)
      return -1;
    if (!p1.flags?.isMonitoringRelevant && p2.flags?.isMonitoringRelevant)
      return 1;

    if (p1.flags?.isOperationRelevant && !p2.flags?.isOperationRelevant)
      return -1;
    if (!p1.flags?.isOperationRelevant && p2.flags?.isOperationRelevant)
      return 1;

    // Sort by value presence
    if (p1.value && !p2.value) return -1;
    if (!p1.value && p2.value) return 1;

    // Sort by key
    return p1.key.localeCompare(p2.key);
  });
}

export function formatPropertiesBeforeSubmit({
  properties,
  timeseries,
  tensors = [],
}: {
  properties: PropertyFormValue[];
  timeseries: PropertyFormValue[];
  tensors?: TensorFormValue[];
}) {
  return properties
    .filter(
      ({ value }) => value !== undefined && value !== null && value !== ""
    )
    .concat(tensors)
    .concat(timeseries)
    .map(
      ({
        propKey,
        value,
        dataSubType,
        dataType,
        description,
        key,
        unit,
        internal,
      }) => ({
        dataSubType,
        dataType,
        key: propKey || key,
        unit: unit === "null" ? null : unit,
        description: description ?? "",
        internal: !!internal,
        value: parsePropertyValue(
          dataType as DataType,
          dataSubType as DataSubType,
          value
        ),
      })
    );
}

const getSource = <T extends Pick<TimeseriesFormValues, "source">>(
  timeseries: T
) => {
  if (timeseries.source.type === "district") {
    return {
      type: timeseries.source.type,
      protocol:
        timeseries.source.protocol === "null"
          ? null
          : timeseries.source.protocol || null,
      externalId: timeseries.source.externalId || null,
      additionalExternalId: timeseries.source.additionalExternalId || null,
      isInverted: !!timeseries.source.isInverted,
      unit:
        timeseries.source.unit === "null"
          ? null
          : timeseries.source.unit || null,
      timezone: timeseries.source.timezone || "UTC",
    };
  }
  if (timeseries.source.type === "inference") {
    return {
      type: timeseries.source.type,
      summation: timeseries.source.summation
        ? omitGraphqlType({
            ...timeseries.source.summation,
            inputs: timeseries.source.summation.inputs.map(omitGraphqlType),
          })
        : null,
    };
  }
  return { type: timeseries.source.type };
};

const getTarget = <T extends Pick<TimeseriesFormValues, "target">>(
  timeseries: T
) => {
  if (timeseries.target.type === "district") {
    return {
      type: timeseries.target.type,
      protocol:
        timeseries.target.protocol === "null"
          ? null
          : timeseries.target.protocol || null,
      externalId: timeseries.target.externalId || null,
      additionalExternalId: timeseries.target.additionalExternalId || null,
      unit:
        timeseries.target.unit === "null"
          ? null
          : timeseries.target.unit || null,
      timezone: timeseries.target.timezone || "UTC",
    };
  }
  return { type: timeseries.target.type };
};

export function formatInitialTimeseriesFormValues<T extends LocalTimeseries>(
  initialValues?: T
): T {
  return omitGraphqlType({
    refTimeseriesProp: {},
    ...(initialValues ?? {}),
    ...(initialValues?.reference
      ? { reference: omitGraphqlType(initialValues?.reference) }
      : {}),
    source: initialValues ? getSource(initialValues) : {},
    target: initialValues ? getTarget(initialValues) : {},
    notes: initialValues?.notes?.map((n) => omitGraphqlType(n)) ?? [],
  }) as unknown as T;
}

export function formatTimeseriesBeforeSubmit<
  T extends LocalTimeseries | TimeseriesFormValues,
>(timeseries: T): T {
  const ts = formatInitialTimeseriesFormValues(timeseries as LocalTimeseries);

  const {
    notes,
    refTimeseriesProp,
    reference,
    inference,
    source,
    metadata,
    ...rest
  } = ts as TimeseriesFormValues;

  return {
    ...(rest as any),
    notes: notes.map((n) => ({
      content: n.content,
      createdBy: n.createdBy,
      createdAt: n.createdAt,
    })),
    source: {
      ...source,
      summation: source.summation
        ? {
            offset: source.summation.offset && +source.summation.offset,
            thresholdType:
              source.summation.thresholdType && +source.summation.thresholdType,
            inputs: source.summation.inputs.map(
              ({ entityKey, propertyKey, factor }) => ({
                entityKey,
                propertyKey,
                factor: factor && +factor,
              })
            ),
          }
        : source.summation,
    },
    ...(metadata
      ? {
          metadata: omitGraphqlType({
            ...metadata,
            ingressDataNote: metadata?.ingressDataNote || null,
          }),
        }
      : {}),
  };
}

export function hasMissingProperties(
  propertyTemplates: PropertyTemplate[],
  existingProperties: LocalProperty[],
  flags: RequiredAssetFlags,
  dataType?: "Param" | "ts" | "tensor"
) {
  const { properties, timeseries, tensors } = mergePropertyTemplates(
    existingProperties,
    [],
    propertyTemplates,
    flags
  );

  const elements =
    dataType === "Param"
      ? properties
      : dataType === "ts"
      ? timeseries
      : dataType === "tensor"
      ? tensors
      : [...properties, ...timeseries, ...tensors];

  return !elements.every((p) => !p.isRequired || isValidPropValue(p.value));
}

export const registerFields = (
  values: Pick<AssetFormValues, "tensors">,
  prefixIdentifier?: string,
  registerFn?: (value: string) => void
) => {
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";
  if (registerFn) {
    values.tensors?.forEach((tensor, i) => {
      registerFn(`${_prefixIdentifier}tensors[${i}].propKey`);
      registerFn(`${_prefixIdentifier}tensors[${i}].dataType`);
      registerFn(`${_prefixIdentifier}tensors[${i}].dataSubType`);
      registerFn(`${_prefixIdentifier}tensors[${i}].unit`);
      registerFn(`${_prefixIdentifier}tensors[${i}].description`);
      registerFn(`${_prefixIdentifier}tensors[${i}].comment`);
      tensor.value?.interpolations.forEach((interpolation, j) => {
        registerFn(
          `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionX.key`
        );
        registerFn(
          `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionX.description`
        );
        registerFn(
          `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionX.unit`
        );
        registerFn(
          `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionY.key`
        );
        registerFn(
          `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionY.description`
        );
        registerFn(
          `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionY.unit`
        );
        interpolation.dimensionX.values.forEach((_, k) => {
          registerFn(
            `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionX.values[${k}]`
          );
        });
        interpolation.dimensionY.values.forEach((_, k) => {
          registerFn(
            `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].dimensionY.values[${k}]`
          );
        });
        interpolation.values.forEach((value, k) => {
          value.forEach((_, z) => {
            registerFn(
              `${_prefixIdentifier}tensors[${i}].value.interpolations[${j}].values[${k}][${z}]`
            );
          });
        });
      });
    });
  }
};

export function timeseriesChanged(
  existingTs: Pick<LocalTimeseries, "source" | "target" | "notes">,
  updatedTs: Pick<LocalTimeseries, "source" | "target" | "notes">
) {
  const relevantKeys: (keyof Pick<
    LocalTimeseries,
    "source" | "target" | "notes"
  >)[] = ["source", "target", "notes"];

  const reducedExistingTs = pickKeys(
    formatInitialTimeseriesFormValues(existingTs as LocalTimeseries),
    relevantKeys
  );

  const reducedUpdatedTs = pickKeys(
    formatInitialTimeseriesFormValues(updatedTs as LocalTimeseries),
    relevantKeys
  );

  return !deepEqual(reducedExistingTs, reducedUpdatedTs);
}

export async function syncTimeseriesAfterUpdate(
  companyKey: string,
  districtKey: string,
  entityKey: string,
  timeseries: (PropertyFormValue & { relativeTimeseries: LocalTimeseries[] })[],
  updateTimeseries: ReturnType<typeof useUpdateTimeseriesMutation>[0],
  reloadTimeseries: GetTimeseriesQueryHookResult["refetch"],
  onError: (errors?: readonly GraphQLError[]) => void
) {
  // RELOAD AND UPDATE TIMESERIES
  await reloadTimeseries().then(({ data }) => {
    return Promise.all(
      timeseries.map((ts) => {
        if (!ts.relativeTimeseries?.length) {
          return Promise.resolve();
        }

        const existingTs = (data?.timeseries ?? []).find(
          (t) =>
            (t.reference.propertyKey === ts.key ||
              t.reference.propertyKey === ts.propKey) &&
            t.reference.entityKey === entityKey
        ) || {
          source: {
            type: "inference",
          },
          target: {
            type: "none",
          },
          notes: [],
        };

        ts.relativeTimeseries.forEach((rts) => {
          if (timeseriesChanged(existingTs, rts)) {
            return updateTimeseries({
              variables: {
                timeseries: formatTimeseriesBeforeSubmit({
                  ...existingTs,
                  ...rts,
                } as TimeseriesFormValues),
              },
              refetchQueries: [
                {
                  query: GetTimeseriesDocument,
                  variables: {
                    districtIdentifier: {
                      company: companyKey,
                      district: districtKey,
                    },
                    filter: {
                      sourceType: ["district", "inference"],
                    },
                  },
                },
              ],
            }).then(({ errors }) => {
              onError(errors);
            });
          }
        });

        return Promise.resolve();
      })
    );
  });
}

export const externalIdChanged = (
  ts1: ReducedTimeseries,
  ts2: ReducedTimeseries
) => {
  return (
    ts1.source.externalId !== ts2.source.externalId ||
    ts1.target.externalId !== ts2.target.externalId
  );
};
