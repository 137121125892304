import { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnFilter, TimeseriesViewFilter } from "../components/types";
import {
  FilterState,
  LOCAL_STORAGE_FILTER_IDENTIFIER,
  appendSessionData,
  getSession,
} from "../services/session.service";
import deepEqual from "deep-equal";

const getSavedFilter = (index?: number) => {
  const session = getSession<FilterState>(LOCAL_STORAGE_FILTER_IDENTIFIER);
  const savedFilters = session?.timeseriesFilters;

  if (!savedFilters || !session || session.activeTimeseriesFilter === undefined)
    return;

  return savedFilters[index ?? session.activeTimeseriesFilter];
};

const mapColumnFilter = (columnFilter: ColumnFilter[]) =>
  columnFilter.reduce(
    (acc, { accessor, defaultValue }) => ({
      ...acc,
      [accessor]: !!defaultValue,
    }),
    {}
  );

export const initialColumnFilter: TimeseriesViewFilter["columnFilter"] = [
  { accessor: "building", label: "Gebäude", defaultValue: true },
  { accessor: "description", label: "Beschreibung", defaultValue: true },
  {
    accessor: "source.type",
    label: "Quelle - Typ",
    defaultValue: true,
  },
  {
    accessor: "source.protocol",
    label: "Quelle - Protokoll",
    defaultValue: true,
  },
  { accessor: "source.unit", label: "Quelle - Unit", defaultValue: true },
  {
    accessor: "source.isInverted",
    label: "Quelle - Is Inverted",
    defaultValue: true,
  },
  {
    accessor: "source.externalId",
    label: "Quelle - External ID",
    defaultValue: true,
  },
  { accessor: "target.type", label: "Target - Typ" },
  { accessor: "target.protocol", label: "Target - Protokoll" },
  { accessor: "target.unit", label: "Target - Unit" },
  { accessor: "target.externalId", label: "Target - External ID" },
  { accessor: "refTimeseriesProp[0].dataSubType", label: "Datatype" },
  {
    accessor: "metadata.isIngressConnectionActive",
    label: "Datenverbindung aktiv",
  },
  {
    accessor: "metadata.isMsrTestSuccessful",
    label: "Test MSR erfolgt & erfolgreich",
  },
  {
    accessor: "metadata.isIngressDataPlausible",
    label: "Datenpunkt plausibel",
  },
  { accessor: "metadata.ingressDataNote", label: "Notiz Dateningress" },
  { accessor: "uuid", label: "UUID" },
].map((v) => ({ ...v, defaultValue: !!v.defaultValue }));

export const useTimeseriesFilters = (
  initialRowFilter: TimeseriesViewFilter["rowFilter"]
) => {
  const defaultFilter = useMemo(() => getSavedFilter(), []);

  const [savedFilters, setSavedFilters] = useState<FilterState>();

  const [filterChanged, setFilterChanged] = useState(false);

  const [rowFilter, setRowFilter] = useState<TimeseriesViewFilter["rowFilter"]>(
    defaultFilter?.rowFilter ?? initialRowFilter
  );

  const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>(
    mapColumnFilter(defaultFilter?.columnFilter ?? initialColumnFilter)
  );

  const [textSearch, setTextSearch] = useState<{ id: string; value: string }[]>(
    []
  );

  const onRowFilterChanged = useCallback(
    (rowFilter: TimeseriesViewFilter["rowFilter"]) => {
      setRowFilter(rowFilter);
    },
    [setRowFilter]
  );

  const onColumnFilterChanged = useCallback(
    (columnFilter: Record<string, boolean>) => {
      setVisibleColumns(columnFilter);
    },
    [setVisibleColumns]
  );

  const onSavedFilterChanged = useCallback(() => {
    if (
      !savedFilters ||
      (!savedFilters.timeseriesFilters?.length &&
        savedFilters.activeTimeseriesFilter === -1)
    ) {
      setFilterChanged(
        !deepEqual(
          { rowFilter: initialRowFilter, columnFilter: initialColumnFilter },
          {
            rowFilter,
            columnFilter: initialColumnFilter.map((cf) => ({
              ...cf,
              defaultValue: visibleColumns[cf.accessor],
            })),
          }
        )
      );
      return;
    }

    const savedFilter =
      savedFilters?.timeseriesFilters?.[
        savedFilters?.activeTimeseriesFilter ?? 0
      ];

    if (savedFilter) {
      setFilterChanged(
        !deepEqual(savedFilter, {
          rowFilter,
          columnFilter: initialColumnFilter.map((cf) => ({
            ...cf,
            defaultValue: visibleColumns[cf.accessor],
          })),
        })
      );
    }
  }, [savedFilters, initialRowFilter, rowFilter, visibleColumns]);

  const saveFilter = useCallback(
    (index) => {
      let newFilters = savedFilters?.timeseriesFilters ?? [];

      const newFilter = {
        rowFilter,
        columnFilter: initialColumnFilter.map((cf) => ({
          ...cf,
          defaultValue: visibleColumns[cf.accessor],
        })),
      };

      if (index === undefined) {
        return;
      } else if (index === -1) {
        newFilters.push(newFilter);
      } else {
        newFilters.splice(index, 1, newFilter);
      }

      const newState = {
        timeseriesFilters: newFilters,
        activeTimeseriesFilter: index > -1 ? index : newFilters?.length - 1,
      };

      setSavedFilters(newState);
      appendSessionData(newState, LOCAL_STORAGE_FILTER_IDENTIFIER);

      onSavedFilterChanged();
    },
    [rowFilter, visibleColumns, savedFilters, onSavedFilterChanged]
  );

  const loadFilter = useCallback(
    (index: number) => {
      const savedFilter = savedFilters?.timeseriesFilters?.[index];

      if (savedFilter) {
        setRowFilter(savedFilter.rowFilter);
        setVisibleColumns(mapColumnFilter(savedFilter.columnFilter));
        setSavedFilters({
          timeseriesFilters: savedFilters.timeseriesFilters,
          activeTimeseriesFilter: index,
        });
        appendSessionData(
          {
            activeTimeseriesFilter: index,
          },
          LOCAL_STORAGE_FILTER_IDENTIFIER
        );
      }
    },
    [savedFilters]
  );

  const deleteFilter = useCallback(
    (index: number) => {
      if (savedFilters?.timeseriesFilters) {
        const newFilters = savedFilters.timeseriesFilters.concat();
        newFilters.splice(index, 1);

        const newState = {
          timeseriesFilters: newFilters,
          activeTimeseriesFilter:
            index === savedFilters.activeTimeseriesFilter
              ? -1
              : savedFilters.activeTimeseriesFilter,
        };

        setSavedFilters(newState);
        appendSessionData(newState, LOCAL_STORAGE_FILTER_IDENTIFIER);
      }
    },
    [savedFilters]
  );

  const resetFilter = useCallback(() => {
    if (
      savedFilters?.timeseriesFilters &&
      savedFilters?.activeTimeseriesFilter !== undefined &&
      savedFilters?.activeTimeseriesFilter !== -1
    ) {
      const originalFilter =
        savedFilters.timeseriesFilters[savedFilters.activeTimeseriesFilter];

      if (originalFilter) {
        setRowFilter(originalFilter.rowFilter);
        setVisibleColumns(mapColumnFilter(originalFilter.columnFilter));
      }
    } else {
      setRowFilter(defaultFilter?.rowFilter ?? initialRowFilter);
      setVisibleColumns(
        mapColumnFilter(defaultFilter?.columnFilter ?? initialColumnFilter)
      );
    }
  }, [defaultFilter, initialRowFilter, savedFilters]);

  useEffect(() => {
    const session = getSession<FilterState>(LOCAL_STORAGE_FILTER_IDENTIFIER);
    setSavedFilters(session);
  }, []);

  useEffect(() => {
    onSavedFilterChanged();
  }, [onSavedFilterChanged]);

  return {
    rowFilter,
    setAllRowFilters: (values: TimeseriesViewFilter["rowFilter"]) =>
      onRowFilterChanged({ ...rowFilter, ...values }),
    setRowFilter: (key: string, value: string[]) =>
      onRowFilterChanged({ ...rowFilter, [key]: value }),
    columnFilter: initialColumnFilter,
    visibleColumns,
    setVisibleColumns: onColumnFilterChanged,
    textSearch,
    setTextSearch,
    filterChanged,
    onSavedFilterChanged,
    savedFilters: savedFilters?.timeseriesFilters,
    saveFilter,
    loadFilter,
    deleteFilter,
    resetFilter,
  };
};
