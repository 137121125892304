import React, { MouseEvent, useCallback, useContext, useMemo } from "react";
import { useTheme } from "styled-components";
import { useFormikContext } from "formik";
import {
  BaseIcon,
  DynamicListItemProps,
  Icons,
  Input as RawInput,
  Theme,
  TooltipLight,
  useDynamicListItem,
} from "@ampeersenergy/ampeers-ui-components";

import { PropertyTemplate } from "../../graphql/sdks/controller";
import { AssetFormValues, LocalUnit, PropertyFormValue } from "../types";
import {
  DeleteButtonWithMargin,
  PropertyListItemContainer,
  PropertyListItemWrapper,
  SpacedTag,
} from "./style";
import { InlineCheckbox, Input, NewItemWrapper, PlusIcon } from "../style";
import { isValidPropValue } from "../../helpers/properties.utils";
import { PropertyEditForm } from "./property-edit-form";
import { MultistepFormContext } from "../multistep-form/context";
import { findValue } from "../../helpers/object.utils";

export interface PropertyListItemProps extends DynamicListItemProps {
  isEditing?: boolean;
  item: DynamicListItemProps["item"] & PropertyFormValue;
  units: LocalUnit[];
  prefixIdentifier?: string;
  propertyTemplates: PropertyTemplate[];
  canDuplicate?: boolean;
}

export const PropertyListItem: React.FC<PropertyListItemProps> = (props) => {
  const {
    item,
    index,
    isEditing,
    units,
    prefixIdentifier,
    propertyTemplates,
    canDuplicate,
  } = props;
  const _prefixIdentifier = prefixIdentifier ? `${prefixIdentifier}.` : "";

  const { values, setFieldValue, errors } = useFormikContext<AssetFormValues>();

  const items = useMemo(
    () =>
      prefixIdentifier
        ? findValue<AssetFormValues>(prefixIdentifier, values).properties
        : values.properties,
    [prefixIdentifier, values]
  );

  const { unregisterField } = useContext(MultistepFormContext) ?? {};
  const theme = useTheme() as Theme;
  const {
    editMode,
    setEditMode,
    isValid,
    onAddItem,
    onDelete,

    Icon,
    type,
  } = useDynamicListItem({
    ...props,
  });

  const onPropDelete = useCallback(() => {
    const lastIndex = items.length - 1;
    if (unregisterField) {
      unregisterField(`${_prefixIdentifier}properties[${lastIndex}].propKey`);
      unregisterField(`${_prefixIdentifier}properties[${lastIndex}].value`);
      unregisterField(`${_prefixIdentifier}properties[${lastIndex}].dataType`);
      unregisterField(
        `${_prefixIdentifier}properties[${lastIndex}].dataSubType`
      );
      unregisterField(`${_prefixIdentifier}properties[${lastIndex}].unit`);
      unregisterField(
        `${_prefixIdentifier}properties[${lastIndex}].description`
      );
      unregisterField(`${_prefixIdentifier}properties[${lastIndex}].comment`);
    }
    onDelete();
  }, [_prefixIdentifier, items, onDelete, unregisterField]);

  const onDuplicateProperty = useCallback(() => {
    setFieldValue(`${_prefixIdentifier}properties`, [
      ...items,
      {
        ...item,
        propKey: undefined,
        isPlaceholder: true,
      },
    ]);
  }, [setFieldValue, items, item, _prefixIdentifier]);

  const unitKeys = useMemo(
    () =>
      !item.isPlaceholder
        ? item.unit
          ? [item.unit]
          : []
        : (units ?? []).map(({ key }) => key),
    [item, units]
  );

  const CurrentIcon: typeof BaseIcon | undefined = useMemo(() => {
    if (!isValid || !isValidPropValue(item.value)) return Icons.Warning;
    return Icon;
  }, [isValid, Icon, item]);

  if (item.isNewValue) {
    return (
      <NewItemWrapper key={item.key} onClick={onAddItem}>
        <PlusIcon size={30} color={theme.primaryColor} />
        {type} hinzufügen
      </NewItemWrapper>
    );
  }

  if (editMode) {
    return (
      <PropertyEditForm
        item={item}
        index={index}
        isEditing={isEditing}
        prefixIdentifier={prefixIdentifier}
        propertyTemplates={propertyTemplates}
        unitKeys={unitKeys}
        setEditMode={setEditMode}
        onDelete={onPropDelete}
        canDuplicate={canDuplicate}
        onDuplicate={onDuplicateProperty}
      />
    );
  }

  return (
    <PropertyListItemWrapper>
      <PropertyListItemContainer onClick={() => setEditMode(!editMode)}>
        {CurrentIcon && (
          <CurrentIcon
            className="icon"
            size={20}
            color={
              !isValid || !isValidPropValue(item.value)
                ? item.isRequired
                  ? theme.palette.error.color
                  : theme.palette.warning.color
                : theme.primaryColor
            }
          />
        )}
        <span className="key">
          {item.propKey || item.key ? (
            <strong>
              {item.propKey || item.key}{" "}
              {values.flags?.isMonitoringRelevant &&
                item.flags?.isMonitoringRelevant && (
                  <SpacedTag color={theme.palette.warning.color}>mon</SpacedTag>
                )}
              {values.flags?.isOptimizationRelevant &&
                item.flags?.isOperationRelevant && (
                  <SpacedTag color={theme.palette.success.color}>op</SpacedTag>
                )}
            </strong>
          ) : (
            <span style={{ color: theme.palette.textSubtle }}>(key fehlt)</span>
          )}
        </span>
        <span className="value">
          {isEditing ? (
            item.dataType === "Param" ? (
              item.dataSubType === "boolean" ? (
                <InlineCheckbox
                  id={`${_prefixIdentifier}properties[${index}].value`}
                  key={`${_prefixIdentifier}properties[${index}].value`}
                  label={`${item.value}`}
                  checked={item.value === "true"}
                  onChange={(v) => {
                    setFieldValue(
                      `${_prefixIdentifier}properties[${index}].value`,
                      v ? "true" : "false"
                    );
                  }}
                  disabled={!isEditing}
                />
              ) : (
                <Input
                  flex={6}
                  name={`${_prefixIdentifier}properties[${index}].value`}
                  onClick={(e: MouseEvent) => e.stopPropagation()}
                />
              )
            ) : (
              item.dataType === "scalarList" && (
                <RawInput
                  className="input"
                  id={`${_prefixIdentifier}properties[${index}].value`}
                  value={(items[index]?.value ?? [])
                    .map(({ value }: { value: string }) => value)
                    .join(",")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value
                      .split(",")
                      .map((value: string, index: number) => ({
                        key: index,
                        value,
                      }));
                    setFieldValue(
                      `${_prefixIdentifier}properties[${index}].value`,
                      newValue
                    );
                  }}
                  errorMsg={
                    errors[`${_prefixIdentifier}properties[${index}].value`]
                  }
                />
              )
            )
          ) : props.item.dataType === "scalarList" ? (
            (props.item.value ?? [])
              .map(({ value }: { value: string | number }) => value)
              .join(",")
          ) : props.item.dataType === "scalarTimeDependent" ? (
            ""
          ) : (
            props.item.value
          )}
        </span>
        <span className="unit">{props.item.unit || ""}</span>
      </PropertyListItemContainer>
      {isEditing && canDuplicate && (
        <TooltipLight id="duplicate-property" text="Property duplizieren">
          <DeleteButtonWithMargin
            data-tip
            data-for="duplicate-property"
            secondary
            icon={Icons.AccountingRecuring}
            onClick={onDuplicateProperty}
          />
        </TooltipLight>
      )}
      {isEditing && !item.isRequired && (
        <DeleteButtonWithMargin
          secondary
          icon={Icons.Delete}
          onClick={onPropDelete}
          color={theme.palette.error.color}
        />
      )}
    </PropertyListItemWrapper>
  );
};
