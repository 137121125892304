import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { saveAs } from "file-saver";
import {
  Button,
  IFrameModal,
  SpinnerDark,
} from "@ampeersenergy/ampeers-ui-components";
import {
  DocumentList,
  Document as DocumentType,
} from "@ampeersenergy/dm-ui-core-components";

import {
  Document,
  useRemoveDocumentsMutation,
} from "../../graphql/sdks/document-manager";
import { Building } from "../../graphql/sdks/controller";

import { documentTypes } from "../../helpers/document.utils";

import { UploadDocumentModal } from "../../components/upload-document-modal";
import { ExtendedAsset } from "../../components/types";
import { useDocuments } from "../../hooks/useDocuments";
import { EmptyResult as EmptyResultComponent } from "../../components/table";
import { _ae_env_ } from "../../env";
import { APP_KEY, getSession } from "../../services/session.service";

const EmptyResult = styled(EmptyResultComponent)`
  border: 1px solid ${(props) => props.theme.palette.border};
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.background};
  border-radius: 4px;
  padding: 10px 14px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74vh;
  width: 100%;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 27px;
  margin-bottom: 20px;
`;

const mapDocument = (document: Document, entry: string) => {
  return {
    contentType: "application/pdf",
    createdAt: document.createdAt,
    fileURL: document.hash,
    fileName: document.name,
    userUpload: true,
    // @ts-ignore
    type: entry,
    deletable: true,
    groupId: document.groupId,
    previewEnabled: document.name.endsWith(".pdf"),
  };
};

type ViewDocument = { fileURL: string; type: string } | null;

export interface DocumentsProps {
  buildings: Building[];
  assets: ExtendedAsset[];
  districtId: string;
  districtKey: string;
  companyKey: string;
  loadingDistrict?: boolean;
}

export const Documents: React.FC<DocumentsProps> = ({
  buildings,
  assets,
  districtId,
  districtKey,
  companyKey,
  loadingDistrict,
}) => {
  const { data, loading, refetch } = useDocuments({
    entityIds: [districtId, ...assets.map(({ id }) => id)],
  });
  const [removeDocument] = useRemoveDocumentsMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewDocument, setViewDocument] = useState<ViewDocument>(null);

  const fetchDocument = useCallback(
    async (
      document: DocumentType,
      onSuccess: (response: any, fileName?: string) => void
    ) => {
      const { clientId, token, appKey } = getSession() || {};

      if (!clientId || !token) return Promise.resolve();
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open(
          "GET",
          `${_ae_env_.REACT_APP_BACKEND_URL}/document/${document.fileURL}`
        );
        xhr.onreadystatechange = handler;
        xhr.responseType = "arraybuffer";
        xhr.setRequestHeader("x-ampeers-client-id", clientId!);
        xhr.setRequestHeader("x-ampeers-user-token", token);
        xhr.setRequestHeader("x-ampeers-app-verify", appKey || APP_KEY);
        xhr.setRequestHeader("Content-Type", "application/pdf");
        xhr.send();

        function handler(this: any) {
          if (this.readyState === this.DONE) {
            if (this.status === 200) {
              if (document.previewEnabled)
                onSuccess(
                  new Blob([this.response], { type: "application/pdf" }),
                  document.fileName
                );
              else onSuccess(new Blob([this.response]), document.fileName);
              resolve(this.response);
            } else {
              console.error("Failed to load document:", this);
              reject("Failed to load document");
            }
          }
        }
      });
    },
    []
  );

  const onCreate = useCallback(() => {}, []);

  const onOpen = useCallback(
    (document: DocumentType, _type: string) => {
      fetchDocument(document, (response) => {
        setViewDocument({
          fileURL: URL.createObjectURL(response),
          type: _type,
        });
      });
    },
    [fetchDocument]
  );

  const onDelete = useCallback(
    (document) => {
      removeDocument({ variables: { hash: document.fileURL } }).then(() =>
        refetch({ variables: { groupId: document.groupId } })
      );
    },
    [removeDocument, refetch]
  );

  const onDownload = useCallback(
    (document: DocumentType) => {
      fetchDocument(document, saveAs);
    },
    [fetchDocument]
  );

  const entries = useMemo(() => {
    const districtDocuments = data.filter((d) => d.entityType === "district");
    const assetDocuments = data.filter((d) => d.entityType === "asset");

    const groupedAssetDocuments = assetDocuments.reduce<
      Record<string, Document[]>
    >((groups, d) => {
      return {
        ...groups,
        [d.entityId!]: [...(groups[d.entityId!] ?? []), d],
      };
    }, {});

    return {
      district: districtDocuments.map((document) =>
        mapDocument(
          document,
          // @ts-ignore
          documentTypes.district[document.groupId!]
        )
      ),
      asset: Object.entries(groupedAssetDocuments)
        .map(([assetId, documents]) => {
          const asset = assets.find((a) => a.id === assetId);

          return {
            type: asset?.name ?? asset?.key ?? assetId,
            documents: documents.map((d) =>
              mapDocument(
                d,
                // @ts-ignore
                `${documentTypes.asset[d.groupId!]}${
                  documents.length === 1
                    ? ` ${asset?.name ?? asset?.key ?? assetId}`
                    : ""
                }`
              )
            ),
          };
        })
        .sort((a, b) => a.type.localeCompare(b.type)),
    };
  }, [data, assets]);

  if (loading || loadingDistrict) {
    return (
      <SpinnerWrapper>
        <SpinnerDark size={25} />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <TopBar>
        <Button onClick={() => setIsModalOpen(true)}>Dokument hochladen</Button>
      </TopBar>
      <Wrapper>
        {entries.district.length || entries.asset.length ? (
          <DocumentList
            onCreate={onCreate}
            onOpen={onOpen}
            onDelete={onDelete}
            onDownload={onDownload}
            types={[
              ...(entries.district.length
                ? [{ type: "Quartier", documents: entries.district }]
                : []),
              ...(entries.asset.length ? entries.asset : []),
            ]}
          />
        ) : (
          <EmptyResult>Keine Dokumente gefunden</EmptyResult>
        )}
      </Wrapper>
      {viewDocument && (
        <IFrameModal
          src={viewDocument.fileURL}
          contentLabel={`Show `}
          closeModal={() => setViewDocument(null)}
          title={viewDocument.type}
        />
      )}
      <UploadDocumentModal
        districtId={districtId}
        districtKey={districtKey}
        companyKey={companyKey}
        buildings={buildings}
        assets={assets}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        refetchDocuments={refetch}
      />
    </>
  );
};
