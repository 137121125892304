import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { Accordion, AccordionItem } from "@ampeersenergy/ampeers-ui-components";

import { DistrictTemplate } from "../../graphql/sdks/controller";
import { SubTitleWithMargin } from "../style";
import { ExtendedProperty, PropertyFormValue } from "../types";

import { mergePropertyTemplates } from "../../helpers/properties.utils";

import { FillPropertiesButton } from "../fill-properties-button";
import { PropertiesForm } from "../properties-form";
import { TimeseriesForm } from "../timeseries-form";

export interface PropertiesStepProps {
  districtTemplate?: DistrictTemplate;
}

export const PropertiesStep: React.FC<PropertiesStepProps> = ({
  districtTemplate,
}) => {
  const { values, setValues } = useFormikContext<{
    properties: PropertyFormValue[];
    timeseries: PropertyFormValue[];
  }>();

  const onFillProperties = useCallback(() => {
    setValues({
      ...values,
      ...mergePropertyTemplates(
        [...values.properties, ...values.timeseries] as ExtendedProperty[],
        [],
        districtTemplate?.properties ?? [],
        {
          isMonitoringRelevant: true,
          isOptimizationRelevant: true,
          isOnsite: false,
        }
      ),
    });
  }, [values, setValues, districtTemplate]);

  return (
    <>
      <Accordion
        withPadding={false}
        autoClose={false}
        LabelComponent={SubTitleWithMargin}
        initialOpenIds={[0, 1]}
      >
        <AccordionItem label="Properties">
          <PropertiesForm isEditing />
        </AccordionItem>
        <AccordionItem label="Zeitreihen">
          <TimeseriesForm isEditing />
        </AccordionItem>
      </Accordion>
      <FillPropertiesButton
        isEditing={true}
        properties={[]}
        onFillProperties={onFillProperties}
      />
    </>
  );
};
