import React, { ChangeEvent } from "react";
import {
  FormikInput,
  Select as RawSelect,
} from "@ampeersenergy/ampeers-ui-components";
import { useFormikContext } from "formik";
import { BuildingFormValues } from ".";
import { Input, Row } from "../style";

export interface BuildingFormTemplateProps {
  isEditing?: boolean;
  existingBuilding: boolean;
  energyCenterId?: string;
}

export const BuildingFormTemplate: React.FC<BuildingFormTemplateProps> = ({
  isEditing,
  existingBuilding,
}) => {
  const { values, setFieldValue } = useFormikContext<BuildingFormValues>();

  return (
    <>
      <RawSelect
        id="assetType"
        name="assetType"
        label="Asset type *"
        isEditing={isEditing}
        value={values.assetType || ""}
        onChange={(e: ChangeEvent<{ value: string }>) => {
          setFieldValue("assetType", e.target.value);
        }}
      >
        <option key="building" value="building">
          Building
        </option>
        <option key="energyCenter" value="energyCenter">
          Energy center
        </option>
      </RawSelect>
      <Row>
        <Input
          name="key"
          label="Key *"
          placeholder="Key"
          required
          isEditing={isEditing}
          disabled={!!existingBuilding}
        />
        <Input
          name="name"
          label="Name *"
          placeholder="Name"
          required
          isEditing={isEditing}
          hint="Name, der in der District Management App angezeigt wird"
        />
      </Row>
      <FormikInput
        name="description"
        label="Beschreibung"
        placeholder="Beschreibung"
        isEditing={isEditing}
      />
    </>
  );
};
