import React from "react";
import { useTheme } from "styled-components";
import { Icons, Theme } from "@ampeersenergy/ampeers-ui-components";
import { Dropdown, DropdownProps } from "./dropdown";

export interface QuickActionsProps
  extends Omit<DropdownProps, "children" | "onClose"> {
  id: string;
  onClick: () => void;
  children?: React.ReactNode;
}

export const QuickActions: React.FC<QuickActionsProps> = ({
  id,
  items,
  isOpen,
  onClick,
  onItemClick,
}) => {
  const theme = useTheme() as Theme;

  const Icon = isOpen ? Icons.ChevronUp : Icons.ChevronDown;

  return (
    <Dropdown
      key={id}
      items={items}
      isOpen={isOpen}
      onClose={() => isOpen && onClick}
      onItemClick={onItemClick}
      position="right"
    >
      <Icon
        color={theme.primaryColor}
        size={25}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      />
    </Dropdown>
  );
};
