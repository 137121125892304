import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormikHelpers } from "formik";
import { ApolloError } from "@apollo/client";
import {
  AlertRetryable,
  SuccessAlert,
} from "@ampeersenergy/ampeers-ui-components";

import {
  Address,
  AddressInput,
  AssetTemplate,
  Building,
  GetAssetsDocument,
  GetDistrictsDocument,
  GetTimeseriesQueryHookResult,
  useCreateAssetMutation,
  useGetAssetTemplateLazyQuery,
  useGetAssetTemplatesQuery,
  useGetPrefilledAssetsLazyQuery,
  useRemoveAssetsMutation,
  useUpdateAssetMutation,
  useUpdateTimeseriesMutation,
} from "../../graphql/sdks/controller";
import {
  AssetFormValues,
  AssetListItem,
  ExtendedAsset,
  LocalAsset,
  LocalTimeseries,
} from "../types";

import {
  AssetType,
  formatInitialAssetFormValues,
  getAssetKeyAndName,
  omitGraphqlType,
} from "../../helpers/asset.utils";
import {
  formatPropertiesBeforeSubmit,
  hasMissingProperties as hasMissingPropertiesFn,
  syncTimeseriesAfterUpdate,
} from "../../helpers/properties.utils";
import { extractErrorMessage } from "../../helpers/error.utils";
import useCloneAsset from "../../hooks/useCloneAsset";

import { assetValidationSchema } from "../validation";

import { AssetFormFlags } from "./flags";
import { ActionButtons } from "./action-buttons";
import { MetadataForm } from "../metadata-form";
import { MultistepForm } from "../multistep-form";
import { AssetFormTemplate } from "./template";
import { AssetFormAddress } from "./address";
import { AssetFormBuilding } from "./building";
import { DeleteConfirmModal } from "../delete-confirm-modal";
import { PropertiesMultistepForm } from "../properties-multistep-form";
import { TimeseriesMultistepForm } from "../timeseries-multistep-form";

import {
  FormSection,
  FormSectionHeader,
  Spinner,
  SpinnerWrapper,
} from "../style";
import { AccordionForm } from "../accordion-form";
import { ChildrenAssetsForm } from "./children-assets-form";
import { TensorsForm } from "../tensors-form";
import { NotesForm } from "../notes-form";

const refetchQueries = (districtKey: string, companyKey: string) => [
  {
    query: GetDistrictsDocument,
    variables: {
      filter: {
        multipleDistricts: [
          {
            company: "all",
            district: "all",
          },
        ],
      },
    },
  },
  {
    query: GetAssetsDocument,
    variables: {
      districtIdentifier: {
        company: companyKey,
        district: districtKey,
      },
    },
  },
];

export interface CreateEditAssetFlowProps {
  buildings: Building[];
  assets: ExtendedAsset[];
  timeseries: LocalTimeseries[];
  asset?: Partial<AssetListItem>;
  districtKey: string;
  companyKey: string;
  districtId: string;
  startOnEdit?: boolean;
  isNewAsset?: boolean;
  address?: Address;
  renderAsMultistepForm?: boolean;

  onSuccess?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
  setAssetToEdit?: (asset?: Partial<AssetListItem>) => void;
  reloadTimeseries: GetTimeseriesQueryHookResult["refetch"];
}

export const CreateEditAssetFlow: React.FC<CreateEditAssetFlowProps> = (
  props
) => {
  const {
    buildings,
    assets,
    timeseries,
    asset,
    districtId,
    startOnEdit = false,
    isNewAsset,
    districtKey,
    companyKey,
    address,
    renderAsMultistepForm,
    onSuccess,
    onDelete,
    onClose,
    setAssetToEdit,
    reloadTimeseries,
  } = props;

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [error, setError] = useState<Error | any | undefined>();
  const [isEditing, setIsEditing] = useState(startOnEdit);
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);

  const {
    data: assetTemplates,
    error: templatesError,
    loading: loadingTemplates,
  } = useGetAssetTemplatesQuery();

  const [getAssetTemplate, { data: assetTemplate }] =
    useGetAssetTemplateLazyQuery();

  const [getPrefilledAssets] = useGetPrefilledAssetsLazyQuery();

  const [createAsset, { data: createdAsset }] = useCreateAssetMutation({
    errorPolicy: "all",
    refetchQueries: refetchQueries(districtKey, companyKey),
  });

  const [updateAsset, { data: updatedAsset }] = useUpdateAssetMutation({
    errorPolicy: "all",
    refetchQueries: refetchQueries(districtKey, companyKey),
  });

  const [removeAssets, { error: removeError }] = useRemoveAssetsMutation({
    errorPolicy: "all",
    refetchQueries: refetchQueries(districtKey, companyKey),
  });

  const [cloneAsset, { loading: cloneAssetLoading }] = useCloneAsset(companyKey, districtKey);

  const [updateTimeseries] = useUpdateTimeseriesMutation();

  const template = assetTemplate?.assetTemplate;

  useEffect(() => {
    if (asset?.template) {
      getAssetTemplate({
        variables: {
          templateKey: asset.template,
        },
      });
    }
  }, [asset, getAssetTemplate]);

  const onDuplicate = useCallback(() => {
    if (asset && asset.key) {
      cloneAsset({
        variables: {
          sourceKey: asset.key,
          targetKey: getAssetKeyAndName(asset.template as AssetType, assets)
            .key,
        },
      }).then(({ data, errors }) => {
        if (errors) {
          setError(errors[0].message);
        } else {
          setAssetToEdit?.(data?.cloneAsset);
          setSuccessfulUpdate(true);
          setTimeout(() => setSuccessfulUpdate(false), 5000);
        }
      });
    }
  }, [asset, assets, setError, cloneAsset, setAssetToEdit]);

  const onSubmit = useCallback(
    async (
      {
        newValues: values,
        initialValues,
      }: {
        newValues: AssetFormValues;
        initialValues: AssetFormValues;
      },
      { setValues, setFieldError }: FormikHelpers<any>
    ) => {
      try {
        const {
          parentId,
          id,
          companyKey,
          districtKey,
          buildingKey,
          buildingId,
          buildingName,
          metadata,
          contracts,
          uiAssets,
          elementType,
          properties,
          timeseries,
          tensors,
          propertyTemplates,
          address,
          displayWarning,
          type,
          notes,
          initialStepIndex,
          index,
          children,
          prefilledAssets,
          prefilledAsset,
          manufacturer,
          manufacturerType,
          ...restValues
        } = values;

        if (!companyKey || !districtKey) {
          throw new Error(`Company key, district key or parent id missing`);
        }

        const assetValues = {
          ...restValues,
          ...(address
            ? { address: omitGraphqlType(address) as AddressInput }
            : {}),
          properties: formatPropertiesBeforeSubmit({
            properties,
            timeseries,
            tensors,
          }),
          metadata: metadata.map(({ key, label, value }) => ({
            key,
            label,
            value:
              value === true ? "true" : value === false ? "false" : value ?? "",
          })),
          notes: notes.map((n) => ({
            content: n.content,
            createdBy: n.createdBy,
            createdAt: n.createdAt,
          })),
          children: children.map(
            ({
              propertyTemplates: childPropertyTemplates,
              properties: childProperties,
              timeseries: childTimeseries,
              tensors: childTensors,
              ...child
            }) => ({
              ...child,
              properties: formatPropertiesBeforeSubmit({
                properties: childProperties,
                timeseries: childTimeseries,
                tensors: childTensors,
              }),
            })
          ),
        };

        let newAssetValues: LocalAsset | undefined;
        if (isNewAsset) {
          // CREATE NEW ASSET
          const { data, errors } = await createAsset({
            variables: {
              parentId: parentId || districtId,
              asset: assetValues,
            },
          });
          newAssetValues = data?.createAsset;
          if (errors?.length) {
            setError(errors[0].message);
            throw errors[0].message ?? "Unknown Error";
          }
        } else {
          // UPDATE EXISTING ASSET
          const { data, errors } = await updateAsset({
            variables: {
              parentId: parentId || districtId,
              asset: assetValues,
            },
          });

          newAssetValues = data?.updateAsset;

          // SET ERRORS FOR FAILED ASSET CREATE OR UPDATE
          if (errors?.length) {
            errors.forEach((error) => {
              if (error.message.includes("Property ")) {
                const [propKey, errorMsg] = error.message
                  .split("Property ")[1]
                  ?.split(".")[1]
                  ?.split(":");

                if (propKey && errorMsg) {
                  const propIndex = values.properties.findIndex(
                    (p) => p.key === propKey
                  );
                  const tsIndex = values.timeseries.findIndex(
                    (p) => p.key === propKey
                  );

                  if (propIndex !== -1) {
                    setFieldError(`properties[${propIndex}].key`, errorMsg);
                    setError(errors[0].message);
                  } else if (tsIndex !== -1) {
                    setError({
                      ...errors[0],
                      message: errors[0].message.replace(
                        "Property",
                        "Timeseries"
                      ),
                    });
                    setFieldError(`timeseries[${tsIndex}].key`, errorMsg);
                  }
                }
              } else {
                setError(errors[0].message);
              }
            });

            throw errors[0]?.message ?? "Unknown Error";
          }
        }

        // RELOAD AND UPDATE TIMESERIES
        await syncTimeseriesAfterUpdate(
          companyKey,
          districtKey,
          restValues.key,
          [...timeseries, ...children.flatMap((child) => child.timeseries)],
          updateTimeseries,
          reloadTimeseries,
          (errors) => {
            if (errors?.length) {
              setError(errors[0].message);
              throw errors[0]?.message ?? "Unknown Error";
            }
          }
        );

        setIsEditing(false);

        if (newAssetValues) {
          setValues(
            formatInitialAssetFormValues(
              newAssetValues as ExtendedAsset,
              districtId,
              districtKey,
              companyKey,
              template?.properties ?? [],
              template?.children.reduce<
                AssetTemplate["children"][0]["properties"]
              >((acc, child) => [...acc, ...child.properties], []),
              assets,
              address ?? undefined
            )
          );
        }
        onClose?.();

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        if (e instanceof ApolloError) {
          throw extractErrorMessage(e);
        } else if (typeof e === "string") {
          throw e;
        } else {
          throw e;
        }
      }
    },
    [
      isNewAsset,
      updateTimeseries,
      reloadTimeseries,
      onClose,
      onSuccess,
      createAsset,
      districtId,
      updateAsset,
      template?.properties,
      template?.children,
      assets,
    ]
  );

  const { hasMissingProperties, hasMissingTensors, hasMissingTimeseries } =
    useMemo(() => {
      if (!asset || !template) {
        return { hasMissingProperties: false, hasMissingTimeseries: false };
      }

      return {
        hasMissingProperties: hasMissingPropertiesFn(
          template.properties ?? [],
          asset.properties ?? [],
          asset.flags ?? {
            isMonitoringRelevant: false,
            isOptimizationRelevant: false,
            isOnsite: false,
          },
          "Param"
        ),
        hasMissingTensors: hasMissingPropertiesFn(
          template.properties ?? [],
          asset.properties ?? [],
          asset.flags ?? {
            isMonitoringRelevant: false,
            isOptimizationRelevant: false,
            isOnsite: false,
          },
          "tensor"
        ),
        hasMissingTimeseries: hasMissingPropertiesFn(
          template.properties ?? [],
          asset.properties ?? [],
          asset.flags ?? {
            isMonitoringRelevant: false,
            isOptimizationRelevant: false,
            isOnsite: false,
          },
          "ts"
        ),
      };
    }, [asset, template]);

  const initialValues = useMemo(() => {
    return formatInitialAssetFormValues(
      updatedAsset?.updateAsset ?? createdAsset?.createAsset ?? asset,
      asset?.buildingId || districtId,
      districtKey,
      companyKey,
      template?.properties ?? [], // This line is causing the form to flicker when choosing a new asset template
      template?.children.reduce<AssetTemplate["children"][0]["properties"]>(
        (acc, child) => [...acc, ...child.properties],
        []
      ),
      assets,
      address,
      timeseries
    );
  }, [
    template,
    districtKey,
    companyKey,
    assets,
    address,
    asset,
    updatedAsset,
    createdAsset,
    districtId,
    timeseries,
  ]);

  if (cloneAssetLoading) {
    return (
      <SpinnerWrapper>
        <Spinner size={25} />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      {successfulUpdate && (
        <SuccessAlert>
          Anlage {asset?.name} erfolgreich dupliziert.
        </SuccessAlert>
      )}
      {error && (
        <AlertRetryable
          message={error}
          onRetry={() => setError(undefined)}
        ></AlertRetryable>
      )}
      {renderAsMultistepForm && (
        <>
          {templatesError && (
            <AlertRetryable message={templatesError?.message} />
          )}
          <MultistepForm
            initialValues={initialValues}
            validationSchema={assetValidationSchema}
            onSubmit={onSubmit}
            showSubmitInEveryStep
            extraActionButtons={
              !isNewAsset && asset ? (
                <ActionButtons
                  onDuplicate={onDuplicate}
                  setConfirmDeleteModalOpen={setConfirmDeleteModalOpen}
                />
              ) : null
            }
          >
            <MultistepForm.Step title="Template">
              <FormSection>
                <FormSectionHeader>Template</FormSectionHeader>
                <AssetFormTemplate
                  companyKey={companyKey}
                  districtKey={districtKey}
                  assets={assets}
                  templates={assetTemplates?.assetTemplates ?? []}
                  timeseries={timeseries}
                  loadingTemplates={loadingTemplates}
                  getAssetTemplate={getAssetTemplate}
                  getPrefilledAssets={getPrefilledAssets}
                  isEditing={isEditing}
                  existingAsset={!isNewAsset}
                />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step title="Gebäude">
              <FormSection>
                <FormSectionHeader>Gebäude</FormSectionHeader>
                <AssetFormBuilding
                  isEditing={isEditing}
                  buildings={buildings}
                  displayAddress
                />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step title="Adresse">
              <FormSection>
                <FormSectionHeader>Adresse</FormSectionHeader>
                <AssetFormAddress isEditing={isEditing} />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step
              title="Flags"
              hasError={error?.message?.includes(".flags")}
            >
              <FormSection>
                <FormSectionHeader>Flags</FormSectionHeader>
                <AssetFormFlags isEditing={isEditing} timeseries={timeseries} />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step title="Metadaten">
              <FormSection>
                <FormSectionHeader>Metadaten</FormSectionHeader>
                <MetadataForm isEditing={isEditing} templateRequired />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step
              title="Properties"
              hasWarning={hasMissingProperties}
              hasError={error?.message?.includes("Property")}
            >
              <FormSection>
                <PropertiesMultistepForm isEditing={isEditing} withHeader />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step
              title="Kennfelder"
              hasWarning={hasMissingTensors}
            >
              <FormSection>
                <FormSectionHeader>Kennfelder</FormSectionHeader>
                <TensorsForm isEditing={isEditing} />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step
              title="Zeitreihen"
              hasWarning={hasMissingTimeseries}
              hasError={error?.message?.includes("Timeseries")}
            >
              <TimeseriesMultistepForm isEditing={isEditing} withHeader />
            </MultistepForm.Step>
            <MultistepForm.Step title="Anhängende Assets">
              <FormSection>
                <FormSectionHeader>Anhängende Assets</FormSectionHeader>
                <ChildrenAssetsForm
                  isEditing={isEditing}
                  template={template}
                  loadingTemplates={loadingTemplates}
                  timeseries={timeseries}
                />
              </FormSection>
            </MultistepForm.Step>
            <MultistepForm.Step title="Notizen">
              <FormSection>
                <FormSectionHeader>Notizen</FormSectionHeader>
                <NotesForm isEditing={isEditing} />
              </FormSection>
            </MultistepForm.Step>
          </MultistepForm>
        </>
      )}
      {!renderAsMultistepForm && (
        <AccordionForm
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          showErrorAtBottom
          initialValues={initialValues}
          validationSchema={assetValidationSchema}
          onSubmit={onSubmit}
          onDelete={() => setConfirmDeleteModalOpen(true)}
        >
          <AccordionForm.Item label="Template">
            <AssetFormTemplate
              companyKey={companyKey}
              districtKey={districtKey}
              assets={assets}
              templates={assetTemplates?.assetTemplates ?? []}
              timeseries={timeseries}
              loadingTemplates={loadingTemplates}
              getAssetTemplate={getAssetTemplate}
              getPrefilledAssets={getPrefilledAssets}
              isEditing={isEditing}
              existingAsset={!isNewAsset}
            />
          </AccordionForm.Item>
          {asset?.buildingKey && (
            <AccordionForm.Item label="Gebäude">
              <AssetFormBuilding isEditing={isEditing} buildings={buildings} />
            </AccordionForm.Item>
          )}
          <AccordionForm.Item label="Adresse">
            <AssetFormAddress isEditing={isEditing} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Flags">
            <AssetFormFlags isEditing={isEditing} timeseries={timeseries} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Metadaten">
            <MetadataForm isEditing={isEditing} templateRequired />
          </AccordionForm.Item>
          <AccordionForm.Item label="Properties">
            <PropertiesMultistepForm isEditing={isEditing} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Kennfelder">
            <TensorsForm isEditing={isEditing} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Zeitreihen">
            <TimeseriesMultistepForm isEditing={isEditing} />
          </AccordionForm.Item>
          <AccordionForm.Item label="Notizen">
            <NotesForm isEditing={isEditing} />
          </AccordionForm.Item>
        </AccordionForm>
      )}
      {!isNewAsset && asset && (
        <DeleteConfirmModal
          entityName={asset?.name ?? "--"}
          isOpen={confirmDeleteModalOpen}
          setIsOpen={setConfirmDeleteModalOpen}
          onConfirm={() => {
            if (!asset?.id) {
              return;
            }
            removeAssets({ variables: { ids: [asset.id] } });
            onDelete?.();
            onClose?.();
          }}
          errorMsg={removeError && extractErrorMessage(removeError)}
        />
      )}
    </>
  );
};
