import React from "react";
import { DatePicker, Section } from "@ampeersenergy/ampeers-ui-components";
import styled from "styled-components";
import { DateTime } from "luxon";

const DateRangeWrapper = styled(Section)`
  margin-bottom: 12px;
`;

const Label = styled.span`
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.6px;
  color: ${(props) => props.theme.primaryColor};
`;

export interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  setDates: ({
    startDate,
    endDate,
  }: {
    startDate?: Date;
    endDate?: Date;
  }) => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  setDates,
}) => {
  return (
    <DateRangeWrapper narrow>
      <Label>Von: </Label>
      <DatePicker
        date={startDate}
        maxDate={DateTime.fromJSDate(endDate).minus({ days: 1 }).toJSDate()}
        onDateChange={(value) => setDates({ startDate: value })}
      />
      <Label> Bis: </Label>
      <DatePicker
        date={endDate}
        minDate={DateTime.fromJSDate(startDate).plus({ days: 1 }).toJSDate()}
        onDateChange={(value) => setDates({ endDate: value })}
      />
    </DateRangeWrapper>
  );
};
