import { DateTime } from "luxon";
import * as yup from "yup";

const sharedAddressSchema = yup.object().shape({
  street: yup
    .string()
    .required()
    .matches(/^[\p{L}\d\s.$%&]*$/u, "Ungültiges Format"),
  streetNr: yup
    .string()
    .required()
    .matches(
      /^\d+[a-zA-Z]*(-\d+[a-zA-Z]*)*$/,
      `Format: 94 oder 94a oder 94-98`
    ),
  zip: yup.number().required(),
  city: yup
    .string()
    .required()
    .matches(/^[\p{L}\d\s.$%&]*$/u, "Ungültiges Format"),
  country: yup.string().required(),
});

const keyFormat = (example: string) =>
  yup
    .string()
    .matches(
      new RegExp(/^[a-z][a-zA-Z0-9]*$/),
      `Format: ${example} (Camel Case, nur Zahlen und Buchstaben, keine Umlaute oder Sonderzeichen wie ß, - oder _)`
    );

const wgs84Format = yup
  .number()
  .typeError(
    "Muss eine Zahl mit Format 00[.000000] nach dem World Geodetic System 1984 (WGS84) sein"
  )
  .lessThan(90)
  .moreThan(-90);

const dateIsoFormat = yup
  .string()
  .test("date-string", "Muss ein Datum sein. Format: YYYY-MM-DD", (value) => {
    return !value || DateTime.fromISO(value).isValid;
  });

export const addressValidationSchema = sharedAddressSchema.shape({
  latitude: wgs84Format.required(),
  longitude: wgs84Format.required(),
});

export const buildingAddressValidationSchema = sharedAddressSchema.shape({
  latitude: wgs84Format,
  longitude: wgs84Format,
});

export const dimensionValidationSchema = yup.object({
  key: yup.string().required(),
  description: yup.string().required(),
  values: yup.array(yup.number().required()).required(),
  unit: yup.string().nullable(),
});

export const sharedPropertyValidationSchema = yup.object().shape({
  propKey: yup.string().required(),
  dataType: yup.string().required(),
  dataSubType: yup.string().required(),
  unit: yup.string().nullable(),
  description: yup.string().required(),
  comment: yup.string().nullable(),
});

export const propertyValidationSchema = sharedPropertyValidationSchema.shape({
  value: yup.lazy((value, { parent }) => {
    if (parent.dataType === "scalarList" && Array.isArray(value)) {
      return yup.array().of(
        yup.object().shape({
          value: yup
            .string()
            .required()
            .test(
              "number-or-string",
              "Muss eine Zahl sein. Zahlen Format: 1234.1234",
              (value, context) => {
                // TODO: simplify these validations
                if (!value) return true;

                const match = context.path.match(/properties\[(\d+)\]/);
                const propIndex = match?.[1] ? +match?.[1] : undefined;

                if (
                  propIndex === undefined ||
                  propIndex === null ||
                  !context.options.context
                ) {
                  return true;
                }

                const parent = context.options.context?.properties[propIndex];

                const { dataType, dataSubType } = parent ?? {};

                if (!dataType || !dataSubType) return true;

                if (
                  dataType === "scalarList" &&
                  ["float", "integer"].includes(dataSubType?.toLowerCase())
                ) {
                  return !isNaN(+value);
                }

                return true;
              }
            ),
        })
      );
    }

    if (parent.dataType === "scalarTimeDependent" && Array.isArray(value)) {
      return yup.array().of(
        yup.object().shape({
          start: dateIsoFormat.required(),
          end: dateIsoFormat.required(),
          value: yup
            .string()
            .test(
              "string-as-number",
              "Muss eine Zahl sein. Format: 1234.123",
              (value) => {
                return yup.number().isValidSync(value ? +value : value);
              }
            )
            .required(),
        })
      );
    }

    return yup
      .string()
      .nullable()
      .notRequired()
      .when(["dataType", "dataSubType", "value"], {
        is: (dataType: string, dataSubtype: string, value: number) =>
          dataType === "Param" && dataSubtype === "integer" && !!value,
        then: (schema) =>
          schema.test(
            "string-as-integer",
            "Muss eine Ganzzahl sein. Format: 123",
            (value) => {
              return yup
                .number()
                .integer()
                .isValidSync(value ? +value : value);
            }
          ),
      })
      .when(["dataType", "dataSubType", "value"], {
        is: (dataType: string, dataSubtype: string, value: number) =>
          dataType === "Param" && dataSubtype === "float" && !!value,
        then: (schema) =>
          schema.test(
            "string-as-number",
            "Muss eine Zahl sein. Format: 1234.123",
            (value) => {
              return yup.number().isValidSync(value ? +value : value);
            }
          ),
      })
      .when(["dataType", "dataSubType", "value"], {
        is: (dataType: string, dataSubtype: string, value: number) =>
          dataType === "Param" && dataSubtype === "date" && !!value,
        then: () => dateIsoFormat,
      });
  }),
});

export const tensorValidationSchema = sharedPropertyValidationSchema.shape({
  value: yup.object().shape({
    interpolations: yup.array(
      yup.object().shape({
        dimensionX: dimensionValidationSchema,
        dimensionY: dimensionValidationSchema,
        values: yup
          .array(yup.array(yup.number().nullable().notRequired()).required())
          .required(),
      })
    ),
  }),
});

export const extTimeseriesSummationInputValidationSchema = yup.object().shape({
  entityKey: yup.string().required(),
  propertyKey: yup.string().required(),
  factor: yup.number().required(),
});

export const extTimeseriesSummationValidationSchema = yup.object().shape({
  offset: yup.number().nullable(),
  thresholdType: yup
    .string()
    .nullable()
    .test(
      "number-or-string",
      "Muss eine Zahl sein. Zahlen Format: 1234.1234",
      (value) => {
        if (value === "null" || value === null) return true;

        return yup.number().integer().isValid(value);
      }
    ),
  inputs: yup.array().of(extTimeseriesSummationInputValidationSchema),
});

export const extTimeseriesValidationSchema = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(),
  unit: yup.string().nullable(),
  description: yup.string().required(),
  reference: yup.object().shape({
    type: yup.string().required(),
    templateKey: yup.string().required(),
    entityKey: yup.string(),
    propertyKey: yup.string(),
  }),
  source: yup.object().shape({
    type: yup
      .string()
      .oneOf([undefined, "district", "inference"])
      .nullable()
      .required(),
    protocol: yup.string().nullable(),
    externalId: yup.string().nullable(),
    isInverted: yup.boolean().nullable(),
    unit: yup.string().nullable(),
    summation: extTimeseriesSummationValidationSchema.nullable(),
  }),
});

export const timeseriesValidationSchema = sharedPropertyValidationSchema.shape({
  value: yup.lazy((value) => {
    return Array.isArray(value)
      ? yup.array().of(yup.string().nullable().notRequired())
      : yup.string().nullable().notRequired();
  }),
});

export const metadataValidationSchema = yup.array(
  yup.object({
    key: yup.string().required(),
    type: yup.string(),
    label: yup.string().required(),
    value: yup.string(),
  })
);

export const noteValidationSchema = yup.object().shape({
  content: yup.string().required(),
  createdAt: yup.date().required(),
  createdBy: yup.string().required(),
});

export const districtValidationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  companyKey: keyFormat("companyKey01").required(),
  key: keyFormat("districtKey01").required(),
  address: addressValidationSchema,
  properties: yup.array(propertyValidationSchema),
  timeseries: yup.array(timeseriesValidationSchema),
  metadata: metadataValidationSchema,
  contact: yup.object().shape({
    alertingEmails: yup
      .array()
      .of(yup.object().shape({ value: yup.string().required() }))
      .required(),
    reportingEmails: yup
      .array()
      .of(yup.object().shape({ value: yup.string().required() }))
      .required(),
  }),
});

export const buildingValidationSchema = yup.object().shape({
  districtKey: keyFormat("districtKey01").required(),
  companyKey: keyFormat("companyKey01").required(),
  key: keyFormat("buildingKey01").required(),
  name: yup.string().required(),
  assetType: yup.string().required(),
  description: yup.string().nullable(),
  address: buildingAddressValidationSchema,
  notes: yup.array(noteValidationSchema),
});

export const childAssetValidationSchema = yup.object().shape({
  key: keyFormat("assetKey01").required(),
  name: yup.string().required(),
  template: yup.string().required(),
  properties: yup.array(propertyValidationSchema),
  timeseries: yup.array(timeseriesValidationSchema),
  tensors: yup.array(tensorValidationSchema),
});

export const assetValidationSchema = yup.object().shape({
  districtKey: keyFormat("districtKey01").required(),
  companyKey: keyFormat("companyKey01").required(),
  buildingKey: keyFormat("buildingKey01"),
  key: keyFormat("assetKey01").required(),
  name: yup.string().required(),
  template: yup.string().required(),
  properties: yup.array(propertyValidationSchema),
  timeseries: yup.array(timeseriesValidationSchema),
  tensors: yup.array(tensorValidationSchema),
  address: buildingAddressValidationSchema,
  metadata: metadataValidationSchema,
  notes: yup.array(noteValidationSchema),
  flags: yup.object().shape({
    isOnsite: yup.boolean(),
    isMonitoringRelevant: yup.boolean(),
    isOptimizationRelevant: yup.boolean(),
    isEndConsumption: yup.boolean(),
  }),
  children: yup.array(childAssetValidationSchema),
});

export const contractValidationSchema = yup.object().shape({
  key: keyFormat("contractKey01").required(),
  name: yup.string(),
  template: yup.string(),
  description: yup.string().nullable(),
  properties: yup.array(propertyValidationSchema),
  timeseries: yup.array(timeseriesValidationSchema),
  assetId: yup.string().required(),
  metadata: metadataValidationSchema,
  notes: yup.array(noteValidationSchema),
});

export const uploadDocumentSchema = yup.object().shape({
  entity: yup.string().oneOf(["district", "asset"]).required(),
  type: yup
    .string()
    .oneOf([
      "district_heat_diagram",
      "district_electricity_diagram",
      "district_contract",
      "district_other",
      "asset_datasheet",
      "asset_contract",
      "asset_interface_documentation",
      "asset_construction_documentation",
      "asset_other",
    ])
    .required(),
  fileName: yup.string().required(),
  assetId: yup
    .string()
    .nullable()
    .notRequired()
    .when("entity", {
      is: (entity: "district" | "asset") => entity === "asset",
      then: (schema) => schema.required(),
    }),
});
