import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { DynamicList } from "@ampeersenergy/ampeers-ui-components";

import { ListContainer, Muted } from "../style";
import { AssetFormValues } from "../types";
import { TimeDependentValuesListItem } from "./time-dependent-value-list-item";

export interface TimeDependentValuesListProps {
  isEditing?: boolean;
  index: number;
}

export const TimeDependentValuesList: React.FC<
  TimeDependentValuesListProps
> = ({ isEditing, index }) => {
  const { values } = useFormikContext<AssetFormValues>();

  const PropertyValueListItemComponent = useCallback(
    (props) => {
      return (
        <TimeDependentValuesListItem
          {...props}
          propIndex={index}
          isEditing={isEditing}
        />
      );
    },
    [isEditing, index]
  );

  const validateProperty = useCallback((item) => {
    return true;
  }, []);

  return (
    <ListContainer>
      {isEditing || values.properties[index].value?.length ? (
        <DynamicList
          name={`properties[${index}].value`}
          items={values.properties[index].value ?? []}
          type="Wert"
          validateItem={validateProperty}
          ListItemComponent={PropertyValueListItemComponent}
          interactive={isEditing}
        />
      ) : (
        <Muted>Keine Werte vorhanden</Muted>
      )}
    </ListContainer>
  );
};
