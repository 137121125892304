import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Header,
  Icons,
  Main,
  Overlay,
  Table,
  Tag,
} from "@ampeersenergy/ampeers-ui-components";

import { District, useGetDistrictsQuery } from "../graphql/sdks/controller";

import { appendSessionData } from "../services/session.service";
import { getAssetAddress } from "../helpers/asset.utils";

import { CreateDistrictFlow } from "../components/create-district-flow";
import { EnergyConceptImport } from "../components/energy-concept-import";

const colors: Record<string, string> = {
  collect: "#256bab",
  display: "#3cc9bd",
  monitor: "#ff9b00",
  operative: "#119142",
};

export const DistrictsPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { data: districts, loading: loadingDistricts } = useGetDistrictsQuery({
    errorPolicy: "all", // https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
    variables: {
      filter: {
        multipleDistricts: [
          {
            company: "all",
            district: "all",
          },
        ],
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        accessor: "state",
        Header: "State",
        width: "2%",
        Cell: ({ value }: { value: string }) => (
          <Tag color={colors[value]}>{value ?? "unbekannt"}</Tag>
        ),
        sortType: (rowA: any, rowB: any, columnId: string) => {
          const statePriorities: Record<string, number> = {
            deactivated: 0,
            collect: 1,
            display: 2,
            monitor: 3,
            operative: 4,
          };

          const prioA = statePriorities[rowA.values[columnId]];
          const prioB = statePriorities[rowB.values[columnId]];

          if (prioA === prioB) return 0;
          else if (prioA === undefined) return -1;
          else if (prioB === undefined) return 1;
          return prioA > prioB ? 1 : -1;
        },
      },
      {
        accessor: "name",
        Header: "Name",
        width: "30%",
      },
      {
        accessor: "companyKey",
        Header: "Company",
      },
      {
        accessor: "key",
        Header: "Key",
      },
      {
        accessor: "address",
        Header: "Address",
        width: "20%",
      },
    ],
    []
  );

  return (
    <>
      <Main style={{ width: "100%" }}>
        <Header
          actions={() => (
            <ButtonGroup>
              <EnergyConceptImport />
              <Button onClick={() => setIsOpen(true)}>Quartier anlegen</Button>
            </ButtonGroup>
          )}
          Icon={Icons.Project}
        >
          Quartiere
        </Header>
        <Table
          withAlternatingRows
          withBoxShadow
          isLoading={loadingDistricts}
          onRowClick={(row) => {
            appendSessionData({
              districtKey: row.original.key?.toUpperCase(),
              companyKey: row.original.companyKey?.toUpperCase(),
            });
            history.push(
              `/districts/${row.original.companyKey}-${row.original.key}`
            );
          }}
          columns={columns}
          data={(districts?.districts ?? []).map((d) => ({
            ...d,
            address: getAssetAddress(d as District),
          }))}
          initialState={{
            sortBy: [
              {
                id: "state",
                desc: true,
              },
              {
                id: "name",
              },
            ],
          }}
        />
      </Main>
      {/* Added an extra div here to prevent the style from main to be applied to the overlay wrapper directly.
        This is a temporary fix that can be removed once the PageTitleLayout gets updated in the ui components
        to receive className.
        TODO: remove this
      */}
      <div style={{ display: isOpen ? "block" : "none" }}>
        <Overlay
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          title="Quartier anlegen"
        >
          <CreateDistrictFlow setIsOverlayOpen={setIsOpen} />
        </Overlay>
      </div>
    </>
  );
};
