import React from "react";
import styled from "styled-components";

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .label {
    font-size: 8pt;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1pt;
    margin-right: 8px;
  }
`;

const Color = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  background-color: #00607b;
  border-radius: 2px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export interface LabelProps {
  color: string;
  label: string;
}

export const Label: React.FC<LabelProps> = ({ color, label }) => {
  return (
    <LabelWrapper>
      <Color color={color} />
      <span className="label">{label}</span>
    </LabelWrapper>
  );
};
