import React, { ChangeEvent, useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { DynamicListItemProps } from "@ampeersenergy/ampeers-ui-components";

import {
  ExtendedTimeseries,
  LocalAsset,
  TimeseriesInferenceFormValues,
} from "../types";

import { Input, Row, FlexRawSelect } from "../style";

export interface InputsEditFormProps {
  item: DynamicListItemProps["item"] & {
    inputAsset?: LocalAsset;
    inputTimeseries?: ExtendedTimeseries;
  };
  index: number;
  assets: LocalAsset[];
  isEditing?: boolean;
}

export const InputsEditForm: React.FC<InputsEditFormProps> = ({
  item,
  index,
  assets,
  isEditing,
}) => {
  const { values, setFieldValue, errors } =
    useFormikContext<TimeseriesInferenceFormValues>();

  const onAssetChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      const newAsset = assets.find((asset) => asset.key === e.target.value);

      if (newAsset) {
        setFieldValue(
          `source.summation.inputs[${index}].entityKey`,
          newAsset.key
        );

        if (
          !newAsset.properties.some(
            (p) => p.key === values.source.summation?.inputs[index].propertyKey
          )
        ) {
          setFieldValue(
            `source.summation.inputs[${index}].propertyKey`,
            undefined
          );
        }
      }
    },
    [assets, setFieldValue, index, values.source.summation?.inputs]
  );

  const onPropertyChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      const newProperty = item.inputAsset?.properties.find(
        (property) => property.key === e.target.value
      );

      if (newProperty) {
        setFieldValue(
          `source.summation.inputs[${index}].propertyKey`,
          newProperty.key
        );
      }
    },
    [item.inputAsset, setFieldValue, index]
  );

  const properties = useMemo(
    () => item.inputAsset?.properties.filter((p) => p.dataType === "ts") ?? [],
    [item]
  );

  return (
    <>
      <Row>
        <FlexRawSelect
          id="assetKey"
          name={`source.summation.inputs[${index}].entityKey`}
          label="Anlage"
          isEditing={isEditing}
          value={values.source.summation?.inputs[index].entityKey}
          onChange={onAssetChange}
          errorMsg={
            (errors.source?.summation as any)?.inputs?.[index]?.entityKey
          }
        >
          {assets.map((asset) => (
            <option key={asset.key} value={asset.key}>
              {asset.name}
            </option>
          ))}
        </FlexRawSelect>
        <FlexRawSelect
          id="propKey"
          name={`source.summation.inputs[${index}].propertyKey`}
          label="Property"
          isEditing={isEditing}
          value={values.source.summation?.inputs[index].propertyKey}
          onChange={onPropertyChange}
          errorMsg={
            (errors.source?.summation as any)?.inputs?.[index]?.propertyKey
          }
        >
          <option
            disabled={!!values.source.summation?.inputs?.[index]?.propertyKey}
            value=""
            key={"-"}
          >
            Bitte wählen
          </option>
          {properties.map((property) => (
            <option key={property.key} value={property.key}>
              {property.key}
            </option>
          ))}
        </FlexRawSelect>
      </Row>
      <Row>
        <Input
          name={`source.summation.inputs[${index}].factor`}
          label="Faktor *"
          placeholder="Faktor"
          required
          type="number"
          isEditing={isEditing}
        />
      </Row>
    </>
  );
};
