import React from "react";
import styled from "styled-components";

import {
  BaseIcon,
  ButtonIcon,
  Icons,
} from "@ampeersenergy/ampeers-ui-components";

import { Dropdown, DropdownProps } from "./dropdown";

export const DropdownButtonWrapper = styled(ButtonIcon)`
  height: 100%;

  span {
    display: flex;
    align-items: center;
  }

  svg:last-child {
    margin-left: 8px;
  }
`;

export interface DropdownButtonProps extends Omit<DropdownProps, "children"> {
  onOpen: () => void;
  icon: typeof BaseIcon;
  buttonColor?: string;
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  icon,
  buttonColor,
  children,
  ...props
}) => {
  const Chevron = props.isOpen ? Icons.ChevronUp : Icons.ChevronDown;

  return (
    <Dropdown {...props}>
      <DropdownButtonWrapper
        secondary
        small
        icon={icon}
        onClick={props.isOpen ? props.onClose : props.onOpen}
        color={buttonColor}
      >
        {children}
        <Chevron size={22} />
      </DropdownButtonWrapper>
    </Dropdown>
  );
};
